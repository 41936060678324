import { Row, Image, Grid } from "antd";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "./imageGallery.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { baseUrl } from "../../commonRequests/axiosInstance";
import useIsMobile from "../../hooks/useMobile";

export interface ImageGalleryProps {
  imageIds?: { id: number }[];
  title?: string;
}

const ImageGallery = ({ imageIds, title }: ImageGalleryProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPreviewedImage, setCurrentPreviewedImage] = useState(0); //Index
  const size = Grid.useBreakpoint()

  const isMobile = useIsMobile()

  if (!imageIds || imageIds.length === 0) {
    return (
      <Carousel>
        {[
          <div style={{ cursor: "zoom-in" }}>
            <img
              src={"https://i.postimg.cc/BnL46bdV/logo-Generic.png"}
              alt="institucion educativa"
            />
          </div>,
        ]}
      </Carousel>
    );
  }


  const onImageClick = (url: string, index: number) => {
    setIsModalOpen(true);
    setCurrentPreviewedImage(index);
  };


  let maxImageWidth = "500px";
  let maxImageHeight = "500px"

  if (size['xl']) {
    maxImageWidth = "500px"
  }

  if (size['xxl']) {
    maxImageWidth = "700px"
  }

  if (isMobile) {
    maxImageHeight = "300px"
  }

  return (
    <>
      <div
        // align="middle"
        // justify="center"
        style={{ cursor: "zoom-in", height: "100%", width: "100%" }}
      >
        <Carousel
          stopOnHover
          // autoPlay
          showThumbs
          swipeable
          infiniteLoop
          className="presentation-mode"
        >
          {imageIds.map((imagenId, index) => {
            const url = `${baseUrl}instituciones/images/${imagenId.id}`;
            return (
              <div
                onClick={() => onImageClick(url, index)}
                style={{maxHeight: maxImageHeight, overflowY: "auto", marginRight: isMobile ? undefined : "2em"}}
                // className="image-wrapper"
              >
                <img
                  className="gallery-image"
                  src={url}
                  alt="institucion educativa"
                  style={{
                    maxHeight: "auto",
                    maxWidth: maxImageWidth,
                    overflow: "hidden",
                    verticalAlign: "middle"
                  }}
                />
              </div>
            );
          })}
        </Carousel>
      </div>

      {isModalOpen && (
        <div style={{ backgroundColor: "white" }}>
          <div style={{ display: "none" }}>
            <Image.PreviewGroup
              preview={{
                visible: true,
                onVisibleChange: (vis) => setIsModalOpen(vis),
                current: currentPreviewedImage,
              }}
            >
              {imageIds.map((imagenId) => (
                <Image src={`${baseUrl}instituciones/images/${imagenId.id}`} />
              ))}
            </Image.PreviewGroup>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageGallery;
