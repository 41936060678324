import { compareComplexFilter } from "./instituciones.types";

export interface FilterCategory<T> {
  title: string;
  category: keyof T;
  filters: Filter[];
}

export interface Filter {
  name: string;
  id: number;
  availableMatches: number;
}

export interface QuantityFilterCategory {
  title: string;
  category: keyof CommonFilters;
  filters: QuantityFilter[];
}

export interface QuantityFilter {
  name: string;
  min: number;
  max: number;
  availableMatches: number;
}

export interface CommonFilters {
  nombre?: string;
  genero_alumnos?: number[];
  modalidad_estudios?: number[];
  mensualidad?: { min?: number; max?: number };
  mensualidad_min?: number;
  mensualidad_max?: number;
  comuna_id?: number;
  region_id?: number;
  pais_id?: number;
  tipo_institucion?: number[];
  skip?: number;
  take?: number;
  user_id?: number;
  disable_pagination?: boolean;
  hidden?: boolean;
  needsReview?: boolean;
  latitud?: number;
  longitud?: number;
  geo_direccion?: string;
  is_sponsored?: boolean,
}


export type AllFilters =
  | InstitucionEducativaFilters
  | EscuelaVeranoFilters
  | AcademiaDeportivaFilters
  | PreuniversitarioFilters
  | AcademiaIdiomasFilters
  | AcademiaArtisticaFilters;

export interface InstitucionEducativaFilters extends CommonFilters {
  nivel_escolar?: number[];
  tipo_dependencia?: number[];
  tipo_educacion?: number[];
  orientacion_religiosa?: number[];
  idiomas?: number[];
  alumnos_curso?: { min?: number; max?: number };
}

export type InstitucionEducativaFilterSelectors = {
  generosFiltros: FilterCategory<
    Pick<InstitucionEducativaFilters, "genero_alumnos">
  >;
  tipoDependencia: FilterCategory<
    Pick<InstitucionEducativaFilters, "tipo_dependencia">
  >;
  nivelesEducativos: FilterCategory<
    Pick<InstitucionEducativaFilters, "nivel_escolar">
  >;
  tiposEducacion: FilterCategory<
    Pick<InstitucionEducativaFilters, "tipo_educacion">
  >;
  orientacionReligiosa: FilterCategory<
    Pick<InstitucionEducativaFilters, "orientacion_religiosa">
  >;
  idiomas: FilterCategory<Pick<InstitucionEducativaFilters, "idiomas">>;
  modalidadesEstudio: FilterCategory<
    Pick<InstitucionEducativaFilters, "modalidad_estudios">
  >;
  mensualidad: QuantityFilterCategory;
  alumnosCurso: QuantityFilterCategory;
};

export const insEduFilterComparator = (
  current: InstitucionEducativaFilters | undefined,
  old: InstitucionEducativaFilters | undefined
) => {
  if (!current) {
    return !old ? true : false;
  }

  old = old as InstitucionEducativaFilters;

  const isEqual =
    current.nombre === old?.nombre &&
    current.alumnos_curso?.min === old?.alumnos_curso?.min &&
    current.alumnos_curso?.max === old?.alumnos_curso?.max &&
    current.mensualidad?.min === old?.mensualidad?.min &&
    current.mensualidad?.max === old?.mensualidad?.max &&
    current.comuna_id === old?.comuna_id &&
    current.pais_id === old?.pais_id &&
    compareComplexFilter(current.tipo_dependencia, old.tipo_dependencia) &&
    compareComplexFilter(current.genero_alumnos, old.genero_alumnos) &&
    compareComplexFilter(current.nivel_escolar, old.nivel_escolar) &&
    compareComplexFilter(current.tipo_educacion, old.tipo_educacion) &&
    compareComplexFilter(current.idiomas, old.idiomas) &&
    compareComplexFilter(current.modalidad_estudios, old.modalidad_estudios) &&
    compareComplexFilter(
      current.orientacion_religiosa,
      old.orientacion_religiosa
    ) &&
    compareComplexFilter(current.tipo_institucion, old.tipo_institucion) &&
    commonFiltersComparator(current as CommonFilters, old as CommonFilters);

  return isEqual;
};

export const commonFiltersComparator = (
  current: CommonFilters,
  old: CommonFilters
) => {
  return (
    current.nombre === old.nombre &&
    current.hidden === old.hidden &&
    current.needsReview === old.needsReview &&
    current.disable_pagination === old.disable_pagination &&
    current.take === old.take &&
    current.skip === old.skip &&
    current.mensualidad?.min === old.mensualidad?.min &&
    current.mensualidad?.max === old.mensualidad?.max &&
    current.pais_id === old.pais_id &&
    current.region_id === old.region_id &&
    current.comuna_id === old.comuna_id &&
    compareComplexFilter(current.modalidad_estudios, old.modalidad_estudios) &&
    compareComplexFilter(current.tipo_institucion, old.tipo_institucion)
  );
};

/////// EscuelaVerano

export interface EscuelaVeranoFilters extends CommonFilters {
  esGuarderia?: boolean;
}

export type EscuelaVeranoFiltersFilterSelectors = {
  modalidadesEstudio: FilterCategory<
    Pick<InstitucionEducativaFilters, "modalidad_estudios">
  >;
  mensualidad: QuantityFilterCategory;
};

export const escuelaVeranoFilterComparator = (
  current: EscuelaVeranoFilters | undefined,
  old: EscuelaVeranoFilters | undefined
) => {
  if (!current) {
    return !old ? true : false;
  }

  old = old as EscuelaVeranoFilters;

  const isEqual = commonFiltersComparator(
    current as CommonFilters,
    old as CommonFilters
  );

  return isEqual;
};

export interface AcademiaDeportivaFilters extends CommonFilters {
  deportes?: number[];
}

export type AcademiaDeportivaFiltersFilterSelectors = {
  modalidadesEstudio: FilterCategory<Pick<CommonFilters, "modalidad_estudios">>;
  mensualidad: QuantityFilterCategory;
  deportes: FilterCategory<Pick<AcademiaDeportivaFilters, "deportes">>;
};

export interface PreuniversitarioFilters extends CommonFilters {
  niveles?: number[];
  asignaturas?: number[];
  isReforzamiento?: boolean;
}

export type PreuniversitarioFiltersFilterSelectors = {
  modalidadesEstudio: FilterCategory<Pick<CommonFilters, "modalidad_estudios">>;
  mensualidad: QuantityFilterCategory;
  niveles: FilterCategory<Pick<PreuniversitarioFilters, "niveles">>;
  asignaturas: FilterCategory<Pick<PreuniversitarioFilters, "asignaturas">>;
};

export const preuniversitarioFiltersComparator = (
  current: PreuniversitarioFilters | undefined,
  old: PreuniversitarioFilters | undefined
) => {
  if (!current) {
    return !old ? true : false;
  }

  old = old as PreuniversitarioFilters;

  const isEqual =
    current.nombre === old.nombre &&
    current.isReforzamiento === old.isReforzamiento &&
    compareComplexFilter(current.niveles, old.niveles) &&
    compareComplexFilter(current.asignaturas, old.asignaturas) &&
    commonFiltersComparator(current, old);

  return isEqual;
};

export interface AcademiaIdiomasFilters extends CommonFilters {
  idiomas?: number[];
}

export const academiaIdiomasFiltersComparator = (
  current: AcademiaIdiomasFilters | undefined,
  old: AcademiaIdiomasFilters | undefined
) => {
  if (!current) {
    return !old ? true : false;
  }

  old = old as AcademiaIdiomasFilters;

  const isEqual =
    current.nombre === old.nombre &&
    compareComplexFilter(current.idiomas, old.idiomas) &&
    commonFiltersComparator(current, old);
  return isEqual;
};

export type AcademiaIdiomasFilterSelectors = {
  modalidadesEstudio: FilterCategory<Pick<CommonFilters, "modalidad_estudios">>;
  mensualidad: QuantityFilterCategory;
  idiomas: FilterCategory<Pick<AcademiaIdiomasFilters, "idiomas">>;
};

export interface AcademiaArtisticaFilters extends CommonFilters {
  artes?: number[];
  instrumentos?: number[];
}

export type AcademiaArtisticaFilterSelectors = {
  modalidadesEstudio: FilterCategory<Pick<CommonFilters, "modalidad_estudios">>;
  mensualidad: QuantityFilterCategory;
  artes: FilterCategory<Pick<AcademiaArtisticaFilters, "artes">>;
  instrumentos: FilterCategory<Pick<AcademiaArtisticaFilters, "instrumentos">>;
};

export const academiaArtisticaFiltersComparator = (
  current: AcademiaArtisticaFilters | undefined,
  old: AcademiaArtisticaFilters | undefined
) => {
  if (!current) {
    return !old ? true : false;
  }

  old = old as AcademiaArtisticaFilters;

  const isEqual =
    compareComplexFilter(current.modalidad_estudios, old.modalidad_estudios) &&
    current.nombre === old.nombre &&
    compareComplexFilter(current.artes, old.artes) &&
    compareComplexFilter(current.instrumentos, old.instrumentos) &&
    commonFiltersComparator(current, old);
  return isEqual;
};
