import { Button, ConfigProvider, Form, Space } from "antd";
import { useEffect, useState } from "react";
import StepOne from "./formSteps/StepOne";
import HeaderModern from "../../header/HeaderModern";
import StepTwo from "./formSteps/StepTwo";
import StepThree from "./formSteps/StepThree";
import StepFour from "./formSteps/StepFour";
import StepFive from "./formSteps/StepFive";
import cogoToast from "cogo-toast";
import {
  createInsEdu,
  updateInsEdu,
} from "../../../commonRequests/insEduRequests";
import FinalStep from "./formSteps/FinalStep";
import {
  transformInsEduPayload,
  transformSingleInsEduToFormData,
} from "./submitInsEdu";
import { useParams, useSearchParams } from "react-router-dom";
import { useRequest } from "ahooks";
import reqSingleInsEdu from "../../InsEduDetailView/InsEduDetailViewRequests";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { CreateInsEduFormData } from "../../../types/insEdu.types";
import useIsMobile from "../../../hooks/useMobile";

const SubmitInsEduPage = () => {
  const [form] = Form.useForm<CreateInsEduFormData>();
  const [currentStep, setCurrentStep] = useState(0);
  const [createdInsEduId, setCreatedInsEduId] = useState<number>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const { id: isUpdate } = useParams();

  const [searchParams] = useSearchParams();

  const tipoInstitucionId = searchParams.get("tipo");

  const isMobile = useIsMobile();

  const onNextStep = () => {
    window.scrollTo(0, 0);
    setCurrentStep(currentStep + 1);
    window.scrollTo(0, 0);
  };
  const onBackStep = () => setCurrentStep(currentStep - 1);

  const InsEduReq = useRequest(async () => {
    if (!isUpdate) {
      return;
    }

    const singleInsEdu = await reqSingleInsEdu(+(isUpdate as string));
    if (!singleInsEdu) {
      return null;
    }

    const institucionFormData = transformSingleInsEduToFormData(singleInsEdu);
    //@ts-ignore
    form.setFieldsValue(institucionFormData);
  });

  if (isNaN(parseInt(tipoInstitucionId as string)) && !isUpdate) {
    return <>404</>;
  }

  if (!isUpdate) {
    form.setFieldsValue({ tipoInstitucionId: +(tipoInstitucionId as string) });
  }

  if (isUpdate && InsEduReq.loading) {
    return <LoadingSpinner />;
  }

  const onEdit = async () => {
    const data = transformInsEduPayload(form);

    const loadingToast = cogoToast.loading(
      "Editando institución educativa...",
      {
        hideAfter: 0,
      }
    );
    const response = await updateInsEdu({ ...data, id: +(isUpdate as string) });

    loadingToast.hide?.();
    if (!response) {
      cogoToast.error("No fue posible crear la institución");
    } else {
      setCreatedInsEduId(response?.id);
      setCurrentStep(5);
      cogoToast.success("Institución creado correctamente...");
    }
  };

  const onSubmit = async () => {
    if (isUpdate) {
      return onEdit();
    }

    const data = transformInsEduPayload(form);

    const loadingToast = cogoToast.loading("Creando institución educativa", {
      hideAfter: 0,
    });
    const response = await createInsEdu(data);

    loadingToast.hide?.();
    if (!response) {
      cogoToast.error("No fue posible crear la institución");
    } else {
      setCreatedInsEduId(response?.id);
      setCurrentStep(5);
      cogoToast.success("Institución creado correctamente...");
    }

    console.log(form.getFieldsValue(true));
  };

  const steps = [
    {
      step: 0,
      component: <StepOne form={form} onNext={onNextStep} />,
    },
    {
      step: 1,
      component: (
        <StepTwo form={form} onNext={onNextStep} onBack={onBackStep} />
      ),
    },
    {
      step: 2,
      component: (
        <StepThree form={form} onNext={onNextStep} onBack={onBackStep} />
      ),
    },
    {
      step: 3,
      component: (
        <StepFour form={form} onNext={onNextStep} onBack={onBackStep} />
      ),
    },
    {
      step: 4,
      component: (
        <StepFive form={form} onSubmit={onSubmit} onBack={onBackStep} />
      ),
    },
    {
      step: 5,
      component: <FinalStep id={createdInsEduId} />,
    },
  ];

  return (
    <>
      <HeaderModern affixTop />
      <div
        style={
          isMobile
            ? { paddingLeft: "1em", paddingRight: "1em" }
            : { paddingLeft: "15%", paddingRight: "15%" }
        }
      >
        {/* <Space direction="horizontal">
          <Button onClick={() => setCurrentStep(0)}>1</Button>
          <Button onClick={() => setCurrentStep(1)}>2</Button>
          <Button onClick={() => setCurrentStep(2)}>3</Button>
          <Button onClick={() => setCurrentStep(3)}>4</Button>
          <Button onClick={() => setCurrentStep(4)}>5</Button>
          <Button onClick={() => setCurrentStep(5)}>6</Button>
        </Space> */}
        <ConfigProvider
          componentSize="large"
          form={{ validateMessages: { required: "Campo obligatorio" } }}
        >
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            {steps.find((step) => step.step === currentStep)?.component}
          </Form>
        </ConfigProvider>
      </div>
    </>
  );
};

export default SubmitInsEduPage;
