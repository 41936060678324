import { Affix, Col, Row } from "antd";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./css/Header.css";
const logo = require("./img/logo.png");

function HeaderDesign() {
  return (
    <Affix offsetTop={0}>
      <Row align="middle" style={{backgroundColor: "white"}}>
        <Col span={6} className="columna4">
          <Link to="/">
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
        </Col>
        <Col xs={7} sm={1} md={7} lg={7} xl={7} className="columna4"></Col>
        <Col xs={7} sm={1} md={7} lg={7} xl={7} className="columna4"></Col>
        <Col span={3} className="columna4">
          <div className="logoTextoHeader">
            <Link to="/inicio-de-sesion">
              <FaUser className="logoUser"></FaUser>
              <br />
              <p>
                Registro
                <br />
                Establecimientos
              </p>
            </Link>
          </div>
        </Col>
      </Row>
    </Affix>
  );
}

export default HeaderDesign;
