import request from "./axiosInstance";
import {
  AcademiaDeportiva,
  AcademiaDeportivaCreateObject,
  AcademiaDeportivaUpdateObject,
  DeportesAcademiaDeportiva,
} from "../types/academiaDeportiva.type";
import { FilterGroup } from "../types/filters.type";
import { AcademiaDeportivaFilters } from "../types/filtros.type";

export const requestManyAcademiaDeportiva = async (
  filters: AcademiaDeportivaFilters
) => {
  try {
    const response = await request.post<{
      items: AcademiaDeportiva[];
      total: number;
    }>("/academia-deportiva", filters );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestSingleAcademiaDeportiva = async (id: number) => {
  try {
    const response = await request.get<AcademiaDeportiva>(
      `/academia-deportiva/${id}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestDeportes = async () => {
  try {
    const response = await request.get<DeportesAcademiaDeportiva[]>(
      "/academia-deportiva/deportes"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqAcademiaDeportivaFilters = async (
  currentFilters: AcademiaDeportivaFilters
) => {
  const filtersResponse = await request.post<
    FilterGroup<AcademiaDeportivaFilters>[]
  >("/academia-deportiva/filters", currentFilters);

  return filtersResponse.data;
};

export const reqAcademiaDeportivaCambiarVisibilidad = async (
  id: number,
  hidden: boolean
) => {
  try {
    const response = await request.post(`/academia-deportiva/visibilidad/${id}`, { hidden });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqCreateAcademiaDeportiva = async (
  data: AcademiaDeportivaCreateObject
) => {
  try {
    const response = await request.post<{ id: number }>("/academia-deportiva/create", data);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqUpdateAcademiaDeportiva = async (
  data: AcademiaDeportivaUpdateObject
) => {
  try {
    const response = await request.put<{ id: number }>("/academia-deportiva", data);
    return response.data;
  } catch (error) {
    return null;
  }
};
