import { useRequest } from "ahooks";
import { Button, Checkbox, Col, Form, Input, Row, Typography } from "antd";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { reqArtes } from "../../../../commonRequests/academiaArtisticasRequests";
import TextArea from "antd/lib/input/TextArea";

const ArtesSelectorInput = () => {
  const { data: artes, loading } = useRequest(reqArtes);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Typography.Title level={3}>Artes</Typography.Title>
      <Form.Item
        name="artes"
        label="Seleccione las disciplinas artísticas instruidas por su institución"
        rules={[{ required: true }]}
      >
        <Checkbox.Group>
          <Row gutter={AntdConsts.rowGutter}>
            {artes?.map((d) => (
              <Col {...AntdConsts.fourColLayout}>
                <Checkbox value={d.id}>{d.nombre}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item name="artesCustom" label={"Ingrese artes faltantes (una por linea)"}>
        <TextArea rows={4}></TextArea>
      </Form.Item>
    </>
  );
};

export default ArtesSelectorInput;
