import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { FcGlobe, FcBinoculars, FcSearch } from "react-icons/fc";
import useIsMobile from "../../../hooks/useMobile";
import useNavigateScrollTop from "../../../hooks/useNavigateScrollTop";
import "../css/PresentationCards.css";
import CategoriasPagPrincipal from "./CategoriasPagPrincipal";


function PresentationCards() {
  const navigate = useNavigateScrollTop();
  const isMobile = useIsMobile();
  

  return (
    <>
      <Row id="con">
        <Row>
          <div id="categorias">
            <CategoriasPagPrincipal />
          </div>
        </Row>
        <Row gutter={[0, 0]} justify="space-around" style={{ width: "95%" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h3
              style={isMobile ? { fontSize: "2.5em" } : undefined}
              className="txtTarjetasBusqueda"
            >
              Encuentra el <strong>Establecimiento</strong> que{" "}
              <strong>buscas</strong>
            </h3>
          </Col>
        </Row>
        <Row
          gutter={[0, 30]}
          justify="space-around"
          align="middle"
          className="cardsOptions"
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="colCards">
            <Card
              onClick={() => navigate(`/busqueda-por-ubicacion`)}
              hoverable
              id="tarjetaBusLoc"
              cover={<FcGlobe id="cardIco" />}
            >
              <Meta
                title="Búsqueda Por Ubicación"
                description="Puedes Buscar Establecimientos sabiendo la región, comuna y ciudad donde esté ubicado."
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="colCards">
            <Card
              onClick={() => navigate(`/busqueda-por-nombre`)}
              hoverable
              id="tarjetaBusNom"
              cover={<FcBinoculars id="cardIco" />}
            >
              <Meta
                title="Búsqueda Por Nombre"
                description="Si buscas información de un Establecimiento en particular, puedes encontrarlo por su nombre."
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Card
              hoverable
              cover={<FcSearch id="cardIco" />}
              id="tarjetaBusDirect"
              onClick={() => {document.getElementById("categorias")?.scrollIntoView({behavior: "smooth"});}}
            >
              <Meta
                title="Categorías de Instituciones"
                description="Click para ir directamente al listado de Categorías y poder buscar la mejor opción para ti."
              />
            </Card>
          </Col>
        </Row>
      </Row>
    </>
  );
}

export default PresentationCards;
