import { Col, Form, Input, Row, Typography } from "antd";
import AntdConsts from "../../../../designConstants/antd-constants";

const DatosContactoForm = () => {
  const emailRegexValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <>
      <Typography.Title level={2}>Datos de Contacto</Typography.Title>

      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Teléfono"
            name="telefono"
            rules={[{ required: true }]}
          >
            <Input placeholder="Teléfono" />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Email Institucional"
            name="email"
            rules={[
              { required: true },
              {
                pattern: emailRegexValidation,
                message: "Ingrese un correo válido",
              },
            ]}
          >
            <Input placeholder="Ej. correo@tucolegio.com" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Página Web" name="paginaWeb">
        <Input placeholder="Ej. www.tucolegio.cl" />
      </Form.Item>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item label="Link Instagram" name="instagram">
            <Input placeholder="https://www.instagram.com/instagram/" />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item label="Link Twitter" name="twitter">
            <Input placeholder="https://www.twitter.com/twitter/" />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item label="Link Facebook" name="facebook">
            <Input placeholder="https://www.facebook.com/Facebook" />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item label="Número WhatsApp" name="whatsapp">
            <Input placeholder="Ej. +56999999999" />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Link video corporativo (solo YouTube)"
            name="videoInstitucion"
          >
            <Input placeholder="https://www.youtube.com/watch?v=sEhQTjgoTdU" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default DatosContactoForm;
