import { Card, Col, Descriptions, Row, Space, Typography } from "antd";
import { EnrichedGeolocationCoordinates } from "react-hook-geolocation";
import { MdLocationPin } from "react-icons/md";
import {
  FaChalkboard,
  FaDirections,
  FaMoneyBillAlt,
} from "react-icons/fa";
import currencyFormatter from "../../../../utils";
import { AcademiaIdiomas } from "../../../../types/academiaIdiomas.type";
import FlagsViews from "../../../../components/FlagsViews/FlagsViews";
import { formatDistance } from "../../../../utils/filterUtils";
import { BsGenderAmbiguous } from "react-icons/bs";
import CardContainer from "../../../../components/CardContainer";
import AntdConsts from "../../../../designConstants/antd-constants";
import useIsMobile from "../../../../hooks/useMobile";

interface academiaIdiomasSimpleViewProps {
  academiaIdiomas: AcademiaIdiomas;
  geoLocation?: EnrichedGeolocationCoordinates;
  className?: string;
}

const AcademiaIdiomasSimpleView = ({
  academiaIdiomas,
}: academiaIdiomasSimpleViewProps) => {
  const isMobile = useIsMobile()
  const geoDistance = academiaIdiomas.distance;

  const idiomasUnicos = [...new Map(academiaIdiomas.idiomas.map(i => [i.iso639_2_code, i])).values()]

  const info = (
    <>
      <Descriptions size="small" column={1} bordered title="INFORMACIÓN">
        {geoDistance && (
          <Descriptions.Item>
            <Space size="small" direction="horizontal">
              <FaDirections style={{ color: "blue" }} />
              <Typography.Text
                ellipsis={{
                  tooltip: `${academiaIdiomas.comuna.nombre}, ${academiaIdiomas.region.nombre}`,
                }}
                style={{ width: "15em" }}
              >
                {`A ${formatDistance(geoDistance)} de su ubicación`}
              </Typography.Text>
            </Space>
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={
            <Space size="small" direction="horizontal">
              <MdLocationPin style={{ color: "blue" }} />
              <Typography.Text>Dirección</Typography.Text>
            </Space>
          }
        >
          {academiaIdiomas.direccion}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size="small" direction="horizontal">
              <FaDirections style={{ color: "blue" }} />
              <Typography.Text>Comuna</Typography.Text>
            </Space>
          }
        >
          {academiaIdiomas.comuna.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size="small" direction="horizontal">
              <BsGenderAmbiguous style={{ color: "blue" }} />
              <Typography.Text>Género alumnos</Typography.Text>
            </Space>
          }
        >
          {academiaIdiomas.generoAlumnos.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size="small" direction="horizontal">
              <FaMoneyBillAlt style={{ color: "blue" }} />
              <Typography.Text>Precio</Typography.Text>
            </Space>
          }
        >
          {`${currencyFormatter.format(
            academiaIdiomas.precioMin
          )} - ${currencyFormatter.format(academiaIdiomas.precioMax)}`}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size="small" direction="horizontal">
              <FaChalkboard style={{ color: "blue" }} />
              <Typography.Text>Modalidad</Typography.Text>
            </Space>
          }
        >
          {academiaIdiomas.modalidad.nombre}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  return (
    <CardContainer noPadding style={isMobile ? { padding: "1em" } : undefined}>
      <Card
        onClick={() =>
          window.open(`/academia-idiomas/${academiaIdiomas.id}`, "_blank")
        }
        hoverable
        className="small-card-body-padding"
        title={academiaIdiomas.nombre.toUpperCase()}
        cover={
          <img
            style={{ aspectRatio: "1 / 1", overflow: "hidden", position: "relative", minHeight: "100%", maxHeight: "100%", minWidth: "100%", maxWidth: "100%" }}
            alt="example"
            src={`${process.env.REACT_APP_API_URL}instituciones/images/${academiaIdiomas.fotos?.[0].id}`}
          />
        }
      >
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>{info}</Col>
          <Col {...AntdConsts.fullSizeColLayout} style={{ padding: "1em" }}>
            <Typography.Title level={4}>Idiomas</Typography.Title>
            <Space size="small" direction="horizontal">
              <Space direction="horizontal" size="small" wrap>
                {
                  idiomasUnicos.map(i => (
                    <FlagsViews
                      key={i.iso639_2_code}
                      iconSize={isMobile ? "25px" : undefined}
                      flagsIso639Codes={[i.iso639_2_code || i.nombre]}
                      overridePopOver={
                        academiaIdiomas.idiomas.filter(i2 => i2.iso639_2_code === i.iso639_2_code).map(i2 => i2.nombre).join(" - ")
                      }
                    />
                  ))
                }
              </Space>
              {geoDistance && <Typography.Text></Typography.Text>}
            </Space>
          </Col>
        </Row>
      </Card>
    </CardContainer>
  );
};

export default AcademiaIdiomasSimpleView;
