import { Button, Popconfirm } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { cambiarVisibilidad } from "../../../commonRequests/insEduRequests";
import {
  TipoInstitucionEducativaIds,
  TipoInstitucionIds,
} from "../../../types/instituciones.types";
import { LoadingOutlined } from "@ant-design/icons";
import { reqAcademiaDeportivaCambiarVisibilidad } from "../../../commonRequests/academiaDeportesRequest";
import { reqAcademiaIdiomasCambiarVisibilidad } from "../../../commonRequests/academiaIdiomasRequest";
import { reqAcademiaArtisticaCambiarVisibilidad } from "../../../commonRequests/academiaArtisticasRequests";
import { reqPreuniversitarioCambiarVisibilidad } from "../../../commonRequests/preuniversitarioRequests";
import EscuelaVeranoReq from "../../../commonRequests/escuelaVerano.req";

interface CambiarVisibilidadInstitucionButtonProps {
  tipoInstitucionId: TipoInstitucionIds;
  id: number;
  startsHidden: boolean;
  onChangeVisibility?: (newVisibility: boolean) => any;
}

const CambiarVisibilidadInstitucionButton = ({
  tipoInstitucionId,
  id,
  startsHidden,
  onChangeVisibility,
}: CambiarVisibilidadInstitucionButtonProps) => {
  const [hidden, setHidden] = useState(startsHidden);
  const [loading, setLoading] = useState(false);

  if (hidden === undefined || hidden === null) {
    return <></>;
  }

  //TODO: agregar otros tipos de institución
  const onClick = async () => {
    setLoading(true);

    let response: any = null;
    if (TipoInstitucionEducativaIds.includes(tipoInstitucionId)) {
      response = await cambiarVisibilidad(id, !hidden);
    } else if (tipoInstitucionId === TipoInstitucionIds.ACADEMIA_DEPORTIVA) {
      response = await reqAcademiaDeportivaCambiarVisibilidad(id, !hidden);
    } else if (tipoInstitucionId === TipoInstitucionIds.ACADEMIA_IDIOMAS) {
      response = await reqAcademiaIdiomasCambiarVisibilidad(id, !hidden);
    } else if (
      tipoInstitucionId === TipoInstitucionIds.PREUNIVERSITARIO ||
      tipoInstitucionId === TipoInstitucionIds.REFORZAMIENTO
    ) {
      response = await reqPreuniversitarioCambiarVisibilidad(id, !hidden);
    } else if (tipoInstitucionId === TipoInstitucionIds.ACADEMIA_ARTISTICA) {
      response = await reqAcademiaArtisticaCambiarVisibilidad(id, !hidden);
    } else if (tipoInstitucionId === TipoInstitucionIds.ESCUELA_VERANO) {
      response = await EscuelaVeranoReq.cambiarVisibilidad(id, !hidden);
    } else if (tipoInstitucionId === TipoInstitucionIds.GUARDERIA_INFANTIL) {
      response = await EscuelaVeranoReq.cambiarVisibilidad(id, !hidden);
    }

    if (response === null) {
      cogoToast.error(
        "No fue posible cambiar la visibilidad de la institución"
      );
      setLoading(false);
      return;
    }

    cogoToast.success("Visibilidad de la institución modificada correctamente");
    onChangeVisibility?.(response);
    setLoading(false);
    setHidden(response);
  };

  return (
    <Popconfirm
      title="Cambiar estado de visibilidad?"
      okText="Confirmar"
      cancelText="Cancelar"
      onConfirm={onClick}
      disabled={loading}
    >
      <Button
        block
        type="primary"
        disabled={loading}
        icon={loading ? <LoadingOutlined /> : undefined}
      >
        {hidden ? "Hacer Visible" : "Ocultar"}
      </Button>
    </Popconfirm>
  );
};

export default CambiarVisibilidadInstitucionButton;
