import { useRequest } from "ahooks";
import { Button, Result } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { reqVerifyEmail } from "../commonRequests/authRequests";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import HeaderModern from "./header/HeaderModern";

const VerificarEmailPage = () => {
  const { verifyEmailCode } = useParams();
  const navigate = useNavigate();

  const data = useRequest(() => reqVerifyEmail(verifyEmailCode));

  if (data.loading) {
    return <LoadingSpinner />;
  }

  if (!data.data) {
    return (
      <>
        <HeaderModern hideSearchbar />
        <Result
          status="warning"
          title="No fue posible verificar el correo, por favor intentelo nuevamente"
          extra={
            <Button type="primary" key="console" onClick={() => navigate("/")}>
              Ir a la pagina principal
            </Button>
          }
        />
      </>
    );
  }

  return (
    <>
      <HeaderModern hideSearchbar />
      <Result
        title="Correo verificado correctamente!"
        extra={
          <Button type="primary" key="console" onClick={() => navigate("/")}>
            Empezar a usar buscaestudio.cl
          </Button>
        }
      />
    </>
  );
};

export default VerificarEmailPage;
