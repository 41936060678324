import { AuthTokenPayload } from "../types/auth.types";
import { useAuthStorage } from "./useBrowserStorage";

export const useUserPrivileges = () => {
  const userJWT = useAuthStorage(true) as AuthTokenPayload;
  if (!userJWT) {
    return null;
  }
  return userJWT?.profileType?.type;
};
