import { Button, Modal, Typography } from "antd";
import { useState } from "react";
import useIsMobile from "../../hooks/useMobile";

const TerminosYCondiciones = () => {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button type="link" onClick={() => setIsOpen(true)}>
        Términos de uso
      </Button>
      <Modal
        title="Términos y Condiciones, Cookies y Política de Privacidad y Protección de Datos de Byweb Chile SpA, a través de su Sitio Web: www.buscaestudio.cl."
        width={isMobile ? "90vw" : "60vw"}
        visible={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        footer={
          <Button type="primary" onClick={() => setIsOpen(false)}>
            Cerrar
          </Button>
        }
      >
        <div
          style={{
            maxHeight: "50vh",
            overflowY: "scroll",
            textAlign: "justify",
            paddingRight: "2em",
          }}
        >
          <Typography.Paragraph>
            Estos términos y condiciones describen las reglas y regulaciones
            para el uso del sitio web www.buscaestudio.cl (en adelante Sitio
            Web), gestionado por <strong>Byweb Chile SpA.</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>BUSCAESTUDIO.CL</strong> es un sitio web de{" "}
            <strong>Byweb Chile SpA</strong>, todos los acuerdos, contratos,
            gestiones e información se realizarán a través de{" "}
            <strong>Byweb Chile SpA</strong> como titular del servicio,
            utilizando los canales disponibles de comunicación
            contacto@buscaestudio.cl, admin@buscaestudio.cl.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>1.- General</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            Al acceder a este sitio web como usuario o suscriptor, asumimos que
            acepta estos términos y condiciones en su totalidad, como
            instrumento jurídico. No continúe usando el sitio web
            www.buscaestudio.cl si no acepta todos los términos y condiciones
            establecidos en esta página. Al aceptar estos Términos y
            Condiciones, reconoce tener la capacidad jurídica para realizar
            actos de contratación de servicios de acuerdo con la legislación
            chilena.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Esta Política de Privacidad es de aplicación para todos los usuarios
            y suscriptores de www.buscaestudio.cl. Los datos corporativos son
            todos aquellos datos que se refieren a su establecimiento como, por
            ejemplo: El nombre, RUT corporativo, dirección, dirección de correo
            electrónico, contacto corporativo, RBD, teléfono de contacto
            corporativo, así como todos los datos que pueden aparecer en el
            perfil del Establecimiento publicado en buscaestudio.cl. Por lo que
            cada Suscriptor tiene una clave interna propia que le permite
            administrar sus datos sólo con esa clave única que el Suscriptor
            creó, por ende, nadie como usuario podrá intervenir sus datos o
            ingresar información no autorizada por el Suscriptor y la
            administración de BuscaEstudio.cl.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Al marcar el botón{" "}
            <strong>
              {" "}
              “Acepto los Términos y Condiciones, Cookies y Política de
              Privacidad y Protección de Datos de Byweb Chile SpA"
            </strong>
            , los Suscriptores afirman que han leído y que consienten
            expresamente la presente Política de Privacidad de Datos.
          </Typography.Paragraph>

          <Typography.Paragraph>
            Estos Términos y Condiciones permanecerán en todo momento a su
            disposición en el Sitio Web www.buscaestudio.cl. En caso de que
            alguna de sus cláusulas sea declarada nula, se eliminará de forma
            automática, pero el resto de las cláusulas conservarán su validez.
          </Typography.Paragraph>
          <Typography.Paragraph>
            El Suscriptor se compromete a introducir datos reales, veraces y
            vigentes. Asimismo, será el único responsable de los daños y
            perjuicios que buscaestudio.cl y su titular pudiera sufrir como
            consecuencia de la falta de veracidad, inexactitud, falta de
            vigencia y autenticidad de los datos facilitados.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Los datos recabados por Buscaestudio.cl, serán exclusivamente
            utilizados para la consecución del objeto definido en los Términos y
            Condiciones de uso que pueden ser consultadas en la web
            www.buscaestudio.cl y cuyos fines más destacables son la búsqueda de
            los Establecimientos Educativos con los que el Usuario tiene una
            mayor afinidad o decisión de tomar el servicio del Establecimiento
            educativo y la notificación a éstos de dicha recomendación cuando el
            usuario lo solicite mediante nuestra web. Byweb Chile SpA facilita a
            los establecimientos educativos la información a los usuarios
            durante la utilización del sitio www.buscaestudio.cl.
          </Typography.Paragraph>
          <Typography.Paragraph>
            La siguiente terminología se aplica a estos Términos y Condiciones,
            Cookies y Política de Privacidad y Protección de Datos y cualquiera
            o todos los Acuerdos:{" "}
            <strong>El Establecimiento, Cliente, Suscriptor, Usted y Su</strong>{" "}
            se refieren a usted, la persona que accede a este sitio web y acepta
            los términos y condiciones de Buscaestudio.cl.
            <strong>Titular, Nosotros y Nuestro,</strong> se refiere a{" "}
            <strong>Byweb Chile SpA. Parte, Partes o Nosotros,</strong> se
            refiere en conjunto al Cliente y a Nosotros mismos, o al Cliente o a
            Nosotros Mismos.<strong>Sitio Web</strong> se refiere a
            <strong>www.buscaestudio.cl.</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>Todos los términos</strong> se refieren a la oferta,
            aceptación, consideración y pago necesario para efectuar el proceso
            de nuestra asistencia al Establecimiento de la manera más adecuada,
            ya sea mediante nuestro correo corporativo: admin@buscaestudio.cl,
            reuniones formales presenciales u online, de una duración fija, con
            el propósito expreso de conocer las necesidades del Establecimiento
            Educativo, con respecto a la provisión de los servicios/productos
            declarados de Buscaestudio.cl, de acuerdo con y sujeto a la ley
            vigente de la República de Chile.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Cualquier uso de la terminología anterior u otras palabras en
            singular, plural, mayúsculas y/o, él/ella o ellos, se consideran
            intercambiables y, por lo tanto, se refieren a lo mismo.
          </Typography.Paragraph>
          <Typography.Paragraph>
            La presente Política de Privacidad y Protección de Datos es parte
            integrante de los “Términos y Condiciones “del sitio web
            www.buscaestudio.cl y mediante la aceptación de éstos, el Usuario
            acepta la Política de Privacidad aquí contenida.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>Byweb Chile SpA</strong> podrá realizar modificaciones
            pertinentes en parte o en la totalidad de los Servicios o en los
            Términos y Condiciones, Cookies y Política de Privacidad y
            Protección de Datos, informando a los usuarios y suscriptores de
            tales modificaciones con, al menos, una semana de antelación. Se
            entenderá que otorga su consentimiento a tal actualización si
            continúa utilizando los Servicios a través del Sitio Web. En caso de
            no estar de acuerdo con las actualizaciones realizadas, podrá
            renunciar dando aviso en admin@buscaestudio.cl.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Puedes notificar cualquier incidencia relativa al funcionamiento del
            Servicio a través de la dirección de correo electrónico:
            contacto@buscaestudio.cl.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Los establecimientos deben respetar las buenas prácticas que{" "}
            <strong>Byweb Chile SpA,</strong> a través de su sitio web:
            <strong>www.buscaestudio.cl</strong> exige, pudiendo ser expulsados
            y tener prohibición de uso del sitio web en caso de no respetarlos.
            Estas buenas prácticas están en publicar información actualizada,
            verdadera y neutral.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Las presentes Términos y Condiciones se rigen por la legislación
            chilena.{" "}
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>2.- COOKIES E IPs</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            Empleamos el uso de cookies al utilizar el sitio:
            <strong>www.buscaestudio.cl,</strong> usted acepta el uso de cookies
            de acuerdo con la política de privacidad de Buscaestudio.cl. La
            mayoría de los sitios web modernos e interactivos de hoy en día usan
            cookies para permitirnos recuperar los detalles del usuario para
            cada visita.
          </Typography.Paragraph>
          <Typography.Paragraph>
            El Usuario acepta el uso de cookies y seguimientos de IPs, para
            brindar información más personalizada al punto de conexión. Se
            tratan de datos estadísticos sobre las acciones y patrones de
            navegación de nuestros usuarios y no identifican a ninguna persona.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Las cookies se utilizan en algunas áreas de nuestro sitio para
            habilitar la funcionalidad de esta área y la facilidad de uso para
            las personas que lo visitan. Algunos de nuestros socios
            afiliados/publicitarios también pueden usar cookies.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>3.-LICENCIA/PROPIEDAD INTELECTUAL E INDUSTRIAL.</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            A menos que el titular indique lo contrario, www.buscaestudio.cl les
            pertenecen los derechos de propiedad intelectual de todo el material
            de www.buscaestudio.cl y se encuentran protegidos por los regímenes
            de propiedad intelectual o industrial, y son de propiedad de{" "}
            <strong> Byweb Chile SpA.</strong> Los datos de los suscriptores
            corresponden a los establecimientos que publiquen en este Sitio Web.
          </Typography.Paragraph>
          <Typography.Paragraph>
            La contratación de cualquiera de los Servicios no supone una
            transmisión de derechos de propiedad intelectual o industrial a su
            favor, sino únicamente la concesión de un derecho de uso y
            visualización limitado al contexto de la utilización del Sitio Web.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Todos los derechos de propiedad intelectual están reservados y
            protegidos y pertenecen a <strong>Byweb Chile SpA.</strong> Puedes
            ver y/o imprimir páginas desde www.buscaestudio.cl para su uso
            personal sujeto a las restricciones establecidas en estos términos y
            condiciones.
          </Typography.Paragraph>
          <Typography.Paragraph>No debe:</Typography.Paragraph>
          <Typography.Paragraph>
            Volver a publicar material desde www.buscaestudio.cl .
          </Typography.Paragraph>
          <Typography.Paragraph>
            Vender, arrendar u otorgar una sublicencia de material desde
            www.buscaestudio.cl.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Reproducir, duplicar o copiar material desde www.buscaestudio.cl
          </Typography.Paragraph>
          <Typography.Paragraph>
            Redistribuir contenido de www.buscaestudio.cl, a menos de que el
            contenido se haga específicamente para la redistribución.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Queda prohibido cualquier uso comercial, reventa, distribución,
            modificación o comercialización bajo cualquier forma de cualesquiera
            contenidos incluidos en el Sitio Web, o del software de
            buscaestudio.cl, sin autorización previa y expresa de{" "}
            <strong> Byweb Chile SpA.</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            Los activos intangibles incluidos o relacionados con el Sitio Web
            www.buscaestudio.cl se encuentran protegidos por los regímenes de
            propiedad intelectual o industrial, y son titularidad de{" "}
            <strong>Byweb Chile SpA</strong> y de los terceros que el titular
            autorice.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Todos los logos, marcas, nombres de dominio, marcas y signos que
            aparecen en el sitio web buscaestudio.cl, son propiedad de{" "}
            <strong>Byweb Chile SpA</strong> o de terceros que el titular
            autorice y no podrán ser objeto de explotación sin la autorización
            expresa del titular.
          </Typography.Paragraph>
          <Typography.Paragraph>
            En ningún caso el usuario está autorizado para realizar acto de
            copia, explotación de: Todos los contenidos, entre los que se
            incluyen títulos, los textos, vídeos, elementos gráficos, iconos,
            infografías, imágenes y diseño del sitio web buscaestudio.cl. Todos
            éstos son propiedad del titular y de terceros autorizados por{" "}
            <strong>Byweb Chile SpA</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            En ningún caso el usuario está autorizado para realizar acto de
            copia, explotación de: Software en sus versiones de código fuente y
            código objeto, éstas son propiedad exclusiva de{" "}
            <strong>Byweb Chile SpA</strong> y su titular y de terceros
            autorizados por <strong>Byweb Chile SpA</strong>, a través de su
            titular, por lo que ningún Usuario está autorizado para realizar
            acto de explotación alguno sobre ellos diferentes a los expresamente
            indicados en esta política.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Los usuarios del Sitio web deben abstenerse de realizar cualquier
            acto considerado dañino de los que perjudique los intereses y
            derechos de <strong>Byweb Chile SpA</strong> y, en particular,
            abstenerse de realizar cualquier acción que pueda vulnerar de los
            derechos de propiedad intelectual e industrial titularidad de{" "}
            <strong>Byweb Chile SpA</strong> y de su Sitio web: buscaestudio.cl
            o de terceros.{" "}
          </Typography.Paragraph>
          <Typography.Paragraph>
            Cualquier acto que afecte los derechos e intereses de{" "}
            <strong>Byweb Chile SpA</strong> y de su Sitio web buscaestudio.cl o
            de terceros, provocado por un usuario, deberá indemnizarnos por los
            daños y perjuicios causados de acuerdo con la legislación chilena.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>4.- DESCRIPCIÓN DEL SERVICIO.</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            Buscaestudio.cl, es un sitio web que permite la búsqueda de
            establecimientos educativos formales e informales existentes en
            Chile, entregando información verdadera y actual a los usuarios
            sobre los establecimientos más afines a sus necesidades según la
            aplicación de filtros y búsqueda por nombre del establecimiento, por
            cercanía a la ubicación del usuario o por ciudad específica.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Al ingresar al sitio Web, el usuario podrá buscar el establecimiento
            más afín a sus necesidades, podrá elegir, a través de filtros, sus
            preferencias.
          </Typography.Paragraph>
          <Typography.Paragraph>
            El usuario al utilizar el Sitio Web acepta que{" "}
            <strong>Byweb Chile SpA</strong> les recomiende los Establecimientos
            más afines a sus necesidades.
          </Typography.Paragraph>
          <Typography.Paragraph>
            El usuario al utilizar el Sitio Web acepta que se informen los
            establecimientos de las tendencias de los usuarios, para ofrecer la
            mejor opción.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>5.- CONTRATO Y FORMA DE PAGO.</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            El uso del sitio web www.buscaestudio.cl por{" "}
            <strong>Byweb Chile SpA</strong>, es gratuito para los Usuarios.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>Byweb Chile SpA</strong>, establecerá relaciones comerciales
            solo con los Establecimientos registrados en buscaestudio.cl. El
            valor del servicio, los diferentes planes y la forma del pago, serán
            informados en los acuerdos de entrega de servicios el cual debe ser
            leído y aceptado por el Establecimiento.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>
              NOTA: El contrato del servicio será enviado únicamente desde el
              correo admin@buscaestudio.cl.{" "}
            </strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>
              6.- CONDICIONES Y OBLIGACIONES DE LOS USUARIOS Y SUSCRIPTORES:
            </strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            Introducir en el Sitio Web cualquier software, malware, virus o
            troyano que pudiera causar daños o alteraciones en el funcionamiento
            de éstos y/o en el Servicio.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Proteger las claves de acceso a la plataforma, no ceder ni permitir
            su uso a terceros, recuerde que su clave es personal e
            intransferible.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Abstenerse de realizar cualquier acción que pueda resultar lesiva
            para los intereses de <strong>Byweb Chile SpA</strong> y de su Sitio
            web buscaestudio.cl o que sea contraria a la ley, la moral o el
            orden público.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>7.- RESPONSABILIDAD.</strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>Byweb Chile SpA</strong> y de su Sitio web buscaestudio.cl
            no será responsable en modo alguno por las siguientes acciones:
          </Typography.Paragraph>
          <Typography.Paragraph>
            Acciones fraudulentas, inadecuadas o contrarias a lo establecido en
            estos Términos y Condiciones o contrarias a lo legal, que se
            realicen en el sitio web. En estos casos{" "}
            <strong>Byweb Chile SpA</strong> se reserva el derecho de suspender,
            sin previo aviso, la relación con el usuario o suscriptores de
            buscaestudio.cl, pudiendo dar lugar a acciones civiles o penales por
            parte de <strong>Byweb Chile SpA</strong>.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Fallas en el servidor que produzcan interrupción del servicio.{" "}
          </Typography.Paragraph>
          <Typography.Paragraph>
            Falla de red que produzcan interrupción del servicio.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Acceso no autorizado de terceros al sitio web, por una falta de
            resguardo de las claves de acceso por parte del Usuario.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>Byweb Chile SpA</strong> y de su Sitio web buscaestudio.cl,
            no será responsable de las recomendaciones finales que pueda
            entregar un establecimiento, ni de las elecciones de
            establecimientos que realice el usuario
          </Typography.Paragraph>
          <Typography.Paragraph>
            La relación final y decisiones que exista entre el usuario y el
            establecimiento no será de responsabilidad de Byweb Chile SpA y de
            su Sitio web buscaestudio.cl.
          </Typography.Paragraph>
          <Typography.Paragraph>
            El acceso al Sitio Web y los riesgos derivados de su utilización son
            responsabilidad exclusiva del Usuario.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>Byweb Chile SpA</strong> no garantiza la continuidad en la
            disponibilidad del Sitio Web, su seguridad o la inexistencia de
            anomalías por causas de fuerza mayor o que estén fuera del alcance
            de <strong>Byweb Chile SpA</strong>.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Los contenidos de los sitios web que puedan aparecer en
            buscaestudio.cl como link o enlaces, son de total responsabilidad de
            sus titulares, <strong>Byweb Chile SpA</strong> no responderá por
            tales contenidos.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Si consideras que alguno de los contenidos incluidos en el Sitio Web
            o en los sitios web enlazados son inadecuados o contrarios a
            derechos o intereses de terceros, puedes comunicárnoslo a través de
            la dirección de correo electrónico: contacto@buscaestudio.cl
          </Typography.Paragraph>
          <Typography.Paragraph>
            La duración del Sitio Web buscaestudio.cl es de forma indefinida,
            sin embargo, <strong>Byweb Chile SpA</strong> se reserva el derecho de suspender
            temporalmente o en forma definitiva los servicios que dan origen a
            este sitio web. Esta suspensión será informada a los suscriptores
            con 10 días como mínimo de anticipación.
          </Typography.Paragraph>
        </div>
      </Modal>
    </>
  );
};

export default TerminosYCondiciones;
