import { Popover, Space } from "antd";
import { CircleFlag } from "react-circle-flags";

interface FlagsViewsProps {
  flagsIso639Codes: string[] | undefined;
  iconSize?: string | number;
  overridePopOver?: string;
}

const FlagsViews = ({ flagsIso639Codes, iconSize, overridePopOver }: FlagsViewsProps) => {
  if (!flagsIso639Codes || flagsIso639Codes.length === 0) {
    return <p>Sin idiomas disponibles</p>;
  }

  const flagsDict = [
    {
      country: "Frances",
      iso643: "fra",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="fr"
        />
      ),
    },
    {
      country: "Español",
      iso643: "spa",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="es"
        />
      ),
    },
    {
      country: "Inglés",
      iso643: "eng",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="gb"
        />
      ),
    },
    {
      country: "Alemán",
      iso643: "deu",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="de"
        />
      ),
    },
    {
      country: "Italiano",
      iso643: "ita",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="it"
        />
      ),
    },
    {
      country: "Japones",
      iso643: "jpn",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="jp"
        />
      ),
    },
    {
      country: "Chino",
      iso643: "chn",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="cn"
        />
      ),
    },
    {
      country: "Portugues",
      iso643: "por",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="pt"
        />
      ),
    },
    {
      country: "Coreano",
      iso643: "kor",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="kr"
        />
      ),
    },
    {
      country: "Hebreo",
      iso643: "heb",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="il"
        />
      ),
    },
    {
      country: "Árabe",
      iso643: "ara",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="sa"
        />
      ),
    },
    {
      country: "Ruso",
      iso643: "rus",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="ru"
        />
      ),
    },
    {
      country: "Griego",
      iso643: "gre",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="gr"
        />
      ),
    },
    {
      country: "Polaco",
      iso643: "pol",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="pl"
        />
      ),
    },
    {
      country: "Holandés",
      iso643: "dum",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="nl"
        />
      ),
    },
    {
      country: "Noruego",
      iso643: "nno",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="no"
        />
      ),
    },
    {
      country: "Sueco",
      iso643: "swe",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="se"
        />
      ),
    },
    {
      country: "Suizo",
      iso643: "gsw",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="ch"
        />
      ),
    },
    {
      country: "Irlandes",
      iso643: "gle",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="ie"
        />
      ),
    },
    {
      country: "Hindi",
      iso643: "hin",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="in"
        />
      ),
    },
    {
      country: "Creole/Haitiano",
      iso643: "hat",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="ht"
        />
      ),
    },
    {
      country: "Turco",
      iso643: "tur",
      element: (
        <CircleFlag
          width={iconSize || "35px"}
          height={iconSize || "35px"}
          countryCode="tr"
        />
      ),
    },
  ];

  return (
    <Space direction="horizontal" size="small" wrap>
      {flagsIso639Codes.map((iso643Code, index) => {
        const flagSrc = flagsDict.find((fd) => iso643Code === fd.iso643);
        if (!flagSrc) {
          return (
            <div style={{
              backgroundColor: "#4195ca", borderRadius: "50%",
              display: "table-cell",
              textAlign: "center",
              verticalAlign: "middle",
              height: iconSize || "35px",
              width: iconSize || "35px",
              fontSize: "12px",
              padding: "1em",
              fontWeight: "bold",
              color: "white",
            }}>
              {iso643Code}
            </div>
          )
        }

        return <Popover key={index} content={overridePopOver || flagSrc.country}>{flagSrc.element}</Popover>;
      })}
    </Space>
  );
};

export default FlagsViews;
