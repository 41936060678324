import { Gutter } from "antd/lib/grid/row";

const rowGutter: Gutter | [Gutter, Gutter] = [
  { xs: 8, sm: 16, md: 24, lg: 32 },
  { xs: 8, sm: 16, md: 24, lg: 32 },
];

const twoColsFormRowLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 12 },
  },
};
const threeColsFormRowLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 8 },
    xxl: { span: 8 },
  },
};

const twoColLayout = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 };
const RegLogCol = { xs: 24, sm: 24, md: 8, lg: 24, xl: 6, xxl: 8 };
const RegLogImg = { xs: 0, sm: 0, md: 16, lg: 0, xl: 18, xxl: 16 };
const threeColLayout = { xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 8 };
const fourColLayout = { xs: 24, sm: 24, md: 12, lg: 6, xl: 6, xxl: 6 };
const fullSizeColLayout = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

const removeColOnMobileLayout = { xs: 0, sm: 0, md: 0 };
const fullSizeColOnMobileLayout = { xs: 24, sm: 24, md: 24 };

const headerHeight = 80; //px

const AntdConsts = {
  RegLogImg,
  RegLogCol,
  rowGutter,
  twoColsFormRow: twoColsFormRowLayout,
  threeColsFormRow: threeColsFormRowLayout,
  twoColLayout,
  threeColLayout,
  fourColLayout,
  fullSizeColLayout,
  headerHeight,
  removeColOnMobileLayout,
  fullSizeColOnMobileLayout,
};

export default AntdConsts;
