import { useRequest } from "ahooks";
import { Button, Col, Input, Row } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { requestTipoInstitucion } from "../../../../commonRequests/params";
import SelectComuna from "../../../../components/Input/SelectComuna/SelectComuna";
import SelectTipoInsEdu from "../../../../components/Input/SelectTipoInstitucion/SelectTipoInstitucion";
import SelectRegion from "../../../../components/SelectRequest/SelectRegion";
import AntdConsts from "../../../../designConstants/antd-constants";
import {
  CommonFilters,
  InsEduTiposIds,
  TipoInstitucionIds,
} from "../../../../types/instituciones.types";
import { FilterObjectToUrlParams } from "../../../../utils/filterUtils";
import HeaderModern from "../../../header/HeaderModern";
import "./index.css";

interface BuscadorLocalizacionColegiosProps {
  showTipoEstablecimiento?: boolean;
  hideFilters?: boolean;
  tipoInsEduDefaultId?: number;
}

function BuscadorLocalizacionColegios({
  showTipoEstablecimiento,
  tipoInsEduDefaultId,
}: BuscadorLocalizacionColegiosProps) {
  const navigate = useNavigate();

  const [regionId, setRegionId] = useState<number>();
  const [comunaId, setComunaId] = useState<number>();
  const [dirección, setDireccion] = useState<string>();
  const [tipoInsEduId, setTipoInsEduId] = useState<number>();

  const onRegionChange = (newRegionId: number) => {
    setRegionId(newRegionId);
  };

  const onComunaChange = (newComunaId: number) => {
    setComunaId(newComunaId);
  };

  const onTipoInsEduChange = (newInsEduId: number) => {
    setTipoInsEduId(newInsEduId);
  };

  const onSearch = () => {
    if (!tipoInsEduId) {
      cogoToast.info("Debe seleccionar un tipo de establecimiento");
      return;
    }

    if (regionId) {
      localStorage.setItem("REGION", regionId.toString());
    }

    if (comunaId) {
      localStorage.setItem("COMUNA", comunaId.toString());
    }

    if (dirección) {
      localStorage.setItem("DIRECCION", dirección.toString());
    }

    try {
      if (InsEduTiposIds.includes(tipoInsEduId as number)) {
        navigate(
          `/institucion-educativa/busqueda?${FilterObjectToUrlParams({
            tipo_institucion: tipoInsEduId,
          })}`
        );
      } else if (tipoInsEduId === TipoInstitucionIds.ACADEMIA_DEPORTIVA) {
        navigate(`/academia-deportiva/busqueda`);
      } else if (tipoInsEduId === TipoInstitucionIds.ACADEMIA_IDIOMAS) {
        navigate(`/academia-idiomas/busqueda`);
      } else if (tipoInsEduId === TipoInstitucionIds.ACADEMIA_ARTISTICA) {
        navigate(`/academia-artistica/busqueda`);
      } else if (tipoInsEduId === TipoInstitucionIds.PREUNIVERSITARIO) {
        navigate(`/preuniversitario/busqueda`);
      } else if (tipoInsEduId === TipoInstitucionIds.REFORZAMIENTO) {
        navigate(`/reforzamiento/busqueda`);
      } else if (tipoInsEduId === TipoInstitucionIds.ESCUELA_VERANO) {
        navigate(`/escuela-verano/busqueda`);
      } else if (tipoInsEduId === TipoInstitucionIds.GUARDERIA_INFANTIL) {
        navigate(`/reforzamiento/busqueda`);
      }
    } catch (error) {
      navigate(`/institucion-educativa/busqueda?`);
    }
  };

  return (
    <>
      <div className="contBuscadorLocCol">
        <HeaderModern hideSearchbar />
        <br />
        <br />
        <br />
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className="titleBusLoc">
              Aquí podrás encontrar instituciones por <strong>Localización</strong> y <br/>las más cercanas a la <strong>Dirección</strong>(opcional) que ingreses:
            </h2>
          </Col>
        </Row>
        <br/>
        <br/>
        <br/>
        <div className="dropMenusBusqueda">
          <Row
            gutter={[10, 30]}
            justify={"space-around"}
            align="bottom"
            style={{ padding: "1em" }}
          >
            <Col {...AntdConsts.fourColLayout}>
              {(showTipoEstablecimiento || !!tipoInsEduId) && (
                <>
                  <label className="labels">Tipo de Establecimiento</label>
                  <br />
                  <br />
                  <SelectTipoInsEdu
                    className="typeSelectorEd"
                    onChange={onTipoInsEduChange}
                    value={
                      tipoInsEduDefaultId ? tipoInsEduDefaultId : tipoInsEduId
                    }
                    disabled={!!tipoInsEduDefaultId}
                  />
                </>
              )}
            </Col>
            <Col {...AntdConsts.fourColLayout}>
              <label className="labels">Región</label>
              <br />
              <br />
              <SelectRegion
                className="typeSelectorEd"
                onChange={onRegionChange}
                style={{ width: "100%" }}
              />
            </Col>
            <Col {...AntdConsts.fourColLayout}>
              <label className="labels">Comuna</label>
              <br />
              <br />
              <SelectComuna
                disabled={!regionId}
                className="typeSelectorEd"
                style={{ width: "100%" }}
                regionId={regionId}
                onChange={onComunaChange}
                value={comunaId}
              />
            </Col>
            <Col {...AntdConsts.fourColLayout}>
              <label className="labels">Dirección particular o del Establecimiento (opcional).</label>
              <br />
              <br />
              <Input
                placeholder="Dirección"
                onChange={(e) => setDireccion(e.target.value)}
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <Button
                className="botonBuscar1"
                type="primary"
                onClick={onSearch}
                icon={<FaSearch style={{ fontSize: "25px" }} />}
                block
              ></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="divtofit"></div>
            </Col>
          </Row>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </>
  );
}

export default BuscadorLocalizacionColegios;
