import { Routes, Route, BrowserRouter } from "react-router-dom";
import Inicio from "./views/registro/InicioDeSesion";
import ColegioSelected from "./views/buscadorCategorias/Colegios/ColegioSelected";
import BusquedaInsEduPage from "./views/listaInsEdu";
import InsEduDetailView from "./views/InsEduDetailView/InsEduDetailView";
import "./App.css";
import HomeScreen from "./views/home/HomeScreen";
import "./colorPalette.css";
import SubmitInsEduPage from "./views/SubmitInsEduViews/submitInsEduPage";
import AcademiaDeportivaFormPage from "./views/SubmitInsEduViews/submitacademiasDeportivasPage/AcademiaDeportivaFormPage";
import ListaAcademiaDeportesPage from "./views/AcademiaDeportesPages/ListaAcademiaDeportesPage";
import BusquedaAcademiaIdiomasPage from "./views/AcademiaIdiomasPages/ListaAcademiaIdiomasPage";
import VistaDetalleAcademiaDeportesPage from "./views/AcademiaDeportesPages/VistaDetalleAcademiaDeportesPage";
import FormularioRegistroUser from "./views/registro/FormularioRegistroUser";
import VistaDetalleAcademiaIdiomasPage from "./views/AcademiaIdiomasPages/VistaDetalleAcademiaIdiomasPage";
import RegisteredUserAdminPage from "./adminViews/RegisteredUserAdminPage/RegisteredUserAdminPage";
import BusquedaAcademiaArtisticaPage from "./views/AcademiaArtisticaPages/ListaAcademiaArtisticaPage";
import BusquedaPreuniversitarioPage from "./views/PreuniversitarioPages/ListaPreuniversitarioPage";
import VistaDetallePreuniversitarioPage from "./views/PreuniversitarioPages/VistaDetallePreuniversitarioPage";
import BuscadorLocalizacionColegios from "./views/buscadorCategorias/Colegios/components/BuscadorLocalizacionColegios";
import BusquedaNombreEstablecimiento from "./views/home/components/BusquedaNombreEstablecimiento";
import VerificarEmailPage from "./views/VerificarEmail";
import ListaVendedoresPage from "./adminViews/vendedores/ListaVendedoresPage";
import DetallerVendedorPage from "./adminViews/vendedores/DetalleVendedoresPage";
import VistaDetalleAcademiaArtisticaPage from "./views/AcademiaArtisticaPages/VistaDetallAcademiaArtisticaPage";
import ForgotPasswordPage from "./views/registro/ForgotPasswordPage";
import AdsAdminPage from "./adminViews/AdsAdminPage";
import SubmitPreuniversitario from "./views/SubmitInsEduViews/submitPreuniversitario";
import ProtectedRoute from "./adminViews/ProtectedRoute";
import UserDetailPage from "./adminViews/UserDetailPage";
import EditPageSelector from "./views/SubmitInsEduViews/EditPageSelector";
import AcademiaIdiomasFormPage from "./views/SubmitInsEduViews/submitacademiaIdiomaPage/AcademiaIdiomasFormPage";
import VistaDetalleEscuelaVeranoPage from "./views/EscuelaVeranoPages/VistaDetalleEscuelaVeranoPage";
import BusquedaEscuelaVeranoPage from "./views/EscuelaVeranoPages/ListaEscuelaVeranoPagePage";
import AcademiaVeranoFormPage from "./views/SubmitInsEduViews/submitEscuelaVeranoPage/AcademiaVeranoFormPage";
import Footer from "./views/home/components/Footer";
import TerminosYCondicionesHome from "./views/home/components/TerminosYCondicionesHome";
import SubmitAcademiaArtistica from "./views/SubmitInsEduViews/submitAcademiaArtisticaPage/AcademiaArtisticaFormPage";
import CambiarPassword from "./components/CambiarPassword";
import SponsoredAdminTable from "./adminViews/SponsoredAdminPage/SponsoredAdminPage";
import ScrollTopOnUrlChange from "./ScrollTopOnUrlChange";


function App() {
  return (
    <>

      <div className="appContent" id="appContent">
        <BrowserRouter>
          <ScrollTopOnUrlChange />
          <Routes>
            <Route
              path="/escuela-verano/:id"
              element={<VistaDetalleEscuelaVeranoPage />}
            />

            <Route
              path="/escuela-verano/busqueda"
              element={<BusquedaEscuelaVeranoPage />}
            />

            <Route
              path="/create/escuela-verano/"
              element={
                <ProtectedRoute>
                  <AcademiaVeranoFormPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/escuela-verano/update/:id"
              element={
                <ProtectedRoute>
                  <AcademiaVeranoFormPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/guarderia/:id"
              element={<VistaDetalleEscuelaVeranoPage />}
            />

            <Route
              path="/guarderia/busqueda"
              element={<BusquedaEscuelaVeranoPage />}
            />

            <Route
              path="/create/guarderia/"
              element={
                <ProtectedRoute>
                  <AcademiaVeranoFormPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/guarderia/update/:id"
              element={
                <ProtectedRoute>
                  <AcademiaVeranoFormPage />
                </ProtectedRoute>
              }
            />

            <Route path="/inicio-de-sesion" element={<Inicio />} />

            <Route
              path="/busqueda-por-nombre"
              element={<BusquedaNombreEstablecimiento />}
            />

            <Route
              path="/busqueda-por-ubicacion"
              element={<BuscadorLocalizacionColegios showTipoEstablecimiento />}
            />

            <Route
              path="/academia-deportiva/busqueda"
              element={<ListaAcademiaDeportesPage />}
            />
            <Route
              path="/academia-deportiva/:id"
              element={<VistaDetalleAcademiaDeportesPage />}
            />
            <Route
              path="/create/academia-deportiva"
              element={
                <ProtectedRoute>
                  <AcademiaDeportivaFormPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/academia-deportiva/update/:id"
              element={
                <ProtectedRoute>
                  <AcademiaDeportivaFormPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/academia-artistica/busqueda"
              element={<BusquedaAcademiaArtisticaPage />}
            />
            <Route
              path="/academia-artistica/:id"
              element={<VistaDetalleAcademiaArtisticaPage />}
            />
            <Route
              path="/terminos-y-condiciones"
              element={<TerminosYCondicionesHome />}
            />

            <Route
              path="/create/academia-artistica"
              element={
                <ProtectedRoute>
                  <SubmitAcademiaArtistica />
                </ProtectedRoute>
              }
            />

            <Route
              path="/academia-artistica/update/:id"
              element={
                <ProtectedRoute>
                  <SubmitAcademiaArtistica />
                </ProtectedRoute>
              }
            />

            <Route
              path="/preuniversitario/busqueda"
              element={<BusquedaPreuniversitarioPage />}
            />

            <Route
              path="/create/preuniversitario"
              element={
                <ProtectedRoute>
                  <SubmitPreuniversitario />
                </ProtectedRoute>
              }
            />

            <Route
              path="/preuniversitario/update/:id"
              element={
                <ProtectedRoute>
                  <SubmitPreuniversitario />
                </ProtectedRoute>
              }
            />

            <Route
              path="/create/reforzamiento"
              element={
                <ProtectedRoute>
                  <SubmitPreuniversitario />
                </ProtectedRoute>
              }
            />

            <Route
              path="/reforzamiento/update/:id"
              element={
                <ProtectedRoute>
                  <SubmitPreuniversitario />
                </ProtectedRoute>
              }
            />

            <Route
              path="/reforzamiento/busqueda"
              element={<BusquedaPreuniversitarioPage />}
            />
            <Route
              path="/preuniversitario/:id"
              element={<VistaDetallePreuniversitarioPage />}
            />
            <Route
              path="/reforzamiento/:id"
              element={<VistaDetallePreuniversitarioPage />}
            />

            <Route
              path="/academia-idiomas/busqueda"
              element={<BusquedaAcademiaIdiomasPage />}
            />
            <Route
              path="/academia-idiomas/:id"
              element={<VistaDetalleAcademiaIdiomasPage />}
            />

            <Route
              path="/create/academia-idiomas"
              element={
                <ProtectedRoute>
                  <AcademiaIdiomasFormPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/academia-idiomas/update/:id"
              element={
                <ProtectedRoute>
                  <AcademiaIdiomasFormPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/institucion-educativa/busqueda"
              element={<BusquedaInsEduPage />}
            />

            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <RegisteredUserAdminPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/sponsored"
              element={
                <ProtectedRoute>
                  <SponsoredAdminTable />
                </ProtectedRoute>
              }
            />

            <Route path="/categoria/colegios" element={<ColegioSelected />} />
            <Route path="/create/colegio" element={<SubmitInsEduPage />} />
            <Route path="/update/colegio/:id" element={<SubmitInsEduPage />} />

            <Route
              path="/create"
              element={
                <ProtectedRoute>
                  <EditPageSelector />
                </ProtectedRoute>
              }
            />

            <Route
              path="/categoria/colegios/:id"
              element={<InsEduDetailView />}
            />

            <Route path="/registro" element={<FormularioRegistroUser />} />

            <Route path="/forgot-password" element={<ForgotPasswordPage />} />

            <Route
              path="/cambiar-password/:token"
              element={<CambiarPassword />}
            />

            <Route path="/" element={<HomeScreen />} />

            <Route
              path="/verificar-email/:verifyEmailCode"
              element={<VerificarEmailPage />}
            />

            <Route
              path="/admin/vendedores"
              element={
                <ProtectedRoute shouldBeAdmin>
                  <ListaVendedoresPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/anuncios"
              element={
                <ProtectedRoute shouldBeAdmin>
                  <AdsAdminPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/vendedores/:id"
              element={
                <ProtectedRoute shouldBeAdmin>
                  <DetallerVendedorPage />
                </ProtectedRoute>
              }
            />

            <Route path="/admin/usuario/:id" element={<UserDetailPage />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
}

export default App;
