import { useRequest } from "ahooks";
import { Button, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { getInstitucionesUsuario } from "../../../commonRequests/adminRequests";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useAuthStorage } from "../../../hooks/useBrowserStorage";
import { AuthTokenPayload } from "../../../types/auth.types";
import { EyeTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  CommonInstitucion,
  goToInstitucion,
} from "../../../types/instituciones.types";
import { goToEditPage } from "../../../components/AdminBannerOverDetailView";
import { TipoInstitucion } from "../../../types/params.type";

interface TableDataSource {
  key: string;
  id: number;
  name: string;
  region: string;
  comuna: string;
  tipoInstitucion: TipoInstitucion;
  estado: "NO VISIBLE" | "VISIBLE";
}

interface InstitucionesUsuarioTableProps {
  userId?: number;
}
const InstitucionesUsuarioTable = ({
  userId,
}: InstitucionesUsuarioTableProps) => {
  const userJwt = useAuthStorage(true) as AuthTokenPayload;
  const instituciones = useRequest(() => {
    // Si se entrega un prop usuarioId obtenemos las instituciones de ese usuario en vez de las del usuario loggeado
    if (userId) {
      return getInstitucionesUsuario(userId);
    }
    return getInstitucionesUsuario(userJwt.userId);
  });

  if (instituciones.loading) {
    return (
      <>
        <Typography.Title level={3}>INSTITUCIONES CREADAS</Typography.Title>
        <LoadingSpinner />
      </>
    );
  }

  if (!instituciones.data) {
    return <></>;
  }

  const activasDataSource = getDataSource(instituciones.data.activas);
  const enRevisionDataSource = getDataSource(instituciones.data.enRevision);
  const ocultasDataSource = getDataSource(instituciones.data.ocultas);

  const columns: ColumnsType<TableDataSource> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      onCell: (record) => ({
        onClick: () => goToInstitucion(record),
      }),
    },
    {
      title: "Región",
      dataIndex: "region",
      key: "region",
      onCell: (record) => ({
        onClick: () => goToInstitucion(record),
      }),
    },
    {
      title: "Comuna",
      dataIndex: "comuna",
      key: "comuna",
      onCell: (record) => ({
        onClick: () => goToInstitucion(record),
      }),
    },
    {
      title: "Tipo",
      dataIndex: "tipoInstitucion",
      key: "tipo",
      render: (value) => value?.nombre,
      onCell: (record) => ({
        onClick: () => goToInstitucion(record),
      }),
    },
    {
      title: "Acciones",
      render: (_, record) => {
        return (
          <Space direction="horizontal">
            <Button
              icon={<EyeTwoTone style={{ color: "var(--papaya)" }} />}
              onClick={() => goToInstitucion(record)}
            >
              Ver
            </Button>
            <Button
              onClick={() => goToEditPage(record.tipoInstitucion.id, record.id)}
              type="ghost"
              icon={<EditTwoTone />}
            >
              Editar
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Typography.Title level={3}>INSTITUCIONES CREADAS</Typography.Title>
      <Typography.Title level={4}>Instituciones activas</Typography.Title>
      <Table
        scroll={{ x: "max-content" }}
        columns={columns as any}
        dataSource={activasDataSource}
        size="small"
        bordered
        style={{ cursor: "pointer" }}
      />
      <Typography.Title level={4}>
        Instituciones en revisión de contenido
      </Typography.Title>
      <Table
        scroll={{ x: "max-content" }}
        columns={columns as any}
        dataSource={enRevisionDataSource}
        size="small"
        bordered
        style={{ cursor: "pointer" }}
      />
      <Typography.Title level={4}>Instituciones ocultas</Typography.Title>
      <Table
        columns={columns as any}
        dataSource={ocultasDataSource}
        size="small"
        bordered
        style={{ cursor: "pointer" }}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

const getDataSource = (data: CommonInstitucion[]) => {
  return data.map((i) => ({
    key: `${i.tipoInstitucion.nombre} ${i.id}`,
    id: i.id as number,
    name: i.nombre,
    region: i.region.nombre,
    comuna: i.comuna.nombre,
    tipoInstitucion: i.tipoInstitucion,
    estado: i.hidden ? "NO VISIBLE" : "VISIBLE",
  }));
};

export default InstitucionesUsuarioTable;
