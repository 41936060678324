/* eslint-disable no-restricted-globals */
import { Button, Col, Form, Input, Row } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SelectComuna from "../../../components/Input/SelectComuna/SelectComuna";
import SelectTipoInsEdu from "../../../components/Input/SelectTipoInstitucion/SelectTipoInstitucion";
import SelectRegion from "../../../components/SelectRequest/SelectRegion";
import useNavigateScrollTop from "../../../hooks/useNavigateScrollTop";
import {
  InsEduTiposIds,
  TipoInstitucionIds,
} from "../../../types/instituciones.types";
import { FilterObjectToUrlParams } from "../../../utils/filterUtils";

interface FormData {
  tipoInstitucionId: number;
  regionId?: number;
  comunaId: number;
}

interface SearchBarHeaderProps {
  showNameSearch?: boolean;
}

function SearchBarHeader({ showNameSearch }: SearchBarHeaderProps) {
  const [regionId, setRegionId] = useState<number>();
  const navigate = useNavigateScrollTop();
  const normalNavigate = useNavigate();

  const onSearch = ({ tipoInstitucionId, regionId, comunaId }: FormData) => {
    if (!tipoInstitucionId) {
      cogoToast.info("Debe seleccionar un tipo de establecimiento");
      return;
    }

    if (regionId) {
      localStorage.setItem("REGION", regionId.toString());
    }

    if (comunaId) {
      localStorage.setItem("COMUNA", comunaId.toString());
    }

    try {
      // normalNavigate(0); is used to force page reload, page reload does no trigger because sometimes only the query params change but not the main url
      if (InsEduTiposIds.includes(tipoInstitucionId as number)) {
        navigate(
          `/institucion-educativa/busqueda?${FilterObjectToUrlParams({
            tipo_institucion: tipoInstitucionId,
          })}`
        );
        normalNavigate(0);
      } else if (tipoInstitucionId === TipoInstitucionIds.ACADEMIA_DEPORTIVA) {
        navigate(`/academia-deportiva/busqueda`);
        normalNavigate(0);
      } else if (tipoInstitucionId === TipoInstitucionIds.ACADEMIA_IDIOMAS) {
        navigate(`/academia-idiomas/busqueda`);
        normalNavigate(0);
      } else if (tipoInstitucionId === TipoInstitucionIds.ACADEMIA_ARTISTICA) {
        navigate(`/academia-artistica/busqueda`);
        normalNavigate(0);
      } else if (tipoInstitucionId === TipoInstitucionIds.PREUNIVERSITARIO) {
        navigate(`/preuniversitario/busqueda`);
        normalNavigate(0);
      } else if (tipoInstitucionId === TipoInstitucionIds.REFORZAMIENTO) {
        navigate(`/reforzamiento/busqueda`);
        normalNavigate(0);
      } else if (tipoInstitucionId === TipoInstitucionIds.ESCUELA_VERANO) {
        navigate(`/escuela-verano/busqueda`);
        normalNavigate(0);
      } else if (tipoInstitucionId === TipoInstitucionIds.GUARDERIA_INFANTIL) {
        navigate(`/guarderia/busqueda`);
        normalNavigate(0);
      }
    } catch (error) {
      navigate(`/institucion-educativa/busqueda?`);
    }
  };

  const colSpans = { xs: 24, sm: 24, md: 24, lg: 6, xl: 6, xxl: 6 };

  return (
    <div className="headerSearchBar">
      <Form layout="vertical" onFinish={onSearch}>
        <Row gutter={4} justify="space-between">
          <Col xs={24} sm={24} md={7} lg={4} xl={7}>
            <Form.Item
              name={"tipoInstitucionId"}
              label={"Tipo Establecimiento"}
              rules={[{ required: true, message: "" }]}
              required={false}
            >
              <SelectTipoInsEdu />
            </Form.Item>
          </Col>
          <Col {...colSpans}>
            <Form.Item name={"regionId"} label={"Región"}>
              <SelectRegion onChange={setRegionId} />
            </Form.Item>
          </Col>
          <Col {...colSpans}>
            <Form.Item name={"comunaId"} label={"Comuna"}>
              <SelectComuna regionId={regionId} disabled={!regionId} />
            </Form.Item>
          </Col>
          {showNameSearch && (
            <Col span={24}>
              <Form.Item name={"nombre"} label={"Nombre Institución"}>
                <Input placeholder="Nombre Institución" />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <Form.Item label=" ">
              <Button htmlType="submit">Buscar</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default SearchBarHeader;
