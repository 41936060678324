import { useRequest } from "ahooks";
import { Affix, Col, Pagination, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import AntdConsts from "../../../../designConstants/antd-constants";
import EscuelaVeranoSimpleView from "./EscuelaVeranoSimpleView";
import CardLoading from "../../../../components/CardLoading";
import { useCustomCompareEffect } from "use-custom-compare";
import useGeo from "../../../../hooks/useGeo";
import EscuelaVeranoReq from "../../../../commonRequests/escuelaVerano.req";
import {
  escuelaVeranoFilterComparator,
  EscuelaVeranoFilters,
} from "../../../../types/filtros.type";
import { useParams } from "react-router-dom";
import { parseUrlQueryInsEdu } from "../../../../utils/filterUtils";
import QuitarUbicacionButton from "../../../../components/QuitarUbicacionButton";
import Swal from "sweetalert2";
import PopUpBusquedas from "../../../../components/PopUpBusquedas";
import AdsBanner from "../../../../components/AdsBanner";

interface ListaEscuelaVeranoProps {
  filters?: EscuelaVeranoFilters;
  setFilters?: (filters: EscuelaVeranoFilters) => void;
}

const ListaEscuelaVeranoPage = ({
  filters,
  setFilters,
}: ListaEscuelaVeranoProps) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(filters?.take || 18);

  const geolocation = useGeo();

  const {
    data: manyEscuelaVerano,
    loading,
    runAsync: loadMoreEscuelaVerano,
  } = useRequest(
    async () => {
      const EscuelaVeranoList = await EscuelaVeranoReq.getMany({
        ...filters,
        take: pageSize,
        skip: (page - 1) * pageSize,
        latitud: geolocation?.latitude,
        longitud: geolocation?.longitude,
      });
      if (EscuelaVeranoList === null) {
        cogoToast.error("No se pudo cargar la información solicitada");
      }

      return EscuelaVeranoList;
    },
    { manual: true }
  );

  const params = useParams();

  useEffect(() => {
    setFilters?.({
      ...filters,
      tipo_institucion: parseUrlQueryInsEdu()?.tipo_institucion,
    });
  }, [params]);

  useCustomCompareEffect(
    () => {
      loadMoreEscuelaVerano();
    },
    [filters],
    (prevDeps, nextDeps) =>
      escuelaVeranoFilterComparator(nextDeps[0], prevDeps[0])
  );

  const onPageChange = async (newPage: number, newPageSize: number) => {
    window.scrollTo(0, 0);
    setPage(newPage);
    setPageSize(pageSize);
    setFilters?.({
      ...filters,
      take: pageSize,
      skip: (page - 1) * pageSize,
    });
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <>
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
      </>
    );
  }

  if (!manyEscuelaVerano) {
    return <div></div>;
  }

  if (manyEscuelaVerano.items.length === 0) {
    { PopUpBusquedas() }
    return (
      <>
        <Row gutter={AntdConsts.rowGutter} justify="center">
          <Typography.Text>
            {window.scrollTo(0, 0)}
            No se encontraron Instituciones.
          </Typography.Text>
        </Row>
      </>
    );
  }

  const cardSpans = { xs: 24, md: 24, lg: 12, xl: 12, xxl: 12 };

  return (
    <>
      <Row gutter={AntdConsts.rowGutter} justify="center">
        {manyEscuelaVerano.items.map((ev, index) => {
          const showAd = index % 4 === 0 && index !== 0;
          return (
            <>
              {showAd && (
                <Col {...AntdConsts.fullSizeColLayout}>
                  <AdsBanner />
                </Col>
              )}
              <Col {...cardSpans} style={{ display: "flex" }}>
                <EscuelaVeranoSimpleView
                  key={ev.id}
                  escuelaVerano={ev}
                  geoLocation={geolocation}
                />
              </Col>
            </>
          )
        })}
      </Row>
      <Pagination
        style={{
          backgroundColor: "whitesmoke",
          padding: "1em",
          zIndex: 1000,
        }}
        onChange={onPageChange}
        total={manyEscuelaVerano.total}
        responsive
        current={page}
      />
    </>
  );
};

export default ListaEscuelaVeranoPage;
