import { AcademiaArtisticaFilterSelectors, AcademiaDeportivaFiltersFilterSelectors, AcademiaIdiomasFilterSelectors, EscuelaVeranoFiltersFilterSelectors, InstitucionEducativaFilterSelectors, PreuniversitarioFiltersFilterSelectors } from "../types/filtros.type";
import request from "./axiosInstance";

const getFiltersInstitucionEducativa = async () => {
  try {
    const response = await request.post<InstitucionEducativaFilterSelectors>(
      "/institucion-educativa/filters"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getFiltersEscuelaVerano = async () => {
  try {
    const response = await request.post<EscuelaVeranoFiltersFilterSelectors>(
      "/escuela-verano/filters"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getFiltersAcademiaDeportiva = async () => {
  try {
    const response = await request.post<AcademiaDeportivaFiltersFilterSelectors>(
      "/academia-deportiva/filters"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getFiltersPreuniversitario = async () => {
  try {
    const response = await request.post<PreuniversitarioFiltersFilterSelectors>(
      "/preuniversitario/filters"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getFiltersAcademiaIdiomas = async () => {
  try {
    const response = await request.post<AcademiaIdiomasFilterSelectors>(
      "/academia-idiomas/filters"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getFiltersAcademiaArtistica = async () => {
  try {
    const response = await request.post<AcademiaArtisticaFilterSelectors>(
      "/academia-artistica/filters"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const FiltersReq = {
  getFiltersInstitucionEducativa,
  getFiltersEscuelaVerano,
  getFiltersAcademiaDeportiva,
  getFiltersPreuniversitario,
  getFiltersAcademiaIdiomas,
  getFiltersAcademiaArtistica
};

export default FiltersReq;
