import { Table, Typography } from "antd";
import SingleInstitucionEducativa from "../types/SingleInsEdu.type";

interface TablaSimceProps {
  insEdu: SingleInstitucionEducativa;
  className?: string;
}

const TablaSimce = ({ insEdu }: TablaSimceProps) => {
  const columns = [
    {
      title: "NIVEL",
      dataIndex: "nivel",
      key: "nivel",
    },
    {
      title: "PUNTAJE PRUEBA DE MATEMÁTICA",
      dataIndex: "puntajeMatematica",
      key: "puntajeMatematica",
    },
    {
      title: "PUNTAJE PRUEBA DE LENGUAJE",
      dataIndex: "puntajeLenguaje",
      key: "puntajeLenguaje",
    },
  ];

  const dataSource = [
    {
      key: "1",
      nivel: "Básica",
      puntajeMatematica: insEdu.resultadoSimceBasicaMatematica || "-",
      puntajeLenguaje: insEdu.resultadoSimceBasicaLenguaje || "-",
    },
    {
      key: "1",
      nivel: "Media",
      puntajeMatematica: insEdu.resultadoSimceMediaMatematica || "-",
      puntajeLenguaje: insEdu.resultadoSimceMediaLenguaje || "-",
    },
  ];

  return (
    <>
      <Typography.Title level={5}>Resultados SIMCE</Typography.Title>
      <Table
        style={{ width: "100%" }}
        bordered
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </>
  );
};

export default TablaSimce;
