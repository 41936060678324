import { Spin } from "antd";
import HeaderModern from "../../views/header/HeaderModern";
import "./LoadingSpinner.css";

interface LoadingSpinnerProps {
  active?: boolean;
  fullScreen?: boolean;
}

const LoadingSpinner = ({ active, fullScreen }: LoadingSpinnerProps) => {
  if (!active && active !== undefined) {
    return null;
  }

  return (
    <div
      style={{
        left: fullScreen ? "0%" : "50%",
        top: fullScreen ? "0%" : "50%",
        float: "initial",
        textAlign: "center",
        // position: "absolute",
        width: fullScreen ? "100vw" : undefined,
        height: fullScreen ? "100vh" : undefined,
        backgroundColor: fullScreen ? "white" : undefined,
        zIndex: 1000,
      }}
    >
      {
        fullScreen &&
        <HeaderModern hideSearchbar hideButtons />
      }
      <Spin size="large" style={{marginTop: "20vw"}}/>
    </div>
  );
};

export default LoadingSpinner;
