import { useRequest } from "ahooks";
import { Col, Form, FormInstance, InputNumber, Row, Typography } from "antd";
import { requestAniosEscolares } from "../../../../commonRequests/params";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";

interface VacantesInputProps {
  form: FormInstance;
}

const VacantesInput = ({ form }: VacantesInputProps) => {
  const nivelesEducativosIds = form.getFieldValue(
    "nivelesEducativosIds"
  ) as number[];

  const aniosEscolaresReq = useRequest(requestAniosEscolares);

  if (aniosEscolaresReq.loading) {
    return <LoadingSpinner />;
  }

  if (!aniosEscolaresReq.data) {
    aniosEscolaresReq.runAsync();
    return <LoadingSpinner />;
  }

  const aniosEscolaresPresentes = aniosEscolaresReq.data.filter((anio) =>
    nivelesEducativosIds?.includes(anio.nivelEscolarId)
  );

  return (
    <>
      <Typography.Title level={3}>{"Vacantes"}</Typography.Title>
      <Typography.Text>
        Ingrese las vacantes presentes en cada año escolar ofrecido por su
        institución, se permite agregar un máximo y un mínimo por ser datos
        cambiantes
      </Typography.Text>
      <br />
      <br />
      {aniosEscolaresPresentes?.map((a) => (
        <>
          <Typography.Title level={5}>{a.nombre}</Typography.Title>
          <Row gutter={AntdConsts.rowGutter}>
            <Col {...AntdConsts.twoColLayout}>
              <Form.Item
                initialValue={a.id}
                name={[
                  "nivelesEducativos",
                  a.nivelEscolarId,
                  "aniosEscolares",
                  a.id,
                  "id",
                ]}
                noStyle
              />
              <Form.Item
                rules={[{ required: true, message: "Campo obligatorio" }]}
                name={[
                  "nivelesEducativos",
                  a.nivelEscolarId,
                  "aniosEscolares",
                  a.id,
                  "vacantesMinimas",
                ]}
                label="Vacantes mínimas"
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col {...AntdConsts.twoColLayout}>
              <Form.Item
                rules={[{ required: true, message: "Campo obligatorio" }]}
                name={[
                  "nivelesEducativos",
                  a.nivelEscolarId,
                  "aniosEscolares",
                  a.id,
                  "vacantesMaximas",
                ]}
                label="Vacantes máximas"
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </>
      ))}
    </>
  );
};

export default VacantesInput;
