import { useInterval } from "ahooks";
import { Button, ButtonProps, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { reqResendVerificationMail } from "../commonRequests/authRequests";

interface ResendEmailVerificationButtonProps extends ButtonProps {}

const ResendEmailVerificationButton = (props: ResendEmailVerificationButtonProps) => {
  const [timeToAllowResendVerificationEmail, setTime] = useState(0); //miliseconds

  // decrease Time allow verification email resend by one every second
  useInterval(() => {
    if (timeToAllowResendVerificationEmail - 1000 < 0) {
      setTime(0);
    }
    if (timeToAllowResendVerificationEmail > 0) {
      setTime(timeToAllowResendVerificationEmail - 1000);
    }

    if (timeToAllowResendVerificationEmail < 0) {
      setTime(0);
    }
  }, 1000);

  const resendMail = async () => {
    setTime(1000*60) // 1minuto
    const response = await reqResendVerificationMail();
    if (!response) {
      cogoToast.error("No fue posible re-enviar el email");
      return;
    }

    cogoToast.success("Correo re-enviado correctamente");
  };

  return (
    <>
      {timeToAllowResendVerificationEmail === 0 && (
        <Button {...props} onClick={resendMail} style={{cursor: "pointer"}}>
          Reenviar correo?
        </Button>
      )}
      {timeToAllowResendVerificationEmail > 0 && (
        <Typography.Text strong>{`Correo reenviado!. Para reenviar nuevamente espere ${Math.floor(
          timeToAllowResendVerificationEmail / 1000
        )} segundos`}</Typography.Text>
      )}
    </>
  );
};

export default ResendEmailVerificationButton;
