import { useDeepCompareEffect, useRequest } from "ahooks";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { getUsuarios } from "../../../commonRequests/adminRequests";
import SelectTipoUsuario from "../../../components/Input/SelectTipoUsuario";
import AntdConsts from "../../../designConstants/antd-constants";
import { UserFilters } from "../../../types/auth.types";
import { useState } from "react";
import UsuariosTableActions from "./UsuariosTableActions";
import { Vendedor } from "../../../types/vendedores.type";
import useIsMobile from "../../../hooks/useMobile";

interface TableDataSource {
  id: number;
  correo: string;
  verificado: boolean;
  profile: "ADMIN" | "PUBLISHER";
  codigo: Vendedor;
}
const UsuariosTable = () => {
  const [filters, setFilters] = useState<UserFilters>({ take: 10, skip: 0 });

  const isMobile = useIsMobile();

  const usuarios = useRequest(() => getUsuarios(filters));

  useDeepCompareEffect(() => {
    usuarios.refreshAsync();
  }, [filters]);

  const onSearch = (values: UserFilters) => {
    if (values.nombre === "") {
      values.nombre = undefined;
    }
    if (values.email === "") {
      values.email = undefined;
    }
    setFilters(values);
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    setFilters({
      ...filters,
      take: pageSize,
      skip: (page - 1) * pageSize,
    });
  };

  //@ts-ignore
  const dataSource: TableDataSource[] | undefined = usuarios?.data?.items?.map(
    (u) => ({
      id: u.id,
      correo: u.email,
      nombre: u.nombre,
      verificado: u.verificado,
      profile: u.profile.type,
      rut: u.rutInstitucion,
      tipoInstitucion: u.tipoInstitucion.nombre,
      institucion: u.nombreInstitucion,
      telefono: u.telefono,
      codigo: u.vendedor,
    })
  );

  const columns: ColumnsType<TableDataSource> = [
    {
      title: "Correo",
      dataIndex: "correo",
      key: "correo",
      onCell: (record) => ({
        onClick: () => window.open(`/admin/usuario/${record.id}`, "_blank"),
      }),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      onCell: (record) => ({
        onClick: () => window.open(`/admin/usuario/${record.id}`, "_blank"),
      }),
    },
    {
      title: "RUT",
      dataIndex: "rut",
      key: "rut",
      onCell: (record) => ({
        onClick: () => window.open(`/admin/usuario/${record.id}`, "_blank"),
      }),
    },
    {
      title: "Institución",
      dataIndex: "institucion",
      key: "institucion",
      onCell: (record) => ({
        onClick: () => window.open(`/admin/usuario/${record.id}`, "_blank"),
      }),
    },
    {
      title: "Tipo de Establecimiento",
      dataIndex: "tipoInstitucion",
      key: "tipoInstitucion",
      onCell: (record) => ({
        onClick: () => window.open(`/admin/usuario/${record.id}`, "_blank"),
      }),
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      onCell: (record) => ({
        onClick: () => window.open(`/admin/usuario/${record.id}`, "_blank"),
      }),
    },
    {
      title: "Codigo vendedor",
      render: (_, record) => {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        if (!record?.codigo) {
          return "SIN VENDEDOR";
        }
        return (
          <a
            href={`/admin/vendedores/${record?.codigo?.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {record.codigo.nombre.toUpperCase()}
          </a>
        );
      },
    },
    {
      title: "Tipo usuario",
      dataIndex: "profile",
      key: "profile",
      render: (profileName) => (
        <Tag color={profileName === "PUBLISHER" ? "red" : undefined}>
          {profileName}
        </Tag>
      ),
    },
    {
      title: "Acciones",
      render: (_, record) => {
        return (
          <UsuariosTableActions
            key={record.id}
            userId={record.id}
            initialVerificationStatus={record.verificado}
          />
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Typography.Title level={3}>Usuarios</Typography.Title>
        <Form layout="vertical" onFinish={onSearch}>
          <Row gutter={AntdConsts.rowGutter}>
            <Col>
              <Form.Item name="email" label="E-mail">
                <Input placeholder="Buscar por email" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="nombre" label="Nombre">
                <Input placeholder="Buscar por nombre" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="profileId" label="Nivel de privilegios">
                <SelectTipoUsuario allowClear />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="verificado" valuePropName="checked" label={" "}>
                <Checkbox>Verificado?</Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={" "}>
                <Button htmlType="submit" type="primary">
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Row justify="space-around">
        <Col span={24}>
          <Table           
            scroll={{x: "max-content"}}
            style={{ cursor: "pointer"}}
            columns={columns}
            dataSource={dataSource}
            pagination={{
              responsive: true,
              pageSize: filters.take,
              current: Math.floor(filters.skip / filters.take + 1),
              total: usuarios.data?.total,
              onChange: onPaginationChange,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default UsuariosTable;
