import { Layout } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import HeaderDesign from "../../header/HeaderDesign";
import BuscadorLocalizacionColegios from "./components/BuscadorLocalizacionColegios";
import BuscadorNombreColegio from "./components/BuscadorNombreColegio";
import "./css/CategoriaColegios.css";

const colegio = require("../img/colegios.png");

interface ColegioSelectedProps {
  children?: JSX.Element;
}

function ColegioSelected({ children }: ColegioSelectedProps) {
  return (
    <>
      <HeaderDesign />
      <Layout>
        <Content style={{ padding: "0 0px", backgroundColor: "white" }}>
          <div>
            <img
              src={colegio}
              className="LogoColegio"
              alt="Logo categoría Colegios"
            />
            <label className="etiquetaCole">COLEGIOS</label>
          </div>
          <div className="barCatColegios">
            Buscador de Colegios por localización
          </div>
          <BuscadorLocalizacionColegios tipoInsEduDefaultId={1}/>
          <div className="barCatColegios">Buscador de Colegios por Nombre</div>
          <BuscadorNombreColegio />
        </Content>
        <Footer style={{ backgroundColor: "#1a17c2" }}></Footer>
      </Layout>
    </>
  );
}

export default ColegioSelected;
