import React from "react";
import Swal from "sweetalert2";

function PopUpBusquedas() {
  const reg = localStorage.getItem("REGION");
  const com = localStorage.getItem("COMUNA");
  const dir = localStorage.getItem("DIRECCION");

  if (reg || com || dir) {
    Swal.fire({
      icon: "error",
      title: "No se encontraron instituciones...",
      text: "En la ubicación seleccionada, verifique los datos y pruebe nuevamente.",
      confirmButtonText: "Buscar en todo Chile",
      confirmButtonColor: "#000080",
      showConfirmButton: true,
      position: "top",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("REGION");
        localStorage.removeItem("COMUNA");
        localStorage.removeItem("DIRECCION");
        window.location.reload();
      }
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "No se encontraron instituciones...",
      text: "Con el Nombre o filtros Ingresados, verifique los datos y pruebe nuevamente.",
      confirmButtonText: "Volver a la lista",
      denyButtonText: `Volver a la Página Principal`,
      confirmButtonColor: "#000080",
      denyButtonColor: "#FF006A",
      showDenyButton: true,
      showConfirmButton: true,
      position: "top",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
          var url = window.location.href;
          const newUrl = url.replace(/nombre=.*/, "");
          window.location.href = newUrl;
      }else if (result.isDenied) {
        window.location.href = "/";
      }
    });
  }

  return <div></div>;
}

export default PopUpBusquedas;
