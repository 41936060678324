import { Row, Col } from "antd";
import AntdConsts from "../../designConstants/antd-constants";
import useIsMobile from "../../hooks/useMobile";
import useNavigateScrollTop from "../../hooks/useNavigateScrollTop";
import { TipoInstitucionIds } from "../../types/instituciones.types";
import HeaderModern from "../header/HeaderModern";
import {
  academiaArtisticaIcon,
  academiaDeportivaIcon,
  colegioOnlineIcon,
  colegiosIcon,
  escuelaIdiomasIcon,
  EscuelaLenguajeIcon,
  EscuelasLibresIcon,
  escuelaVeranoIcon,
  guarderiaInfantilIcon,
  JardinInfantilIcon,
  libroIcon,
  preuniversitarioIcon,
} from "../home/components/CategoriasPagPrincipal";

const EditPageSelector = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigateScrollTop();

  const colSpan = { xs: 12, sm: 12, md: 8, lg: 8, xl: 4, xxl: 4 };

  return (
    <>
      <HeaderModern />

      <Row gutter={[20, 30]} align="middle" style={{ width: "100%" }}>
        <Col {...AntdConsts.fullSizeColLayout}>
          <h3
            className="titleCat"
            style={isMobile ? { fontSize: "2.5em" } : { marginTop: "2em" }}
          >
            Elija el tipo de <strong>Establecimiento</strong> que desea crear
          </h3>
        </Col>

        <Col {...colSpan} style={{ textAlign: "center" }}>
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                onClick={() =>
                  navigate(`/create/colegio?tipo=${TipoInstitucionIds.COLEGIO}`)
                }
                src={colegiosIcon}
                className="categoria-icon"
                alt="Colegios"
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Colegios</label>
            </Col>
          </Row>
        </Col>

        <Col {...colSpan} style={{ textAlign: "center" }}>
          <Row justify="center">
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                onClick={() =>
                  navigate(
                    `/create/colegio?tipo=${TipoInstitucionIds.JARDIN_INFANTIL}`
                  )
                }
                src={JardinInfantilIcon}
                className="categoria-icon"
                alt="Jardin Infantil"
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Jardín Infantil</label>
            </Col>
          </Row>
        </Col>

        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                onClick={() =>
                  navigate(
                    `/create/colegio?tipo=${TipoInstitucionIds.ESCUELA_LENGUAJE}`
                  )
                }
                src={EscuelaLenguajeIcon}
                className="categoria-icon"
                alt="Escuelas de Lenguaje"
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Escuela de Lenguaje</label>
            </Col>
          </Row>
        </Col>

        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                onClick={() =>
                  navigate(
                    `/create/colegio?tipo=${TipoInstitucionIds.ESCUELA_LIBRE}`
                  )
                }
                src={EscuelasLibresIcon}
                className="categoria-icon"
                alt="Escuelas Libres"
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Escuelas Libres</label>
            </Col>
          </Row>
        </Col>

        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                src={colegioOnlineIcon}
                onClick={() =>
                  navigate(
                    `/create/colegio?tipo=${TipoInstitucionIds.COLEGIO_ONLINE}`
                  )
                }
                className="categoria-icon"
                alt="Colegios online"
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Colegios Online</label>
            </Col>
          </Row>
        </Col>

        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                style={{ marginTop: "35px" }}
                onClick={() => navigate("/create/academia-deportiva")}
                src={academiaDeportivaIcon}
                className="categoria-icon"
                alt="Academias Deportivas"
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Academias Deportivas</label>
            </Col>
          </Row>
        </Col>

        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                src={escuelaIdiomasIcon}
                className="categoria-icon"
                alt="Escuela de idiomas"
                onClick={() => navigate("/create/academia-idiomas")}
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Instituto de idiomas</label>
            </Col>
          </Row>
        </Col>

        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                src={preuniversitarioIcon}
                className="categoria-icon"
                alt="Preuniversitario"
                onClick={() => navigate("/create/preuniversitario")}
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Preuniversitario</label>
            </Col>
          </Row>
        </Col>
        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                src={escuelaVeranoIcon}
                className="categoria-icon"
                alt="Escuela de Verano"
                onClick={() => navigate(`/create/escuela-verano`)}
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Escuela de Verano</label>
            </Col>
          </Row>
        </Col>

        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                src={guarderiaInfantilIcon}
                className="categoria-icon"
                alt="Escuela de Verano"
                onClick={() => navigate("/create/guarderia/")}
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Guardería Infantil</label>
            </Col>
          </Row>
        </Col>
        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                onClick={() => navigate(`/create/academia-artistica`)}
                src={academiaArtisticaIcon}
                className="categoria-icon"
                alt="Academia Artistica"
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Academias Artísticas</label>
            </Col>
          </Row>
        </Col>
        <Col
          {...colSpan}
          className="contIconos"
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col {...AntdConsts.fullSizeColLayout}>
              <img
                style={{ marginTop: "30px" }}
                onClick={() => navigate(`/create/reforzamiento`)}
                src={libroIcon}
                className="categoria-icon"
                alt="Reforzamiento"
              />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <label className="txtCategoria">Reforzamiento</label>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EditPageSelector;
