import { Card, Col, Descriptions, List, Row, Space, Typography } from "antd";
import { EnrichedGeolocationCoordinates } from "react-hook-geolocation";
import {
  FaChalkboardTeacher,
  FaClock,
  FaDirections,
  FaMapMarkedAlt,
  FaMoneyBillAlt,
} from "react-icons/fa";
import currencyFormatter from "../../../../utils";
import CardContainer from "../../../../components/CardContainer";
import AntdConsts from "../../../../designConstants/antd-constants";
import { formatDistance } from "../../../../utils/filterUtils";
import { EscuelaVerano } from "../../../../types/escuelaVerano.type";

interface escuelaVeranoSimpleViewProps {
  escuelaVerano: EscuelaVerano;
  geoLocation?: EnrichedGeolocationCoordinates;
  className?: string;
}

const EscuelaVeranoSimpleView = ({
  escuelaVerano,
}: escuelaVeranoSimpleViewProps) => {
  //

  const geoDistance = escuelaVerano?.distance;

  const info = (
    <>
      {geoDistance && (
        <Space direction="horizontal">
          <FaDirections color="blue" fontSize={"1.5em"} />
          <Typography.Text>
            {`A ${formatDistance(geoDistance)} de su ubicación`}
          </Typography.Text>
        </Space>
      )}
      <Descriptions size="small" bordered column={1} title={<b>INFORMACIÓN</b>}>
        <Descriptions.Item
          label={
            <Space>
              <FaDirections color="blue" size={"1.3em"} />{" "}
              <Typography.Text>Dirección</Typography.Text>
            </Space>
          }
        >
          {escuelaVerano.direccion}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FaMapMarkedAlt color="blue" size={"1.3em"} />{" "}
              <Typography.Text>Comuna</Typography.Text>
            </Space>
          }
        >
          {escuelaVerano.comuna.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FaMoneyBillAlt color="blue" size={"1.5em"} />{" "}
              <Typography.Text>Precio</Typography.Text>
            </Space>
          }
        >
          {`${currencyFormatter.format(
            escuelaVerano.precioMin
          )} - ${currencyFormatter.format(escuelaVerano.precioMax)}`}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FaChalkboardTeacher color="blue" size={"1.5em"} />{" "}
              <Typography.Text>Modalidad</Typography.Text>
            </Space>
          }
        >
          {escuelaVerano.modalidad.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FaClock color="blue" size={"1.5em"} />{" "}
              <Typography.Text>Jornada</Typography.Text>
            </Space>
          }
        >
          {escuelaVerano.jornada.nombre}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  const esGuarderia = window.location.href.includes("guarderia");

  return (
    <CardContainer smallPadding>
      <Card
        className="small-card-body-padding"
        title={escuelaVerano.nombre.toUpperCase()}
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (esGuarderia == true) {
            window.open(`/guarderia/${escuelaVerano.id}`);
          } else {
            window.open(`/escuela-verano/${escuelaVerano.id}`);
          }
        }}
        cover={
          <img
            style={{
              aspectRatio: "1 / 1",
              overflow: "hidden",
              position: "relative",
              minHeight: "100%",
              maxHeight: "100%",
              minWidth: "100%",
              maxWidth: "100%",
            }}
            alt="example"
            src={`${process.env.REACT_APP_API_URL}instituciones/images/${escuelaVerano.fotos?.[0].id}`}
          />
        }
      >
        <Row gutter={AntdConsts.rowGutter}>
          <Col {...AntdConsts.fullSizeColLayout}>{info}</Col>
          <Col
            {...AntdConsts.fullSizeColLayout}
            style={{
              paddingLeft: "2em",
            }}
          >
            <Typography.Title level={5}>ACTIVIDADES</Typography.Title>
            <List
              style={{ maxHeight: "170px", overflowY: "auto" }}
              size="small"
              dataSource={escuelaVerano.actividades}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Col>
        </Row>
      </Card>
    </CardContainer>
  );
};

export default EscuelaVeranoSimpleView;
