import { Button, Popconfirm } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { UpdateSponsoredStatus } from "../../commonRequests/adminRequests";
import {
  TipoInstitucionEducativaIds,
  TipoInstitucionIds,
} from "../../types/instituciones.types";

interface DestacarInstitucionButtonProps {
  tipoInstitucionId: TipoInstitucionIds;
  id: number;
  startsDestacado: boolean;
  onChangeVisibility?: (newVisibility: boolean) => any;
}

const DestacarInstitucionButton = ({
  tipoInstitucionId,
  id,
  startsDestacado,
  onChangeVisibility,
}: DestacarInstitucionButtonProps) => {
  const [destacado, setDestacados] = useState(startsDestacado);
  const [loading, setLoading] = useState(false);

  if (destacado === undefined || destacado === null) {
    return <></>;
  }

  //TODO: agregar otrso tipos de institucion
  const onClick = async () => {
    setLoading(true);
    let response: any = null;
    if (TipoInstitucionEducativaIds.includes(tipoInstitucionId)) {
      response = await UpdateSponsoredStatus(tipoInstitucionId, id, !destacado);
    } else if (tipoInstitucionId === TipoInstitucionIds.ACADEMIA_DEPORTIVA) {
      response = await UpdateSponsoredStatus(tipoInstitucionId, id, !destacado);
    } else if (tipoInstitucionId === TipoInstitucionIds.ACADEMIA_IDIOMAS) {
      response = await UpdateSponsoredStatus(tipoInstitucionId, id, !destacado);
    } else if (
      tipoInstitucionId === TipoInstitucionIds.PREUNIVERSITARIO ||
      tipoInstitucionId === TipoInstitucionIds.REFORZAMIENTO
    ) {
      response = await UpdateSponsoredStatus(tipoInstitucionId, id, !destacado);
    } else if (tipoInstitucionId === TipoInstitucionIds.ACADEMIA_ARTISTICA) {
      response = await UpdateSponsoredStatus(tipoInstitucionId, id, !destacado);
    } else if (
      tipoInstitucionId === TipoInstitucionIds.ESCUELA_VERANO ||
      tipoInstitucionId === TipoInstitucionIds.GUARDERIA_INFANTIL
    ) {
      response = await UpdateSponsoredStatus(tipoInstitucionId, id, !destacado);
    }

    if (response === null) {
      cogoToast.error("No fue posible cambiar la institución");
      setLoading(false);
      return;
    }

    cogoToast.success("Institución modificada correctamente");
    onChangeVisibility?.(response);
    setLoading(false);
    setDestacados(response);
  };

  return (
    <Popconfirm
      title="Cambiar estado de la institución?"
      okText="Confirmar"
      cancelText="Cancelar"
      onConfirm={onClick}
      disabled={loading}
    >
      <Button
        block
        type={destacado ? "primary" : "default"}
        disabled={loading}
        icon={loading ? <LoadingOutlined /> : undefined}
      >
        {destacado ? "Quitar de destacados" : "Destacar"}
      </Button>
    </Popconfirm>
  );
};

export default DestacarInstitucionButton;
