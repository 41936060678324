import {
  Card,
  Col,
  Descriptions,
  List,
  Rate,
  Row,
  Space,
  Typography,
} from "antd";
import { EnrichedGeolocationCoordinates } from "react-hook-geolocation";
import { MdLocationPin } from "react-icons/md";
import {
  FaDirections,
  FaMapSigns,
  FaMoneyBillAlt,
  FaHome,
  FaCalendarAlt
} from "react-icons/fa";
import {BsGenderAmbiguous} from 'react-icons/bs'
import currencyFormatter from "../../../../utils";
import CardContainer from "../../../../components/CardContainer";
import { AcademiaArtistica } from "../../../../types/academiaArtistica.type";
import AntdConsts from "../../../../designConstants/antd-constants";
import { formatDistance } from "../../../../utils/filterUtils";
import IconoTablas from "../../../../components/iconosTablas/IconoTablas";
import Meta from "antd/lib/card/Meta";

interface AcademiaArtisticaSimpleViewProps {
  academiaArtistica: AcademiaArtistica;
  geoLocation?: EnrichedGeolocationCoordinates;
  className?: string;
}

const AcademiaArtisticaSimpleView = ({
  academiaArtistica,
}: AcademiaArtisticaSimpleViewProps) => {
  //

  const geoDistance = academiaArtistica?.distance;

  let rangoEdadStr = '';
  if (academiaArtistica.edadMinma === 0 || !academiaArtistica.edadMinma) {
    rangoEdadStr = `Hasta ${academiaArtistica.edadMaxima} años`;
  } else {
    rangoEdadStr = `${academiaArtistica.edadMinma} - ${academiaArtistica.edadMaxima}`;
  }

  const info = (
    <>
      {geoDistance && (
        <Space direction="horizontal">
          <FaDirections color="blue" fontSize={"1.5em"} />
          <Typography.Text>
            {`A ${formatDistance(geoDistance)} de su ubicación`}
          </Typography.Text>
        </Space>
      )}
      <Descriptions
        size="small"
        title="INFORMACIÓN"
        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        bordered
        layout="horizontal"
      >
        <Descriptions.Item
          label={
            <Space size={4}>
              <MdLocationPin style={{ color: "blue", fontSize: "1.5em" }} />
              Dirección
            </Space>
          }
        >
          {academiaArtistica.direccion}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size={4}>
              <FaMapSigns style={{ color: "blue", fontSize: "1.5em" }} />
              Comuna
            </Space>
          }
        >
          {academiaArtistica.comuna.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size={4}>
              <FaHome style={{ color: "blue", fontSize: "1.5em" }} />
              Modalidad
            </Space>
          }
        >
          {academiaArtistica.modalidad.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size={4}>
              <FaMoneyBillAlt style={{ color: "blue", fontSize: "1.5em" }} />
              Precio
            </Space>
          }
        >{`${currencyFormatter.format(
          academiaArtistica.precioMin
        )} - ${currencyFormatter.format(
          academiaArtistica.precioMax
        )}`}</Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size={4}>
              <FaCalendarAlt style={{ color: "blue", fontSize: "1.5em" }} />
              Edades (años)
            </Space>
          }
        >{rangoEdadStr}</Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size={4}>
              <BsGenderAmbiguous style={{ color: "blue", fontSize: "1.5em" }} />
              Genero
            </Space>
          }
        >{academiaArtistica.generoAlumnos.nombre}</Descriptions.Item>
      </Descriptions>
    </>
  );

  return (
    <CardContainer noPadding>
      <Card
        className="small-card-body-padding"
        style={{ width: "100%", cursor: "pointer" }}
        title={academiaArtistica.nombre.toUpperCase()}
        onClick={() =>
          window.open(`/academia-artistica/${academiaArtistica.id}`, "_blank")
        }
        cover={
          <img
            style={{
              aspectRatio: "1 / 1",
              overflow: "hidden",
              position: "relative",
              minHeight: "100%",
              maxHeight: "100%",
              minWidth: "100%",
              maxWidth: "100%",
            }}
            alt="example"
            src={`${process.env.REACT_APP_API_URL}instituciones/images/${academiaArtistica.fotos?.[0].id}`}
          />
        }
      >
        <Space direction="horizontal">
          <FaDirections color="blue" fontSize={"1.5em"} />
          <Typography.Title level={5}>
            {`${academiaArtistica.direccion}, ${academiaArtistica.comuna.nombre}, ${academiaArtistica.region.nombre}`}
          </Typography.Title>
        </Space>

        {geoDistance && (
          <Space direction="horizontal">
            <FaDirections color="blue" fontSize={"1.5em"} />
            <Typography.Text>
              {`A ${formatDistance(geoDistance)} de su ubicación`}
            </Typography.Text>
          </Space>
        )}
        <Row gutter={100} justify="space-between">
          <Col {...AntdConsts.fullSizeColLayout}>{info}</Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            {/* <Typography.Title level={5}>ARTES</Typography.Title> */}
            <div
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                fontSize: "1.2em",
              }}
            >
              <b>{"ARTES"}</b>
            </div>
            <List
              style={{
                width: "100%",
                marginTop: "1em",
                height: "100%",
                minHeight: "150px",
                maxHeight: "180px",
                overflowY: "auto",
              }}
              size="small"
              //header={<b>{"ARTES"}</b>}
              grid={{
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
                xxl: 4,
              }}
              dataSource={academiaArtistica.artes}
              bordered
              renderItem={(c) => (
                <List.Item style={{ margin: 0 }} key={c.id}>
                  <Space align="center">
                    <IconoTablas nombreIcono={c.nombre} />
                    <Typography.Text>{c.nombre.toUpperCase()}</Typography.Text>
                  </Space>
                </List.Item>
              )}
            />
          </Col>
          {academiaArtistica.instrumentos &&
            academiaArtistica.instrumentos.length !== 0 && (
              <Col {...AntdConsts.fullSizeColLayout}>
                <div
                  style={{
                    marginTop: "2.5em",
                    marginBottom: "1em",
                    fontSize: "1.2em",
                  }}
                >
                  <b>{"INTRUMENTOS"}</b>
                </div>
                <List
                  style={{
                    width: "100%",
                    marginTop: "1em",
                    height: "100%",
                    minHeight: "150px",
                    maxHeight: "180px",
                    overflowY: "auto",
                  }}
                  size="small"
                  // header={<b>{"INSTRUMENTOS"}</b>}
                  grid={{
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 4,
                  }}
                  dataSource={academiaArtistica.instrumentos}
                  bordered
                  renderItem={(c) => (
                    <List.Item style={{ margin: 0 }} key={c.id}>
                      <Space align="center">
                        <IconoTablas nombreIcono={c.nombre} />
                        <Typography.Text>
                          {c.nombre.toUpperCase()}
                        </Typography.Text>
                      </Space>
                    </List.Item>
                  )}
                />
              </Col>
            )}
        </Row>
      </Card>
    </CardContainer>
  );
};

export default AcademiaArtisticaSimpleView;
