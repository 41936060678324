import { Button, Descriptions, Space } from "antd";
import { useState } from "react";
import { SocialIcon } from "react-social-icons";
import SingleInstitucionEducativa from "../types/SingleInsEdu.type";
import ContactInsEduFormModal from "./ContactInsEduForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboardTeacher,
  faDesktop,
  faLocationDot,
  faMailBulk,
  faPhone,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import ContactarInstitucionButton from "../../../components/ContactarInstitucionButton";
import { CommonInstitucion } from "../../../types/instituciones.types";
import SocialNetworkButtons from "../../../components/SocialNetworkButtons";

interface InsEduContactInfoProps {
  insEdu: SingleInstitucionEducativa;
  className?: string;
}

const InsEduContactInfo = ({ insEdu, className }: InsEduContactInfoProps) => {
  return (
    <>
      <div>
        <Descriptions
          style={{ width: "100%" }}
          labelStyle={{ backgroundColor: "var(--labelsDescription)" }}
          contentStyle={{ backgroundColor: "white" }}
          title="CONTACTO"
          bordered
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          className={className}
        >
          <Descriptions.Item
            label={
              <Space align="center">
                <FontAwesomeIcon
                  style={{ color: "#1f3de7", fontSize: "25px" }}
                  icon={faLocationDot}
                />
                {"   "}
                Dirección
              </Space>
            }
          >
            {insEdu?.direccion}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Space align="center">
                <FontAwesomeIcon
                  style={{ color: "#1f3de7", fontSize: "25px" }}
                  icon={faPhone}
                />
                <b>Teléfono</b>
              </Space>
            }
          >
            {insEdu?.telefono}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Space align="center">
                <FontAwesomeIcon
                  style={{ color: "#1f3de7", fontSize: "25px" }}
                  icon={faDesktop}
                />
                {"   "}
                Página Web
              </Space>
            }
          >
            <a href={`https://${insEdu?.paginaWeb?.replace("https://","")}`} target="_blank" rel="noreferrer">{insEdu?.paginaWeb}</a>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Space align="center">
                <FontAwesomeIcon
                  style={{ color: "#1f3de7", fontSize: "25px" }}
                  icon={faMailBulk}
                />
                {"   "}
                Correo
              </Space>
            }
          >
            {insEdu?.email}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Space align="center">
                <FontAwesomeIcon
                  style={{ color: "#1f3de7", fontSize: "25px" }}
                  icon={faUserTie}
                />
                {"   "}
                Director(a)
              </Space>
            }
          >
            {insEdu?.director}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Space align="center">
                <FontAwesomeIcon
                  style={{ color: "#1f3de7", fontSize: "25px" }}
                  icon={faChalkboardTeacher}
                />
                {"   "}
                Modelo educativo
              </Space>
            }
          >
            {insEdu?.tipoEducacion?.nombre}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <br />
        <br />
        <Space direction="vertical" style={{ padding: "1em" }}>
          <p>
            <span className="blue-main-text">Redes Sociales: </span>
          </p>
          <SocialNetworkButtons
            institucion={insEdu.redesSociales as unknown as CommonInstitucion}
          />
          <b />
        </Space>
        <Space direction="vertical" style={{ padding: "1em" }}>
          <p>
            <span className="blue-main-text">Contacto: </span>
          </p>
          <ContactarInstitucionButton
            institucion={insEdu as unknown as CommonInstitucion}
          />
        </Space>
        <b />
      </div>
    </>
  );
};
export default InsEduContactInfo;
