import {
  Button,
  Checkbox,
  CheckboxOptionType,
  Col,
  ConfigProvider,
  Form,
  Input,
  Radio,
  Result,
  Row,
  Typography,
} from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  registerUserRequest,
  reqCheckEmailUnique,
} from "../../commonRequests/authRequests";
import SelectTipoInsEdu from "../../components/Input/SelectTipoInstitucion/SelectTipoInstitucion";
import AntdConsts from "../../designConstants/antd-constants";
import useIsMobile from "../../hooks/useMobile";
import { CreateUserPayload } from "../../types/auth.types";
import HeaderModern from "../header/HeaderModern";
import "./css/FormularioRegistro.css";
import TerminosYCondiciones from "./TerminosYCondiciones";
import image from "./img/registroPic.png";
import VendedorRequest from "../../commonRequests/vendedores.req";
import ResendEmailVerificationButton from "../../components/ResendEmailVerificationButton";

function FormularioRegistroUser() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [showResult, setShowResult] = useState(false);

  const rbd_junji_options = [
    { label: "RBD", value: "rbd" },
    { label: "Código JUNJI", value: "junji" },
    { label: "No posee", value: false },
  ];

  const [rbdOJunji, setRbdOJunji] = useState<"rbd" | "junji">();

  const onSubmit = async (data: CreateUserPayload) => {
    const isEmailUnique = await reqCheckEmailUnique(data.email);
    if (!isEmailUnique) {
      cogoToast.error("Ya existe una cuenta asociada a ese E-mail");
      return;
    }

    if (data.codigoVendedor) {
      const vendedorExiste = await VendedorRequest.checkExistence(
        data.codigoVendedor
      );
      if (!vendedorExiste) {
        cogoToast.error(
          "El código ingresado no está asociado a ningún vendedor, por favor verifíquelo o remueva el código del formulario",
          { hideAfter: 10 }
        );
        return;
      }
    }

    const authToken = await registerUserRequest(data);
    window.scrollTo(0, 0);
    if (!authToken) {
      cogoToast.error(
        "No fue posible crear la cuenta, por favor inténtelo en unos momentos"
      );
      return;
    }

    sessionStorage.setItem(
      process.env.REACT_APP_AUTH_TOKEN_NAME as string,
      authToken
    );

    setShowResult(true);
  };

  const successJSX = (
    <Result
      status="success"
      title="Cuenta de usuario creada correctamente"
      subTitle={
        <>
          <div>
            <Typography.Text>
              Gracias por registrarse en BUSCAESTUDIO.CL Para activar su cuenta, recuerde seguir el link enviado a su correo electrónico.
            </Typography.Text>
            <br/><br/>
          </div>
          <ResendEmailVerificationButton/>
        </>
      }
      extra={[
        <Button type="primary" key="console" onClick={() => navigate("/")}>
          Ir a la página principal
        </Button>,
      ]}
    />
  );

  const registerForm = (
    <ConfigProvider componentSize="large">
      <Form
        form={form}
        layout="vertical"
        name="register"
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Row gutter={AntdConsts.rowGutter[0]}>
          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="nombre"
              label="Nombre Completo (Nombre y Apellidos)"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su nombre",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="telefono"
              label="Teléfono contacto"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su teléfono!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <Form.Item
              name="email"
              label="Correo institucional"
              rules={[
                {
                  type: "email",
                  message: "El email ingresado no es válido!",
                },
                {
                  required: true,
                  message: "Por favor ingrese su email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="tipoInstitucionId"
              label="Tipo de Institución"
              rules={[
                {
                  required: true,
                  message: "Dato requerido",
                },
              ]}
            >
              <SelectTipoInsEdu />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="nombreInstitucion"
              label="Nombre Institución"
              rules={[
                {
                  required: true,
                  message: "Dato requerido!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="rutInstitucion"
              label="RUT establecimiento"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="rbdOJunji"
              label="Su establecimiento posee RBD o código JUNJI?"
              rules={[
                {
                  required: true,
                  message: "Dato requerido",
                },
              ]}
            >
              <Radio.Group
                options={rbd_junji_options as unknown as CheckboxOptionType[]}
                onChange={(v) => setRbdOJunji(v.target.value)}
              />
            </Form.Item>
          </Col>
          {rbdOJunji && (
            <Col {...AntdConsts.fullSizeColLayout}>
              <Form.Item
                name="rbd"
                label={rbdOJunji === "rbd" ? "RBD" : "Código JUNJI"}
                rules={[
                  {
                    required: true,
                    message: "Dato requerido",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="password"
              label="Contraseña"
              rules={[
                {
                  required: true,
                  message: "por favor ingrese su password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="confirmPassword"
              label="Confirme contraseña"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Por favor confirme su contraseña!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Las contraseñas no coinciden")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col {...AntdConsts.fullSizeColLayout}>
            <Form.Item name="codigoVendedor" label="Código vendedor (Opcional)">
              <Input />
            </Form.Item>
          </Col>

          <Col {...AntdConsts.fullSizeColLayout}>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Debe aceptar los términos de uso!")
                        ),
                },
              ]}
            >
              <Checkbox>
                He leído y acepto los <TerminosYCondiciones />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Registrarse
          </Button>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );

  if (isMobile) {
    return (
      <>
        <HeaderModern hideButtons hideSearchbar />

        <div style={{ padding: "1em" }}>
          {!showResult && registerForm}
          {showResult && successJSX}
        </div>
      </>
    );
  }

  return (
    <>
      <HeaderModern hideButtons hideSearchbar hideEmailNotVerifiedWarning/>
      <Row>
        <Col xs={24} sm={24} md={14} lg={14} xl={14} className="frosted-glass-background-no-hover">
          <div
            style={{
              padding: "1em",
              marginLeft: "15%",
              marginTop: "2%",
              backgroundColor: "rgba(252,252,252, 0.8)",
              backdropFilter: "blur(16.1px)",
            }}
          >
            {!showResult && (
              <>
                <Typography.Title level={2}>
                  Hola, vamos a configurar su cuenta
                </Typography.Title>
                <Typography.Paragraph style={{ fontSize: "1.56em" }}>
                  Cree una cuenta en buscaestudio.cl y de a conocer su
                  institución en tan solo unos minutos. <br /> ¿Ya tiene una
                  cuenta? <a href="/inicio-de-sesion">Inicie sesión aquí!</a>
                </Typography.Paragraph>
                {registerForm}
              </>
            )}
            {showResult && successJSX}
          </div>
        </Col>
        <Col xs={0} sm={0} md={10} lg={10} xl={10}>
          <img
            src={image}
            alt="Estudiantes en diferentes contextos"
            className="imgRegistro"
          />
        </Col>
      </Row>
    </>
  );
}

export default FormularioRegistroUser;
