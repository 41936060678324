import { FormInstance } from "antd";
import _ from "lodash";
import { baseUrl } from "../../../commonRequests/axiosInstance";
import { transformUploadFilesToFormData } from "../../../designConstants/antd-utils";
import { Preuniversitario } from "../../../types/preuniversitario.type";

export const formDataToPreuniversitarioCreateObject = (form: FormInstance) => {
  const data = _.cloneDeep(form.getFieldsValue(true));
  // Obtenemos los valores de los campos de imagenes
  data.fotos = transformUploadFilesToFormData(form);

  return { ...data, defaultImage: undefined, restOfImages: undefined };
};

export const preiuniversitarioToFormData = (preuniversitario: Preuniversitario) => {
  return {
    ...preuniversitario,
    asignaturasExtra: preuniversitario.asignaturas.filter(a => a.esComun === false).map(a => a.nombre).join("\n"),
    asignaturas: preuniversitario.asignaturas.filter(a => a.esComun === true).map((i) => i.id),
    nivelesExtra: preuniversitario.niveles.filter(a => a.esComun === false).map(a => a.nombre).join("\n"),
    niveles: preuniversitario.niveles.filter(a => a.esComun === true).map((i) => i.id),
    jornadaId: preuniversitario?.jornada?.id,
    modalidadEstudioId: preuniversitario.modalidad.id,
    defaultImage: [
      {
        uid: 0,
        name: `default.png`,
        status: "done",
        response: preuniversitario?.fotos?.[0],
        url: `${baseUrl}instituciones/images/${preuniversitario?.fotos?.[0]?.id}`,
      },
    ],
    restOfImages: preuniversitario.fotos.slice(1).map((f, index) => ({
      uid: 0,
      name: `${index}.png`,
      status: "done",
      response: f,
      url: `${baseUrl}instituciones/images/${f?.id}`,
    })),
  };
};
