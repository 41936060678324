import { useRequest } from "ahooks";
import { Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import { reqTipoEducacion } from "../commonRequests/params";

interface SelecttTipoEducacionProps {
  value?: number;
  onChange?: (newValue: any) => any;
  placeholder?: string;
  props?: SelectProps;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const SelectTipoEducacion = ({
  value,
  onChange,
  placeholder,
  className,
  style,
  disabled,
  ...props
}: SelecttTipoEducacionProps) => {
  const [internalValue, setInternalValue] = useState<number>();

  const { data: comunas, loading } = useRequest(reqTipoEducacion);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <Select
      style={style}
      className={className}
      disabled={loading || disabled}
      value={internalValue}
      onChange={onChange}
      placeholder={placeholder ? placeholder : "Tipo de educación"}
      {...props}
    >
      {comunas?.map((c) => {
        return <Select.Option value={c.id}>{c.nombre}</Select.Option>;
      })}
    </Select>
  );
};

export default SelectTipoEducacion;
