import { Button, Col, Divider, Row } from "antd";
import FilterSelector from "../../components/FilterSelector/FilterSelector";
import HeaderModern from "../header/HeaderModern";
import ListaInsEduPage from "./components/BuscadorInsEdu";
import "./ListaInsEdu.less";
import "./css/InsEduSimpleView.css";
import AdsBanner from "../../components/AdsBanner";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useState } from "react";
import { InstitucionEducativaFilters } from "../../types/filtros.type";
import { parseUrlQueryInsEdu } from "../../utils/filterUtils";
import SponsoredCarousel from "../../components/SponsoredCarousel";
import BuscadorPorUbicacionMobile from "../../components/BuscadorPorUbicacionMobile";

const BusquedaInsEduPage = () => {
  const [filters, setFilters] = useState<InstitucionEducativaFilters>({
    ...parseUrlQueryInsEdu(),
    region_id: +(localStorage.getItem("REGION") as string) || undefined,
    comuna_id: +(localStorage.getItem("COMUNA") as string) || undefined,
    geo_direccion: localStorage.getItem("DIRECCION") || undefined,
    hidden: false,
  });

  const onChangeLocation = () => {
    setFilters({
      ...filters,
      region_id: +(localStorage.getItem("REGION") as string) || undefined,
      comuna_id: +(localStorage.getItem("COMUNA") as string) || undefined,
      geo_direccion: localStorage.getItem("DIRECCION") || undefined,
    });
  };


  return (
    <>
      <HeaderModern affixTop askLocation onChangeLocation={onChangeLocation} />

      <Row>
        <SponsoredCarousel
          tipoInstitucionId={filters.tipo_institucion?.[0] || undefined}
        />
      </Row>

      <div
        style={{
          paddingTop: "2em",
          overflowX: "scroll",
        }}
        className="pagina-busqueda"
      >
        <BuscadorPorUbicacionMobile
          style={{ paddingLeft: "1em", paddingRight: "1em" }}
        />

        <Row gutter={10}>
          <Col
            style={
              {
                // padding: "0.5em",
                // marginRight: "10px"
              }
            }
            xs={24}
            sm={24}
            md={5}
            lg={5}
            xl={5}
            xxl={5}
          >
            <FilterSelector currentFilters={filters} setFilters={setFilters} />
            <Divider type="vertical" />
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Button type="text" href="/" style={{ fontWeight: "bold" }}>
              <FaArrowCircleLeft />
              &nbsp;&nbsp;Volver a la Página Principal
            </Button>
            <ListaInsEduPage filters={filters} setFilters={setFilters} />
          </Col>
          <Col xs={0} sm={0} md={3} lg={3} xl={3} xxl={3}>
            <AdsBanner vertical />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BusquedaInsEduPage;