import { useRequest } from "ahooks";
import { Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import { requestTiposUsuarios } from "../../commonRequests/params";

interface SelectTipoUsuarioProps extends SelectProps {
  value?: number;
  onChange?: (newValue: any) => any;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const SelectTipoUsuario = ({
  value,
  onChange,
  placeholder,
  className,
  style,
  disabled,
  ...props
}: SelectTipoUsuarioProps) => {
  const [internalValue, setInternalValue] = useState<number>();

  const { data: comunas, loading } = useRequest(requestTiposUsuarios);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <Select
      style={style}
      className={className}
      disabled={loading || disabled}
      value={internalValue}
      onChange={onChange}
      placeholder={placeholder ? placeholder : "Privilegios Usuario"}
      allowClear
      {...props}
    >
      {comunas?.map((c) => {
        return <Select.Option value={c.id}>{c.type}</Select.Option>;
      })}
    </Select>
  );
};

export default SelectTipoUsuario;
