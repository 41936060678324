import { Affix, Button, Col, Result, Row, Space, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import AntdConsts from "../../../../designConstants/antd-constants";
import AcademiaDeportiveFormStepCounter from "../../submitacademiasDeportivasPage/components/AcademiaDeportiveFormStepCounter";

interface AcademiaDeportivaFinalStepProps {
  id: number;
}

const AcademiaArtisticaFinalStep = ({
  id,
}: AcademiaDeportivaFinalStepProps) => {
  const navigate = useNavigate();

  // Si la url contiene una id es que es una edicion
  const { id: academiaArtisticaId } = useParams();


  return (
    <>
      <Row>
        <Col
          lg={8}
          xl={8}
          xxl={8}
          {...AntdConsts.removeColOnMobileLayout}
          style={{ padding: "2em", paddingTop: "20px" }}
        >
          <Affix offsetTop={AntdConsts.headerHeight + 20}>
            <AcademiaDeportiveFormStepCounter
              currentStep={0}
              direction="vertical"
            />
          </Affix>
        </Col>
        <Col
          lg={16}
          xl={16}
          xxl={16}
          {...AntdConsts.fullSizeColOnMobileLayout}
          style={{ padding: "2em", backgroundColor: "white" }}
        >
          <Result
            status="success"
            title={
              academiaArtisticaId
                ? "Institución editada correctamente!"
                : "Institución creada correctamente!"
            }
            extra={
              <Space>
                <Typography.Text type="secondary">
                  Antes de publicarse los cambios, estos serán revisados por BuscaEstudio.
                </Typography.Text>
                <br/>
                <Button
                  type="primary"
                  key="console"
                  block
                  onClick={() => navigate(`/preuniversitario/${id}`)}
                >
                  Ver institucion
                </Button>
                ,
                <Button block key="buy">
                  Volver al inicio
                </Button>
                ,
              </Space>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default AcademiaArtisticaFinalStep;
