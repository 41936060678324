import { Button, Form, Input, Modal, Typography } from "antd";
import { useState } from "react";
import SelectComuna from "../Input/SelectComuna/SelectComuna";
import SelectRegion from "../SelectRequest/SelectRegion";

interface AskRegionModalProps {
  isOpen: boolean;
  handleOk: Function;
  handleClose: Function;
}

function AskRegionModal({
  isOpen,
  handleOk,
  handleClose,
}: AskRegionModalProps) {
  const [regionId, setRegionId] = useState<number>();
  const [comunaId, setComunaId] = useState<number>();
  const [direccion, setDireccion] = useState<string>();

  const onRegionChange = (id: number) => {
    setRegionId(id);
  };

  const a = localStorage.getItem("COMUNA");

  return (
    <>
      <Modal
        title="Ubicación"
        visible={isOpen}
        onOk={() => handleOk(regionId, comunaId, direccion)}
        onCancel={() => handleClose()}
        cancelText="Cancelar"
      >
        <Typography.Paragraph>
          Ingrese su dirección para buscar el colegio más cercano a su
          ubicación.
        </Typography.Paragraph>
        <Form layout="vertical">
          <Form.Item
            label="Región"
            // initialValue={+localStorage.removeItem("REGION") || undefined}
          >
            <SelectRegion onChange={onRegionChange} />
          </Form.Item>
          <Form.Item
            label="Comuna"
            // initialValue={+localStorage.removeItem("COMUNA") || undefined}
          >
            <SelectComuna
              regionId={regionId}
              onChange={setComunaId}
              disabled={!regionId}
            />
          </Form.Item>
          <Form.Item
            label="Dirección (Opcional)"
            // initialValue={+localStorage.removeItem("DIRECCION") || undefined}
          >
            <Input onChange={(e) => setDireccion(e.target.value)} />
          </Form.Item>
        </Form>
        <Button
          type="link"
          onClick={() => {
            localStorage.removeItem("COMUNA");
            localStorage.removeItem("REGION");
            localStorage.removeItem("UBICACION");
            window.location.reload();
          }}
        >
          Eliminar ubicación
        </Button>
      </Modal>
    </>
  );
}

export default AskRegionModal;
