import request from "../../commonRequests/axiosInstance";
import SingleInstitucionEducativaEasyVisualization from "./types/SingleInstitucionEducativaEasyVisualization.type";

const reqSingleInsEdu = async (id: number) => {
  try {
    const singleInsEdu =
      await request.get<SingleInstitucionEducativaEasyVisualization>(
        `institucion-educativa/${id}`
      );
    return singleInsEdu.data;
  } catch (error) {
    return null;
  }
};

export default reqSingleInsEdu;
