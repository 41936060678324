import { Form, Input } from "antd";
import { useParams } from "react-router-dom";

const DescripcionEdicionInput = () => {
  const { id: isUpdate } = useParams();
  return (
    <>
      {isUpdate && (
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio"},{min: 10, message: "Debe escribir la razón de su edición, utilice al menos 10 caracteres"}]}
          name="descripcionEdicion"
          label="Por favor ingrese una breve explicación sobre la razón que hizo necesaria la edición de su establecimiento (Opcional)"
        >
          <Input.TextArea />
        </Form.Item>
      )}
    </>
  );
};

export default DescripcionEdicionInput;
