import { Affix, Button, Col, Divider, Form, FormInstance, Row, Space } from "antd";
import cogoToast from "cogo-toast";
import AntdConsts from "../../../../designConstants/antd-constants";
import { scrollToFieldWithOffsetFunction } from "../../../../designConstants/antd-utils";
import CaracteristicasExtraInput from "../components/CaracteristicasExtraInput";
import ExtracurricularesInput from "../components/ExtracurricularesInput";
import FormStepCounter from "../components/FormStepCounter";

interface StepThreeProps {
  form: FormInstance;
  onNext: () => void;
  onBack: () => void;
}

const StepThree = ({ form, onNext, onBack }: StepThreeProps) => {
  const onStepFinish = () => {
    form
      .validateFields()
      .then((_) => onNext())
      .catch((error) => {
        form.scrollToField(error.errorFields?.[0]?.name, {
          behavior: scrollToFieldWithOffsetFunction,
        });
        cogoToast.error("Existen errores en el formulario");
      });
  };

  return (
    <>
      <Row>
        <Col
          lg={8}
          xl={8}
          xxl={8}
          {...AntdConsts.removeColOnMobileLayout}
          style={{ padding: "2em", paddingTop: "20px" }}
        >
          <Affix offsetTop={AntdConsts.headerHeight + 20}>
            <FormStepCounter currentStep={2} direction="vertical" />
          </Affix>
        </Col>
        <Col
          lg={16}
          xl={16}
          xxl={16}
          {...AntdConsts.fullSizeColOnMobileLayout}
          style={{ padding: "2em", backgroundColor: "white" }}
        >
          {/* <CaracteristicasYExtracurricularesInput form={form} /> */}
          <CaracteristicasExtraInput />
          <Divider />
          <ExtracurricularesInput />
          <Space
            direction="horizontal"
            style={{ float: "right", marginTop: "2em" }}
          >
            <Button type="default" onClick={onBack}>
              Anterior
            </Button>
            <Button type="primary" onClick={onStepFinish}>
              Siguiente
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default StepThree;
