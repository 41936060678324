import { Space } from "antd";
import { SocialIcon } from "react-social-icons";
import { CommonInstitucion } from "../types/instituciones.types";

const SocialNetworkButtons = ({
  institucion,
}: {
  institucion: CommonInstitucion;
}) => {
  if (
    !institucion.facebook &&
    !institucion.whatsapp &&
    !institucion.twitter &&
    !institucion.instagram
  ) {
    return <>Sin links a redes sociales</>;
  }
  return (
    <Space align="center" direction="horizontal" style={{ paddingLeft: "2em" }}>
      {institucion.facebook && (
        <SocialIcon
          url={`//${institucion.facebook.replace("https://","")}`}
          network="facebook"
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
      {institucion.whatsapp && (
        <SocialIcon
          url={`http://wa.me/${institucion.whatsapp.replace(/\D/g, "")}`}
          network="whatsapp"
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
      {institucion.twitter && (
        <SocialIcon
          url={`//${institucion.twitter.replace("https://","")}`}
          network="twitter"
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
      {institucion.instagram && (
        <SocialIcon
          url={`//${institucion.instagram.replace("https://","")}`}
          network="instagram"
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
    </Space>
  );
};

export default SocialNetworkButtons;
