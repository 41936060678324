import { ConfigProvider, Form } from "antd";
import { useState } from "react";
import HeaderModern from "../../header/HeaderModern";

import cogoToast from "cogo-toast";
import AcademiaDeportivaFormStepOne from "./steps/AcademiaDeportivaFormStepOne";
import AcademiaDeportivaFormStepTwo from "./steps/AcademiaDeportivaFormStepTwo";
import {
  reqCreateAcademiaDeportiva,
  requestSingleAcademiaDeportiva,
  reqUpdateAcademiaDeportiva,
} from "../../../commonRequests/academiaDeportesRequest";
import AcademiaDeportivaFinalStep from "./steps/FinalStep";
import {
  academiaDeportesToFormData,
  transformPayloadAcademiaDeportiva,
} from "./transformerAcademiaDeportes";
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import useIsMobile from "../../../hooks/useMobile";

const AcademiaDeportivaFormPage = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [createdId, setCreatedId] = useState<number>();

  const isMobile = useIsMobile();

  const onNextStep = () => {
    window.scrollTo(0, 0);
    setCurrentStep(currentStep + 1); window.scrollTo(0, 0);
  };
  const onBackStep = () => setCurrentStep(currentStep - 1);

  const academiaDeportivaReq = useRequest(async () => {
    if (!id) {
      return;
    }
    const response = await requestSingleAcademiaDeportiva(+id);

    if (!response) {
      return;
    }
    const formData = academiaDeportesToFormData(response);
    form.setFieldsValue(formData);
  });

  const onEdit = async () => {
    const data = transformPayloadAcademiaDeportiva(form);
    const response = await reqUpdateAcademiaDeportiva({
      ...data,
      id: +(id as string),
    });
    if (!response) {
      cogoToast.error("No fue posible editar la Institución");
    } else {
      setCreatedId(response.id);
      setCurrentStep(2);
      cogoToast.success("Institución editada correctamente");
    }
  };

  const onSubmit = async () => {
    if (id) {
      return onEdit();
    }
    const data = transformPayloadAcademiaDeportiva(form);
    const response = await reqCreateAcademiaDeportiva(data);
    if (!response) {
      cogoToast.error("No fue posible crear la institución");
    } else {
      setCreatedId(response.id);
      setCurrentStep(2);
      cogoToast.success("Institución creada correctamente");
    }
  };

  const onFormError = (values: any) => {
    cogoToast.error("Existen errores en el formulario");
  };

  if (academiaDeportivaReq.loading) {
    return <LoadingSpinner />;
  }

  const steps = [
    {
      step: 0,
      component: (
        <AcademiaDeportivaFormStepOne form={form} onNext={onNextStep} />
      ),
    },
    {
      step: 1,
      component: (
        <AcademiaDeportivaFormStepTwo onNext={onNextStep} onBack={onBackStep} />
      ),
    },
    {
      step: 2,
      component: <AcademiaDeportivaFinalStep id={createdId as number} />,
    },
  ];

  return (
    <>
      <HeaderModern affixTop />
      <div
        style={
          isMobile
            ? { paddingLeft: "1em", paddingRight: "1em" }
            : { paddingLeft: "15%", paddingRight: "15%" }
        }
      >
        <ConfigProvider
          componentSize="large"
          form={{ validateMessages: { required: "Campo obligatorio" } }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={onFormError}
          >
            {steps.find((step) => step.step === currentStep)?.component}
          </Form>
        </ConfigProvider>
      </div>
    </>
  );
};

export default AcademiaDeportivaFormPage;
