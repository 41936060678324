import {
  AcademiaArtistica,
  AcademiaArtisticaCreateObject,
  AcademiaArtisticaUpdateObject,
} from "../types/academiaArtistica.type";
import { FilterGroup } from "../types/filters.type";
import { AcademiaArtisticaFilters } from "../types/filtros.type";
import request from "./axiosInstance";

export const reqAcademiaArtisticaFilters = async (
  currentFilters: AcademiaArtisticaFilters
) => {
  const filtersResponse = await request.post<
    FilterGroup<AcademiaArtisticaFilters>[]
  >("/academia-artistica/filters", currentFilters);

  return filtersResponse.data;
};

export const requestManyAcademiaArtistica = async (
  filters: AcademiaArtisticaFilters
) => {
  try {
    const response = await request.post<{
      items: AcademiaArtistica[];
      total: number;
    }>("/academia-artistica/find", filters);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqSingleAcademiaArtistica = async (id: number) => {
  try {
    const response = await request.get<AcademiaArtistica>(
      `/academia-artistica/${id}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqCreateAcademiaArtistica = async (
  data: AcademiaArtisticaCreateObject
) => {
  try {
    const response = await request.post<AcademiaArtistica>(
      `/academia-artistica/create`,
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqUpdateAcademiaArtistica = async (
  data: AcademiaArtisticaUpdateObject
) => {
  try {
    const response = await request.put<AcademiaArtistica>(
      `/academia-artistica`,
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqArtes = async () => {
  try {
    const response = await request.get<{ id: number; nombre: string }[]>(
      `/academia-artistica/artes`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqInstrumentos = async () => {
  try {
    const response = await request.get<{ id: number; nombre: string }[]>(
      `/academia-artistica/instrumentos`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqAcademiaArtisticaCambiarVisibilidad = async (
  id: number,
  hidden: boolean
) => {
  try {
    const response = await request.post<{ id: number; nombre: string }[]>(
      `/academia-artistica/visibilidad/${id}`,
      { hidden }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};
