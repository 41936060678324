import { Card, Descriptions, Space, Typography } from "antd";
import { EnrichedGeolocationCoordinates } from "react-hook-geolocation";
import InstitucionEducativaMany from "../types/InstitucionEducativaMany.type";
import {
  FaBible,
  FaChalkboardTeacher,
  FaDirections,
  FaLanguage,
  FaMoneyBillAlt,
  FaSchool,
} from "react-icons/fa";
import currencyFormatter from "../../../utils";
import useIsMobile from "../../../hooks/useMobile";
import FlagsViews from "../../../components/FlagsViews/FlagsViews";
import { formatDistance } from "../../../utils/filterUtils";
import CardContainer from "../../../components/CardContainer";
import { RiGovernmentLine } from "react-icons/ri";
import { BsGenderAmbiguous } from "react-icons/bs";
import { MdSchool } from "react-icons/md";

interface InsEduSimpleViewProps {
  insEdu: InstitucionEducativaMany;
  geoLocation?: EnrichedGeolocationCoordinates;
  className?: string;
}

const InsEduSimpleView = ({ insEdu }: InsEduSimpleViewProps) => {
  const isMobile = useIsMobile();

  if (!insEdu) {
    return <></>;
  }

  const geoDistance = insEdu?.distance;

  const nivelEducativoMenor = insEdu.nivelesEducativos?.[0];
  const nivelesEducativoMAyor =
    insEdu.nivelesEducativos?.[insEdu.nivelesEducativos.length - 1];

  const idiomasUnicos = [...new Map(insEdu.idiomasEducativos.map(i => [i.iso639_2_code, i])).values()]

  const detalles = (
    <Descriptions
      size="small"
      column={isMobile ? 1 : 2}
      bordered
      labelStyle={{ marginLeft: "0px" }}
    >
      <Descriptions.Item
        span={2}
        key="0"
        label={
          <Space>
            <FaSchool color="rgb(0,0,150)" size={"1.5em"} />
            Tipo Establecimiento
          </Space>
        }
      >
        {insEdu.tipoInstitucion.nombre}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={
          <Space>
            <RiGovernmentLine color="rgb(0,0,150)" size={"1.5em"} />
            Dependencia
          </Space>
        }
      >
        {insEdu.tipoDependencia.nombre}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={
          <Space>
            <BsGenderAmbiguous color="rgb(0,0,150)" size={"1.5em"} />
            Género
          </Space>
        }
      >
        {insEdu.generoAlumnos.nombre}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={
          <Space>
            <FaBible color="rgb(0,0,150)" size={"1.5em"} />
            Orientación religiosa
          </Space>
        }
      >
        {insEdu.orientacionReligiosa.nombre}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={
          <Space>
            <FaLanguage color="rgb(0,0,150)" size={"1.5em"} />
            Idiomas
          </Space>
        }
      >
        <Space direction="horizontal" size="small">
          {
            idiomasUnicos.map(i => (
              <FlagsViews
                key={i.iso639_2_code}
                iconSize={isMobile ? "25px" : undefined}
                flagsIso639Codes={[i.iso639_2_code]}
                overridePopOver={
                  insEdu.idiomasEducativos.filter(i2 => i2.iso639_2_code === i.iso639_2_code).map(i2 => i2.nombre).join(" - ")
                }
              />
            ))
          }
        </Space>
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={
          <Space>
            <FaMoneyBillAlt color="rgb(0,0,150)" size={"1.5em"} />
            Precio
          </Space>
        }
      >
        {`${currencyFormatter.format(
          insEdu.rangoPrecios.min
        )} - ${currencyFormatter.format(insEdu.rangoPrecios.max)}`}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={
          <Space>
            <MdSchool color="rgb(0,0,150)" size={"1.5em"} />
            Niveles
          </Space>
        }
      >
        {`${nivelEducativoMenor.nombre} - ${nivelesEducativoMAyor.nombre}`}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={
          <Space>
            <FaChalkboardTeacher color="rgb(0,0,150)" size={"1.5em"} />
            Modelo Educativo
          </Space>
        }
      >
        {insEdu.tipoEducacion?.nombre || "-"}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={
          <Space>
            <FaChalkboardTeacher color="rgb(0,0,150)" size={"1.5em"} />
            Modalidad
          </Space>
        }
      >
        {insEdu.modalidad?.nombre || "-"}
      </Descriptions.Item>
    </Descriptions>
  );
  return (
    <CardContainer smallPadding>
      <Card
        className="small-card-body-padding"
        title={
          <Typography.Title level={5}>
            {insEdu.nombre.toUpperCase()}
          </Typography.Title>
        }
        style={{
          color: "transparent",
          cursor: "pointer",
          padding: "0px",
        }}
        onClick={() => window.open(`/categoria/colegios/${insEdu.id}`)}
        cover={
          <img
            style={{ aspectRatio: "1 / 1", overflow: "hidden", position: "relative", minHeight: "100%", maxHeight: "100%", minWidth: "100%", maxWidth: "100%" }}
            alt="example"
            src={`${process.env.REACT_APP_API_URL}instituciones/images/${insEdu.fotos?.[0].id}`}
          />
        }
      >
        <Space direction="horizontal">
          <FaDirections color="blue" fontSize={"1.5em"} />
          <Typography.Title level={5}>
            {`${insEdu.direccion}, ${insEdu.comuna.nombre}, ${insEdu.region.nombre}`}
          </Typography.Title>
        </Space>

        {geoDistance && (
          <Space direction="horizontal">
            <FaDirections color="blue" fontSize={"1.5em"} />
            <Typography.Text>
              {`A ${formatDistance(geoDistance)} de su ubicación`}
            </Typography.Text>
          </Space>
        )}
        {detalles}
      </Card>
    </CardContainer>
  );
};

export default InsEduSimpleView;
