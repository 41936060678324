import { useRequest } from "ahooks";
import { Select, SelectProps } from "antd";
import { getRegiones } from "../requests";
import { LoadingOutlined } from "@ant-design/icons";

interface SelectRegionProps extends SelectProps {
  paisId?: number;
  value?: number;
  onChange?: (value: any) => any;
  placeholder?: string;
  className?: string;
  props?: SelectProps;
  style?: React.CSSProperties;
}

const SelectRegion = ({
  paisId,
  value,
  className,
  onChange,
  placeholder,
  style,
  ...props
}: SelectRegionProps) => {
  const { data: regiones, loading } = useRequest(async () => {
    return await getRegiones(paisId);
  });
  return (
    <Select
      style={style}
      disabled={loading}
      className={className}
      value={value}
      onChange={onChange}
      placeholder={placeholder ? placeholder : "Región"}
      suffixIcon={loading ? <LoadingOutlined /> : undefined}
      allowClear
      {...props}
    >
      {regiones?.map((r) => {
        return <Select.Option value={r.id}>{r.nombre}</Select.Option>;
      })}
    </Select>
  );
};

export default SelectRegion;
