import {
  CreateInsEduFormData,
  InsEduCreateObject,
  NivelesEducativoCreateObj,
} from "../../../types/insEdu.types";
import _ from "lodash";
import SingleInstitucionEducativaEasyVisualization from "../../InsEduDetailView/types/SingleInstitucionEducativaEasyVisualization.type";
import { transformUploadFilesToFormData } from "../../../designConstants/antd-utils";
import { FormInstance } from "antd";
import { baseUrl } from "../../../commonRequests/axiosInstance";
import { DOCUMENTOS_INS_EDU } from "./components/DocumentosInput";

const transformDocumentosPayload = (
  form: FormInstance<CreateInsEduFormData>
) => {
  const getDocumentId = (name: string) => {
    const documentData = form.getFieldValue(name);
    let documentId = documentData?.fileList?.[0]?.response?.id;

    if (!documentId) {
      documentId = documentData?.[0]?.response?.id;
    }

    return documentId;
  };

  const reglamentoInternoFile = getDocumentId("reglamentoInternoFile");
  const reglamentoEvaluacionFile = getDocumentId("reglamentoEvaluacionFile");
  const protocolaConvivenciaFile = getDocumentId("protocolaConvivenciaFile");
  const listadoUtilesFile = getDocumentId("listadoUtilesFile");
  const protocoloCovidFile = getDocumentId("protocoloCovidFile");

  return {
    reglamentoInternoFile: reglamentoInternoFile
      ? { id: reglamentoInternoFile as number }
      : undefined,
    reglamentoEvaluacionFile: reglamentoEvaluacionFile
      ? { id: reglamentoEvaluacionFile as number }
      : undefined,
    protocolaConvivenciaFile: protocolaConvivenciaFile
      ? { id: protocolaConvivenciaFile as number }
      : undefined,
    listadoUtilesFile: listadoUtilesFile
      ? { id: listadoUtilesFile as number }
      : undefined,
    protocoloCovidFile: protocoloCovidFile
      ? { id: protocoloCovidFile as number }
      : undefined,
  };
};

export const transformInsEduPayload = (
  form: FormInstance<CreateInsEduFormData>
): InsEduCreateObject => {
  //Obtenemos los datos del formualrio (clonandolos para no editarlos)
  const data = form.getFieldsValue(true) as CreateInsEduFormData;

  const fotos = transformUploadFilesToFormData(form);

  const documents = transformDocumentosPayload(form);

  let nivelesEducativos = data.nivelesEducativos
    .filter((n) => !!n && data.nivelesEducativosIds.includes(n.id))
    .map((n) => {
      if (!n) {
        throw new Error("Niveles educativos form llenados incorrectamente");
      }
      const aniosEscolares = n.aniosEscolares.filter((anio) => !!anio);
      return { ...n, aniosEscolares } as NivelesEducativoCreateObj;
    });

  return {
    paisId: data.paisId,
    comunaId: data.comunaId,
    nombre: data.nombre,
    rbd: data.rbd,
    regionId: data.regionId,
    direccion: data.direccion,
    director: data.director,
    descripcion: data.descripcion,
    telefono: data.telefono,
    email: data.email,
    paginaWeb: data.paginaWeb,
    modalidadEstudioId: data.modalidadEstudioId,
    instagram: data.instagram,
    facebook: data.facebook,
    whatsapp: data.whatsapp,
    twitter: data.twitter,
    videoInstitucion: data.videoInstitucion,
    descripcionEdicion: data.descripcionEdicion,
    mision: data.mision,
    vision: data.vision,
    tipoDependenciaId: data.tipoDependenciaId,
    orientacionReligiosaId: data.orientacionReligiosaId,
    cantAlumnosPorCurso: data.cantAlumnosPorCurso,
    alumnosMatriculados: data.alumnosMatriculados,
    tipoEducacionId: data.tipoEducacionId,
    generoAlumnosId: data.generoAlumnosId,
    nivelesEducativosIds: data.nivelesEducativosIds,
    nivelesEducativos,
    caracteristicasExtra: data.caracteristicasExtra?.map((id) => ({ id: id })),
    caracteristicasCustom: data.caracteristicasCustom,
    extraprogramaticas: data.extraprogramaticas?.map((id) => ({ id: id })),
    extracurricularCustom: data.extracurricularCustom,
    alianzas: data.alianzas?.filter((a) => !!a),
    becas: data.becas?.filter((b) => !!b),
    planesYProgramas: data.planesYProgramas?.filter((b) => !!b),
    tipoInstitucionId: data.tipoInstitucionId,
    resultadoSimceBasicaMatematica: data.resultadoSimceBasicaMatematica,
    resultadoSimceBasicaLenguaje: data.resultadoSimceBasicaLenguaje,
    resultadoSimceMediaMatematica: data.resultadoSimceMediaMatematica,
    resultadoSimceMediaLenguaje: data.resultadoSimceMediaLenguaje,
    empresaUniformes: data.empresaUniformes,
    fotos,
    documents,
  };
};

export const transformSingleInsEduToFormData = (
  singleInsEdu: SingleInstitucionEducativaEasyVisualization
) => {
  const getDocuments = (documentName: DOCUMENTOS_INS_EDU) => {
    const doc = singleInsEdu.insEduDocumentos.find(
      (d) => d.nombre === documentName
    );

    if (!doc) {
      return undefined;
    }

    return [
      {
        uid: doc.documentoId.toString(),
        name: `${documentName}`,
        status: "done",
        response: { id: doc.documentoId },
        url: `${baseUrl}instituciones/documentos/${doc.documentoId}`,
      },
    ];
  };

  const institucionFormData = {
    id: singleInsEdu.id,
    paisId: singleInsEdu.pais.id,
    comunaId: singleInsEdu.comuna.id,
    nombre: singleInsEdu.nombre,
    rbd: singleInsEdu.rbd,
    regionId: singleInsEdu.region.id,
    direccion: singleInsEdu.direccion,
    director: singleInsEdu.director,
    descripcion: singleInsEdu.descripcion,
    telefono: singleInsEdu.telefono,
    email: singleInsEdu.email,
    paginaWeb: singleInsEdu.paginaWeb,
    instagram: singleInsEdu.redesSociales.instagram,
    facebook: singleInsEdu.redesSociales.facebook,
    whatsapp: singleInsEdu.redesSociales.whatsapp,
    twitter: singleInsEdu.redesSociales.twitter,
    videoInstitucion: singleInsEdu.videoInstitucion,
    mision: singleInsEdu.mision,
    vision: singleInsEdu.vision,
    orientacionReligiosaId: singleInsEdu.orientacionReligiosa.id,
    cantAlumnosPorCurso: singleInsEdu.cantAlumnosPorCurso,
    alumnosMatriculados: singleInsEdu.totalMatriculados,
    generoAlumnosId: singleInsEdu.generoAlumnos.id,
    modalidadEstudioId: singleInsEdu.modalidad.id,
    tipoEducacionId: singleInsEdu.tipoEducacion?.id,
    nivelesEducativosIds: singleInsEdu.niveles.map((n) => n.nivelEscolarId),
    descripcionEdicion: singleInsEdu.descripcionEdicion,
    nivelesEducativos: orderArrayasedOnIds<any>(
      singleInsEdu.niveles.map((n) => ({
        id: n.nivelEscolarId,
        nivelEscolarId: n.nivelEscolarId,
        opcionHorarioExtendido: n.opcionHorarioExtendido,
        jornadaEscolarId: n.jornada.id,
        idiomasEducativos: n.idiomas.map((i) => i.id),
        numeroCuotas: n.numeroCuotas,
        costoMensualidad: n.constoMensualidad,
        costoMatricula: n.costoMatricula,
        aniosEscolares: orderArrayasedOnIds(
          n.aniosEscolares.map((anio) => {
            const a = {
              id: anio.id,
              vacantesMinimas: anio.vacantes?.[0]?.vacantesMinimo,
              vacantesMaximas: anio.vacantes?.[0]?.vacantesMaximo,
            };
            return a;
          })
        ),
      }))
    ) as unknown as NivelesEducativoCreateObj[],
    tipoDependenciaId: singleInsEdu.tipoDependencia.id,
    caracteristicasExtra: singleInsEdu.caracteristicasExtra
      .filter((c) => c.default)
      .map((c) => c.id),
    caracteristicasCustom: singleInsEdu.caracteristicasExtra
      .filter((c) => !c.default)
      .map((c) => c.nombre),
    extraprogramaticas: singleInsEdu.extraprogramaticas
      .filter((c) => c.default)
      .map((c) => c.id),
    extracurricularCustom: singleInsEdu.extraprogramaticas
      .filter((c) => !c.default)
      .map((c) => c.nombre),
    alianzas: singleInsEdu.alianzas.map((a) => a.nombre),
    becas: singleInsEdu.becas.map((b) => b.nombre),
    tipoInstitucionId: singleInsEdu.tipoInstitucion.id,
    planesYProgramas: singleInsEdu.planesYProgramas.map((p) => p.nombre),
    resultadoSimceBasicaMatematica: singleInsEdu.resultadoSimceBasicaMatematica,
    resultadoSimceBasicaLenguaje: singleInsEdu.resultadoSimceBasicaLenguaje,
    resultadoSimceMediaMatematica: singleInsEdu.resultadoSimceMediaMatematica,
    resultadoSimceMediaLenguaje: singleInsEdu.resultadoSimceMediaLenguaje,
    ownerUserId: singleInsEdu.owningUser.id,
    empresaUniformes: singleInsEdu?.empresaUniforme,

    defaultImage: singleInsEdu.fotos.slice(0, 1).map((f) => ({
      uid: f.id.toString(),
      name: `${f.id}.png`,
      status: "done",
      response: { id: f.id },
      url: `${baseUrl}instituciones/images/${f.id}`,
    })),

    restOfImages: singleInsEdu.fotos.slice(1).map((f) => ({
      uid: f.id.toString(),
      name: `${f.id}.png`,
      status: "done",
      response: { id: f.id },
      url: `${baseUrl}instituciones/images/${f.id}`,
    })),

    reglamentoInternoFile: getDocuments("REGLAMENTO_INTERNO"),
    reglamentoEvaluacionFile: getDocuments("REGLAMENTO_EVALUACION"),
    protocolaConvivenciaFile: getDocuments("PROTOCOLO_CONVIVENCIA"),
    listadoUtilesFile: getDocuments("LISTADO_UTILES"),
    protocoloCovidFile: getDocuments("PROTOCOLO_COVID"),
  };

  return institucionFormData;
};

export const imageIdToUploadFileProp = (fotos: { id: number }[]) => {
  return fotos.map((f) => ({
    uid: f.id.toString(),
    name: `${f.id}.png`,
    status: "done",
    response: { id: f.id },
    url: `${baseUrl}instituciones/images/${f.id}`,
  }));
};

export const documentIdToUploadFileProp = (fotos: { id: number }[], fileName?: string) => {
  return fotos.map((f) => ({
    uid: f.id.toString(),
    name: fileName || `Documento ${f.id}`,
    status: "done",
    response: { id: f.id },
    url: `${baseUrl}instituciones/documentos/${f.id}`,
  }));
};

export const handleLoadDataOntoUploadInputFotos = (form: FormInstance) => {
  const defaultImage = form.getFieldValue("defaultImage");
  const restOfImages = form.getFieldValue("restOfImages");
  if (defaultImage?.fileList) {
    form.setFieldsValue({
      defaultImage: imageIdToUploadFileProp(
        defaultImage.fileList.map((f: any) => ({ id: f.response.id }))
      ),
    });
  }

  if (restOfImages?.fileList) {
    form.setFieldsValue({
      restOfImages: imageIdToUploadFileProp(
        restOfImages.fileList.map((f: any) => ({ id: f.response.id }))
      ),
    });
  }
};

export const handleLoadDataOntoUploadInputDocuments = (form: FormInstance) => {
  const loadDataIntoAntdUpload = (formItemName: string) => {
    const antUploadData = form.getFieldValue(formItemName);
    if (antUploadData?.fileList) {
      form.setFieldsValue({
        [formItemName]: documentIdToUploadFileProp(
          antUploadData.fileList.map((f: any) => ({ id: f.response.id })),
          antUploadData?.file?.name
        ),
      });
    }
  };

  loadDataIntoAntdUpload("reglamentoInternoFile");
  loadDataIntoAntdUpload("reglamentoEvaluacionFile");
  loadDataIntoAntdUpload("protocolaConvivenciaFile");
  loadDataIntoAntdUpload("listadoUtilesFile");
  loadDataIntoAntdUpload("protocoloCovidFile");
};

interface HasId {
  id: number;
  [key: string]: any;
}
export const orderArrayasedOnIds = <T>(
  array: Array<HasId>,
  idName: string = "id"
) => {
  const arr = _.cloneDeep(array);
  let ids = arr.map((a) => Number(a[idName]));
  ids = ids.sort((a, b) => a - b);

  if (ids.length === 0) {
    return [];
  }

  const max = ids[ids.length - 1];

  let obj: any = {};

  for (let i = 0; i <= max; i++) {
    if (ids.includes(i)) {
      obj[i] = arr.find((a) => a.id === i);
      continue;
    }

    obj[i] = null;
  }

  return _.toArray(obj) as unknown as T;
};
