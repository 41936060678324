import { Col, Form, InputNumber, Row, Space, Typography } from "antd";
import SelectGeneroAlumnos from "../../../../components/Input/SelectGeneroAlumnos/SelectGeneroAlumnos";
import SelectJornada from "../../../../components/Input/SelectJornadas/SelectJornada";
import SelectModalidadEstudio from "../../../../components/SelectModalidadEstudio";
import AntdConsts from "../../../../designConstants/antd-constants";

const AcademiaDeportivaDatosInstitucionalesForm = () => {
  return (
    <>
      <Typography.Title level={3}>Datos institucionales</Typography.Title>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name="generoAlumnosId"
            label="Género Alumnos"
            rules={[{ required: true }]}
          >
            <SelectGeneroAlumnos />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name="jornadaId"
            label="Jornada"
            rules={[{ required: true }]}
          >
            <SelectJornada />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.fullSizeColLayout}>
          <Form.Item
            name="modalidadEstudioId"
            label="Modalidad de estudio"
            rules={[{ required: true }]}
          >
            <SelectModalidadEstudio />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item label="Costo (Sin Puntos ni Comas)">
            <Space size="small" align="center">
              <Form.Item
                label="Mínimo"
                name={'precioMin'}
                rules={[{ required: true }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              {" - "}
              <Form.Item
                label="Máximo"
                name={'precioMax'}
                rules={[{ required: true }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Space>
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item label="Edades" rules={[{ required: true }]}>
            <Space size="small" align="center">
              <Form.Item
                label="Mínimo"
                name={'edadMinma'}
                rules={[{ required: true }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              {" - "}
              <Form.Item
                label="Máximo"
                name={'edadMaxima'}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default AcademiaDeportivaDatosInstitucionalesForm;
