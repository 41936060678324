import { Col, Descriptions, Divider, List, Row, Space, Typography } from "antd";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { FaMoneyBillAlt } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { IconContext } from "react-icons/lib";
import AntdConsts from "../../../../designConstants/antd-constants";
import { EscuelaVerano } from "../../../../types/escuelaVerano.type";
import currencyFormatter from "../../../../utils";

interface EscuelaVeranoCaracteristicasProps {
  escuelaVerano: EscuelaVerano;
}

const EscuelaVeranoCaracteristicas = ({
  escuelaVerano,
}: EscuelaVeranoCaracteristicasProps) => {
  return (
    <>
      <Descriptions bordered title={"ACTIVIDADES"}>
        {escuelaVerano.actividades.map((actividad) => {
          return (
            <Descriptions.Item key={actividad}>{actividad}</Descriptions.Item>
          );
        })}
      </Descriptions>
      <Divider />
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <List
            bordered
            header={
              <Space direction="horizontal">
                <IconContext.Provider value={{ color: "blue", size: "2em" }}>
                  <BsFillJournalBookmarkFill />
                </IconContext.Provider>
                <Typography.Title level={4}>Jornada</Typography.Title>
              </Space>
            }
          >
            <List.Item>
              <b>{escuelaVerano.jornada.nombre}</b>
            </List.Item>
          </List>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <List
            bordered
            header={
              <Space direction="horizontal">
                <IconContext.Provider value={{ color: "blue", size: "2em" }}>
                  <FaMoneyBillAlt />
                </IconContext.Provider>
                <Typography.Title level={4}>Rango de precios</Typography.Title>
              </Space>
            }
          >
            <List.Item>
              <b>{`${currencyFormatter.format(
                escuelaVerano.precioMin
              )} - ${currencyFormatter.format(escuelaVerano.precioMax)}`}</b>
            </List.Item>
          </List>
        </Col>
      </Row>
    </>
  );
};

export default EscuelaVeranoCaracteristicas;
