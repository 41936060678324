import { isMobile } from 'react-device-detect';
import useGeolocation from 'react-hook-geolocation';

const useGeo = () => {
  const geolocation = useGeolocation()
  if (!isMobile) {
    return undefined
  }
  return geolocation;
}

export default useGeo;