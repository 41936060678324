import { Space, Tag } from "antd";
import {
  AllFilters,
  FilterCategory,
  QuantityFilterCategory,
} from "../types/filtros.type";

interface SelectedFiltersVisualizatorProps {
  filters: AllFilters;
  filterCategories: FilterCategory<AllFilters>[];
}

const SelectedFiltersVisualizator = ({
  filters,
  filterCategories,
}: SelectedFiltersVisualizatorProps) => {
  const filterTags: React.ReactElement[] = [];

  for (const category of filterCategories) {
    for (let filterData of category.filters) {
      //@ts-ignore
      const filter = filters?.[category as unknown as keyof filters];
      if (!filter) {
        continue;
      }

      if (!Array.isArray(filter)) {
        continue;
      }

      if (filter?.includes(filterData?.id)) {
        filterTags.push(<Tag closable>{filterData.name}</Tag>);
      }
    }
  }

  return (
    <Space direction="horizontal" wrap>
      {filterTags}
    </Space>
  );
};

export default SelectedFiltersVisualizator;
