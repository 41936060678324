import request from "./axiosInstance";
import { Image } from "../types/Images.type";
import {
  OrientacionReligiosa,
  TipoDependencia,
} from "../views/listaInsEdu/types/SingleInstitucionEducativa.type";
import {
  GetManyInsEduResponse,
  InsEduCreateObject,
  InsEduFilters,
  InsEduUpdateObject,
} from "../types/insEdu.types";

export const getManyInsEdu = async (filters: InsEduFilters) => {
  try {
    const response = await request.get<GetManyInsEduResponse>(
      "/institucion-educativa",
      { params: filters }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const cambiarVisibilidad = async (id: number, hidden: boolean) => {
  try {
    const response = await request.post(`/institucion-educativa/visibilidad/${id}`, { hidden });
    return response.data
  } catch (error) {
    return null;
  }
};

export const requestTipoDependencia = async () => {
  try {
    const response = await request.get<TipoDependencia[]>(
      "/params/tipo-dependencia"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestOrientacionesReligiosas = async () => {
  try {
    const response = await request.get<OrientacionReligiosa[]>(
      "/params/orientacion-religiosa"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestGeneroAlumnos = async () => {
  try {
    const response = await request.get<OrientacionReligiosa[]>(
      "/params/genero-alumnos"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const postImage = async (formData: FormData) => {
  const response = await request.post<Image>(
    "/institucion-educativa/images",
    formData,
    { headers: { "content-type": "multipart/form-data" } }
  );
  return response.data;
};

export const createInsEdu = async (data: InsEduCreateObject) => {
  try {
    const response = await request.post<Image>(
      "/institucion-educativa/create",
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const updateInsEdu = async (data: InsEduUpdateObject) => {
  try {
    const response = await request.patch<Image>(
      "/institucion-educativa",
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};
