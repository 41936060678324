import "./filterSelector.css";
import {
  AllFilters,
  FilterCategory,
  InstitucionEducativaFilters,
} from "../../types/filtros.type";
import { List } from "antd";
import currencyFormatter from "../../utils";
import { useRequest } from "ahooks";
import FiltersReq from "../../commonRequests/filters.req";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import useIsMobile from "../../hooks/useMobile";
import CardContainer from "../CardContainer";
import NombreFilterSelector from "./NombreFilterSelector";
import SelectedFiltersVisualizator from "../SelectedFiltersVisualizator";
import MobileFiltersWrapper from "./MobileFilterWrapper";
import { parseUrlQueryInsEdu } from "../../utils/filterUtils";
import {
  NivelesEducativosPorTipoInstitucion,
  TipoInstitucionIds,
} from "../../types/instituciones.types";

interface FilterSelectorProps {
  currentFilters: InstitucionEducativaFilters;
  setFilters?: (filters: InstitucionEducativaFilters) => any;
}

const FilterSelector = ({
  currentFilters,
  setFilters,
}: FilterSelectorProps) => {
  const filterSelectorReq = useRequest(() =>
    FiltersReq.getFiltersInstitucionEducativa()
  );

  const isMobile = useIsMobile();

  if (filterSelectorReq.loading) {
    <LoadingSpinner />;
  }

  if (!filterSelectorReq.data) {
    return <p>Error, no fue posible obtener los filtros</p>;
  }

  let filterSelectors = filterSelectorReq.data;

  const tiposInstitucionId = parseUrlQueryInsEdu()
    .tipo_institucion as TipoInstitucionIds[];
  if (
    tiposInstitucionId &&
    tiposInstitucionId.includes(TipoInstitucionIds.JARDIN_INFANTIL)
  ) {
    filterSelectors.nivelesEducativos.filters = filterSelectors.nivelesEducativos.filters.filter(
      (f) => NivelesEducativosPorTipoInstitucion.JARDIN_INFANTIL.includes(f.id)
    );
  }

  const onFilterClick = (
    filterId: number,
    category: keyof InstitucionEducativaFilters
  ) => {
    const filter = currentFilters[category] as number[] | undefined;

    if (!filter) {
      setFilters?.({ ...currentFilters, [category]: [filterId] });
      return;
    }
    if (!filter?.includes(filterId)) {
      setFilters?.({ ...currentFilters, [category]: [...filter, filterId] });
      return;
    }

    const newFilter = filter.filter((id) => id !== filterId);
    setFilters?.({
      ...currentFilters,
      [category]: newFilter.length === 0 ? undefined : newFilter,
    });
    return;
  };

  const onQuantityFilterClick = (
    min: number,
    max: number,
    key: keyof InstitucionEducativaFilters
  ) => {
    if (checkNumberFilterRange(min, max)) {
      setFilters?.({
        ...currentFilters,
        [key]: undefined,
      });

      return;
    }

    setFilters?.({
      ...currentFilters,
      [key]: { min, max },
    });
  };

  const checkNumberFilterRange = (min: number, max: number) => {
    if (!currentFilters.mensualidad) {
      return;
    }

    if (
      (!currentFilters.mensualidad?.min &&
        currentFilters.mensualidad.min !== 0) ||
      !currentFilters.mensualidad.max
    ) {
      return false;
    }

    return (
      min <= currentFilters.mensualidad.min &&
      max >= currentFilters.mensualidad.max
    );
  };

  const filters = (
    <>
      <SelectedFiltersVisualizator
        filters={currentFilters}
        filterCategories={
          Object.values(filterSelectors) as FilterCategory<AllFilters>[]
        }
      />
      <NombreFilterSelector
        setFilters={setFilters}
        currentFilters={currentFilters}
      />
      <List
        header={<b>{filterSelectors.generosFiltros.title}</b>}
        dataSource={filterSelectors.generosFiltros.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.genero_alumnos?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "genero_alumnos")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.tipoDependencia.title}</b>}
        dataSource={filterSelectors.tipoDependencia.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.tipo_dependencia?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "tipo_dependencia")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.nivelesEducativos.title}</b>}
        dataSource={filterSelectors.nivelesEducativos.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.nivel_escolar?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "nivel_escolar")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.tiposEducacion.title}</b>}
        dataSource={filterSelectors.tiposEducacion.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.tipo_educacion?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "tipo_educacion")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.orientacionReligiosa.title}</b>}
        dataSource={filterSelectors.orientacionReligiosa.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.orientacion_religiosa?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "orientacion_religiosa")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.modalidadesEstudio.title}</b>}
        dataSource={filterSelectors.modalidadesEstudio.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.modalidad_estudios?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "modalidad_estudios")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.idiomas.title}</b>}
        dataSource={filterSelectors.idiomas.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.idiomas?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "idiomas")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.mensualidad.title}</b>}
        dataSource={filterSelectors.mensualidad.filters.slice(0, -1)}
        renderItem={(filter) => (
          <List.Item
            className={
              checkNumberFilterRange(filter.min, filter.max)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() =>
              onQuantityFilterClick(filter.min, filter.max, "mensualidad")
            }
          >
            {`${currencyFormatter.format(
              filter.min
            )} - ${currencyFormatter.format(filter.max)}`}
          </List.Item>
        )}
      >
        <List.Item
          className={
            currentFilters.mensualidad?.min &&
            currentFilters.mensualidad?.min > 600000
              ? "selected-filter"
              : "filters-row"
          }
          style={{ marginLeft: "1em", cursor: "pointer" }}
          onClick={() => {
            if (
              !currentFilters.mensualidad ||
              !currentFilters.mensualidad.min
            ) {
              setFilters?.({
                ...currentFilters,
                mensualidad: { min: 600001, max: undefined },
              });
              return;
            }

            if (currentFilters.mensualidad.min > 600000) {
              setFilters?.({
                ...currentFilters,
                mensualidad: undefined,
              });
            } else {
              setFilters?.({
                ...currentFilters,
                mensualidad: { min: 600001, max: undefined },
              });
            }
          }}
        >
          600.000 +
        </List.Item>
      </List>

      <List
        header={<b>{filterSelectors.alumnosCurso.title}</b>}
        dataSource={filterSelectors.alumnosCurso.filters.slice(0, -1)}
        renderItem={(filter) => (
          <List.Item
            className={
              checkNumberFilterRange(filter.min, filter.max)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() =>
              onQuantityFilterClick(filter.min, filter.max, "alumnos_curso")
            }
          >
            {`${filter.min}
             - ${filter.max}`}
          </List.Item>
        )}
      >
        <List.Item
          className={
            currentFilters.alumnos_curso?.min &&
            currentFilters.alumnos_curso?.min > 40
              ? "selected-filter"
              : "filters-row"
          }
          style={{ marginLeft: "1em", cursor: "pointer" }}
          onClick={() => {
            if (
              !currentFilters.alumnos_curso ||
              !currentFilters.alumnos_curso.min
            ) {
              setFilters?.({
                ...currentFilters,
                alumnos_curso: { min: 600001, max: undefined },
              });
              return;
            }

            if (currentFilters.alumnos_curso.min > 600000) {
              setFilters?.({
                ...currentFilters,
                alumnos_curso: undefined,
              });
            } else {
              setFilters?.({
                ...currentFilters,
                alumnos_curso: { min: 600001, max: undefined },
              });
            }
          }}
        >
          40 +
        </List.Item>
      </List>
    </>
  );

  if (isMobile) {
    return <MobileFiltersWrapper>{filters}</MobileFiltersWrapper>;
  }

  return <CardContainer>{filters}</CardContainer>;
};

export default FilterSelector;
