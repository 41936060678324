import {
  AcademiaIdiomaCreateObject,
  AcademiaIdiomas,
  AcademiaIdiomaUpdateObject,
} from "../types/academiaIdiomas.type";
import { FilterGroup } from "../types/filters.type";
import { AcademiaIdiomasFilters } from "../types/filtros.type";
import { IdiomaEducativo } from "../views/listaInsEdu/types/SingleInstitucionEducativa.type";
import request from "./axiosInstance";

export const requestManyAcademiaIdiomas = async (
  filters: AcademiaIdiomasFilters
) => {
  try {
    const response = await request.post<{
      items: AcademiaIdiomas[];
      total: number;
    }>("/academia-idiomas/find", filters );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestSingleAcademiaIdiomas = async (id: number) => {
  try {
    const response = await request.get<AcademiaIdiomas>(
      `/academia-idiomas/${id}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestIdiomas = async () => {
  try {
    const response = await request.get<IdiomaEducativo[]>(
      "/academia-idiomas/idiomas"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqAcademiaIdiomasFilters = async (
  currentFilters: AcademiaIdiomasFilters
) => {
  const filtersResponse = await request.post<
    FilterGroup<AcademiaIdiomasFilters>[]
  >("/academia-idiomas/filters", currentFilters);

  return filtersResponse.data;
};

export const reqAcademiaIdiomasCambiarVisibilidad = async (
  id: number,
  hidden: boolean
) => {
  try {
    const response = await request.post<boolean>(
      `/academia-idiomas/visibilidad/${id}`,
      { hidden }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqCreateAcademiaIdiomas = async (
  data: AcademiaIdiomaCreateObject
) => {
  try {
    const response = await request.post<{ id: number }>(
      "/academia-idiomas/create",
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqUpdateAcademiaIdiomas = async (
  data: AcademiaIdiomaUpdateObject
) => {
  try {
    const response = await request.put<{ id: number }>(
      "/academia-idiomas",
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};
