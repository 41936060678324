import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { useState } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import AntdConsts from "../../../../designConstants/antd-constants";

const EmpresaUniformesInput = () => {
  const [nombreEmpresaUniformes, setNombreEmpresaUniformes] =
    useState<string>();

  return (
    <>
      <Typography.Title level={3}>
        Datos empresa de uniforme (Opcional)
      </Typography.Title>

      <Form.List name="empresaUniformes">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <>
                <Typography.Title level={4}>
                  {`Empresa de uniformes ${index + 1}`}{" "}
                </Typography.Title>
                <Row
                  style={{ paddingLeft: "1em" }}
                  gutter={AntdConsts.rowGutter}
                >
                  <Col {...AntdConsts.twoColLayout}>
                    <Form.Item
                      {...field}
                      name={[field.name, "nombre"]}
                      label="Nombre empresa uniformes"
                    >
                      <Input
                        placeholder="Nombre empresa uniforme"
                        onChange={(e) =>
                          setNombreEmpresaUniformes(e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col {...AntdConsts.twoColLayout}>
                    <Form.Item
                      {...field}
                      name={[field.name, "direccion"]}
                      label="Dirección"
                      rules={[
                        {
                          required: !!nombreEmpresaUniformes,
                          message:
                            "La empresa de uniformes debe completarse completamente o no completarse",
                        },
                      ]}
                    >
                      <Input placeholder="Dirección" />
                    </Form.Item>
                  </Col>
                  <Col {...AntdConsts.twoColLayout}>
                    <Form.Item
                      {...field}
                      name={[field.name, "telefono"]}
                      label="Teléfono"
                      rules={[
                        {
                          required: !!nombreEmpresaUniformes,
                          message:
                            "La empresa de uniformes debe completarse completamente o no completarse",
                        },
                      ]}
                    >
                      <Input placeholder="Teléfono" />
                    </Form.Item>
                  </Col>
                  <Col {...AntdConsts.twoColLayout}>
                    <Form.Item
                      {...field}
                      name={[field.name, "email"]}
                      label="E-Mail"
                      rules={[{ type: "email", message: "E-mail inválido" }]}
                    >
                      <Input placeholder="E-Mail" />
                    </Form.Item>
                  </Col>
                  <Col flex={4}>
                    <Form.Item
                      {...field}
                      name={[field.name, "linkContacto"]}
                      label="Link de contacto (Ej. Página web o link a red social)"
                    >
                      <Input placeholder="Link de contacto" />
                    </Form.Item>
                  </Col>
                  <Col flex={1}>
                    <Form.Item label=" ">
                      <Button
                        block
                        type="primary"
                        onClick={() => remove(field.name)}
                        icon={<MinusCircleOutlined />}
                      >
                        Quitar empresa
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
              </>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Añadir empresa de uniformes
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      {/* <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name={[field.name, "nombre"]}
            label="Nombre empresa uniformes"
          >
            <Input
              placeholder="Nombre empresa uniforme"
              onChange={(e) => setNombreEmpresaUniformes(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name={[field.name, "direccion"]}
            label="Dirección"
            rules={[
              {
                required: !!nombreEmpresaUniformes,
                message:
                  "La empresa de uniformes debe completarse completamente o no completarse",
              },
            ]}
          >
            <Input placeholder="Dirección" />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name={[field.name, "telefono"]}
            label="Teléfono"
            rules={[
              {
                required: !!nombreEmpresaUniformes,
                message:
                  "La empresa de uniformes debe completarse completamente o no completarse",
              },
            ]}
          >
            <Input placeholder="Teléfono" />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name={[field.name, "email"]}
            label="E-Mail"
            rules={[{ type: "email", message: "E-mail inválido" }]}
          >
            <Input placeholder="E-Mail" />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.fullSizeColLayout}>
          <Form.Item
            name={[field.name, "linkContacto"]}
            label="Link de contacto (Ej. Página web o link a red social)"
          >
            <Input placeholder="Link de contacto" />
          </Form.Item>
        </Col>
      </Row> */}
    </>
  );
};

export default EmpresaUniformesInput;
