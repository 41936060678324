import { useRequest } from "ahooks";
import { Affix, Button, Col, Pagination, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import AntdConsts from "../../../../designConstants/antd-constants";
import PreuniversitarioSimpleView from "./PreuniversitarioSimpleView";
import CardLoading from "../../../../components/CardLoading";

import { requestManyPreuniversitario } from "../../../../commonRequests/preuniversitarioRequests";
import { useCustomCompareEffect } from "use-custom-compare";
import useGeo from "../../../../hooks/useGeo";
import { FaArrowCircleLeft } from "react-icons/fa";
import {
  PreuniversitarioFilters,
  preuniversitarioFiltersComparator,
} from "../../../../types/filtros.type";
import QuitarUbicacionButton from "../../../../components/QuitarUbicacionButton";
import Swal from "sweetalert2";
import PopUpBusquedas from "../../../../components/PopUpBusquedas";
import AdsBanner from "../../../../components/AdsBanner";

interface ListaPreuniversitarioPageProps {
  filters?: PreuniversitarioFilters;
  setFilters?: (filters: PreuniversitarioFilters) => any;
}

const ListaPreuniversitarioPage = ({
  filters,
  setFilters,
}: ListaPreuniversitarioPageProps) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(filters?.take || 18);

  const isReforzamiento = window.location.href.includes("reforzamiento");

  const geolocation = useGeo();

  const {
    data: manyPreuniversitarios,
    loading,
    runAsync: loadMorePreuniversitarios,
  } = useRequest(
    async () => {
      const preuniversitariosList = await requestManyPreuniversitario({
        ...filters,
        isReforzamiento,
        latitud: geolocation?.latitude,
        longitud: geolocation?.longitude,
        take: pageSize,
        skip: (page - 1) * pageSize,
      });
      if (preuniversitariosList === null) {
        cogoToast.error("No se pudo cargar la información solicitada");
      }

      return preuniversitariosList;
    },
    { manual: true }
  );

  useCustomCompareEffect(
    () => {
      loadMorePreuniversitarios();
    },
    [filters],
    (prevDeps, nextDeps) =>
      preuniversitarioFiltersComparator(nextDeps[0], prevDeps[0])
  );

  const onPageChange = async (newPage: number, newPageSize: number) => {
    window.scrollTo(0, 0);
    setPage(newPage);
    setPageSize(pageSize);
    setFilters?.({
      ...filters,
      take: pageSize,
      skip: (newPage - 1) * pageSize,
    });
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <>
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
      </>
    );
  }

  if (!manyPreuniversitarios) {
    return <div></div>;
  }

  if (manyPreuniversitarios.items.length === 0) {
    { PopUpBusquedas() }
    return (
      <>
        <Row gutter={AntdConsts.rowGutter} justify="center">
          <Typography.Text>
            {window.scrollTo(0, 0)}
            No se encontraron Instituciones.
          </Typography.Text>
        </Row>
      </>
    );
  }

  const cardSpans = { xs: 24, md: 24, lg: 12, xl: 12, xxl: 12 };

  return (
    <>
      <Row>
        <Col>
          <Button type="text" href="/" style={{ fontWeight: "bold" }}>
            <FaArrowCircleLeft />
            &nbsp;&nbsp;Volver a la Página Principal
          </Button>
        </Col>
      </Row>
      <Row gutter={AntdConsts.rowGutter} justify="center">
        {manyPreuniversitarios.items.map((preu, index) => {
          const showAd = index % 4 === 0 && index !== 0;
          return (
            <>
              {showAd && (
                <Col {...AntdConsts.fullSizeColLayout}>
                  <AdsBanner />
                </Col>
              )}
              <Col {...cardSpans} style={{ display: "flex" }}>
                <PreuniversitarioSimpleView
                  key={preu.id}
                  preuniversitario={preu}
                  geoLocation={geolocation}
                />
              </Col>
            </>
          );
        })}
      </Row>
      <Pagination
        style={{
          backgroundColor: "whitesmoke",
          padding: "1em",
          zIndex: 1000,
        }}
        onChange={onPageChange}
        total={manyPreuniversitarios.total}
        defaultPageSize={pageSize}
        responsive
        current={page}
      />
    </>
  );
};

export default ListaPreuniversitarioPage;
