import { Affix, Button, Col, Divider, Form, FormInstance, Row, Typography } from "antd";
import cogoToast from "cogo-toast";
import FileUpload from "../../../../components/FileUpload";
import AntdConsts from "../../../../designConstants/antd-constants";
import {
  checkDefaultImageExist,
  scrollToFieldWithOffsetFunction,
} from "../../../../designConstants/antd-utils";
import { useAuthStorage } from "../../../../hooks/useBrowserStorage";
import { AuthToken } from "../../../../types/auth.types";
import { handleLoadDataOntoUploadInputFotos } from "../../submitInsEduPage/submitInsEdu";
import AcademiaDeportiveFormStepCounter from "../components/AcademiaDeportiveFormStepCounter";
import DatosBasicosAcademiaArtisticaForm from "../components/DatosBasicosAcademiaArtisticaForm";
import DatosContactoAcademiaArtisticaForm from "../components/DatosContactoAcademiaArtisticaForm";

interface AcademiaDeportivaFormStepOneProps {
  form: FormInstance;
  onNext: () => void;
}

const AcademiaArtisticaFormStepOne = ({
  form,
  onNext,
}: AcademiaDeportivaFormStepOneProps) => {
  const authToken = useAuthStorage();

  handleLoadDataOntoUploadInputFotos(form);

  const onStepFinish = () => {
    if (!checkDefaultImageExist(form)) {
      cogoToast.error("Debe seleccionar una imagen de portada y al menos una imagen de galeria");
      return;
    }
    form
      .validateFields()
      .then((_) => onNext())
      .catch((error) => {
        form.scrollToField(error.errorFields?.[0]?.name, {
          behavior: scrollToFieldWithOffsetFunction,
        });
        cogoToast.error("Existen errores en el formulario");
      });
  };

  return (
    <>
      <Row>
        <Col
          lg={8}
          xl={8}
          xxl={8}
          {...AntdConsts.removeColOnMobileLayout}
          style={{ padding: "2em", paddingTop: "20px" }}
        >
          <Affix offsetTop={AntdConsts.headerHeight + 20}>
            <AcademiaDeportiveFormStepCounter
              currentStep={0}
              direction="vertical"
            />
          </Affix>
        </Col>
        <Col
          lg={16}
          xl={16}
          xxl={16}
          {...AntdConsts.fullSizeColOnMobileLayout}
          style={{ padding: "2em", backgroundColor: "white" }}
        >
          <DatosBasicosAcademiaArtisticaForm />
          <Divider />
          <Typography.Title level={5}>Imagenes (se admiten archivos PNG, JPG y JPEG)</Typography.Title>
          <br/>
          <Row gutter={AntdConsts.rowGutter}>
            <Col {...AntdConsts.twoColLayout}>
              <Form.Item name="defaultImage" label="Imagen de portada (requerido)">
                <FileUpload
                  action={`${process.env.REACT_APP_API_URL}instituciones/images/default`}
                  listType="picture-card"
                  maxCount={1}
                  headers={{ Authorization: authToken as AuthToken }}
                  defaultFileList={form.getFieldValue("defaultImage")}
                  accept="image/png, image/gif, image/jpeg"
                >
                  <Button>+</Button>
                </FileUpload>
              </Form.Item>
            </Col>
            <Col {...AntdConsts.twoColLayout}>
              <Form.Item name="restOfImages" label="Resto de las imágenes (máximo 5 archivos, requerido)">
                <FileUpload
                  action={`${process.env.REACT_APP_API_URL}instituciones/images`}
                  listType="picture-card"
                  maxCount={5}
                  multiple
                  headers={{ Authorization: authToken as AuthToken }}
                  defaultFileList={form.getFieldValue("restOfImages")}
                  accept="image/png, image/gif, image/jpeg"
                >
                  <Button>+</Button>
                </FileUpload>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <DatosContactoAcademiaArtisticaForm />
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={onStepFinish}
          >
            Siguiente
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AcademiaArtisticaFormStepOne;
