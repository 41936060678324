import { Alert, Button, Col, Row, Space } from "antd";
import { useAuthStorage } from "../hooks/useBrowserStorage";
import { AuthTokenPayload } from "../types/auth.types";
import {
  CommonInstitucion,
  TipoInstitucionEducativaIds,
  TipoInstitucionIds,
} from "../types/instituciones.types";
import CambiarVisibilidadInstitucionButton from "../adminViews/RegisteredUserAdminPage/components/CambiarVisibilidadInstitucionButton";
import { useState } from "react";
import useIsMobile from "../hooks/useMobile";
import AntdConsts from "../designConstants/antd-constants";
import DestacarInstitucionButton from "../adminViews/SponsoredAdminPage/DestacarInstitucionButton";
import { FaStar } from "react-icons/fa";

export const goToEditPage = (
  tipoInstitucionIdNumber: number,
  id: number | undefined
) => {
  if (TipoInstitucionIds.ACADEMIA_ARTISTICA === tipoInstitucionIdNumber) {
    window.location.href = `/academia-artistica/update/${id}`;
  } else if (TipoInstitucionIds.ACADEMIA_DEPORTIVA === tipoInstitucionIdNumber) {
    window.location.href = `/academia-deportiva/update/${id}`;
  } else if (TipoInstitucionIds.ACADEMIA_IDIOMAS === tipoInstitucionIdNumber) {
    window.location.href = `/academia-idiomas/update/${id}`;
  } else if (TipoInstitucionIds.PREUNIVERSITARIO === tipoInstitucionIdNumber) {
    window.location.href = `/preuniversitario/update/${id}`;
  } else if (TipoInstitucionEducativaIds.includes(tipoInstitucionIdNumber)) {
    window.location.href = `/update/colegio/${id}`;
  } else if (tipoInstitucionIdNumber === TipoInstitucionIds.ESCUELA_VERANO) {
    window.location.href = `/escuela-verano/update/${id}`;
  } else if (tipoInstitucionIdNumber === TipoInstitucionIds.REFORZAMIENTO){
    window.location.href = `/reforzamiento/update/${id}`;
  } else if (tipoInstitucionIdNumber === TipoInstitucionIds.GUARDERIA_INFANTIL){
    window.location.href = `/guarderia/update/${id}`;
  }
};

interface AdminBannerOverDetailViewProps {
  institucion: CommonInstitucion;
}

const AdminBannerOverDetailView = ({
  institucion,
}: AdminBannerOverDetailViewProps) => {
  const tipoInstitucionId = institucion.tipoInstitucion.id;
  const id = institucion.id;
  const ownerUser = institucion.owningUser
    ? institucion.owningUser
    : institucion.User;

  const [isHidden, setIsHidden] = useState(institucion.hidden);

  const auth = useAuthStorage(true) as AuthTokenPayload;

  const isMobile = useIsMobile();

  if (!auth) {
    return <></>;
  }

  const isAdmin = auth?.profileType?.type === "ADMIN";
  const isOwningUser = auth.userId === ownerUser?.id;

  if (!isAdmin && !isOwningUser) {
    return <></>;
  }

  const goToUserPage = () => {
    window.open(`/admin/usuario/${ownerUser?.id}`, "_blank");
  };

  const action = (
    <Space direction="horizontal">
      {(isOwningUser || isAdmin) && (
        <Button
          block
          type="primary"
          onClick={() => goToEditPage(tipoInstitucionId, id)}
        >
          Editar
        </Button>
      )}

      {isAdmin && (
        <CambiarVisibilidadInstitucionButton
          tipoInstitucionId={tipoInstitucionId}
          id={id as number}
          startsHidden={institucion.hidden as boolean}
          onChangeVisibility={setIsHidden}
        />
      )}
      {isAdmin && (
        <DestacarInstitucionButton
          tipoInstitucionId={tipoInstitucionId}
          id={id as number}
          startsDestacado={institucion.isSponsored}
        />
      )}
      {isAdmin && (
        <Button block onClick={goToUserPage} type="ghost">
          Ver datos del usuario
        </Button>
      )}
      <Button block type="link" href={`mailto:${ownerUser?.email}`}>
        Contactar con encargado(a) del establecimiento
      </Button>
    </Space>
  );

  const actionIsMobile = (
    <Row gutter={[5, 5]}>
      <Col {...AntdConsts.fullSizeColLayout}>
        {institucion.isSponsored
          ? "Herramientas - Institucion destacada"
          : "Herramientas"}
        {isHidden
          ? "Herramientas - Institución se encuentra oculta"
          : "Herramientas"}
      </Col>
      {(isOwningUser || isAdmin) && (
        <Col {...AntdConsts.fullSizeColLayout}>
          <Button
            block
            type="primary"
            onClick={() => goToEditPage(tipoInstitucionId, id)}
          >
            Editar
          </Button>
        </Col>
      )}

      {(isOwningUser || isAdmin) && (
        <Col {...AntdConsts.fullSizeColLayout}>
          <CambiarVisibilidadInstitucionButton
            tipoInstitucionId={tipoInstitucionId}
            id={id as number}
            startsHidden={institucion.hidden as boolean}
            onChangeVisibility={setIsHidden}
          />
        </Col>
      )}
       {isAdmin && (
        <DestacarInstitucionButton
          tipoInstitucionId={tipoInstitucionId}
          id={id as number}
          startsDestacado={institucion.isSponsored}
        />
      )}
      {isAdmin && (
        <Col {...AntdConsts.fullSizeColLayout}>
          <Button block onClick={goToUserPage} type="ghost">
            Ver datos del usuario
          </Button>
        </Col>
      )}

      <Col {...AntdConsts.fullSizeColLayout}>
        <Button block type="link" href={`mailto:${ownerUser?.email}`}>
          Contactar con encargado(a) del establecimiento
        </Button>
      </Col>
    </Row>
  );

  return (
    <Alert
      message={
        isMobile
          ? undefined
          : isHidden
          ? "Herramientas - Institución se encuentra oculta "
          : "Herramientas"
      }
      type={isHidden ? "warning" : "info"}
      icon={
        institucion.isSponsored ? (
          <FaStar color={"orange"} size="1.5em" />
        ) : undefined
      }
      style={institucion.isSponsored ? { backgroundColor: "white" } : undefined}
      showIcon
      action={isMobile ? actionIsMobile : action}
    />
  );
};

export default AdminBannerOverDetailView;
