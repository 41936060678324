import { FormInstance } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { CustomScrollAction } from "scroll-into-view-if-needed/typings/types";
import AntdConsts from "./antd-constants";

/***
 * used with the FormInstance scrollToField prop to scroll with offset
 * see https://github.com/ant-design/ant-design/issues/24308#issuecomment-631857986
 * and https://github.com/stipsan/scroll-into-view-if-needed/tree/ece40bd9143f48caf4b99503425ecb16b0ad8249#options
 */
export const scrollToFieldWithOffsetFunction = (
  actions: CustomScrollAction[]
) => {
  const { el, top, left } = actions[0];
  window.scrollTo(0, 0);
  // window.scrollTo(
  //   left,
  //   top -
  //     AntdConsts.headerHeight -
  //     // hack to calculate the size of a em unit
  //     parseFloat(getComputedStyle(el).fontSize) * 3
  // );
};

export const transformUploadFilesToFormData = (
  form: FormInstance
): { id: number }[] => {
  const defaultImage = form.getFieldValue("defaultImage") as UploadChangeParam;
  const restOfImages = form.getFieldValue("restOfImages") as UploadChangeParam;

  //Obtenemos solo las Ids de las imagenes
  let defaultImageId = defaultImage?.fileList?.map((image) => ({
    id: image.response.id,
  }));

  if (!defaultImageId) {
    //@ts-ignore
    defaultImageId = defaultImage?.map((image) => ({
      id: image?.response?.id,
    }));
  }

  let restOfImagesIds = restOfImages?.fileList?.map((image) => ({
    //@ts-ignore
    id: image?.response?.id,
  }));

  if (!restOfImagesIds) {
    //@ts-ignore
    restOfImagesIds = restOfImages?.map((image) => ({
      id: image?.response?.id,
    }));
  }

  let allImages = defaultImageId?.concat(restOfImagesIds || []);

  // removesmos cualquier imagen que no tenga id de la lista, esto puede suceder en circunstancias excepcionales cuando el usuario
  // está creando una institucion y entremedio de esa acción se relaiza una limpieza de las imagenes no asociadas a ninguna institución 
  // por lo que las imagenes del formulario que aun no se ingresa dejaran de existir
  allImages = allImages.filter(image => {
    return  !Number.isNaN(image?.id)
  })

  return allImages
};

export const youTubeIdFromLink = (url?: string) => {
  try {
    if (!url) {
      return "";
    }

    const match = url.match(
      /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/
    );

    if (!match) {
      return undefined
    }

    return match[1]
  } catch (error) {
    return undefined;
  }
};

export const checkDefaultImageExist = (form: FormInstance) => {
  const defaultImage = form.getFieldValue(
    "defaultImage"
  ) as UploadChangeParam;
  
  let defaultImageId = defaultImage?.fileList?.map((image) => ({
    id: image.response.id,
  }));

  if (defaultImageId?.length === 0) {
    return false;
  }

  if (!defaultImageId) {
    //@ts-ignore
    defaultImageId = defaultImage?.map((image) => ({
      id: image?.response?.id,
    }));
  }

  if (!defaultImageId) {
    return false;
  }


  const restOfImages = form.getFieldValue(
    "restOfImages"
  ) as UploadChangeParam;
  
  let restOfImagesIds = restOfImages?.fileList?.map((image) => ({
    id: image.response.id,
  }));

  if (restOfImagesIds?.length === 0) {
    return false;
  }

  if (!restOfImagesIds) {
    //@ts-ignore
    restOfImagesIds = restOfImages?.map((image) => ({
      id: image?.response?.id,
    }));
  }

  if (!restOfImagesIds) {
    return false;
  }

  return true
}
