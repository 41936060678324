import { Avatar, Card, Skeleton } from "antd";

const CardLoading = () => {
  return (
    <Card style={{ width: 300, marginTop: 16 }}>
      <Skeleton loading={true} avatar active>
        <Card.Meta
          avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
        />
      </Skeleton>
    </Card>
  );
};

export default CardLoading;
