import { Upload, UploadProps } from "antd";

interface FileUploadProps extends UploadProps {
  children?: React.ReactElement;
}

const FileUpload = ({ children, ...uploadProps }: FileUploadProps) => {
  return (
    <>
      <Upload {...uploadProps}>{children}</Upload>
    </>
  );
};

export default FileUpload;
