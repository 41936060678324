import { useRequest } from "ahooks";
import { Col, Descriptions, PageHeader, Row } from "antd";
import { CSSProperties } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { reqGetSingleUser } from "../commonRequests/adminRequests";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../designConstants/antd-constants";
import useIsMobile from "../hooks/useMobile";
import HeaderModern from "../views/header/HeaderModern";
import InstitucionesUsuarioTable from "./RegisteredUserAdminPage/components/InstitucionesUsuarioTable";

const UserDetailPage = () => {
  const { id } = useParams();
  const userReq = useRequest(() => reqGetSingleUser(+(id as string)));
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  if (userReq.loading) {
    return <LoadingSpinner />;
  }

  const user = userReq.data;

  if (!user) {
    return <>404</>;
  }

  const style: CSSProperties = {
    paddingLeft: isMobile ? "1em" : "10%",
    paddingRight: isMobile ? "1em" : "10%",
    paddingTop: "2em",
  };

  return (
    <>
      <HeaderModern />
      <Row gutter={AntdConsts.rowGutter} style={style}>
        <Col {...AntdConsts.fullSizeColLayout}>
          <PageHeader
            title="Volver a página de administración"
            onBack={() => navigate("/admin")}
          />
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Descriptions
            title="Información del usuario"
            layout="horizontal"
            bordered
            column={1}
          >
            <Descriptions.Item label="Nombre">
              {user.nombre.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Correo">{user.email}</Descriptions.Item>
            <Descriptions.Item label="Teléfono">
              {user.telefono}
            </Descriptions.Item>
            <Descriptions.Item label="Perfil">
              {user.profile.type.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Está verificado?">
              {user.verificado ? "VERIFICADO" : "NO VERIFICADO"}
            </Descriptions.Item>
            <Descriptions.Item label="Fecha creación">
              {user.createdAt}
            </Descriptions.Item>
            <Descriptions.Item label="Vendedor">
              {user.vendedor ? (
                <a
                  href={`/admin/vendedores/${user?.vendedor?.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {user.vendedor.nombre.toUpperCase()}
                </a>
              ) : (
                <p>SIN VENDEDOR</p>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Descriptions
            title="Establecimiento del usuario"
            layout="vertical"
            column={1}
            bordered
          >
            <Descriptions.Item label="Nombre">
              {user.nombreInstitucion.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Rut institución">
              {user.rutInstitucion}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo institución">
              {user.tipoInstitucion.nombre.toUpperCase()}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col {...AntdConsts.fullSizeColLayout}>
          <InstitucionesUsuarioTable userId={user.id} />
        </Col>
      </Row>
    </>
  );
};

export default UserDetailPage;
