import request from "./axiosInstance";
import {
  AnioEscolar,
  Extraprogramaticas,
  JornadaEscolar,
} from "../views/InsEduDetailView/types/SingleInsEdu.type";
import {
  Alianzas,
  Becas,
  PlanesYProgramas,
} from "../views/InsEduDetailView/types/SingleInstitucionEducativaEasyVisualization.type";
import { IdiomaEducativo } from "../views/listaInsEdu/types/SingleInstitucionEducativa.type";
import { ModalidadEstudio, TipoInstitucion } from "../types/params.type";
import { ProfileType } from "../types/auth.types";
import { NivelEducativo } from "../types/insEdu.types";

export const requestNivelesEducativos = async () => {
  try {
    const response = await request.get<NivelEducativo[]>(
      "/params/niveles-educativos"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestIdiomasEducativos = async () => {
  try {
    const response = await request.get<IdiomaEducativo[]>(
      "/params/idiomas-educativos"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestCaracteristicasExtra = async () => {
  try {
    const response = await request.get<Extraprogramaticas[]>(
      "/params/caracteristicas-extra"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestExtraprogramaticas = async () => {
  try {
    const response = await request.get<Extraprogramaticas[]>(
      "/params/actividades-extraprogramaticas"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqModalidadEstudio = async () => {
  try {
    const response = await request.get<ModalidadEstudio[]>("/params/modalidad-estudio");
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqTipoEducacion = async () => {
  try {
    const response = await request.get<{id: number, nombre: string}[]>('/params/tipo-educacion');
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestAlianzas = async () => {
  try {
    const response = await request.get<Alianzas[]>("/params/alianzas");
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestBecas = async () => {
  try {
    const response = await request.get<Becas[]>("/params/becas");
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestPlanesYProgramas = async () => {
  try {
    const response = await request.get<PlanesYProgramas[]>(
      "/params/planes-y-programas"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestAniosEscolares = async () => {
  try {
    const response = await request.get<AnioEscolar[]>(
      "/params/anios-escolares"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestJornadas = async () => {
  try {
    const response = await request.get<JornadaEscolar[]>(
      "/params/jornadas-escolares"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestTipoInstitucion = async () => {
  try {
    const response = await request.get<TipoInstitucion[]>(
      "/params/tipo-institucion"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestTiposUsuarios = async () => {
  try {
    const response = await request.get<ProfileType[]>("/params/tipos-usuarios");
    return response.data;
  } catch (error) {
    return null;
  }
};
