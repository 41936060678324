import request from "../../commonRequests/axiosInstance";
import { InsEduFilters } from "../../components/FilterSelector/types/InsEduFilters.type";
import { InstitucionEducativaFilters } from "../../types/filtros.type";
import { GetManyInsEduResponse } from "../../types/insEdu.types";

const getManyInsEdu = async (filters: InstitucionEducativaFilters) => {
  try {
    const response = await request.post<GetManyInsEduResponse>(
      "/institucion-educativa",
      filters
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getManyInsEduWithFilters = async (filters: InsEduFilters) => {
  try {
    const response = await request.get<GetManyInsEduResponse>(
      "/institucion-educativa",
      { params: filters }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export default getManyInsEdu;
