import { AiFillCalculator } from "react-icons/ai";
import { FaAtom, FaDna, FaLanguage, FaMapMarkedAlt } from "react-icons/fa";
import { GiChemicalDrop } from "react-icons/gi";
import { MdOutlineHistoryEdu } from "react-icons/md";

function IconoTablas({ nombreIcono }: { nombreIcono: string }) {
  const iconos: any = {
    "Alpinismo": require("../iconosTablas/iconosExtraPro/ALPINISMO.png"),
    "Apoyo a la Familia": require("../iconosTablas/iconosApoyo/APOYO A LA FAMILIA.png"),
    "Batalla de cuerdas": require("../iconosTablas/iconosExtraPro/BATALLA DE CUERDAS.png"),
    "Bowling": require("../iconosTablas/iconosExtraPro/BOWLING.png"),
    "Buceo": require("../iconosTablas/iconosExtraPro/BUSEO.png"),
    "Defensa personal": require("../iconosTablas/iconosExtraPro/DEFENSA PERSONAL.png"),
    "Equitación": require("../iconosTablas/iconosExtraPro/EQUITACIÓN.png"),
    "Esquí Acuático": require("../iconosTablas/iconosExtraPro/ESQUI ACUATICO.png"),
    "Fútbol Americano": require("../iconosTablas/iconosExtraPro/FUTBOL AMERICANO.png"),
    "Gimnasia Rítmica": require("../iconosTablas/iconosExtraPro/GIMNASIA RITMICA.png"),
    "Kayak": require("../iconosTablas/iconosExtraPro/KAYAK.png"),
    "Paracaidismo": require("../iconosTablas/iconosExtraPro/PARACAIDISMO.png"),
    "Plataforma Online": require("../iconosTablas/iconosServicios/PLATAFORMA ONLINE.png"),
    "Patinaje": require("../iconosTablas/iconosExtraPro/PATINAJE.png"),
    "Polo": require("../iconosTablas/iconosExtraPro/POLO.png"),
    "Senderismo": require("../iconosTablas/iconosExtraPro/SENDERISMO.png"),
    "Surf": require("../iconosTablas/iconosExtraPro/SURF.png"),
    "Windsurf": require("../iconosTablas/iconosExtraPro/WINDSURF.png"),
    "Reciclaje": require("../iconosTablas/iconosExtraPro/RECICLAJE.png"),
    "Tiro al blanco": require("../iconosTablas/iconosExtraPro/TIRO AL BLANCO.png"),
    "Sala Computación": require("../iconosTablas/iconosInfraestructura/SALA DE COMPUTACIÓN.png"),
    "Sala Audiovisual": require("../iconosTablas/iconosInfraestructura/SALA AUDIOVISUAL 96.png"),
    "Laboratorio de Ciencias": require("../iconosTablas/iconosInfraestructura/LABORATORIO 100.png"),
    "Taller de tecnología": require("../iconosTablas/iconosInfraestructura/TALLER DE TECNOLOGIA 100.png"),
    Biblioteca: require("../iconosTablas/iconosInfraestructura/BIBLIOTECA 100.png"),
    Comedor: require("../iconosTablas/iconosInfraestructura/COMEDOR 100 .png"),
    Multicancha: require("../iconosTablas/iconosInfraestructura/MULTICANCHA 96.png"),
    Camarín: require("../iconosTablas/iconosInfraestructura/CAMARIN 100.png"),
    "Kiosco Saludable": require("../iconosTablas/iconosInfraestructura/KIOSCO SALUDABLE 100.png"),
    Piscina: require("../iconosTablas/iconosInfraestructura/PISCINA 100.png"),
    "Áreas Verdes": require("../iconosTablas/iconosInfraestructura/AREAS VERDES .png"),
    Huerto: require("../iconosTablas/iconosInfraestructura/HUERTO 100.png"),
    Gimnasio: require("../iconosTablas/iconosInfraestructura/GIMNASIO 100.png"),
    "Sala de Conferencias": require("../iconosTablas/iconosInfraestructura/SALA CONFERENCIA 100.png"),
    Patio: require("../iconosTablas/iconosInfraestructura/PATIO 100.png"),
    "Patio Infantil": require("../iconosTablas/iconosInfraestructura/PATIO INFANTIL 100.png"),
    "Pizarra interactiva": require("../iconosTablas/iconosInfraestructura/PIZARRA INTERACTIVA 100.png"),
    "Transporte escolar": require("../iconosTablas/iconosServicios/TRANSPORTE ESCOLAR.png"),
    "Menu especial": require("../iconosTablas/iconosServicios/MENU ESPECIAL.png"),
    Enfermería: require("../iconosTablas/iconosServicios/ENFERMERIA.png"),
    Enfermera: require("../iconosTablas/iconosServicios/ENFERMERIA.png"),
    "Wi-Fi": require("../iconosTablas/iconosServicios/WIFI.png"),
    "Uniforme Propio": require("../iconosTablas/iconosServicios/UNIFORME PROPIO.png"),
    Intranet: require("../iconosTablas/iconosServicios/INTRANET.png"),
    "Portal de apoderados": require("../iconosTablas/iconosServicios/PORTAL DE APODERADOS.png"),
    "Clases Online": require("../iconosTablas/iconosServicios/CLASES ONLINE.png"),
    "Cámara de seguridad en línea": require("../iconosTablas/iconosServicios/CAMARA DE SEGURIDAD EN LINEA.png"),
    Reforzamiento: require("../iconosTablas/iconosApoyo/REFORZAMIENTO.png"),
    Psicólogo: require("../iconosTablas/iconosApoyo/PSICÓLOGO.png"),
    Orientador: require("../iconosTablas/iconosApoyo/ORIENTADOR.png"),
    "Profesor Educación Especial Diferencial": require("../iconosTablas/iconosApoyo/PROFESOR EDUCACIÓN ESPECIAL.png"),
    "Mediador Escolar": require("../iconosTablas/iconosApoyo/MEDIADOR ESCOLAR.png"),
    Fonoaudiología: require("../iconosTablas/iconosApoyo/FONOAUDIOLOGO.png"),
    Psicopedagogo: require("../iconosTablas/iconosApoyo/PSICOPEDAGOGO.png"),
    Folklore: require("../iconosTablas/iconosExtraPro/FOLKLORE.png"),
    folklore: require("../iconosTablas/iconosExtraPro/FOLKLORE.png"),
    Ballet: require("../iconosTablas/iconosExtraPro/BALLET.png"),
    Danza: require("../iconosTablas/iconosExtraPro/DANZA.png"),
    Pintura: require("../iconosTablas/iconosExtraPro/PINTURA ACRILICA.png"),
    Teatro: require("../iconosTablas/iconosExtraPro/TEATRO.png"),
    Manualidades: require("../iconosTablas/iconosExtraPro/MANUALIDADES.png"),
    "Taller Música": require("../iconosTablas/iconosExtraPro/TALLER DE MUSICA.png"),
    Piano: require("../iconosTablas/iconosExtraPro/PIANO.png"),
    "Piano/Teclado": require("../iconosTablas/iconosExtraPro/PIANO.png"),
    Violín: require("../iconosTablas/iconosExtraPro/VIOLÍN.png"),
    Viola: require("../iconosTablas/iconosExtraPro/VIOLA.png"),
    "Guitarra Clásica": require("../iconosTablas/iconosExtraPro/GUITARRA CLÁSICA.png"),
    "Guitarra acústica": require("../iconosTablas/iconosExtraPro/GUITARRA CLÁSICA.png"),
    "Guitarra Eléctrica": require("../iconosTablas/iconosExtraPro/GUITARRA ELECTRICA.png"),
    "Guitarra eléctrica": require("../iconosTablas/iconosExtraPro/GUITARRA ELECTRICA.png"),
    Bateria: require("../iconosTablas/iconosExtraPro/BATERIA.png"),
    Batería: require("../iconosTablas/iconosExtraPro/BATERIA.png"),
    "Batería Acústica": require("../iconosTablas/iconosExtraPro/BATERIA.png"),
    "Batería eléctrica": require("../iconosTablas/iconosExtraPro/BATERIA.png"),
    Trompeta: require("../iconosTablas/iconosExtraPro/TROMPETA.png"),
    Trombón: require("../iconosTablas/iconosExtraPro/TROMBON.png"),
    Triangulo: require("../iconosTablas/iconosExtraPro/TRIANGULO.png"),
    Arpa: require("../iconosTablas/iconosExtraPro/ARPA.png"),
    Saxofón: require("../iconosTablas/iconosExtraPro/SAXOFÓN.png"),
    Xilófono: require("../iconosTablas/iconosExtraPro/XILÓFONO 100.png"),
    Canto: require("../iconosTablas/iconosExtraPro/CANTO.png"),
    Natación: require("../iconosTablas/iconosExtraPro/NATACION.png"),
    Atletismo: require("../iconosTablas/iconosExtraPro/ATLETISMO.png"),
    Karate: require("../iconosTablas/iconosExtraPro/KARATE.png"),
    Fútbol: require("../iconosTablas/iconosExtraPro/FUTBOL.png"),
    Yoga: require("../iconosTablas/iconosExtraPro/YOGA.png"),
    Ajedrez: require("../iconosTablas/iconosExtraPro/AJEDREZ.png"),
    Astronomía: require("../iconosTablas/iconosExtraPro/ASTRONOMÍA.png"),
    Robótica: require("../iconosTablas/iconosExtraPro/ROBOTICA.png"),
    "Visita Museos": require("../iconosTablas/iconosExtraPro/VISITA MUSEO.png"),
    Tecnología: require("../iconosTablas/iconosExtraPro/TECNOLOGÍA.png"),
    Excursiones: require("../iconosTablas/iconosExtraPro/EXCURSIONES.png"),
    Dibujo: require("../iconosTablas/iconosExtraPro/DIBUJO.png"),
    Actuación: require("../iconosTablas/iconosExtraPro/ACTUACION.png"),
    "Pintura al óleo": require("../iconosTablas/iconosExtraPro/PINTURA OLEO.png"),
    Escultura: require("../iconosTablas/iconosExtraPro/ESCULTURA.png"),
    Cinematografía: require("../iconosTablas/iconosExtraPro/CINEMATOGRAFIA.png"),
    Fotografía: require("../iconosTablas/iconosExtraPro/FOTOGRAFIA.png"),
    Bandas: require("../iconosTablas/iconosExtraPro/BANDAS.png"),
    Música: require("../iconosTablas/iconosExtraPro/MUSICA.png"),
    "Pintura acrílica": require("../iconosTablas/iconosExtraPro/PINTURA ACRILICA.png"),
    Violonchelo: require("../iconosTablas/iconosExtraPro/VIOLONCHELO 100.png"),
    Trompa: require("../iconosTablas/iconosExtraPro/TROMPA.png"),
    Tuba: require("../iconosTablas/iconosExtraPro/TUBA.png"),
    Acordeón: require("../iconosTablas/iconosExtraPro/ACORDEÓN.png"),
    Armónica: require("../iconosTablas/iconosExtraPro/ARMONICA.png"),
    Platillos: require("../iconosTablas/iconosExtraPro/PLATILLOS.png"),
    Oboe: require("../iconosTablas/iconosExtraPro/OBOE.png"),
    Clarinete: require("../iconosTablas/iconosExtraPro/CLARINETE.png"),
    Charango: require("../iconosTablas/iconosExtraPro/CHARANGO.png"),
    Contrabajo: require("../iconosTablas/iconosExtraPro/CONTRABAJO.png"),
    Quena: require("../iconosTablas/iconosExtraPro/QUENA.png"),
    Flauta: require("../iconosTablas/iconosExtraPro/FLAUTA.png"),
    Zampoña: require("../iconosTablas/iconosExtraPro/ZAMPOÑA 100.png"),
    Bajo: require("../iconosTablas/iconosExtraPro/BAJO.png"),
    Ciclismo: require("../iconosTablas/iconosExtraPro/CICLISMO.png"),
    Gimnasia: require("../iconosTablas/iconosExtraPro/GIMNASIA RITMICA.png"),
    Voleibol: require("../iconosTablas/iconosExtraPro/VOLEIBOL.png"),
    "Deportes de riesgo": require("../iconosTablas/iconosExtraPro/DEPORTE DE ALTO RIESGO.png"),
    Esgrima: require("../iconosTablas/iconosExtraPro/ESGRIMA.png"),
    Golf: require("../iconosTablas/iconosExtraPro/GOLF.png"),
    Hockey: require("../iconosTablas/iconosExtraPro/HOCKEY.png"),
    "Judo/yudo": require("../iconosTablas/iconosExtraPro/JUDO.png"),
    "Pelota vasca": require("../iconosTablas/iconosExtraPro/PELOTA VASCA.png"),
    "Rugby": require("../iconosTablas/iconosExtraPro/RUGBY.png"),
    Taekwondo: require("../iconosTablas/iconosExtraPro/TAEKWONDO.png"),
    Tenis: require("../iconosTablas/iconosExtraPro/TENIS.png"),
    "Tenis de mesa": require("../iconosTablas/iconosExtraPro/TENIS DE MESA.png"),
    "Tiro con arco": require("../iconosTablas/iconosExtraPro/TIRO AL ARCO.png"),
    Béisbol: require("../iconosTablas/iconosExtraPro/BEISBOL.png"),
    Boxeo: require("../iconosTablas/iconosExtraPro/BOXEO.png"),
    "Deportes acuáticos": require("../iconosTablas/iconosExtraPro/ESQUI ACUATICO.png"),
    Hípica: require("../iconosTablas/iconosExtraPro/HIPICO.png"),
    "Karate/Kárate": require("../iconosTablas/iconosExtraPro/KARATE.png"),
    Lucha: require("../iconosTablas/iconosExtraPro/LUCHA.png"),
    Snowboard: require("../iconosTablas/iconosExtraPro/SNOWBOARDN.png"),
    "Tiro olímpico": require("../iconosTablas/iconosExtraPro/TIRO AL BLANCO.png"),
    Baloncesto: require("../iconosTablas/iconosExtraPro/BALONCESTO.png"),
    Bádminton: require("../iconosTablas/iconosExtraPro/BADMINTON.png"),
    Esquí: require("../iconosTablas/iconosExtraPro/ESQUI.png"),
    Halterofilia: require("../iconosTablas/iconosExtraPro/HALTEROFILIA.png"),
    Remo: require("../iconosTablas/iconosExtraPro/REMO 96.png"),
    Vela: require("../iconosTablas/iconosExtraPro/VELA.png"),
    "Waterpolo/Polo acuático": require("../iconosTablas/iconosExtraPro/WATER POLO.png"),
    Motociclismo: require("../iconosTablas/iconosExtraPro/MOTOCICLISMO.png"),
    Balonmano: require("../iconosTablas/iconosExtraPro/BALONMANO.png"),
    "Canotaje": require("../iconosTablas/iconosExtraPro/CANOTAJE-PARAGUISMO.png"),
    "Bandoneón": require("../iconosTablas/iconosExtraPro/BANDONEÓN.png"),
    "Bombo": require("../iconosTablas/iconosExtraPro/BOMBO.png"),
    "Caja de redoble": require("../iconosTablas/iconosExtraPro/CAJA DE REDOBLE .png"),
    "Director de orquesta": require("../iconosTablas/iconosExtraPro/DIRECTOR DE ORQUETA.png"),
    "Coro": require("../iconosTablas/iconosExtraPro/CORO.png"),
    "Fagot": require("../iconosTablas/iconosExtraPro/FAGOT.png"),
    "Flauta traversa": require("../iconosTablas/iconosExtraPro/FLAUTA TRAVERSA.png"),
    "Flautín": require("../iconosTablas/iconosExtraPro/FLAUTIN.png"),
    "Lira": require("../iconosTablas/iconosExtraPro/LIRA.png"),
    "Mandolina": require("../iconosTablas/iconosExtraPro/MANDOLINA.png"),
    "Sintetizador": require("../iconosTablas/iconosExtraPro/SINTETIZADOR.png"),
    "Timbales": require("../iconosTablas/iconosExtraPro/TIMBALES.png"),
    "Banjo": require("../iconosTablas/iconosExtraPro/BANJO.png"),
    "Conga": require("../iconosTablas/iconosExtraPro/CONGA.png"),
    "Corneta": require("../iconosTablas/iconosExtraPro/CORNETA.png"),
    "Taller de Música": require("../iconosTablas/iconosExtraPro/TALLER DE MUSICA.png"),

    
    //ASIGNATURAS
    Lenguaje:  require("../iconosTablas/iconosAsignatura/LENGUAJE.png"),
    Matemática: require("../iconosTablas/iconosAsignatura/MATEMATICA.png"),
    Química: <GiChemicalDrop style={{ fontSize: "1.5em", color: "blue" }} />,
    Física: <FaAtom style={{ fontSize: "1.5em", color: "blue" }} />,
    Biología: require("../iconosTablas/iconosAsignatura/BIOLOGIA.png"),
    Historia: require("../iconosTablas/iconosAsignatura/HISTORIA.png"),
    Geografía: <FaMapMarkedAlt style={{ fontSize: "1.5em", color: "blue" }} />,
    "Ciencias Sociales": require("../iconosTablas/iconosAsignatura/CIENCIAS SOCIALES.png"),
  };

  const anyIcon = require("../iconosTablas/iconosExtraPro/ANY.png");
  const icono = iconos[nombreIcono];

  if (!iconos[nombreIcono]) {
    return <img src={anyIcon} alt="icono" style={{ height: "1.5em" }} />;
  } else {
    if (typeof icono !== "string") {
      return <>{icono}</>;
    }
    return (
      <>
        <img src={icono} alt="icono" style={{ height: "1.5em" }} />
      </>
    );
  }
}

export default IconoTablas;
