import { Table, Typography } from "antd";
import { ImCheckmark } from "react-icons/im";
import { BsDashLg } from "react-icons/bs";
import SingleInstitucionEducativaEasyVisualization from "../types/SingleInstitucionEducativaEasyVisualization.type";

interface NivelesHorariosProps {
  insEdu: SingleInstitucionEducativaEasyVisualization;
}

const NivelesHorararios = ({ insEdu }: NivelesHorariosProps) => {
  const renderedDataSource = insEdu.tablaNivelesHorarios.dataSource.map((t) => {
    return {
      ...t,
      opcionHorarioExtendido: t.opcionHorarioExtendido ? (
        <ImCheckmark />
      ) : (
        <BsDashLg />
      ),
    };
  });

  return (
    <>
      <Typography.Title level={3}>NIVELES - HORARIOS</Typography.Title>
      <Table
        bordered
        pagination={false}
        columns={insEdu.tablaNivelesHorarios.headers}
        dataSource={renderedDataSource}
      />
    </>
  );
};

export default NivelesHorararios;
