export const getEmInPixels = () =>
  parseFloat(getComputedStyle(document.body).fontSize);

export const clearBrowserStorages = (key: string) => {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
};

export const clearAuthToken = () =>
  clearBrowserStorages(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
