import {
  Comuna,
  ModalidadEstudio,
  Pais,
  Region,
  TipoInstitucion,
} from "./params.type";
import _ from "lodash";
import { User } from "./auth.types";

export interface CommonFilters {
  nombre?: string;
  hidden?: boolean;
  needsReview?: boolean;
  disable_pagination?: boolean;
  take?: number;
  skip?: number;
  mensualidad?: { min?: number; max?: number };
  mensualidad_min?: number;
  mensualidad_max?: number;
  comuna_id?: number;
  region_id?: number;
  pais_id?: number;
  modalidad_id?: number[] | number;
  modalidad_estudios?: number[] | number;
  latitud?: number;
  longitud?: number;
  geo_direccion?: string;
  tipo_institucion?: number[] | number;
}

export interface CommonInstitucion {
  id?: number;
  nombre: string;
  pais: Pais;
  region: Region;
  comuna: Comuna;
  direccion: string;
  paginaWeb?: string;
  telefono: string;
  correo: string;
  descripcion: string;
  latitud: number;
  longitud: number;
  hidden?: boolean;
  needsReview?: boolean;
  tipoInstitucion: TipoInstitucion;
  updatedAt: string;
  createdAt: string;
  descripcionEdicion?: string; //ISO string
  isSponsored: boolean;
  distance?: number;
  tipoInstitucionId: number;
  facebook?: string;
  whatsapp?: string;
  twitter?: string;
  instagram?: string;
  fotos: { id: number }[];
  owningUser?: User;
  User?: User;
  modalidad: ModalidadEstudio;
}

export interface CountResponse {
  count: number;
}

export type tipoInstitucionType =
  | "Colegio"
  | "Jardín Infantil"
  | "Escuela de lenguaje"
  | "Escuelas Libres"
  | "Colegio"
  | "Colegios Online"
  | "Academias Deportivas"
  | "Idiomas"
  | "Preuniversitario"
  | "Reforzamiento"
  | "Academias Artísticas"
  | "Guarderias Infantiles";

export enum TipoInstitucionIds {
  COLEGIO = 1,
  JARDIN_INFANTIL = 2,
  ESCUELA_LENGUAJE = 3,
  ESCUELA_LIBRE = 4,
  COLEGIO_ONLINE = 5,
  ACADEMIA_DEPORTIVA = 6,
  ACADEMIA_IDIOMAS = 7,
  PREUNIVERSITARIO = 8,
  ACADEMIA_ARTISTICA = 9,
  GUARDERIA_INFANTIL = 10,
  ESCUELA_VERANO = 11,
  REFORZAMIENTO = 12,
}

export const InsEduTiposIds = [
  TipoInstitucionIds.COLEGIO,
  TipoInstitucionIds.JARDIN_INFANTIL,
  TipoInstitucionIds.ESCUELA_LENGUAJE,
  TipoInstitucionIds.COLEGIO_ONLINE,
  TipoInstitucionIds.ESCUELA_LIBRE,
  TipoInstitucionIds.COLEGIO_ONLINE,
];

export const TipoInstitucionEducativaIds = [
  TipoInstitucionIds.COLEGIO,
  TipoInstitucionIds.JARDIN_INFANTIL,
  TipoInstitucionIds.ESCUELA_LENGUAJE,
  TipoInstitucionIds.ESCUELA_LIBRE,
  TipoInstitucionIds.COLEGIO_ONLINE,
];

export enum NivelesEducativosIds {
  ESTIMULACION_TEMPRANA = 1,
  SALA_CUNA = 2,
  PLAY_GROUP = 3,
  NIVEL_MEDIO_MENOR = 4,
  NIVEL_MEDIO_MAYOR = 5,
  PRIMER_NIVELTRANSICION = 6,
  SEGUNDO_NIVELTRANSICION = 7,
  EDUCACION_BASICA = 8,
  EDUCACION_MEDIA = 9,
}

export const NivelesEducativosPorTipoInstitucion = {
  JARDIN_INFANTIL: [
    NivelesEducativosIds.ESTIMULACION_TEMPRANA,
    NivelesEducativosIds.SALA_CUNA,
    NivelesEducativosIds.PLAY_GROUP,
    NivelesEducativosIds.NIVEL_MEDIO_MENOR,
    NivelesEducativosIds.NIVEL_MEDIO_MAYOR,
    NivelesEducativosIds.PRIMER_NIVELTRANSICION,
    NivelesEducativosIds.SEGUNDO_NIVELTRANSICION,
  ],
};

export enum ArtesAcademiaArtisticaIds {
  MUSICA = 9,
}

export const compareComplexFilter = (
  current: number[] | number | undefined,
  old: number[] | number | undefined
) => {
  if (!current) {
    if (!old) {
      return true;
    }
    return false;
  }

  if (!Array.isArray(current)) {
    current = [current];
  }
  if (!Array.isArray(old)) {
    old = [old as number];
  }

  return _.isEqual(current, old);
};

export const commonFiltersComparator = (
  current: CommonFilters,
  old: CommonFilters
) => {
  return (
    current.hidden === old.hidden &&
    current.needsReview === old.needsReview &&
    current.disable_pagination === old.disable_pagination &&
    current.take === old.take &&
    current.skip === old.skip &&
    current.mensualidad_min === old.mensualidad_min &&
    current.mensualidad_max === old.mensualidad_max &&
    current.pais_id === old.pais_id &&
    current.region_id === old.region_id &&
    current.comuna_id === old.comuna_id &&
    current.mensualidad?.min === old.mensualidad?.min &&
    current.mensualidad?.max === old.mensualidad?.max &&
    compareComplexFilter(current.modalidad_estudios, old.modalidad_estudios) &&
    compareComplexFilter(current.modalidad_id, old.modalidad_id)
  );
};

export interface ContactoInstitucion {
  id: number;
  nombreSolicitante: string;
  telefono: string;
  correo: string;
  anio: number;
  mensaje: string;
  solicitaVisita: boolean;
  aceptaPoliticas: boolean;
  matriculasSolicitadas: number;
}

export type ContactoInstitucionCreateObject = Omit<ContactoInstitucion, "id">;

export interface SponsoredInstitucion {
  id: number;
  nombre: string;
  region: Region;
  pais: Pais;
  comuna: Comuna;
  tipoInstitucion: TipoInstitucion;
  fotos: { id: number }[];
  direccion: string;
  isSponsored: boolean
}

export const goToInstitucion = (record: {
  tipoInstitucion: TipoInstitucion;
  id: number;
  [key: string]: any;
}) => {
  if (InsEduTiposIds.includes(record.tipoInstitucion.id)) {
    window.open(`/categoria/colegios/${record.id}`, "_blank");
  } else if (
    record.tipoInstitucion.id === TipoInstitucionIds.ACADEMIA_DEPORTIVA
  ) {
    window.open(`/academia-deportiva/${record.id}`, "_blank");
  } else if (
    record.tipoInstitucion.id === TipoInstitucionIds.ACADEMIA_IDIOMAS
  ) {
    window.open(`/academia-idiomas/${record.id}`, "_blank");
  } else if (
    record.tipoInstitucion.id === TipoInstitucionIds.PREUNIVERSITARIO
  ) {
    window.open(`/preuniversitario/${record.id}`, "_blank");
  } else if (record.tipoInstitucion.id === TipoInstitucionIds.REFORZAMIENTO) {
    window.open(`/reforzamiento/${record.id}`, "_blank");
  } else if (
    record.tipoInstitucion.id === TipoInstitucionIds.ACADEMIA_ARTISTICA
  ) {
    window.open(`/academia-artistica/${record.id}`, "_blank");
  } else if (record.tipoInstitucion.id === TipoInstitucionIds.GUARDERIA_INFANTIL) {
    window.open(`/guarderia/${record.id}`, "_blank");
  } else if (record.tipoInstitucion.id === TipoInstitucionIds.ESCUELA_VERANO) {
    window.open(`/escuela-verano/${record.id}`, "_blank");
  }
};
