import { Button, Col, Input, Row, Tooltip } from "antd";
import { FaSearch } from "react-icons/fa";

function BuscadorNombreColegio() {
  return (
    <>
      <div className="contbusquedas">
        <div className="contenedorBarrasDebusqueda">
          <Row gutter={{ md: 8, lg: 16 }} justify={"center"}>
            <Col span={8}>
              <Input
                size="large"
                placeholder="Ingrese el Nombre del Establecimiento"
              />
            </Col>
            <Col span={8}>
              <Input size="large" placeholder="Ingrese la Ciudad" />
            </Col>
            <Col span={8}>
              <Tooltip title="Buscar">
                <Button
                  type="primary"
                  shape="circle"
                  size="large"
                  icon={<FaSearch />}
                />
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default BuscadorNombreColegio;
