import { useRequest } from "ahooks";
import { Checkbox, Col, Form, FormInstance, Row } from "antd";
import { requestNivelesEducativos } from "../../../../../commonRequests/params";
import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../../designConstants/antd-constants";

interface NivelesEducativosCheckBoxesProps {
  onChange?: (value: any[]) => any;
  form: FormInstance;
}

const NivelesEducativosCheckBoxes = ({
  onChange,
}: NivelesEducativosCheckBoxesProps) => {
  const nivelesEducativosReq = useRequest(requestNivelesEducativos);

  if (nivelesEducativosReq.loading) {
    return <LoadingSpinner />;
  }

  if (!nivelesEducativosReq.data) {
    nivelesEducativosReq.runAsync();
    return <LoadingSpinner />
  }

  const nivelesEducativos = nivelesEducativosReq.data;

  return (
    <>
      <div
        style={{
          borderStyle: "solid",
          borderColor: "var(--pencilBK)",
          padding: "1em",
        }}
      >
        <Form.Item
          label="Ingrese los niveles educativos presentes en su establecimiento"
          rules={[{ required: true }]}
          name={"nivelesEducativosIds"}
        >
          <Checkbox.Group onChange={onChange}>
            <Row gutter={AntdConsts.rowGutter}>
              {nivelesEducativos?.map((n) => (
                <Col {...AntdConsts.threeColLayout}>
                  <Checkbox value={n.id}>{n.nombre}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </div>
    </>
  );
};

export default NivelesEducativosCheckBoxes;
