import { Button, Drawer } from "antd";
import { useState } from "react";


interface MobileFilterWrapperProps {
  children: React.ReactNode;
}

const MobileFiltersWrapper = ({children}: MobileFilterWrapperProps) =>{

  const [visibleMobileDrawer, setVisibleMobileDrawer] = useState(false);

  return (
    <div style={{ margin: "1em" }}>
      <Button
        style={{ marginTop: "1em" }}
        type="primary"
        onClick={() => setVisibleMobileDrawer(true)}
      >
        Filtros
      </Button>
      <Drawer
        onClose={() => setVisibleMobileDrawer(false)}
        visible={visibleMobileDrawer}
        placement="bottom"
        height={"70%"}
      >
        {children}
      </Drawer>
    </div>
  );
}

export default MobileFiltersWrapper;