import request from "../../../commonRequests/axiosInstance";
import { TipoInsEdu } from "../../../views/listaInsEdu/types/SingleInstitucionEducativa.type";

export const getTipoInsEdu = async (): Promise<TipoInsEdu[] | null> => {
  try {
    const response = await request.get<TipoInsEdu[]>(
      "/params/tipo-institucion"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};
