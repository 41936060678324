import { Col, List, Row, Typography } from "antd";
import AntdConsts from "../../../designConstants/antd-constants";
import SingleInstitucionEducativaEasyVisualization from "../types/SingleInstitucionEducativaEasyVisualization.type";

interface SimpleInfoTablesProps {
  insEdu: SingleInstitucionEducativaEasyVisualization;
  className?: string;
}

const SimpleInfoTables = ({ insEdu }: SimpleInfoTablesProps) => {
  return (
    <>
      <Typography.Title level={3}>Vinculación con el medio</Typography.Title>
      <Row gutter={AntdConsts.rowGutter}>
        {insEdu.alianzas.length !== 0 && (
          <Col style={{ width: "100%" }}>
            <List size="default" header={<b>ALIANZAS</b>} bordered>
              {insEdu.alianzas.map((a) => (
                <List.Item>{`${a.nombre.toUpperCase()}`}</List.Item>
              ))}
            </List>
          </Col>
        )}
        {insEdu.becas.length !== 0 && (
          <Col style={{ width: "100%" }}>
            <List header={<b>BECAS</b>} bordered>
              {insEdu.becas.map((a) => (
                <List.Item>{a.nombre.toUpperCase()}</List.Item>
              ))}
            </List>
          </Col>
        )}
        {insEdu.planesYProgramas.length !== 0 && (
          <Col style={{ width: "100%" }}>
            <List header={<b>PLANES Y PROGRAMAS</b>} bordered>
              {insEdu.planesYProgramas.map((a) => (
                <List.Item>{a.nombre.toUpperCase()}</List.Item>
              ))}
            </List>
          </Col>
        )}
      </Row>
    </>
  );
};

export default SimpleInfoTables;
