import { useRequest } from "ahooks";
import { Badge, Dropdown, Menu } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import {
  countInstitucionesARevisar,
  reqCountUsuariosPorRevisar,
} from "../../../commonRequests/adminRequests";
import { useUserPrivileges } from "../../../hooks/useUserPrivileges";
import { useNavigate } from "react-router-dom";

const NotificationsIcon = () => {
  const userType = useUserPrivileges();
  const institucionesARevisarCount = useRequest(countInstitucionesARevisar);
  const userPorRevisarCount = useRequest(reqCountUsuariosPorRevisar);

  const navigate = useNavigate();

  if (userType !== "ADMIN") {
    return <></>;
  }

  if (institucionesARevisarCount.loading) {
    return <></>;
  }
  if (!institucionesARevisarCount.data) {
    return <></>;
  }

  const countData = institucionesARevisarCount.data;
  const countUsuariosPorRevisar = userPorRevisarCount.data?.count;

  const count =
    countData.institucionEducativa +
    countData.academiaDeportiva +
    countData.academiaIdiomas;

  const menuOverlay = (
    <Menu>
      <b style={{ marginLeft: "1em" }}>Por revisar: </b>
      <Menu.Item onClick={() => navigate("/admin#revision-instituciones")}>
        <Badge
          style={{ backgroundColor: "green" }}
          count={countData.institucionEducativa}
        />{" "}
        Colegios
      </Menu.Item>
      <Menu.Item onClick={() => navigate("/admin#revision-instituciones")}>
        <Badge
          style={{ backgroundColor: "blue" }}
          count={countData.academiaDeportiva}
        />{" "}
        Aca. Deportes
      </Menu.Item>
      <Menu.Item onClick={() => navigate("/admin#revision-instituciones")}>
        <Badge
          style={{ backgroundColor: "red" }}
          count={countData.academiaIdiomas}
        />{" "}
        Aca. Idiomas
      </Menu.Item>
      <Menu.Divider />
      {userPorRevisarCount.data && (
        <Menu.Item onClick={() => navigate("/admin#revision-usuarios")}>
          <Badge
            style={{ backgroundColor: "orange" }}
            count={countUsuariosPorRevisar}
          />{" "}
          Usuarios
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Badge
        count={count}
        overflowCount={99}
        title={`${count} instituciones a revisar`}
        style={{ cursor: "pointer" }}
      >
        {/* <Dropdown overlay={menuOverlay}>
          <NotificationOutlined
            style={{ fontSize: "3em", cursor: "pointer" }}
          />
        </Dropdown> */}
        <div onClick={() => navigate("/admin")}>
          <NotificationOutlined
            style={{ fontSize: "3em", cursor: "pointer" }}
          />
        </div>
      </Badge>
    </>
  );
};

export default NotificationsIcon;
