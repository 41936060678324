import { useRequest } from "ahooks";
import { Select, SelectProps } from "antd";
import { requestOrientacionesReligiosas } from "../../commonRequests/insEduRequests";


interface SelectTipoDependenciaProps {
  paisId?: number;
  value?: number;
  onChange?: (value: any) => any;
  placeholder?: string;
  className?: string;
  props?: SelectProps;
  style?: React.CSSProperties;
}

const SelectOrientacionReligiosa = ({
  paisId,
  value,
  className,
  onChange,
  placeholder,
  style,
  ...props
}: SelectTipoDependenciaProps) => {
  const { data: orientacionReligiosa, loading } = useRequest(requestOrientacionesReligiosas);
  return (
    <Select
      disabled={loading}
      className={className}
      value={value}
      onChange={onChange}
      placeholder={placeholder ? placeholder : "Orientación religiosa"}
      {...props}
    >
      {orientacionReligiosa?.map((r) => {
        return (
          <Select.Option value={r.id} style={style}>
            {r.nombre}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default SelectOrientacionReligiosa;