import { Steps, StepsProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface FormStepCounterProps extends StepsProps {
  currentStep: number;
}

const AcademiaDeportiveFormStepCounter = ({
  currentStep,
  ...props
}: FormStepCounterProps) => {
  return (
    <Steps
      current={currentStep}
      style={{ backgroundColor: "rgba(255,255,255,0.2)" }}
      {...props}
    >
      <Steps.Step
        title="Paso 1"
        description="Datos básicos y de contacto"
        icon={currentStep === 0 ? <LoadingOutlined /> : undefined}
      />
      <Steps.Step
        title="Paso 2"
        description="Datos Institucionales"
        icon={currentStep === 1 ? <LoadingOutlined /> : undefined}
      />
    </Steps>
  );
};

export default AcademiaDeportiveFormStepCounter;
