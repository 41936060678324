import { useRequest } from "ahooks";
import { Button, Col, Form, Input, Result, Row, Select } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import VendedorRequest from "../../../commonRequests/vendedores.req";
import SelectComuna from "../../../components/Input/SelectComuna/SelectComuna";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import SelectRegion from "../../../components/SelectRequest/SelectRegion";
import AntdConsts from "../../../designConstants/antd-constants";
import { VendedorCreate, VendedorUpdate } from "../../../types/vendedores.type";

interface VendedorFormProps {
  isUpdate?: boolean;
  id?: number;
  onSubmitCompleted?: Function;
}

const VendedorForm = ({ isUpdate, id }: VendedorFormProps) => {
  const [form] = Form.useForm();

  const [regionId, setRegionId] = useState<number>();

  const [submitCompleted, setSubmitCompleted] = useState(false);

  const onFormFailed = () => {
    cogoToast.error("Existen errores en el formulario");
  };

  const vendedorReq = useRequest(async () => {
    if (!isUpdate) {
      return;
    }

    if (!id) {
      return;
    }

    const vendedor = await VendedorRequest.getSingle(id);

    if (!vendedor) {
      return;
    }

    form.setFieldsValue({
      nombre: vendedor.nombre,
      rut: vendedor.rut,
      telefono: vendedor.telefono,
      correo: vendedor.correo,
      paisId: vendedor.pais.id,
      regionId: vendedor.region.id,
      comunaId: vendedor.comuna.id,
      direccion: vendedor.direccion,
    });
  });

  if (vendedorReq.loading) {
    return <LoadingSpinner />;
  }

  const onSubmit = async (values: VendedorCreate) => {
    const newVendedor = await VendedorRequest.createVendedor(values);
    if (newVendedor) {
      cogoToast.success("Vendedor creado correctamente");
      setSubmitCompleted(true);
      form.resetFields();
    } else {
      cogoToast.error("No fue posible crear el vendedor");
    }
  };

  const onEdit = async (values: VendedorUpdate) => {
    const newVendedor = await VendedorRequest.createVendedor(values);
    if (newVendedor) {
      cogoToast.success("Vendedor editado correctamente");
      setSubmitCompleted(true);
      form.resetFields();
    } else {
      cogoToast.error("No fue posible editado el vendedor");
    }
  };

  if (submitCompleted) {
    return (
      <Result
        status="success"
        title={
          isUpdate
            ? "Vendedor editado correctamente"
            : "Vendedor creado correctamente"
        }
      />
    );
  }

  return (
    <Form
      form={form}
      onFinishFailed={onFormFailed}
      onFinish={isUpdate ? onEdit : onSubmit}
      layout="vertical"
    >
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input placeholder="Nombre" />
          </Form.Item>
        </Col>

        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Rut"
            name="rut"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input placeholder="Rut" />
          </Form.Item>
        </Col>

        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Telefono"
            name="telefono"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input placeholder="Teléfono" />
          </Form.Item>
        </Col>

        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="E-mail"
            name="correo"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input placeholder="Correo" />
          </Form.Item>
        </Col>

        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Pais"
            name="paisId"
            initialValue={1}
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Select>
              <Select.Option value={1}>Chile</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Región"
            name="regionId"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <SelectRegion onChange={setRegionId} />
          </Form.Item>
        </Col>

        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Comuna"
            name="comunaId"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <SelectComuna regionId={regionId} disabled={!regionId} />
          </Form.Item>
        </Col>

        <Col {...AntdConsts.twoColLayout}>
          <Form.Item label="Dirección" name="direccion">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button block type="primary" htmlType="submit">
          Enviar formulario
        </Button>
      </Form.Item>
    </Form>
  );
};

export default VendedorForm;
