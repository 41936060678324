import { useRequest } from "ahooks";
import { BackTop, Col, Row, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useParams } from "react-router-dom";

import EscuelaVeranoReq from "../../../commonRequests/escuelaVerano.req";
import AdminBannerOverDetailView from "../../../components/AdminBannerOverDetailView";
import CardContainer from "../../../components/CardContainer";
import ContactInfo from "../../../components/ContactInfo";
import InstitucionDetailViewFirstRow from "../../../components/InstitucionDetailViewFirstRow";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import AntdConsts from "../../../designConstants/antd-constants";
import useIsMobile from "../../../hooks/useMobile";
import { CommonInstitucion } from "../../../types/instituciones.types";
import HeaderModern from "../../header/HeaderModern";
import AnchorMenuEscuelaVerano from "./components/AnchorMenuEscuelaVerano";
import EscuelaVeranoCaracteristicas from "./components/EscuelaVeranoCaracteristicas";

const VistaDetalleEscuelaVeranoPage = () => {
  const { id } = useParams();
  const isMobile = useIsMobile();

  const { data: escuelaVerano, loading } = useRequest(async () => {
    const response = await EscuelaVeranoReq.getSingle(+(id as string));
    if (!response) {
      cogoToast.error("Error al cargar datos de la intitución educativa");
    }
    document.title = `BUSCAESTUDIO - ${response?.nombre}`;
    return response;
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!escuelaVerano) {
    return <p>404</p>;
  }

  const colSpan = (normalSpan: number) => ({
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: normalSpan,
    xxl: normalSpan,
  });

  return (
    <div className="detalleCol">
      <HeaderModern affixTop />

      {/* <AnchorMenuPreuniversitario /> */}
      <AnchorMenuEscuelaVerano />
      <div
        style={
          isMobile
            ? { paddingLeft: "0.5em", paddingRight: "0.5em" }
            : { paddingLeft: "16%", paddingRight: "1%" }
        }
      >
        <BackTop />

        <Row gutter={AntdConsts.rowGutter}>
          <Col {...AntdConsts.fullSizeColLayout}>
            <br />
            <AdminBannerOverDetailView
              institucion={escuelaVerano as unknown as CommonInstitucion}
            />
          </Col>
          <Col
            {...AntdConsts.fullSizeColLayout}
            style={{ display: "flex" }}
            id="descripcion-institucion"
          >
            <InstitucionDetailViewFirstRow
              institucion={escuelaVerano as unknown as CommonInstitucion}
            />
          </Col>
          <Col
            {...colSpan(8)}
            style={{ display: "flex" }}
            id="contacto-institucion"
          >
            <CardContainer smallPadding>
              <ContactInfo institucion={escuelaVerano} />
            </CardContainer>
          </Col>

          <Col
            {...colSpan(16)}
            style={{ display: "flex" }}
            id="caracteristicas-institucion"
          >
            <CardContainer smallPadding>
              <EscuelaVeranoCaracteristicas escuelaVerano={escuelaVerano} />
            </CardContainer>
          </Col>

          {/* <Col {...AntdConsts.fullSizeColLayout} id="video-institucion">
            <CardContainer>
              <Typography.Title level={3}>Video institucional</Typography.Title>
              <YoutubeEmbed url={escuelaVerano.videoCorporativo} />
            </CardContainer>
          </Col> */}

          {escuelaVerano?.videoCorporativo && (
              <Col
                {...AntdConsts.fullSizeColLayout}
                style={{ display: "inline-block" }}
                id="video-institucional"
              >
                <CardContainer smallPadding>
                  {escuelaVerano?.videoCorporativo && (
                    <div>
                      <Typography.Title level={3}>
                        Vídeo Institucional
                      </Typography.Title>
                      <YoutubeEmbed url={escuelaVerano.videoCorporativo} />
                    </div>
                  )}
                </CardContainer>
              </Col>
            )}



        </Row>
      </div>
    </div>
  );
};

export default VistaDetalleEscuelaVeranoPage;
