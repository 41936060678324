import { Affix, Button, Col, Divider, Form, Input, Row, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useParams } from "react-router-dom";
import AntdConsts from "../../../../designConstants/antd-constants";
import DescripcionEdicionInput from "../../commonComponents/DescripcionEdicionInput";
import AcademiaIdiomasDatosInstitucionalesForm from "../components/AcademiaDeportivaDatosInstitucionalesForm";
import AcademiaDeportiveFormStepCounter from "../components/AcademiaDeportiveFormStepCounter";
import IdiomasSelectorInput from "../components/IdiomasSelectorInput";

interface AcademiaDeportivaFormStepTwoProps {
  onNext: () => void;
  onBack: () => void;
}

const AcademiaIdiomasFormStepTwo = ({
  onBack,
}: AcademiaDeportivaFormStepTwoProps) => {
  // Si id está definido en la url estamos editando en vez de creando
  const { id } = useParams();

  return (
    <>
      <Row>
        <Col
          lg={8}
          xl={8}
          xxl={8}
          {...AntdConsts.removeColOnMobileLayout}
          style={{ padding: "2em", paddingTop: "20px" }}
        >
          <Affix offsetTop={AntdConsts.headerHeight + 20}>
            <AcademiaDeportiveFormStepCounter
              currentStep={1}
              direction="vertical"
            />
          </Affix>
        </Col>
        <Col
          lg={16}
          xl={16}
          xxl={16}
          {...AntdConsts.fullSizeColOnMobileLayout}
          style={{ padding: "2em", backgroundColor: "white" }}
        >
          <AcademiaIdiomasDatosInstitucionalesForm />
          <Divider />

          <IdiomasSelectorInput />
          <Divider />
          <Form.Item
            name="idiomasExtra"
            label="Ingrese los idiomas faltantes (uno por línea)"
            rules={[{ required: false }]}
          >

            <TextArea rows={4}>
            </TextArea>
          </Form.Item>

          <DescripcionEdicionInput />
          <Space
            direction="horizontal"
            style={{ float: "right", marginTop: "2em" }}
          >
            <Button type="default" onClick={onBack}>
              Anterior
            </Button>
            <Button type="primary" htmlType="submit">
              Enviar formulario
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default AcademiaIdiomasFormStepTwo;
