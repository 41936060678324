import { FilterGroup } from "../types/filters.type";
import { PreuniversitarioFilters } from "../types/filtros.type";
import {
  AsignaturasPreuniversitario,
  NivelesPreuniversitario,
  Preuniversitario,
  PreuniversitarioCreateObject,
  PreuniversitarioUpdateObject,
} from "../types/preuniversitario.type";
import request from "./axiosInstance";

export const reqPreuniversitarioFilters = async (
  currentFilters: PreuniversitarioFilters
) => {
  const filtersResponse = await request.post<
    FilterGroup<PreuniversitarioFilters>[]
  >("/preuniversitario/filters", currentFilters);

  return filtersResponse.data;
};

export const requestManyPreuniversitario = async (
  filters: PreuniversitarioFilters,
) => {
  try {
    const response = await request.post<{
      items: Preuniversitario[];
      total: number;
    }>("/preuniversitario/find", filters);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const requestSinglePreuniversitrario = async (id: number) => {
  try {
    const response = await request.get<Preuniversitario>(
      `/preuniversitario/${id}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqCreatePreuniversitrario = async (
  data: PreuniversitarioCreateObject
) => {
  try {
    const response = await request.post<Preuniversitario>(
      `/preuniversitario/create`,
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqUpdatePreuniversitario = async (
  data: PreuniversitarioUpdateObject
) => {
  try {
    const response = await request.put<Preuniversitario>(
      `/preuniversitario`,
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqPreuniversitarioAsignaturas = async () => {
  try {
    const response = await request.get<AsignaturasPreuniversitario[]>(
      `/preuniversitario/asignaturas`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqPreuniversitarioNiveles = async () => {
  try {
    const response = await request.get<NivelesPreuniversitario[]>(
      `/preuniversitario/niveles`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqPreuniversitarioCambiarVisibilidad = async (
  id: number,
  hidden: boolean
) => {
  try {
    const response = await request.post<boolean>(`/preuniversitario/visibilidad/${id}`, { hidden });
    return response.data
  } catch (error) {
    return null;
  }
};
