import { Col, Row } from "antd";
import useIsMobile from "../../../hooks/useMobile";

import "../css/InfoCircle.css";

function InfoImages() {
  const isMobile = useIsMobile();

  return (
    <>
      <div
        className="contImagesInfo"
      >
        <Row justify="space-around" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h3
              style={isMobile ? { fontSize: "2.5em" } : undefined}
              className="txtImages"
            >
              El <strong>primer paso</strong> es importante porque:
            </h3>
          </Col>
        </Row>
        <br />
        <br />
        <br />

        {/* Primer circulo de imagen */}
        <Row gutter={[10, 40]} align="middle">
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 12, order: 1 }}
          >
            <div className="firstCircle">
              <img
                src={require("../img/globeChilds.jpg")}
                alt="niños viendo un mapa"
                className="Image"
              />
            </div>
          </Col>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 2 }}
            xl={{ span: 12, order: 2 }}
          >
            <h2 className="txtImages1">
              La <strong>educación</strong> es fundamental para enfrentar el{" "}
              <strong>futuro</strong> y tomar las{" "}
              <strong>mejores decisiones.</strong>
            </h2>
          </Col>
        </Row>

        {/* Segundo circulo de Imagen */}
        <br />
        <br />
        <br />
        <br />
        <Row gutter={[10, 40]} justify="space-around" align="middle">
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 12, order: 1 }}
          >
            <h2 className="txtImages1">
              Las <strong>artes</strong>, son las principales aliadas en el{" "}
              <strong>crecimiento</strong> integral del ser{" "}
              <strong>humano</strong>.
            </h2>
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 12, order: 2 }}
            xl={{ span: 12, order: 2 }}
          >
            <div className="secondCircle">
              <img
                src={require("../img/ballet.jpg")}
                alt="clase de ballet"
                className="Image"
              />
            </div>
          </Col>
        </Row>

        {/* Tercer circulo de Imagen */}
        <br />
        <br />
        <br />
        <br />
        <Row gutter={[10, 40]} align="middle">
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 12, order: 1 }}
          >
            <div className="thirdCircle">
              <img
                src={require("../img/athletic.jpg")}
                alt="inicio de carrera"
                className="Image"
              />
            </div>
          </Col>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 2 }}
            xl={{ span: 12, order: 2 }}
          >
            <h2 className="txtImages1">
              <strong>Niños y jóvenes</strong> realizando{" "}
              <strong>deportes</strong>, serán <strong>adultos</strong> más{" "}
              <strong>sanos</strong>.
            </h2>
          </Col>
        </Row>

        {/* Cuarto circulo de imagenes */}
        <br />
        <br />
        <br />
        <br />
        <Row gutter={[10, 40]} align="middle">
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 12, order: 1 }}
          >
            <h2 className="txtImages1">
              <strong>Hablar</strong> más de un <strong>idioma</strong> nos abre
              las puertas a nuevas <strong>experiencias</strong>.
            </h2>
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 12, order: 2 }}
            xl={{ span: 12, order: 2 }}
          >
            <div className="fourthCircle">
              <img
                src={require("../img/pinnedLocations.jpg")}
                alt="pines en el globo terraqueo"
                className="Image"
              />
            </div>
          </Col>
        </Row>

        {/* Quinto circulo de imagenes */}
        <br />
        <br />
        <br />
        <br />
        <Row gutter={[10, 40]} align="middle">
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 12, order: 1 }}
          >
            <div className="fifthCircle">
              <img
                src={require("../img/onlineClass.jpg")}
                alt="niña en clase online"
                className="Image"
              />
            </div>
          </Col>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 2 }}
            xl={{ span: 12, order: 2 }}
          >
            <h2 className="txtImages1">
              El uso de <strong>plataformas online educacionales</strong>{" "}
              permite que niños y jóvenes logren <strong>autonomía.</strong>
            </h2>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default InfoImages;
