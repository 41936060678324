import { Col, Divider, Row } from "antd";
import { useState } from "react";
import AdsBanner from "../../../components/AdsBanner";
import BuscadorPorUbicacionMobile from "../../../components/BuscadorPorUbicacionMobile";
import SponsoredCarousel from "../../../components/SponsoredCarousel";
import AntdConsts from "../../../designConstants/antd-constants";
import useIsMobile from "../../../hooks/useMobile";
import { EscuelaVeranoFilters } from "../../../types/filtros.type";
import { TipoInstitucionIds } from "../../../types/instituciones.types";
import { parseUrlQueryInsEdu } from "../../../utils/filterUtils";
import HeaderModern from "../../header/HeaderModern";
import EscuelaVeranoFilterSelector from "./components/EscuelaVeranoFilterSelector";
import ListaEscuelaVeranoPage from "./components/ListaEscuelaVeranoPage";

const BusquedaEscuelaVeranoPage = () => {
  const isMobile = useIsMobile();

  const esGuarderia = window.location.href.includes("guarderia");

  const [filters, setFilters] = useState<EscuelaVeranoFilters>({
    ...parseUrlQueryInsEdu(),
    region_id: +(localStorage.getItem("REGION") as string) || undefined,
    comuna_id: +(localStorage.getItem("COMUNA") as string) || undefined,
    geo_direccion: localStorage.getItem("DIRECCION") || undefined,
    hidden: false,
    esGuarderia,
  });

  const onChangeLocation = () => {
    setFilters({
      ...filters,
      region_id: +(localStorage.getItem("REGION") as string) || undefined,
      comuna_id: +(localStorage.getItem("COMUNA") as string) || undefined,
      geo_direccion: localStorage.getItem("DIRECCION") || undefined,
    });
  };

  <BuscadorPorUbicacionMobile style={{ padding: "1em" }} />;

  return (
    <>
      <HeaderModern affixTop askLocation onChangeLocation={onChangeLocation} />
      <SponsoredCarousel
        tipoInstitucionId={
          (esGuarderia
            ? TipoInstitucionIds.GUARDERIA_INFANTIL
            : TipoInstitucionIds.ESCUELA_VERANO) || undefined
        }
      />

      <BuscadorPorUbicacionMobile
        style={{ paddingLeft: "1em", paddingRight: "1em" }}
      />

      <Divider />
      <div
        style={{
          paddingLeft: isMobile ? "1em" : "0em",
          paddingRight: isMobile ? "1em" : "0em",
        }}
      >
        <Row gutter={AntdConsts.rowGutter} style={{ marginTop: "2em" }}>
          <Col
            style={{
              padding: "2em",
            }}
            xs={24}
            sm={24}
            md={24}
            lg={5}
            xl={5}
            xxl={5}
          >
            <EscuelaVeranoFilterSelector
              currentFilters={filters}
              setFilters={setFilters}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            <ListaEscuelaVeranoPage filters={filters} setFilters={setFilters} />
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3}>
            <AdsBanner vertical />
            <br />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BusquedaEscuelaVeranoPage;
