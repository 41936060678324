import { Button, Col, Form, Input, Row, Typography } from "antd";
import AntdConsts from "../../../../designConstants/antd-constants";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const AddCustomCaracteristicasExtra = () => {
  return (
    <Form.List name="caracteristicasCustom">
      {(fields, { add, remove }) => (
        <div style={{ width: "100%" }}>
          <Typography.Title level={4}>
            Caracteristica faltantes
          </Typography.Title>
          <Typography.Title level={5}>
            No aparecen todas las caracteristicas de su establecimiento?
            Agreguelas en este campo
          </Typography.Title>
          {fields.map((field, index) => (
            <Form.Item
              {...field}
              key={index}
              fieldKey={field.key}
              rules={[
                {
                  required: true,
                  message: "Por favor rellene el campo o quitelo de la lista",
                },
              ]}
            >
              <Row gutter={AntdConsts.rowGutter}>
                <Col span={20}>
                  <Form.Item {...field}>
                    <Input placeholder="Característica" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <MinusCircleOutlined
                    onClick={() => remove(field.name)}
                    style={{ paddingTop: "0.5em", fontSize: "1.6em" }}
                  />
                </Col>
              </Row>
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add(undefined);
              }}
              style={{ width: "60%", marginTop: "20px" }}
              icon={<PlusOutlined />}
            >
              Agregar caracteristica
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
};

export default AddCustomCaracteristicasExtra;
