import { useRequest } from "ahooks";
import { Button, Popover, Space } from "antd";
import { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import AskRegionModal from "../../../components/AskRegionModal/AskRegionModal";
import { getComunas } from "../../../components/Input/SelectComuna/selectComunaRequests";
import { useStateLocalStorage } from "../../../hooks/useBrowserStorage";
import useIsMobile from "../../../hooks/useMobile";
import { LoadingOutlined } from "@ant-design/icons";

interface UbicacionButtonProps {
  askLocation?: boolean;
  onChangeLocation?: Function;
}

const UbicacionButton = ({
  askLocation,
  onChangeLocation,
}: UbicacionButtonProps) => {
  const [showModalReg, setModalReg] = useState(false);
  const [region, setRegion] = useStateLocalStorage("REGION");
  const [comuna, setComuna] = useStateLocalStorage("COMUNA");
  const [forbidAskLocation, setForbidAskLocation] =
    useStateLocalStorage("ASK_LOCATION");

  const isMobile = useIsMobile();

  const [direccion, setDireccion] = useStateLocalStorage("DIRECCION");

  const [showPopup, setShowPopup] = useState(askLocation);

  const comunasReq = useRequest(getComunas, { debounceWait: 4000 });

  if (comunasReq.loading || !comunasReq.data) {
    // comunasReq.runAsync();
    return (
      <Button
        type="dashed"
        style={{ backgroundColor: "whitesmoke" }}
        icon={<LoadingOutlined />}
      >
        Cargando ubicación
      </Button>
    );
  }

  const onUbicacionSelection = (
    regionId: number,
    comunaId: number,
    direccion: string
  ) => {
    setRegion(regionId.toString());
    setComuna(comunaId.toString());
    setDireccion(direccion);
    setModalReg(false);
    onChangeLocation?.();
    window.location.reload();
  };

  if (!region || !comuna) {
    return (
      <>
        <Popover
          placement="bottom"
          style={{ maxWidth: "10em" }}
          content={
            <>
              <Space direction="vertical">
                <p style={{ textJustify: "inter-word", textAlign: "start" }}>
                  Se recomienda ingresar su ubicación <br />
                  para mejorar sus resultados de busqueda
                </p>
                <Space wrap size="small" direction="horizontal">
                  <Button
                    block={isMobile}
                    type="dashed"
                    onClick={() => setShowPopup(false)}
                  >
                    Cerrar
                  </Button>
                  <Button
                    type="dashed"
                    onClick={() => setForbidAskLocation("TRUE")}
                  >
                    No volver a preguntar
                  </Button>
                </Space>
              </Space>
            </>
          }
          visible={showPopup && !forbidAskLocation}
        >
          <Button
            type="dashed"
            style={{ backgroundColor: "whitesmoke" }}
            onClick={() => {
              setModalReg(true);
              setShowPopup(false);
            }}
          >
            Tu Ubicación
          </Button>
        </Popover>
        <AskRegionModal
          isOpen={showModalReg}
          handleOk={onUbicacionSelection}
          handleClose={() => setModalReg(false)}
        />
      </>
    );
  }

  return (
    <>
      <Button
        type="dashed"
        onClick={() => setModalReg(true)}
        icon={<FaMapMarkerAlt color="blue" />}
      >
        {comunasReq.data.find((c) => c.id === +comuna)?.nombre}
      </Button>
      <AskRegionModal
        isOpen={showModalReg}
        handleOk={onUbicacionSelection}
        handleClose={() => setModalReg(false)}
      />
    </>
  );
};

export default UbicacionButton;
