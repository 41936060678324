import { Col, List, Row, Space, Typography } from "antd";
import AntdConsts from "../../../../designConstants/antd-constants";
import currencyFormatter from "../../../../utils";
import { IoSchool } from "react-icons/io5";
import { IconContext } from "react-icons/lib";
import { GiTeacher } from "react-icons/gi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaMoneyBillAlt, FaStar, FaCalendarAlt } from "react-icons/fa";
import { AcademiaArtistica } from "../../../../types/academiaArtistica.type";
import IconoTablas from "../../../../components/iconosTablas/IconoTablas";
import { BsGenderAmbiguous } from 'react-icons/bs'

interface AcademiaArtisticaCaracteristicasProps {
  academiaArtistica: AcademiaArtistica;
}

const AcademiaArtisticaCaracteristicas = ({
  academiaArtistica,
}: AcademiaArtisticaCaracteristicasProps) => {
  let rangoEdadStr = '';
  if (academiaArtistica.edadMinma === 0 || !academiaArtistica.edadMinma) {
    rangoEdadStr = `Hasta ${academiaArtistica.edadMaxima} años`;
  } else {
    rangoEdadStr = `${academiaArtistica.edadMinma} - ${academiaArtistica.edadMaxima}`;
  }
  return (
    <>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.fullSizeColLayout}>
          <List
            itemLayout="horizontal"
            bordered
            size="small"
            grid={{
              xs: 2,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={academiaArtistica.artes}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <Space>
                  <IconoTablas nombreIcono={item.nombre} />
                  {item.nombre}
                </Space>
              </List.Item>
            )}
            header={
              <Space direction="horizontal">
                <IconContext.Provider value={{ color: "blue", size: "2em" }}>
                  <IoSchool />
                </IconContext.Provider>
                <Typography.Title level={4}>Artes</Typography.Title>
              </Space>
            }
          />
        </Col>
        <Col {...AntdConsts.fullSizeColLayout}>
          <List
            itemLayout="horizontal"
            bordered
            size="small"
            grid={{
              xs: 2,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={academiaArtistica.instrumentos}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <Space>
                  <IconoTablas nombreIcono={item.nombre} />
                  {item.nombre}
                </Space>
              </List.Item>
            )}
            header={
              <Space direction="horizontal">
                <IconContext.Provider value={{ color: "blue", size: "2em" }}>
                  <IoSchool />
                </IconContext.Provider>
                <Typography.Title level={4}>Instrumentos</Typography.Title>
              </Space>
            }
          />
        </Col>
        <Col {...AntdConsts.fullSizeColLayout}>
          <List
            bordered
            header={
              <Space direction="horizontal">
                <IconContext.Provider value={{ color: "blue", size: "2em" }}>
                  <FaStar />
                </IconContext.Provider>
                <Typography.Title level={4}>Otras caracteristicas</Typography.Title>
              </Space>
            }
          >
            <List.Item>
              <b><IconContext.Provider value={{ color: "blue", size: "1.5em" }}>
                <AiOutlineClockCircle />
              </IconContext.Provider> {`Jornada: ${academiaArtistica?.jornada?.nombre || "No disponible"}`}</b>
            </List.Item>
            <List.Item>
              <b><IconContext.Provider value={{ color: "blue", size: "1.5em" }}>
                <FaMoneyBillAlt />
              </IconContext.Provider> {`Rango de precios: ${currencyFormatter.format(
                academiaArtistica.precioMin
              )} - ${currencyFormatter.format(
                academiaArtistica.precioMax
              )}`}</b>
            </List.Item>
            <List.Item>
              <b><IconContext.Provider value={{ color: "blue", size: "1.5em" }}>
                <GiTeacher />
              </IconContext.Provider> {`Modalidad de estudios: ${academiaArtistica.modalidad.nombre}`}</b>
            </List.Item>
            <List.Item>
              <b><IconContext.Provider value={{ color: "blue", size: "1.5em" }}>
                <FaCalendarAlt />
              </IconContext.Provider> {`Rango edades: ${rangoEdadStr}`}</b>
            </List.Item>
            <List.Item>
              <b><IconContext.Provider value={{ color: "blue", size: "1.5em" }}>
                <BsGenderAmbiguous />
              </IconContext.Provider> {`Genero alumnos: ${academiaArtistica.generoAlumnos.nombre}`}</b>
            </List.Item>

          </List>
        </Col>
      </Row>
    </>
  );
};

export default AcademiaArtisticaCaracteristicas;
