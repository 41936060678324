import { useRequest } from "ahooks";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import { reqPreuniversitarioAsignaturas } from "../../../../commonRequests/preuniversitarioRequests";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";

const AsignaturasSelectorInput = () => {
  const { data: asignaturas, loading } = useRequest(reqPreuniversitarioAsignaturas);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Typography.Title level={3}>Asignaturas</Typography.Title>
      <Form.Item
        name="asignaturas"
        label="Seleccione las asignaturas impartidas por su institución"
        rules={[{ required: true }]}
      >
        <Checkbox.Group>
          <Row gutter={AntdConsts.rowGutter}>
            {asignaturas?.map((d) => (
              <Col {...AntdConsts.twoColLayout}>
                <Checkbox value={d.id}>{d.nombre}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default AsignaturasSelectorInput;
