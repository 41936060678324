import { useRequest } from "ahooks";
import {
  Col,
  Divider,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Typography,
} from "antd";
import { requestNivelesEducativos } from "../../../../commonRequests/params";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";

interface DatosDePagoProps {
  form: FormInstance;
}

const DatosDePagoInput = ({ form }: DatosDePagoProps) => {
  const nivelesEducativosIds = form.getFieldValue(
    "nivelesEducativosIds"
  ) as number[];

  const nivelesEducativosReq = useRequest(requestNivelesEducativos);

  if (nivelesEducativosReq.loading) {
    return <LoadingSpinner />;
  }

  if (!nivelesEducativosReq.data) {
    nivelesEducativosReq.runAsync();
    return <LoadingSpinner />;
  }

  const nivelesEducativos = nivelesEducativosReq.data;

  return (
    <>
      <Typography.Title level={2}>Datos de pago</Typography.Title>
      <Typography.Text>
        Ingrese los datos de pago,&nbsp;<strong>sin puntos ni comas</strong>, de cada nivel educativo ofrecido por su
        establecimiento, en caso de ser gratuito ingresar <strong>$0</strong> en cada casilla.
      </Typography.Text>
      <br />
      <br />
      <Form.Item>
        {nivelesEducativosIds?.sort()?.map((nivelEducativoId) => {
          const nombre = nivelesEducativos.find(n => n.id === nivelEducativoId)?.nombre

          if (!nombre) {
            return <></>
          }

          return (
            <Row gutter={AntdConsts.rowGutter}>
              <Col {...AntdConsts.fullSizeColLayout}>
                <Typography.Title level={4}>{nombre}</Typography.Title>
              </Col>
              <Col {...AntdConsts.threeColLayout}>
                <Form.Item
                  label="Número de cuotas"
                  name={["nivelesEducativos", nivelEducativoId, "numeroCuotas"]}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    placeholder="Número de cuotas"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col {...AntdConsts.threeColLayout}>
                <Form.Item
                  label="Mensualidad(Sin puntos ni comas)"
                  name={[
                    "nivelesEducativos",
                    nivelEducativoId,
                    "costoMensualidad",
                  ]}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    placeholder="Mensualidad"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col {...AntdConsts.threeColLayout}>
                <Form.Item
                  label="Matrícula(Sin puntos ni comas)"
                  name={[
                    "nivelesEducativos",
                    nivelEducativoId,
                    "costoMatricula",
                  ]}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    placeholder="Matrícula"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        })}
      </Form.Item>
      <Divider />
    </>
  );
};

export default DatosDePagoInput;