import { Descriptions, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faLocationDot,
  faMailBulk,
  faPersonChalkboard,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { CommonInstitucion } from "../types/instituciones.types";
import ContactarInstitucionButton from "./ContactarInstitucionButton";
import SocialNetworkButtons from "./SocialNetworkButtons";

interface ContactInfoProps {
  institucion: CommonInstitucion;
}

const ContactInfo = ({ institucion }: ContactInfoProps) => {
  return (
    <div id="contacto-institucion">
      <Descriptions
        style={{ width: "100%" }}
        labelStyle={{ backgroundColor: "#B3F2FF" }}
        contentStyle={{ backgroundColor: "white" }}
        title="CONTACTO"
        bordered
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item
          label={
            <Space>
              <FontAwesomeIcon
                style={{ color: "#1f3de7", fontSize: "25px" }}
                icon={faLocationDot}
              />
              Direccion:
            </Space>
          }
        >
          {institucion?.direccion}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FontAwesomeIcon
                style={{ color: "#1f3de7", fontSize: "25px" }}
                icon={faPhone}
              />
              Teléfono
            </Space>
          }
        >
          {institucion?.telefono}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FontAwesomeIcon
                style={{ color: "#1f3de7", fontSize: "25px" }}
                icon={faDesktop}
              />
              Página Web
            </Space>
          }
        >
          <a href={`https://${institucion?.paginaWeb?.replace("https://","")}`} target="_blank" rel="noreferrer">{institucion?.paginaWeb}</a>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FontAwesomeIcon
                style={{ color: "#1f3de7", fontSize: "25px" }}
                icon={faMailBulk}
              />
              Correo
            </Space>
          }
        >
          {institucion.correo}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FontAwesomeIcon
                style={{ color: "#1f3de7", fontSize: "25px" }}
                icon={faPersonChalkboard}
              />
              Modalidad de estudio
            </Space>
          }
        >
          {institucion.modalidad.nombre}
        </Descriptions.Item>
      </Descriptions>

      <Space direction="vertical" style={{ padding: "1em" }}>
        <p>
          <span className="blue-main-text">Redes Sociales: </span>
        </p>
        <SocialNetworkButtons institucion={institucion} />
        <b />
      </Space>
      <Space direction="vertical" style={{ padding: "1em" }}>
        <p>
          <span className="blue-main-text">Contacto: </span>
        </p>
        <ContactarInstitucionButton institucion={institucion} />
      </Space>
      <b />
    </div>
  );
};
export default ContactInfo;
