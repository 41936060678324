import { Descriptions, List, Space, Typography } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChildren,
  faClock,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import currencyFormatter from "../../../../utils";
import { AcademiaIdiomas } from "../../../../types/academiaIdiomas.type";
import FlagsViews from "../../../../components/FlagsViews/FlagsViews";

interface TestProps {
  academiaIdiomas: AcademiaIdiomas;
  className?: string;
}

const AcademiaIdiomasCaracteristicas = ({
  academiaIdiomas,
  className,
}: TestProps) => {
  const info = (
    <Descriptions
      labelStyle={{ backgroundColor: "var(--labelsDescription)" }}
      contentStyle={{ backgroundColor: "white" }}
      style={{ color: "black" }}
      title="CARACTERÍSTICAS"
      bordered
      column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      size="default"
    >
      <Descriptions.Item
        label={
          <b>
            <FontAwesomeIcon
              style={{ color: "#1f3de7", fontSize: "25px" }}
              icon={faChildren}
            />
            {"  "}
            Género
          </b>
        }
      >
        {academiaIdiomas?.generoAlumnos?.nombre || "-"}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <b>
            <FontAwesomeIcon
              style={{ color: "#1f3de7", fontSize: "25px" }}
              icon={faChildren}
            />
            {"  "}
            Edades
          </b>
        }
      >
        {`${academiaIdiomas.edadMinma} - ${academiaIdiomas.edadMaxima} Años`}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <b>
            <FontAwesomeIcon
              style={{ color: "#1f3de7", fontSize: "25px" }}
              icon={faDollarSign}
            />
            {"     "}
            Precio
          </b>
        }
      >{`${currencyFormatter.format(
        academiaIdiomas?.precioMin as number
      )} - ${currencyFormatter.format(
        academiaIdiomas?.precioMax as number
      )}`}</Descriptions.Item>
      <Descriptions.Item
        label={
          <b>
            <FontAwesomeIcon
              style={{ color: "#1f3de7", fontSize: "25px" }}
              icon={faClock}
            />
            {"  "}
            Jornada
          </b>
        }
      >
        {academiaIdiomas?.jornada?.nombre || "-"}
      </Descriptions.Item>
    </Descriptions>
  );

  const idiomas = (
    <List
      bordered
      header={"IDIOMAS"}
      grid={{ xxl: 4, xl: 3, lg: 4, md: 2, sm: 2, xs: 2 }}
      dataSource={academiaIdiomas.idiomas.sort((a, b) => a.nombre.localeCompare(b.nombre))}
      renderItem={(item) => (
        <List.Item>
          <Space>
            <FlagsViews flagsIso639Codes={[item.iso639_2_code]} />
            <span>{item.nombre}</span>
          </Space>
        </List.Item>
      )}
    />
  );

  return (
    <div id="caracteristicas-institucion">
      {info}
      <br />
      <Typography.Title level={5}>IDIOMAS</Typography.Title>
      {idiomas}
    </div>
  );
};

export default AcademiaIdiomasCaracteristicas;
