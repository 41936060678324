import { Anuncio, AnuncioCreate, AnuncioUpdate } from "../types/ads.types";
import request from "./axiosInstance";

const create = async (data: AnuncioCreate) => {
  try {
    const response = await request.post("/ads/create", data);
    return response;
  } catch (error) {
    return null;
  }
};

const update = async (data: AnuncioUpdate) => {
  try {
    const response = await request.patch("/ads/update", data);
    return response.data;
  } catch (error) {
    return null;
  }
};

const get = async (
  quantity: number,
  orientacion: "HORIZONTAL" | "VERTICAL"
) => {
  try {
    const response = await request.post<Anuncio[]>("/ads", {
      quantity,
      orientacion,
    });

    return response.data;
  } catch (error) {
    Array(quantity).fill(null);
  }
};

const getSingle = async (id: number) => {
  try {
    const response = await request.get<Anuncio>(`/ads/${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const countClick = async (id: number) => {
  try {
    await request.get(`/ads/count-click/${id}`);
  } catch (error) {
    return null;
  }
};

const getAll = async () => {
  try {
    const response = await request.get<Anuncio[]>("/ads/all");

    return response.data;
  } catch (error) {
    return null;
  }
};

const deleteAnuncio = async (id: number) => {
  try {
    await request.delete(`/ads/${id}`);
    return true;
  } catch (error) {
    return null;
  }
};

const AdsRequests = {
  get,
  countClick,
  getAll,
  deleteAnuncio,
  create,
  getSingle,
  update
};

export default AdsRequests;
