//@ts-nocheck
import { useRequest } from "ahooks";
import { AutoComplete, Button, Col, Form, Row, Space, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { requestAlianzas } from "../../../../commonRequests/params";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";

const AlianzasInput = () => {
  const { data: alianzas, loading } = useRequest(requestAlianzas);

  if (loading) {
    return <LoadingSpinner />;
  }

  const autocompleteOptions = alianzas?.map((a) => ({
    label: a.nombre,
    value: a.nombre,
  }));

  return (
    <Form.List name="alianzas" initialValue={[undefined]}>
      {(fields, { add, remove }) => (
        <div style={{ width: "100%" }}>
          <Typography.Title level={4}>Alianzas</Typography.Title>
          <Typography.Title level={5}>
            Ingresar las alianzas de la lista presentes en su establecimiento o
            las suyas propias si estas no aparecen{" "}
          </Typography.Title>
          {fields.map((field, index) => (
            <Form.Item {...field}>
              <Row gutter={AntdConsts.rowGutter}>
                <Col span={20}>
                  <Form.Item {...field}>
                    <AutoComplete
                      options={autocompleteOptions}
                      placeholder="Alianza"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      onClick={() => remove(field.name)}
                      style={{ paddingTop: "0.5em", fontSize: "1.6em" }}
                    />
                  ) : null}
                </Col>
              </Row>
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add(undefined);
              }}
              style={{ width: "60%", marginTop: "20px" }}
              icon={<PlusOutlined />}
            >
              Agregar alianza
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
};

export default AlianzasInput;
