import { Col, List, Row, Space, Typography } from "antd";
import AntdConsts from "../../../../designConstants/antd-constants";
import currencyFormatter from "../../../../utils";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { Preuniversitario } from "../../../../types/preuniversitario.type";
import { IoSchool } from "react-icons/io5";
import { IconContext } from "react-icons/lib";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaMoneyBillAlt } from "react-icons/fa";
import IconoTablas from "../../../../components/iconosTablas/IconoTablas";

interface PreuniversitarioCaracteristicasProps {
  preuniversitario: Preuniversitario;
}

const PreuniversitarioCaracteristicas = ({
  preuniversitario,
}: PreuniversitarioCaracteristicasProps) => {
  return (
    <>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.fullSizeColLayout}>
          <Typography.Title
            level={4}
            style={{ marginBottom: 0, paddingBottom: 0 }}
          >
            INFORMACIÓN
          </Typography.Title>
        </Col>
        <Col {...AntdConsts.fullSizeColLayout}>
          <List
            bordered
            itemLayout="horizontal"
            grid={{
              xs: 2,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={preuniversitario.niveles}
            renderItem={(item) => (
              <List.Item key={item.id} style={{ marginTop: "1em" }}>
                <b>{item.nombre}</b>
              </List.Item>
            )}
            header={
              <Space direction="horizontal">
                <IconContext.Provider value={{ color: "blue", size: "2em" }}>
                  <IoSchool />
                </IconContext.Provider>
                <Typography.Text strong style={{ fontSize: "1.2em" }}>
                  Niveles
                </Typography.Text>
              </Space>
            }
          />
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <List
            bordered
            header={
              <Space direction="horizontal">
                <IconContext.Provider value={{ color: "blue", size: "2em" }}>
                  <FaMoneyBillAlt />
                </IconContext.Provider>
                <Typography.Text strong style={{ fontSize: "1.2em" }}>
                  Rango de precios
                </Typography.Text>
              </Space>
            }
          >
            <List.Item>
              <b>{`${currencyFormatter.format(
                preuniversitario.precioMin
              )} - ${currencyFormatter.format(preuniversitario.precioMax)}`}</b>
            </List.Item>
          </List>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <List
            bordered
            header={
              <Space direction="horizontal" align="start">
                <IconContext.Provider value={{ color: "blue", size: "2em" }}>
                  <AiOutlineClockCircle />
                </IconContext.Provider>
                <Typography.Text strong style={{ fontSize: "1.2em" }}>
                  Jornada
                </Typography.Text>
              </Space>
            }
          >
            <List.Item>
              <b>{preuniversitario?.jornada?.nombre}</b>
            </List.Item>
          </List>
        </Col>
        <Col {...AntdConsts.fullSizeColLayout}>
          <List
            grid={{
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            bordered
            header={
              <Space direction="horizontal">
                <IconContext.Provider value={{ color: "blue", size: "2em" }}>
                  <BsFillJournalBookmarkFill />
                </IconContext.Provider>
                <Typography.Text strong style={{ fontSize: "1.2em" }}>
                  Asignaturas
                </Typography.Text>
              </Space>
            }
            dataSource={preuniversitario.asignaturas}
            renderItem={(c) => (
              <List.Item style={{ marginTop: 2 }} key={c.id}>
                <Space align="center">
                  <IconoTablas nombreIcono={c.nombre} />
                  <Typography.Text>{c.nombre.toUpperCase()}</Typography.Text>
                </Space>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default PreuniversitarioCaracteristicas;
