import request from "../../../commonRequests/axiosInstance";
import { Comuna } from "../../../views/listaInsEdu/types/SingleInstitucionEducativa.type";

export const getComunas = async (
  regionId?: number,
  nonEmpty: boolean | undefined = true
): Promise<Comuna[] | null> => {
  try {
    if (regionId) {
      const response = await request.get<Comuna[]>(
        `/params/comunas?region_id=${regionId}`
      );
      return response.data;
    } else {
      const response = await request.get(
        `/params/comunas`
      );
      return response.data;
    }
  } catch (error) {
    return null;
  }
};
