import { useRequest } from "ahooks";
import { BackTop, Col, Row, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useParams } from "react-router-dom";
import { requestSinglePreuniversitrario } from "../../../commonRequests/preuniversitarioRequests";
import AdminBannerOverDetailView from "../../../components/AdminBannerOverDetailView";
import CardContainer from "../../../components/CardContainer";
import ContactInfo from "../../../components/ContactInfo";
import InstitucionDetailViewFirstRow from "../../../components/InstitucionDetailViewFirstRow";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import AntdConsts from "../../../designConstants/antd-constants";
import { Preuniversitario } from "../../../types/preuniversitario.type";
import HeaderModern from "../../header/HeaderModern";
import AnchorMenuPreuniversitario from "./components/AnchorMenuPreuniversitario";
import PreuniversitarioCaracteristicas from "./components/PreuniversitarioCaracteristicas";

const VistaDetallePreuniversitarioPage = () => {
  const { id } = useParams();

  const { data: preuniversitario, loading } = useRequest(async () => {
    const response = await requestSinglePreuniversitrario(+(id as string));
    if (!response) {
      cogoToast.error("Error al cargar datos de la intitución educativa");
    }
    document.title = `BUSCAESTUDIO - ${response?.nombre}`;
    return response;
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!preuniversitario) {
    return <p>404</p>;
  }

  return (
    <div className="detalleCol">
      <HeaderModern affixTop />

      <AnchorMenuPreuniversitario />

      <div style={{ padding: "0.5em" }}>
        <BackTop />

        <br />
        <Row gutter={AntdConsts.rowGutter}>
          <Col {...AntdConsts.fullSizeColLayout}>
            <AdminBannerOverDetailView institucion={preuniversitario} />
          </Col>
          <Col
            {...AntdConsts.fullSizeColLayout}
            style={{ display: "flex" }}
            id="descripcion-institucion"
          >
            <InstitucionDetailViewFirstRow institucion={preuniversitario} />
          </Col>
          <Col
            {...AntdConsts.twoColLayout}
            id="contacto-institucion"
            style={{ display: "flex" }}
          >
            <CardContainer smallPadding>
              <ContactInfo institucion={preuniversitario} />
            </CardContainer>
          </Col>
          <Col
            {...AntdConsts.twoColLayout}
            id="caracteristicas-institucion"
            style={{ display: "flex" }}
          >
            <CardContainer smallPadding>
              <PreuniversitarioCaracteristicas
                preuniversitario={preuniversitario as Preuniversitario}
              />
            </CardContainer>
          </Col>

          {preuniversitario?.videoCorporativo && (
            <Col
              {...AntdConsts.fullSizeColLayout}
              style={{ display: "inline-block" }}
              id="video-institucion"
            >
              <CardContainer smallPadding>
                <div>
                  <Typography.Title level={3}>
                    Vídeo Institucional
                  </Typography.Title>
                  <YoutubeEmbed
                    url={preuniversitario.videoCorporativo}
                  />
                </div>
              </CardContainer>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default VistaDetallePreuniversitarioPage;
