import { Vendedor, VendedorCreate, VendedorFilters } from "../types/vendedores.type";
import request from "./axiosInstance";

const getMany = async (filters: VendedorFilters) => {
  try {
    const response = await request.post<Vendedor[]>("/vendedores/find", filters)
    return response.data;
  } catch (error) {
    return null;
  }
}

const getSingle = async (id: number) => {
  try {
    const response = await request.get<Vendedor>(`/vendedores/${id}`)
    return response.data;
  } catch (error) {
    return null;
  }
}

const deleteVendedor = async (id: number) => {
  try {
    await request.delete(`/vendedores/${id}`)
    return true;
  } catch (error) {
    return false;
  }
}

const checkExistence = async (codigo: string) => {
  try {
    const response = await request.get<boolean>(`/vendedores/check-existence/${codigo}`)
    return response.data;
  } catch (error) {
    return false;
  }
}

const createVendedor = async (data: VendedorCreate) => {
  try {
    await request.post(`/vendedores/create`, data)
    return true;
  } catch (error) {
    return false;
  }
}

const edit = async (data: VendedorCreate) => {
  try {
    await request.patch(`/vendedores/update`, data)
    return true;
  } catch (error) {
    return false;
  }
}

const VendedorRequest = {
  getMany,
  deleteVendedor,
  getSingle,
  checkExistence,
  createVendedor,
  edit
}

export default VendedorRequest