import { useRequest } from "ahooks";
import { Button, Form, Input, Result, Row, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AuthReqs from "../commonRequests/authRequests";
import useIsMobile from "../hooks/useMobile";
import useNavigateScrollTop from "../hooks/useNavigateScrollTop";
import HeaderModern from "../views/header/HeaderModern";
import CardContainer from "./CardContainer";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";

const CambiarPassword = () => {
  const { token } = useParams();

  const isMobile = useIsMobile();

  const navigate = useNavigateScrollTop();

  const [showInvalidTokenScreen, setShowInvalidTokenScreen] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const checkTokenreq = useRequest(async () => {
    const istokenValid = await AuthReqs.checkChangePasswordToken(
      token as string
    );
    if (!istokenValid) {
      setShowInvalidTokenScreen(true);
    }

    return istokenValid;
  });

  if (checkTokenreq.loading) {
    return <LoadingSpinner />;
  }

  if (showInvalidTokenScreen) {
    return (
      <>
        <HeaderModern />
        <Row justify="center">
          <Result
            status="warning"
            title="El link de cambio de contraseña no existe o ha expirado"
            extra={
              <Button
                type="primary"
                onClick={() => {
                  localStorage.removeItem("Authorization")
                  navigate("/");
                }}
              >
                Volver a la página principal
              </Button>
            }
          />
        </Row>
      </>
    );
  }

  const onSubmit = async (values: { newPassword: string }) => {
    const hide = cogoToast.loading("Cargando...", { hideAfter: 0 });
    const resetPassword = await AuthReqs.resetPassword(
      token as string,
      values.newPassword
    );
    hide?.hide?.();
    if (!resetPassword) {
      cogoToast.error("No fue posible cambiar su contraseña");
      return;
    }

    setShowSuccessScreen(true);
  };

  if (showSuccessScreen) {
    return (
      <>
        <HeaderModern />
        <Row justify="center">
          <Result
            status="success"
            title="Contraseña cambiada correctamente"
            extra={
              <Button
                type="primary"
                onClick={() => navigate("/inicio-de-sesion")}
              >
                Iniciar sesión
              </Button>
            }
          />
        </Row>
      </>
    );
  }

  const form = (
    <>
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item
          name="newPassword"
          label="Ingrese su nueva contraseña"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirme contraseña"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "por favor confirme su nueva contraseña!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Las contraseñas no coinciden")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );

  return (
    <>
      <HeaderModern />
      <div
        style={
          isMobile
            ? { marginLeft: "1em", marginRight: "1em", marginTop: "3em" }
            : { marginLeft: "30%", marginRight: "30%", marginTop: "3em" }
        }
      >
        <CardContainer>
          <Typography.Title level={3}>Cambio de contraseña</Typography.Title>
          {form}
        </CardContainer>
      </div>
    </>
  );
};

export default CambiarPassword;
