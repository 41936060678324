import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Anuncio } from "../../../types/ads.types";
import ActionAdButton from "./ActionAdButton";

interface AdsListProps {
  anuncios: Anuncio[];
  onDelete?: Function;
}

const AdsList = ({ anuncios, onDelete }: AdsListProps) => {
  const onCellClick = (record: Anuncio) => {};

  const columns: ColumnsType<Anuncio> = [
    {
      title: "Nombre Cliente",
      dataIndex: "nombreCliente",
      key: "nombreCliente",
      onCell: (record: Anuncio) => ({ onClick: () => onCellClick(record) }),
      render: (nombreCliente: string) => nombreCliente.toUpperCase(),
    },
    {
      title: "Teléfono Cliente",
      dataIndex: "telefonoCliente",
      key: "telefonoCliente",
      onCell: (record: Anuncio) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "E-mail",
      dataIndex: "correoCliente",
      key: "correoCliente",
      onCell: (record: Anuncio) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Rut Cliente",
      dataIndex: "rutCliente",
      key: "rutCliente",
      onCell: (record: Anuncio) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Porcentaje aparición",
      dataIndex: "porcentajeAparicion",
      key: "porcentajeAparicion",
      onCell: (record: Anuncio) => ({ onClick: () => onCellClick(record) }),
      render: (porcentajeAparicion) => `${porcentajeAparicion}%`,
    },
    {
      title: "Cantidad de clicks",
      dataIndex: "clicks",
      key: "clicks",
      onCell: (record: Anuncio) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (link) => (
        <Button type="link" href={link} target="_blank">
          {link}
        </Button>
      ),
    },
    {
      title: "Acciones",
      render: (_, record) => (
        <ActionAdButton anuncio={record} onDelete={onDelete} />
      ),
    },
  ];

  return (
    <>
      <Table
        style={{ cursor: "pointer" }}
        bordered
        columns={columns}
        dataSource={anuncios}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default AdsList;
