import {
  EscuelaVerano,
  EscuelaVeranoCreateObject,
  EscuelaVeranoUpdateObject,
} from "../types/escuelaVerano.type";
import { EscuelaVeranoFilters } from "../types/filtros.type";
import request from "./axiosInstance";

const getSingle = async (id: number) => {
  try {
    const response = await request.get<EscuelaVerano>(`/escuela-verano/${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const getMany = async (filters: EscuelaVeranoFilters) => {
  try {
    const response = await request.post<{
      items: EscuelaVerano[];
      total: number;
    }>(`/escuela-verano`, filters);
    return response.data;
  } catch (error) {
    return null;
  }
};

const create = async (data: EscuelaVeranoCreateObject) => {
  try {
    const response = await request.post<EscuelaVerano>(
      `/escuela-verano/create`,
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const update = async (data: EscuelaVeranoUpdateObject) => {
  try {
    const response = await request.put<EscuelaVerano>(
      `/escuela-verano/update`,
      data
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const cambiarVisibilidad = async (
  id: number,
  hidden: boolean
) => {
  try {
    const response = await request.post<boolean>(`/escuela-verano/visibilidad/${id}`, { hidden });
    return response.data
  } catch (error) {
    return null;
  }
};

const EscuelaVeranoReq = {
  getSingle,
  getMany,
  create,
  update,
  cambiarVisibilidad
};

export default EscuelaVeranoReq;
