import {
  Affix,
  Button,
  Col,
  Divider,
  FormInstance,
  Row,
  Space,
  Typography,
} from "antd";
import cogoToast from "cogo-toast";
import AntdConsts from "../../../../designConstants/antd-constants";
import { scrollToFieldWithOffsetFunction } from "../../../../designConstants/antd-utils";
import AlianzasInput from "../components/AlianzasInput";
import BecasInput from "../components/BecasInput";
import FormStepCounter from "../components/FormStepCounter";
import PlanesYProgramasInput from "../components/PlanesYProgramasInput";
import ResultadosSimceInput from "../components/ResultadosSimceInput";

interface StepFourProps {
  form: FormInstance;
  onNext: () => void;
  onBack: () => void;
}

const StepFour = ({ form, onNext, onBack }: StepFourProps) => {
  const onStepFinish = () => {
    form
      .validateFields()
      .then((_) => onNext())
      .catch((error) => {
        form.scrollToField(error.errorFields?.[0]?.name, {
          behavior: scrollToFieldWithOffsetFunction,
        });
        cogoToast.error("Existen errores en el formulario");
      });
  };

  return (
    <>
      <Row>
        <Col
          lg={8}
          xl={8}
          xxl={8}
          {...AntdConsts.removeColOnMobileLayout}
          style={{ padding: "2em", paddingTop: "20px" }}
        >
          <Affix offsetTop={AntdConsts.headerHeight + 20}>
            <FormStepCounter currentStep={3} direction="vertical" />
          </Affix>
        </Col>
        <Col
          lg={16}
          xl={16}
          xxl={16}
          {...AntdConsts.fullSizeColOnMobileLayout}
          style={{ padding: "2em", backgroundColor: "white" }}
        >
          <Typography.Title level={3}>
            Vinculación con el medio
          </Typography.Title>
          <br />
          <AlianzasInput />
          <BecasInput />
          <PlanesYProgramasInput />
          <Divider />
          <ResultadosSimceInput form={form} />
          <Space
            direction="horizontal"
            style={{ float: "right", marginTop: "2em" }}
          >
            <Button type="default" onClick={onBack}>
              Anterior
            </Button>
            <Button type="primary" onClick={onStepFinish}>
              Siguiente
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default StepFour;
