import { FormInstance } from "antd";
import _ from "lodash";
import { baseUrl } from "../../../commonRequests/axiosInstance";
import { transformUploadFilesToFormData } from "../../../designConstants/antd-utils";
import { AcademiaArtistica, AcademiaArtisticaCreateObject, AcademiaArtisticaFormData } from "../../../types/academiaArtistica.type";

export const transformPayloadAcademiaArtistica = (
  form: FormInstance<AcademiaArtisticaFormData>
): AcademiaArtisticaCreateObject => {
  const data: AcademiaArtisticaFormData = form.getFieldsValue(true);

  const fotos = transformUploadFilesToFormData(form);

  return {
    nombre: data.nombre,
    paisId: data.paisId,
    regionId: data.regionId,
    correo: data.correo,
    comunaId: data.comunaId,
    direccion: data.direccion,
    descripcion: data.descripcion,
    generoAlumnosId: data.generoAlumnosId,
    jornadaId: data.jornadaId,
    telefono: data.telefono,
    paginaWeb: data.paginaWeb,
    instagram: data.instagram,
    facebook: data.facebook,
    whatsapp: data.whatsapp,
    twitter: data.twitter,
    videoCorporativo: data.videoCorporativo,
    modalidadEstudioId: data.modalidadEstudioId,
    precioMin: data.precioMin,
    precioMax: data.precioMax,
    edadMinma: data.edadMinma,
    edadMaxima: data.edadMaxima,
    fotos,
    artes: data.artes.map((id) => ({ id })),
    artesCustom: data.artesCustom?.split('\n').filter((i) => i !== ''),
    instrumentos: data.instrumentos.map((id) => ({ id })),
    instrumentosCustom: data.instrumentosCustom?.split('\n').filter((i) => i !== ''),
    descripcionEdicion: data.descripcionEdicion
  };
};

export const academiaArtisticaToFormData = (
  academia: AcademiaArtistica
): AcademiaArtisticaFormData => {
  let instrumentosCustom = academia.instrumentos
    .filter((i) => !i.default)
    .map((i) => i.nombre);

  let instrumentosCustomStr;
  if (instrumentosCustom.length > 0) {
    instrumentosCustomStr = instrumentosCustom.join('\n');
  } else {
    instrumentosCustomStr = undefined;
  }

  let artesCustom = academia.artes
  .filter((i) => !i.default)
  .map((i) => i.nombre);

let artesCustomStr;
if (artesCustom.length > 0) {
  artesCustomStr = artesCustom.join('\n');
} else {
  artesCustomStr = undefined;
}

  return {
    nombre: academia.nombre,
    paisId: academia.pais.id,
    regionId: academia.region.id,
    correo: academia.correo,
    comunaId: academia.comuna.id,
    direccion: academia.direccion,
    descripcion: academia.descripcion,
    generoAlumnosId: academia.generoAlumnos.id,
    jornadaId: academia.jornada.id,
    telefono: academia.telefono,
    paginaWeb: academia.paginaWeb,
    instagram: academia.instagram,
    facebook: academia.facebook,
    whatsapp: academia.whatsapp,
    twitter: academia.twitter,
    videoCorporativo: academia.videoCorporativo,
    modalidadEstudioId: academia.modalidad.id,
    precioMin: academia.precioMin,
    precioMax: academia.precioMax,
    edadMinma: academia.edadMinma,
    edadMaxima: academia.edadMaxima,
    artes: academia.artes.filter((i) => i.default).map((i) => i.id),
    artesCustom: artesCustomStr,
    instrumentos: academia.instrumentos.filter((i) => i.default).map((i) => i.id),
    instrumentosCustom: instrumentosCustomStr,
    defaultImage: [
      {
        uid: "0",
        name: `default.png`,
        status: "done",
        response: academia?.fotos?.[0],
        url: `${baseUrl}instituciones/images/${academia?.fotos?.[0]?.id}`,
      },
    ],
    restOfImages: academia.fotos.slice(1).map((f, index) => ({
      uid: "rest" + index.toString(),
      name: `${index}.png`,
      status: "done",
      response: f,
      url: `${baseUrl}instituciones/images/${f?.id}`,
    })),
  };
};
