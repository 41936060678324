import { Col, List, Row, Space, Typography } from "antd";
import CardContainer from "../../../components/CardContainer/CardContainer";
import IconoTablas from "../../../components/iconosTablas/IconoTablas";
import AntdConsts from "../../../designConstants/antd-constants";
import useIsMobile from "../../../hooks/useMobile";
import { groupArrayBy } from "../../../utils";
import SingleInstitucionEducativa from "../types/SingleInsEdu.type";

interface CaracteristicasYExtracurricularesProps {
  insEdu: SingleInstitucionEducativa;
}

const CaracteristicasYExtracurriculares = ({
  insEdu,
}: CaracteristicasYExtracurricularesProps) => {
  const isMobile = useIsMobile();

  //Separamos las caracteristicas extra por categoria
  const caracteristicasPorCategorias = groupArrayBy(
    insEdu.caracteristicasExtra.map((c) => ({
      ...c,
      categoria: c.Categoria.nombre,
    })),
    "categoria"
  );

  //Quitamos aquellas categorias vacias
  const categoriasDisponibles = Object.keys(
    caracteristicasPorCategorias
  ).filter((c) => caracteristicasPorCategorias[c].length > 0);

  //Movemos "otros" al final de la lista para que en el front aparezca ultimo
  if (categoriasDisponibles.includes("otro")) {
    categoriasDisponibles.push(
      categoriasDisponibles.splice(categoriasDisponibles.indexOf("otro"), 1)[0]
    );
  }

  //Este objeto guardara el JSx que se renderizara finalmente
  const caracteristicasJsx: JSX.Element[] = [];

  //Por cada categoria agregamos sus caracteristicas con un Description de antd
  for (const category of categoriasDisponibles) {
    const caracteristicas = caracteristicasPorCategorias[category];
    if (caracteristicas.length === 0) {
      continue;
    }
    caracteristicasJsx.push(
      <List
        size="small"
        header={<b>{category.toUpperCase()}</b>}
        grid={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={caracteristicas}
        bordered
        renderItem={(c) => (
          <List.Item style={{ margin: 0 }} key={c.id}>
            <div>
              <Space>
                <IconoTablas nombreIcono={c.nombre} />
                <Typography.Text>{c.nombre.toUpperCase()}</Typography.Text>
              </Space>
            </div>
          </List.Item>
        )}
        style={{ marginBottom: "1em", width: "100%" }}
      />
    );
  }

  // Se repite el mismo proceso para las actividades extraprogramaticas
  // Separamos las extraprogramaticas  por categoria

  const extraprogramaticasPorCategorias = groupArrayBy(
    insEdu.extraprogramaticas.map((c) => ({
      ...c,
      categoria: c.Categoria.nombre,
    })),
    "categoria"
  );

  //Quitamos aquellas categorias vacias
  const categoriasDisponiblesExtraprogramaticas = Object.keys(
    extraprogramaticasPorCategorias
  ).filter((c) => extraprogramaticasPorCategorias[c].length > 0);

  //Movemos "otros" al final de la lista para que en el front aparezca ultimo
  if (categoriasDisponiblesExtraprogramaticas.includes("otro")) {
    categoriasDisponiblesExtraprogramaticas.push(
      categoriasDisponiblesExtraprogramaticas.splice(
        categoriasDisponiblesExtraprogramaticas.indexOf("otro"),
        1
      )[0]
    );
  }

  //Este objeto guardara el JSx que se renderizara finalmente
  const extraprogramaticasJsx: JSX.Element[] = [];

  //Por cada categoria agregamos sus extraprogramaticas con un Description de antd
  for (const category of categoriasDisponiblesExtraprogramaticas) {
    const extraprogramaticas = extraprogramaticasPorCategorias[category];
    extraprogramaticasJsx.push(
      <List
        size="small"
        header={<b>{category.toUpperCase()}</b>}
        grid={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={extraprogramaticas}
        bordered
        renderItem={(c) => (
          <List.Item style={{ margin: 0 }} key={c.id}>
            <div>
              <Space>
                <IconoTablas nombreIcono={c.nombre} />
                <Typography.Text>{c.nombre.toUpperCase()}</Typography.Text>
              </Space>
            </div>
          </List.Item>
        )}
        style={{ marginBottom: "1em", width: "100%" }}
      />
    );
  }
  return (
    <Row gutter={AntdConsts.rowGutter}>
      <Col style={{ display: "flex" }} {...AntdConsts.twoColLayout}>
        {insEdu.caracteristicasExtra.length !== 0 && (
          <CardContainer>
            <Typography.Title level={3}>CARACTERÍSTICAS EXTRAS</Typography.Title>
            <br />
            {caracteristicasJsx}
          </CardContainer>
        )}
        {insEdu.caracteristicasExtra.length === 0 && (
          <CardContainer>
            <Typography.Title level={3}>CARACTERÍSTICAS EXTRAS</Typography.Title>
            <br />
            <Space>
              <Typography.Text>
                La institución no ha Ingresado esta Información o no posee.
              </Typography.Text>
            </Space>
          </CardContainer>
        )}
      </Col>

      <Col style={{ display: "flex" }} {...{ ...AntdConsts.twoColLayout }}>
        {insEdu.extraprogramaticas.length !== 0 && (
          <CardContainer>
            <Typography.Title level={3}>
              ACTIVIDADES EXTRACURRICULARES
            </Typography.Title>
            <br />
            {extraprogramaticasJsx}
          </CardContainer>
        )}
        {insEdu.extraprogramaticas.length === 0 && (
          <CardContainer>
            <Typography.Title level={3}>
              ACTIVIDADES EXTRACURRICULARES
            </Typography.Title>
            <br />
            <Space>
              <Typography.Text>
                La institución no ha Ingresado esta Información o no posee.
              </Typography.Text>
            </Space>
          </CardContainer>
        )}
      </Col>
    </Row>
  );
};

export default CaracteristicasYExtracurriculares;
