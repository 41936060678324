import { Row, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import "./AdsOcurrenceSelector.css";

interface AdsOcurrenceSelectorProps {
  onChange?: (ocurrence: number) => any;
  value?: number;
  usedPercentage: number;
}

type cubeClassName =
  | "ocurrence-square"
  | "ocurrence-square-hover"
  | "ocurrence-square-empty";

const AdsOcurrenceSelector = ({
  value,
  onChange,
  usedPercentage,
}: AdsOcurrenceSelectorProps) => {
  const [percentage, setPercentage] = useState<number | undefined>(50);
  const [hoverPercentage, setHoverPercentage] = useState<number | null>();

  const percentagePerCube = 2.5; // percent

  const avaliablePercentage = 100 - usedPercentage;

  useEffect(() => setPercentage(value), [value]);

  const onMouseOverCube = (cubePercentage: number) => {
    setHoverPercentage(cubePercentage);
  };

  const onCubeClick = (cubePercentage: number) => {
    setPercentage(cubePercentage);
    onChange?.(cubePercentage);
  };

  const getClassName = (cubePercentage: number): cubeClassName => {
    if (hoverPercentage) {
      return cubePercentage <= hoverPercentage
        ? "ocurrence-square-hover"
        : "ocurrence-square-empty";
    }

    if (percentage) {
      return cubePercentage <= percentage
        ? "ocurrence-square"
        : "ocurrence-square-empty";
    }

    return "ocurrence-square-empty";
  };

  const percentageCubes: React.ReactElement[] = [];
  for (let i = 0; i < 100 / percentagePerCube; i += 1) {
    let cubePercentage = (i + 1) * percentagePerCube;

    if (cubePercentage > avaliablePercentage) {
      percentageCubes.push(
        <div style={{ padding: "0.1em" }}>
          <Row
            align="middle"
            justify="center"
            key={i}
            className="ocurrence-square-used"
          >
            <span className="">{`${percentagePerCube.toFixed(1)}%`}</span>
          </Row>
        </div>
      );
      continue;
    }

    percentageCubes.push(
      <Tooltip title={`${cubePercentage}%`}>
        <div
          style={{ padding: "0.1em" }}
          onMouseOver={() => onMouseOverCube(cubePercentage)}
          onMouseLeave={() => setHoverPercentage(null)}
        >
          <Row
            justify="center"
            align="middle"
            key={i}
            className={getClassName(cubePercentage)}
            onClick={() => onCubeClick(cubePercentage)}
          >
            <span className="percentage-label">{`${percentagePerCube.toFixed(
              1
            )}%`}</span>
          </Row>
        </div>
      </Tooltip>
    );
  }

  return <Space wrap>{percentageCubes}</Space>;
};

export default AdsOcurrenceSelector;
