import { ConfigProvider, Form } from "antd";
import { useState } from "react";
import HeaderModern from "../../header/HeaderModern";

import cogoToast from "cogo-toast";
import AcademiaIdiomasFormStepOne from "./steps/AcademiaIdiomasFormStepOne";
import AcademiaIdiomasFormStepTwo from "./steps/AcademiaIdiomasFormStepTwo";
import AcademiaDeportivaFinalStep from "./steps/FinalStep";
import {
  academiaIdiomasToFormData,
  transformPayloadAcademiaIdiomas,
} from "./transformerAcademiaIdiomas";
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {
  reqCreateAcademiaIdiomas,
  requestSingleAcademiaIdiomas,
  reqUpdateAcademiaIdiomas,
} from "../../../commonRequests/academiaIdiomasRequest";
import useIsMobile from "../../../hooks/useMobile";

const AcademiaIdiomasFormPage = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [createdId, setCreatedId] = useState<number>();

  const isMobile = useIsMobile();

  const onNextStep = () => {
    window.scrollTo(0, 0);
    setCurrentStep(currentStep + 1);
    window.scrollTo(0, 0);
  };
  const onBackStep = () => setCurrentStep(currentStep - 1);

  const academiaIdiomasReq = useRequest(async () => {
    if (!id) {
      return;
    }
    const response = await requestSingleAcademiaIdiomas(+id);

    if (!response) {
      return;
    }
    const formData = academiaIdiomasToFormData(response);
    form.setFieldsValue(formData);
  });

  const onEdit = async () => {
    const data = transformPayloadAcademiaIdiomas(form);
    const response = await reqUpdateAcademiaIdiomas({
      ...data,
      id: +(id as string),
    });
    if (!response) {
      cogoToast.error("No fue posible editar la institución");
    } else {
      setCreatedId(response.id);
      setCurrentStep(2);
      cogoToast.success("Editado correctamente");
    }
  };

  const onSubmit = async () => {
    if (id) {
      return onEdit();
    }
    const data = transformPayloadAcademiaIdiomas(form);
    const response = await reqCreateAcademiaIdiomas(data);
    if (!response) {
      cogoToast.error("No fue posible crear la institución");
    } else {
      setCreatedId(response.id);
      setCurrentStep(2);
      cogoToast.success("Institución educativa creada correctamente");
    }
  };

  const onFormError = () => {
    cogoToast.error("Existen errores en el formulario");
  };

  if (academiaIdiomasReq.loading) {
    return <LoadingSpinner />;
  }

  const steps = [
    {
      step: 0,
      component: <AcademiaIdiomasFormStepOne form={form} onNext={onNextStep} />,
    },
    {
      step: 1,
      component: (
        <AcademiaIdiomasFormStepTwo onNext={onNextStep} onBack={onBackStep} />
      ),
    },
    {
      step: 2,
      component: <AcademiaDeportivaFinalStep id={createdId as number} />,
    },
  ];

  return (
    <>
      <HeaderModern affixTop />
      <div
        style={
          isMobile
            ? { paddingLeft: "1em", paddingRight: "1em" }
            : { paddingLeft: "15%", paddingRight: "15%" }
        }
      >
        <ConfigProvider
          componentSize="large"
          form={{ validateMessages: { required: "Campo obligatorio" } }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={onFormError}
          >
            {steps.find((step) => step.step === currentStep)?.component}
          </Form>
        </ConfigProvider>
      </div>
    </>
  );
};

export default AcademiaIdiomasFormPage;
