import { Col, Row, Typography, Divider, Grid } from "antd";
import AntdConsts from "../designConstants/antd-constants";
import { CommonInstitucion } from "../types/instituciones.types";
import MapInsEdu from "../views/InsEduDetailView/components/MapInsEdu";
import CardContainer from "./CardContainer";
import DescripcionInstitucion from "./DescripcionInstitucion";
import ImageGallery from "./ImageGallery/ImageGallery";

const InstitucionDetailViewFirstRow = ({
  institucion,
}: {
  institucion: CommonInstitucion;
}) => {
  const colSpan = (normalSpan: number) => ({
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: normalSpan,
    xxl: normalSpan,
  });

  const bp = Grid.useBreakpoint();

  return (
    <CardContainer>
      <Row justify="center" gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.fullSizeColLayout}>
          <Typography.Title level={2} style={{ color: "GrayText" }}>
            {institucion.nombre.toUpperCase()}
          </Typography.Title>
        </Col>
        <Col {...colSpan(12)} xl={12}>
          <ImageGallery
            imageIds={institucion?.fotos}
            title={institucion?.nombre}
          />
        </Col>
        <Col {...colSpan(12)} xl={12}>
          <DescripcionInstitucion institucion={institucion} />
          {/* <Divider type="vertical" /> */}
        </Col>
        <Col {...colSpan(24)} xl={24}>
          {/* {bp["xl"] && <Divider />} */}
          <Row>
            {institucion.latitud && institucion.longitud && (
              <>
                <Col span={24}>
                  <Typography.Title level={2}>Ubicación</Typography.Title>
                </Col>
                <Col span={24} style={{ height: "300px" }}>
                  <MapInsEdu
                    latitude={institucion?.latitud as number}
                    longitude={institucion?.longitud as number}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default InstitucionDetailViewFirstRow;
