import { useRequest } from "ahooks";
import { Button, Checkbox, Col, Form, Input, Row, Typography } from "antd";
import { requestDeportes } from "../../../../commonRequests/academiaDeportesRequest";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const DeportesSelectorInput = () => {
  const { data: deportes, loading } = useRequest(requestDeportes);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Typography.Title level={3}>Deportes</Typography.Title>
      <Form.Item
        name="deportes"
        label="Seleccione los deportes instruidos por su institución"
        rules={[{ required: true }]}
      >
        <Checkbox.Group>
          <Row gutter={AntdConsts.rowGutter}>
            {deportes?.map((d) => (
              <Col {...AntdConsts.fourColLayout}>
                <Checkbox value={d.id}>{d.nombre}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.List name="deportesCustom">
        {(fields, { add, remove }) => (
          <div style={{ width: "100%" }}>
            <Typography.Title level={4}>Deportes faltantes</Typography.Title>
            <Typography.Title level={5}>
              No aparecen todos los deportes instruidos por su establecimiento?
              Agreguelos en este campo
            </Typography.Title>
            {fields.map((field) => (
              <Form.Item {...field}>
                <Row gutter={AntdConsts.rowGutter}>
                  <Col span={20}>
                    <Form.Item
                      {...field}
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor rellene el campo o quitelo de la lista",
                        },
                      ]}
                    >
                      <Input placeholder="Deporte" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <MinusCircleOutlined
                      onClick={() => remove(field.name)}
                      style={{ paddingTop: "0.5em", fontSize: "1.6em" }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add(undefined);
                }}
                style={{ width: "60%", marginTop: "20px" }}
                icon={<PlusOutlined />}
              >
                Agregar deporte
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </>
  );
};

export default DeportesSelectorInput;
