import { Button, Col, Divider, Form, FormInstance, Row, Typography } from "antd";
import FileUpload from "../../../../components/FileUpload";
import AntdConsts from "../../../../designConstants/antd-constants";
import { useAuthStorage } from "../../../../hooks/useBrowserStorage";
import { AuthToken } from "../../../../types/auth.types";
import { PlusCircleOutlined } from "@ant-design/icons";

export type DOCUMENTOS_INS_EDU =
  | "REGLAMENTO_INTERNO"
  | "REGLAMENTO_EVALUACION"
  | "PROTOCOLO_CONVIVENCIA"
  | "LISTADO_UTILES"
  | "PROTOCOLO_COVID";

const DocumentosInput = ({ form }: { form: FormInstance }) => {
  const authToken = useAuthStorage();

  return (
    <>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name="reglamentoInternoFile"
            label="Cargar reglamento interno"
          >
            <FileUpload
              action={`${process.env.REACT_APP_API_URL}instituciones/documentos`}
              headers={{ Authorization: authToken as AuthToken }}
              defaultFileList={form.getFieldValue("reglamentoInternoFile")}
              accept="application/msword, application/pdf"
              maxCount={1}
            >
              <Button block icon={<PlusCircleOutlined />}>
                Reglamento interno{" "}
              </Button>
            </FileUpload>
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name="reglamentoEvaluacionFile"
            label="Cargar reglamento evaluación"
          >
            <FileUpload
              action={`${process.env.REACT_APP_API_URL}instituciones/documentos`}
              headers={{ Authorization: authToken as AuthToken }}
              defaultFileList={form.getFieldValue("reglamentoEvaluacionFile")}
              accept="application/msword, application/pdf"
              maxCount={1}
            >
              <Button block icon={<PlusCircleOutlined />}>
                Reglamento evaluación{" "}
              </Button>
            </FileUpload>
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name="protocolaConvivenciaFile"
            label="Cargar protocolo de convivencia"
          >
            <FileUpload
              action={`${process.env.REACT_APP_API_URL}instituciones/documentos`}
              headers={{ Authorization: authToken as AuthToken }}
              defaultFileList={form.getFieldValue("protocolaConvivenciaFile")}
              accept="application/msword, application/pdf"
              maxCount={1}
            >
              <Button block icon={<PlusCircleOutlined />}>
                Protocolo de convivencia{" "}
              </Button>
            </FileUpload>
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name="listadoUtilesFile"
            label="Cargar listado general de útiles"
          >
            <FileUpload
              action={`${process.env.REACT_APP_API_URL}instituciones/documentos`}
              headers={{ Authorization: authToken as AuthToken }}
              defaultFileList={form.getFieldValue("listadoUtilesFile")}
              accept="application/msword, application/pdf"
              maxCount={1}
            >
              <Button block icon={<PlusCircleOutlined />}>
                Listado útiles{" "}
              </Button>
            </FileUpload>
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item name="protocoloCovidFile" label="Cargar protocolo COVID">
            <FileUpload
              action={`${process.env.REACT_APP_API_URL}instituciones/documentos`}
              headers={{ Authorization: authToken as AuthToken }}
              defaultFileList={form.getFieldValue("protocoloCovidFile")}
              accept="application/msword, application/pdf"
              maxCount={1}
            >
              <Button block icon={<PlusCircleOutlined />}>
                Protocolo Covid 2019{" "}
              </Button>
            </FileUpload>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default DocumentosInput;
