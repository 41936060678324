import { CSSProperties } from "react";
import "./CardContainer.css";

interface CardContainerProps {
  children: any;
  style?: CSSProperties;
  smallPadding?: boolean;
  noPadding?: boolean;
}

const CardContainer = ({
  children,
  style,
  smallPadding,
  noPadding,
}: CardContainerProps) => {
  const customStyle: CSSProperties = {
    padding: noPadding ? "0px" : smallPadding ? "0.7em" : "1em",
    paddingTop: noPadding ? "0px" : "1em",
    width: "100%",
    borderRadius: 10,
  };

  return (
    <div
      className="frosted-glass-background"
      style={{ ...customStyle, ...style }}
    >
      {children}
    </div>
  );
};

export default CardContainer;
