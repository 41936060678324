import { Col, Row, Typography } from "antd";
import { CSSProperties } from "react";
import CardContainer from "../../../components/CardContainer/CardContainer";
import AntdConsts from "../../../designConstants/antd-constants";
import SingleInstitucionEducativa from "../types/SingleInsEdu.type";

interface ProgramaEducativoProps {
  insEdu: SingleInstitucionEducativa;
  style?: CSSProperties;
}

const ProgramaEducativo = ({ insEdu, style }: ProgramaEducativoProps) => {
  return (
    <CardContainer style={style}>
      <Typography.Title level={3}>PROGRAMA EDUCATIVO</Typography.Title>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Typography.Title level={4}>Nuestra misión</Typography.Title>
          <Typography.Paragraph
            style={{ textAlign: "justify" }}
            ellipsis={{ rows: 11, expandable: true, symbol: "más" }}
          >
            {insEdu.mision}
          </Typography.Paragraph>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Typography.Title level={4}>Nuestra visión</Typography.Title>
          <Typography.Paragraph
            style={{ textAlign: "justify" }}
            ellipsis={{ rows: 11, expandable: true, symbol: "más" }}
          >
            {insEdu.vision}
          </Typography.Paragraph>
        </Col>
      </Row>
      {/* <Space
        direction={isMobile ? "vertical" : "horizontal"}
        size="large"
        style={
          isMobile ? { paddingLeft: "0.5em", paddingRight: "1em" } : undefined
        }
      >
        <div>
          <Typography.Title level={4}>Nuestra mision</Typography.Title>
          <Typography.Paragraph style={{ textAlign: "justify" }}>
            {insEdu.mision}
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Title level={4}>Nuestra Visión</Typography.Title>
          <Typography.Paragraph style={{ textAlign: "justify" }}>
            {insEdu.vision}
          </Typography.Paragraph>
        </div>
      </Space> */}
    </CardContainer>
  );
};

export default ProgramaEducativo;
