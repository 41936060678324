import { Row, Col } from "antd";
import { useState } from "react";
import AdsBanner from "../../../components/AdsBanner";
import BuscadorPorUbicacionMobile from "../../../components/BuscadorPorUbicacionMobile";
import SponsoredCarousel from "../../../components/SponsoredCarousel";
import AntdConsts from "../../../designConstants/antd-constants";
import { AcademiaDeportivaFilters } from "../../../types/filtros.type";
import { TipoInstitucionIds } from "../../../types/instituciones.types";
import { parseUrlQueryInsEdu } from "../../../utils/filterUtils";
import HeaderModern from "../../header/HeaderModern";
import AcademiaDeportesFilterSelector from "./components/AcademiaDeportesFilterSelector/AcademiaDeportesFilterSelector";
import ListaAcademiaDeportesPage from "./components/ListaAcademiaDeportesPage";

const BusquedaAcademiaDeportesPage = () => {
  const [filters, setFilters] = useState<AcademiaDeportivaFilters>({
    ...parseUrlQueryInsEdu(),
    region_id: +(localStorage.getItem("REGION") as string) || undefined,
    comuna_id: +(localStorage.getItem("COMUNA") as string) || undefined,
    geo_direccion: localStorage.getItem("DIRECCION") || undefined,
    hidden: false,
  });

  const onChangeLocation = () => {
    setFilters({
      ...filters,
      region_id: +(localStorage.getItem("REGION") as string) || undefined,
      comuna_id: +(localStorage.getItem("COMUNA") as string) || undefined,
      geo_direccion: localStorage.getItem("DIRECCION") || undefined,
    });
  };

  return (
    <>
      <HeaderModern affixTop askLocation onChangeLocation={onChangeLocation} />
      <SponsoredCarousel
        tipoInstitucionId={TipoInstitucionIds.ACADEMIA_DEPORTIVA || undefined}
      />
      <BuscadorPorUbicacionMobile
        style={{ paddingLeft: "1em", paddingRight: "1em" }}
      />
      <div
        style={{
          paddingTop: "2em",
        }}
      >
        <Row gutter={AntdConsts.rowGutter} style={{ marginTop: "2em" }}>
          <Col
            style={{
              padding: "2em",
            }}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={6}
            xxl={6}
          >
            <AcademiaDeportesFilterSelector
              currentFilters={filters}
              setFilters={setFilters}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={15}>
            <ListaAcademiaDeportesPage
              filters={filters}
              setFilters={setFilters}
            />
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3}>
            <AdsBanner vertical />
            <br />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BusquedaAcademiaDeportesPage;
