import { Avatar, Button, Divider, Drawer, Space, Typography } from "antd";
import cogoToast from "cogo-toast";
import { CSSProperties, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthReqs from "../../../commonRequests/authRequests";
import { useAuthStorage } from "../../../hooks/useBrowserStorage";
import useIsMobile from "../../../hooks/useMobile";
import { AuthTokenPayload } from "../../../types/auth.types";
import { clearAuthToken } from "../../../utils/htmlUtils";

interface UserAvatarProps {
  style?: CSSProperties;
}
const UserAvatar = ({ style }: UserAvatarProps) => {
  const authToken = useAuthStorage(true) as AuthTokenPayload | null;
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  if (!authToken) {
    return <></>;
  }

  const onChangePassword = async () => {
    try {
      const hide = cogoToast.loading("Enviando solicitud", {hideAfter: 0})
      await AuthReqs.changePasswordPetition(authToken.sub);
      hide.hide?.()
      cogoToast.info(
        "Si su correo aparece en nuestros registros recibirá un correo para reestablecer su contraseña",
        { hideAfter: 5000 }
      );
    } catch {}
  };

  const logOut = () => {
    clearAuthToken();
    cogoToast.success("Sesión cerrada correctamente");
    window.location.assign("/");
  };

  return (
    <>
      <div onClick={() => setIsDrawerVisible(true)} style={style}>
        <Avatar
          size="large"
          style={{ backgroundColor: "#87d068", cursor: "pointer" }}
        >
          {authToken.sub.charAt(0).toUpperCase()}
        </Avatar>
      </div>
      <Drawer
        title="Menú de usuario"
        placement={isMobile ? "bottom" : "right"}
        visible={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
        height="92%"
      >
        <Space direction="vertical">
          <Typography.Text>{`Ingresó como: ${authToken.sub}`}</Typography.Text>
          <Typography.Text>{`Perfil: ${authToken.profileType.type}`}</Typography.Text>
        </Space>
        <br />
        <br />

        <Typography.Title level={4}>
          Ingresar nueva institución
        </Typography.Title>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button block onClick={() => navigate("/create")}>
            Crear nueva institución
          </Button>
        </Space>
        <Divider />
        <Typography.Title level={4}>Gestión de usuario</Typography.Title>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button type="dashed" block onClick={() => navigate("/admin")}>
            Gestionar Instituciones creadas
          </Button>
          {authToken.profileType.type === "ADMIN" && (
            <Button
              type="dashed"
              block
              onClick={() => navigate("/admin/vendedores")}
            >
              Gestionar ventas
            </Button>
          )}
          {authToken.profileType.type === "ADMIN" && (
            <Button
              type="dashed"
              block
              onClick={() => navigate("/admin/anuncios")}
            >
              Gestionar de anuncios
            </Button>
          )}
          <Button type="dashed" block onClick={onChangePassword}>
            Cambiar contraseña
          </Button>
          <Button type="dashed" block onClick={logOut}>
            Cerrar sesión
          </Button>
        </Space>
      </Drawer>
    </>
  );
};

export default UserAvatar;
