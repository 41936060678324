import { useDeepCompareEffect, useRequest } from "ahooks";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { getInstitucionesComunes } from "../../../commonRequests/adminRequests";
import { CommonFilters } from "../../../types/filtros.type";
import { tipoInstitucionType } from "../../../types/instituciones.types";
import { NavigateToInstitucion } from "../../../utils/institucionesEducativasRouter";
import CambiarVisibilidadInstitucionButton from "./CambiarVisibilidadInstitucionButton";

interface TableDataSource {
  key: string | undefined;
  id: number;
  name: string;
  region: string;
  comuna: string;
  tipo: tipoInstitucionType;
  motivo: "CREACIÓN" | "EDICIÓN";
  estado: "NO VISIBLE" | "VISIBLE";
  description: string | undefined;
  tipoInstitucionId: number;
}

const InstitucionesOcultasTable = () => {
  const [filters, setFilters] = useState<CommonFilters>({
    take: 10,
    skip: 0,
  });

  const OcultasReq = useRequest(() =>
    getInstitucionesComunes({
      ...filters,
      hidden: true,
      needsReview: false,
    })
  );

  useDeepCompareEffect(() => {
    OcultasReq.runAsync();
  }, [filters]);

  const needsReview = OcultasReq.data?.items;

  const dataSource: TableDataSource[] | undefined = needsReview?.map((i) => ({
    key: `${i.tipoInstitucion.nombre} ${i.id}`,
    id: i.id as number,
    name: i.nombre,
    region: i.region.nombre,
    comuna: i.comuna.nombre,
    tipo: i.tipoInstitucion.nombre as tipoInstitucionType,
    motivo: !i.updatedAt ? "CREACIÓN" : "EDICIÓN",
    estado: i.hidden ? "NO VISIBLE" : "VISIBLE",
    description: !i.updatedAt ? undefined : i.descripcionEdicion,
    tipoInstitucionId: i.tipoInstitucion.id,
  }));

  const columns: ColumnsType<TableDataSource> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      onCell: (record) => ({
        onClick: () => NavigateToInstitucion(record.tipo, record.id, true),
      }),
    },
    {
      title: "Región",
      dataIndex: "region",
      key: "region",
      onCell: (record) => ({
        onClick: () => NavigateToInstitucion(record.tipo, record.id, true),
      }),
    },
    {
      title: "Comuna",
      dataIndex: "comuna",
      key: "comuna",
      onCell: (record) => ({
        onClick: () => NavigateToInstitucion(record.tipo, record.id, true),
      }),
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      onCell: (record) => ({
        onClick: () => NavigateToInstitucion(record.tipo, record.id, true),
      }),
    },
    {
      title: "Acción",
      key: "Volver visible",
      render: (_: any, record: TableDataSource) => (
        <>
          <CambiarVisibilidadInstitucionButton
            tipoInstitucionId={record.tipoInstitucionId}
            id={record.id}
            startsHidden={record.estado === "NO VISIBLE"}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Typography.Title level={3}>Instituciones Ocultas</Typography.Title>
      <Table
        scroll={{ x: "max-content"}}
        style={{ cursor: "pointer" }}
        dataSource={dataSource}
        columns={columns}
        loading={OcultasReq.loading || !OcultasReq.data}
        pagination={{
          total: OcultasReq.data?.total,
          onChange: (page, pageSize) => {
            setFilters({
              ...filters,
              take: pageSize,
              skip: pageSize * (page - 1),
            });
          },
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Typography.Paragraph
              strong
              style={{ marginRight: "1em", marginLeft: "3em" }}
            >
              {`Comentario ultima edición: ${record.description}`}
            </Typography.Paragraph>
          ),
          rowExpandable: (record) =>
            record.motivo === "EDICIÓN" && !!record.description,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                {`${OcultasReq.data?.total} instituciones ocultas`}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        bordered
        size="middle"
      />
    </>
  );
};

export default InstitucionesOcultasTable;
