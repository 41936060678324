import { useRequest } from "ahooks";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { reqPreuniversitarioNiveles } from "../../../../commonRequests/preuniversitarioRequests";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";


const NivelesSelectorInput = () => {
  const { data: niveles, loading } = useRequest(reqPreuniversitarioNiveles);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Typography.Title level={3}>Niveles</Typography.Title>
      <Form.Item
        name="niveles"
        label="Seleccione los niveles de los alumnos de su institución"
        rules={[{ required: true }]}
      >
        <Checkbox.Group>
          <Row gutter={AntdConsts.rowGutter}>
            {niveles?.map((d) => (
              <Col {...AntdConsts.twoColLayout}>
                <Checkbox value={d.id}>{d.nombre}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name={["nivelesExtra"]}
        label="Si su institución tiene niveles que no se encuentran en la lista, por favor indíquelos aquí (Uno por linea)"
      >
        <TextArea>
          
        </TextArea>
      </Form.Item>
    </>
  );
};

export default NivelesSelectorInput;
