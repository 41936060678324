import { Typography } from "antd";
import { CommonInstitucion } from "../types/instituciones.types";

interface PreuniversitarioDescripcionProps {
  institucion: CommonInstitucion;
}

const DescripcionInstitucion = ({
  institucion,
}: PreuniversitarioDescripcionProps) => {
  return (
    <>
      <Typography.Title style={{ paddingLeft: "2em" }} level={4}>DESCRIPCIÓN</Typography.Title>
      <div style={{ paddingLeft: "2em", paddingRight: "2em" }}>
        <div>
          <Typography.Paragraph
            style={{
              paddingLeft: "1em",
              paddingRight: "1em",
              textAlign: "justify",
              maxHeight: "500px",
              overflowY: "auto",
              color: "GrayText",
            }}
            ellipsis={{ rows: 11, expandable: true, symbol: "más" }}
          >
            {institucion.descripcion}
          </Typography.Paragraph>
        </div>
      </div>
    </>
  );
};
export default DescripcionInstitucion;
