import { Button, Col, Form, Input, Row, Typography } from "antd";
import AntdConsts from "../../../../designConstants/antd-constants";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ActividadesSelectorInput = () => {
  return (
    <>
      <Typography.Title level={3}>Actividades</Typography.Title>

      <Form.List name="actividades">
        {(fields, { add, remove }) => (
          <div style={{ width: "100%" }}>
            <Typography.Title level={4}>Actividades establecimiento</Typography.Title>
            <Typography.Title level={5}>
              Ingrese las actividades realizadas en su establecimiento.
            </Typography.Title>
            {fields.map((field) => (
              <Form.Item {...field}>
                <Row gutter={AntdConsts.rowGutter}>
                  <Col span={20}>
                    <Form.Item
                      {...field}
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor rellene el campo o quitelo de la lista",
                        },
                      ]}
                    >
                      <Input placeholder="Actividad" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <MinusCircleOutlined
                      onClick={() => remove(field.name)}
                      style={{ paddingTop: "0.5em", fontSize: "1.6em" }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add(undefined);
                }}
                style={{ width: "60%", marginTop: "20px" }}
                icon={<PlusOutlined />}
              >
                Agregar actividad
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </>
  );
};

export default ActividadesSelectorInput;
