import { Button, Checkbox, Form, Input } from "antd";
import cogoToast from "cogo-toast";
import { Link, useNavigate } from "react-router-dom";
import {
  loginRequest,
  WrongCredentialError,
} from "../../commonRequests/authRequests";

function FormularioInicio() {
  const navigate = useNavigate();

  const onFinish = async (values: {
    username: string;
    password: string;
    remember: boolean;
  }) => {
    try {
      const authtoken = await loginRequest(values.username, values.password);
      if (!authtoken) {
        cogoToast.error(
          "Error al iniciar sesión, por favor intentelo nuevamente"
        );
        return;
      }

      if (values.remember) {
        localStorage.setItem(
          process.env.REACT_APP_AUTH_TOKEN_NAME as string,
          authtoken.authToken
        );
      } else {
        sessionStorage.setItem(
          process.env.REACT_APP_AUTH_TOKEN_NAME as string,
          authtoken.authToken
        );
      }

      navigate("/");
    } catch (error) {
      if (error instanceof WrongCredentialError) {
        cogoToast.error("Credenciales incorrectas");
      }
    }
  };

  return (
    <div style={{padding: "2em"}}>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div>
          {/* <h2 className="login-text">Inicio De Sesion</h2> */}
        </div>
        <Form.Item
          label="Usuario"
          name="username"
          required={false}
          rules={[
            {
              required: true,
              message: "Ingrese su nombre de usuario!",
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          required={false}
          rules={[{ required: true, message: "Ingrese su password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <p>
            ¿Olvidó su 
            <Link to="/forgot-password"> Contraseña </Link>
            ?
          </p>
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Recordarme</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Iniciar
          </Button>
        </Form.Item>
        <Form.Item>
          <p>
            ¿No ha registrado su establecimiento?
            {"  "}
            <Link to="/registro">Registrelo Aquí</Link>
          </p>
        </Form.Item>
      </Form>
    </div>
  );
}

export default FormularioInicio;
