const currencyFormatter = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

type groupArrayByReturn<T> = { [key: string]: T[] };

/***
 * Separa una array en grupos basandose en una propiedad
 *
 * Ejemplo:
 *
 * console.log(groupBy([{key: 1, value: "a"}, {key: 1, value: "b"}, {key: 2, value: "c"}], 'length'));
 *
 * // => {1: ["a", "b"], 2: ["c"]}
 */
export const groupArrayBy = <T>(
  xs: T[],
  key: string
): { [key: string]: T[] } => {
  return xs.reduce((rv: any, x) => {
    //@ts-ignore
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function getDistanceCoordinates(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export default currencyFormatter;
