import {
  Affix,
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import { useParams } from "react-router-dom";
import AntdConsts from "../../../../designConstants/antd-constants";
import DatosDePagoInput from "../components/DatosDePagoInput";
import DocumentosInput from "../components/DocumentosInput";
import EmpresaUniformesInput from "../components/EmpresaUniformesInput";
import FormStepCounter from "../components/FormStepCounter";
import ResultadosSimceInput from "../components/ResultadosSimceInput";
import VacantesInput from "../components/VacantesInput";
import { handleLoadDataOntoUploadInputDocuments } from "../submitInsEdu";

interface StepFiveProps {
  form: FormInstance;
  onSubmit: (values: any) => any;
  onBack: () => void;
}

const StepFive = ({ form, onBack }: StepFiveProps) => {
  const { id: isUpdate } = useParams();
  handleLoadDataOntoUploadInputDocuments(form)
  const a = form.getFieldsValue(true)
  return (
    <>
      <Row>
        <Col
          lg={8}
          xl={8}
          xxl={8}
          {...AntdConsts.removeColOnMobileLayout}
          style={{ padding: "2em", paddingTop: "20px" }}
        >
          <Affix offsetTop={AntdConsts.headerHeight + 20}>
            <FormStepCounter currentStep={4} direction="vertical" />
          </Affix>
        </Col>
        <Col
          lg={16}
          xl={16}
          xxl={16}
          {...AntdConsts.fullSizeColOnMobileLayout}
          style={{ padding: "2em", backgroundColor: "white" }}
        >
          <DatosDePagoInput form={form} />
          <Divider />
          <VacantesInput form={form} />
          <Divider />
          
          <Divider />
          <EmpresaUniformesInput />

          <Divider />
          <Typography.Title level={3}>Subida de documentos</Typography.Title>
          <Typography.Text>
            Por favor cargue los documentos que posea su establecimiento (Se permiten solo archivos PDF y Microsoft Word)
          </Typography.Text>
          <DocumentosInput form={form} />
          <Divider />
          {
            isUpdate &&
            <Form.Item
              rules={[{ required: true,}]}
              name="descripcionEdicion"
              label="Por favor ingrese una breve explicación sobre la razón que hizo necesaria la edición de su establecimiento (Opcional)"
            >
              <Input.TextArea></Input.TextArea>
            </Form.Item>
          }
          <Space
            direction="horizontal"
            style={{ float: "right", marginTop: "2em" }}
          >
            <Button type="default" onClick={onBack}>
              Anterior
            </Button>
            <Button type="primary" htmlType="submit">
              Enviar formulario
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default StepFive;
