import { Table, Typography } from "antd";
import useIsMobile from "../../../hooks/useMobile";
import currencyFormatter from "../../../utils";
import SingleInstitucionEducativaEasyVisualization from "../types/SingleInstitucionEducativaEasyVisualization.type";

interface DatosPagoTableProps {
  insEdu: SingleInstitucionEducativaEasyVisualization;
}

const DatosPagoTable = ({ insEdu }: DatosPagoTableProps) => {
  const isMobile = useIsMobile();

  const renderedDataSource = insEdu.tablaDatosPago.dataSource.map((d) => {
    if (isMobile) {
      return {
        nivelEducativo: d.nivelEducativo,
        data: `${d.cuotas} / ${currencyFormatter.format(
          d.mensualidad
        )} / ${currencyFormatter.format(d.matricula)}`,
      };
    }
    return {
      ...d,
      mensualidad: currencyFormatter.format(d.mensualidad),
      matricula: currencyFormatter.format(d.matricula),
    };
  });

  const mobileColumns = [
    {
      title: "Nivel Educativo",
      dataIndex: "nivelEducativo",
      key: "nivelEducativo",
    },
    {
      title: "Cuotas / Mensualidad / Matrícula",
      dataIndex: "data",
      key: "data",
    },
  ];

  return (
    <>
      <Typography.Title level={3}>DATOS DE PAGO</Typography.Title>
      <Table
        bordered
        pagination={false}
        columns={isMobile ? mobileColumns : insEdu.tablaDatosPago.headers}
        dataSource={renderedDataSource}
      />
    </>
  );
};

export default DatosPagoTable;
