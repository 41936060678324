import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Result,
  Row,
  Select,
  Space,
} from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { reqCreateSolicitudContacto } from "../commonRequests/adminRequests";
import AntdConsts from "../designConstants/antd-constants";
import {
  CommonInstitucion,
  ContactoInstitucionCreateObject,
} from "../types/instituciones.types";
import TerminosYCondiciones from "../views/registro/TerminosYCondiciones";

const ContactarInstitucionButton = ({
  institucion,
}: {
  institucion: CommonInstitucion;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [solicitudRealizada, setSolicitudRealizada] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = async (data: ContactoInstitucionCreateObject) => {
    const response = await reqCreateSolicitudContacto(
      { ...data, anio: +data.anio },
      institucion.tipoInstitucionId,
      institucion.id as number
    );
    if (!response) {
      cogoToast.error("No fue posible realizar la solicitud de contacto");
      return;
    }
    cogoToast.success("Solicitud de contacto enviada!");
    setSolicitudRealizada(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setSolicitudRealizada(false);
    form.resetFields();
  };

  const succesViewJsx = (
    <Result
      status="success"
      title="Solicitud de contacto enviada!"
      extra={[
        <Button block type="primary" key="console" onClick={onModalClose}>
          Volver a la institución
        </Button>,
      ]}
    />
  );

  const formJsx = (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onFinishFailed={() => cogoToast.error("Existen errores en el formulario")}
    >
      <Row gutter={AntdConsts.rowGutter}>
        <Col span={12}>
          <Form.Item
            label="Nombre"
            name="nombreSolicitante"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Telefono"
            name="telefono"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Email"
            name="correo"
            rules={[
              {
                message: "Correo inválido",
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              },
              { required: true, message: "Campo obligatorio" },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={AntdConsts.rowGutter}>
        <Col span={12}>
          <Form.Item
            label="¿Para que año necesita la matricula?"
            name="anio"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Select>
              <Select.Option value={new Date().getFullYear()}>
                {new Date().getFullYear()}
              </Select.Option>
              <Select.Option value={new Date().getFullYear() + 1}>
                {new Date().getFullYear() + 1}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="¿Cuantos estudiantes necesitan la matrícula?"
            name="matriculasSolicitadas"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Select>
              <Select.Option value={1}>{1}</Select.Option>
              <Select.Option value={2}>{2}</Select.Option>
              <Select.Option value={3}>{3}</Select.Option>
              <Select.Option value={4}>{4}</Select.Option>
              <Select.Option value={5}>{5}</Select.Option>
              <Select.Option value={6}>{6}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Mensaje"
        name="mensaje"
        rules={[{ required: false, message: "Campo obligatorio" }]}
      >
        <Input.TextArea rows={5}></Input.TextArea>
      </Form.Item>
      <Form.Item name="solicitaVisita" valuePropName="checked">
        <Checkbox>¿Necesita visita al establecimiento?</Checkbox>
      </Form.Item>
      <Form.Item
        name="aceptaPoliticas"
        valuePropName="checked"
        rules={[{ required: true, message: "Campo obligatorio" }]}
      >
        <Checkbox>
          Acepto los{<TerminosYCondiciones/>}
        </Checkbox>
      </Form.Item>
      <Space>
        <Button type="primary" htmlType="submit">
          Enviar
        </Button>
        <Button type="default" onClick={onModalClose}>
          Cancelar
        </Button>
      </Space>
    </Form>
  );

  return (
    <>
      <Button
        block
        style={{
          paddingLeft: "2em",
          width: "250px",
          marginBottom: "20px",
          borderRadius: "10px",
          backgroundColor: "#66FF66",
          fontWeight: "bold",
          marginLeft: "30px",
        }}
        onClick={() => setIsModalOpen(true)}
      >
        Solicitar una cita
      </Button>
      <Modal
        style={{ top: 20 }}
        title="Formulario de contacto"
        visible={isModalOpen}
        onCancel={onModalClose}
        footer={<></>}
      >
        {solicitudRealizada && succesViewJsx}
        {!solicitudRealizada && formJsx}
      </Modal>
    </>
  );
};

export default ContactarInstitucionButton;
