import { useRequest } from "ahooks";
import { Select, SelectProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getComunas } from "./selectComunaRequests";
import { useEffect, useState } from "react";

interface SelectComunaProps extends SelectProps {
  regionId?: number;
  value?: number;
  onChange?: (value: any) => any;
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
}

const SelectComuna = ({
  regionId,
  value,
  onChange,
  ...props
}: SelectComunaProps) => {
  const [comunaId, setComunaId] = useState<number>()

  const { data: comunas, loading } = useRequest(
    async () => {
      return await getComunas(regionId);
    },
    { refreshDeps: [regionId] }
  );

  useEffect(() => {
    setComunaId(value)
  }, [value])



  return (
    <Select
      disabled={loading}
      value={value}
      onChange={onChange}
      placeholder={"Comuna"}
      suffixIcon={loading ? <LoadingOutlined /> : undefined}
      allowClear
      {...props}
    >
      {comunas?.map((r) => {
        return <Select.Option value={r.id}>{r.nombre}</Select.Option>;
      })}
    </Select>
  );
};

export default SelectComuna;
