import { useRequest } from "ahooks";
import { Form, FormInstance } from "antd";
import {
  requestIdiomasEducativos,
  requestNivelesEducativos,
} from "../../../../../commonRequests/params";
import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import { NivelEducativo } from "../../../../../types/insEdu.types";
import { IdiomaEducativo } from "../../../../listaInsEdu/types/SingleInstitucionEducativa.type";
import NivelEducativoDetalleInput from "./NivelEducativoDetalleInput";
import NivelesEducativosCheckBoxes from "./NivelesEducativosCheckBoxes";

interface NivelEducativoDetailsInputProps {
  form: FormInstance;
}

const NivelEducativoDetailsInput = ({
  form,
}: NivelEducativoDetailsInputProps) => {
  const nivelesEducativosIds = Form.useWatch<number[]>(
    "nivelesEducativosIds",
    form
  );

  const idiomasEducativosReq = useRequest(requestIdiomasEducativos);
  const nivelesEducativosReq = useRequest(requestNivelesEducativos);

  if (idiomasEducativosReq.loading) {
    return <LoadingSpinner />;
  }

  if (nivelesEducativosReq.loading) {
    return <LoadingSpinner />;
  }

  if (!nivelesEducativosReq.data) {
    return <></>;
  }

  if (!idiomasEducativosReq.data) {
    return <></>;
  }

  return (
    <>
      <NivelesEducativosCheckBoxes form={form} onChange={() => { }} />

      {nivelesEducativosIds?.sort().map((id) => (
        <NivelEducativoDetalleInput
          nivelEducativoId={id}
          nivelesEducativos={nivelesEducativosReq.data as NivelEducativo[]}
          idiomasEducativos={idiomasEducativosReq.data?.filter(i => i.esComun) as IdiomaEducativo[]}
        />
      ))}
    </>
  );
};

export default NivelEducativoDetailsInput;
