import { Col, Divider, Form, FormInstance, InputNumber, Row, Typography } from "antd";
import AntdConsts from "../../../../designConstants/antd-constants";
import { NivelesEducativosIds } from "../../../../types/instituciones.types";

interface ResultadosSimceInputProps {
  form: FormInstance;
}

const ResultadosSimceInput = ({ form }: ResultadosSimceInputProps) => {
  const nivelesIds = form.getFieldValue("nivelesEducativosIds") as number[];

  const incluyeEducacionBasica = nivelesIds?.includes(
    NivelesEducativosIds.EDUCACION_BASICA
  );
  const incluyeEducacionMedia = nivelesIds?.includes(
    NivelesEducativosIds.EDUCACION_MEDIA
  );

  if (!incluyeEducacionBasica && !incluyeEducacionMedia) {
    return <></>;
  }

  const simceInputBasica = (
    <>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Resultado matemática"
            name="resultadoSimceBasicaMatematica"
          >
            <InputNumber
              min={0}
              max={1000}
              style={{ width: "100%" }}
              placeholder="Resultado matemática"
            />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Resultado lenguaje"
            name="resultadoSimceBasicaLenguaje"
          >
            <InputNumber
              min={0}
              max={1000}
              style={{ width: "100%" }}
              placeholder="Resultado lenguaje"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const simceInputMedia = (
    <>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Resultado matemática"
            name="resultadoSimceMediaMatematica"
          >
            <InputNumber
              min={0}
              max={1000}
              style={{ width: "100%" }}
              placeholder="Resultado matemática"
            />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Resultado lenguaje"
            name="resultadoSimceMediaLenguaje"
          >
            <InputNumber
              min={0}
              max={1000}
              style={{ width: "100%" }}
              placeholder="Resultado lenguaje"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <Typography.Title level={3}>
        Resultados último SIMCE (si aplica)
      </Typography.Title>

      {incluyeEducacionBasica && (
        <>
          <Typography.Title level={5}>Simce educación básica</Typography.Title>
          {simceInputBasica}
        </>
      )}
      {incluyeEducacionMedia && (
        <>
          <Typography.Title level={5}>Simce educación media</Typography.Title>
          {simceInputMedia}
        </>
      )}
      <Divider />
    </>
  );
};

export default ResultadosSimceInput;
