import { useRequest } from "ahooks";
import { Alert, Space, Typography } from "antd";
import { useState } from "react";
import AuthReqs from "../../commonRequests/authRequests";
import ResendEmailVerificationButton from "../../components/ResendEmailVerificationButton";
import { useAuthStorage } from "../../hooks/useBrowserStorage";
import { AuthTokenPayload } from "../../types/auth.types";

const HeaderEmailNotVerifiedWarning = () => {
  //Para mostrar una notificación si el correo no ha sido verificado
  const [showEmailNotVerifiedWarning, setShowEmailNotVerifiedWarning] =
    useState(false);
  const auth = useAuthStorage(true) as AuthTokenPayload;

  useRequest(async () => {
    if (!auth) {
      return;
    }
    const emailVerified = await AuthReqs.reqCheckEmailVerified(auth.userId);
    if (emailVerified === false) {
      setShowEmailNotVerifiedWarning(true);
    }
  });

  if (!showEmailNotVerifiedWarning) {
    return <></>;
  }

  return (
    <Alert
      type="warning"
      showIcon
      message={
        <Space direction="horizontal" wrap>
          <Typography.Text>
            Su correo electrónico aún no ha sido verificado
          </Typography.Text>
          <ResendEmailVerificationButton />
        </Space>
      }
    />
  );
};

export default HeaderEmailNotVerifiedWarning;
