import { Col, Row } from "antd";
import AntdConsts from "../../../designConstants/antd-constants";
import useIsMobile from "../../../hooks/useMobile";
import useNavigateScrollTop from "../../../hooks/useNavigateScrollTop";
import { TipoInstitucionIds } from "../../../types/instituciones.types";
import "../css/CategoriasColegios.css";

export const colegiosIcon = require("../img/colegios.png");
export const JardinInfantilIcon = require("../img/jardin.png");
export const EscuelaLenguajeIcon = require("../img/lenguaje.png");
export const EscuelasLibresIcon = require("../img/eLibres.png");
export const colegioOnlineIcon = require("../img/colegioOnline.png");
export const academiaDeportivaIcon = require("../img/aDeportiva.png");
export const escuelaIdiomasIcon = require("../img/eIdiomas.png");
export const preuniversitarioIcon = require("../img/preu.png");
export const escuelaVeranoIcon = require("../img/eVerano.png");
export const guarderiaInfantilIcon = require("../img/guarderia.png");
export const academiaArtisticaIcon = require("../img/artist.png");
export const libroIcon = require("../img/libro.png");

function CategoriasPagPrincipal() {
  const isMobile = useIsMobile();
  const navigate = useNavigateScrollTop();


  const colSpan = { xs: 12, sm: 12, md: 8, lg: 8, xl: 4, xxl: 4 };

  return (
    <Row
      gutter={[20, 30]}
      // justify={"space-around"}
      align="middle"
      style={{ width: "100%", padding: "1em" }}
    >
      <Col {...AntdConsts.fullSizeColLayout}>
        <br/>
        <br/>
        <h3
          className="titleCat"
          style={isMobile ? { fontSize: "2.5em" } : undefined}
        >
          Búsqueda por <strong>Establecimiento</strong>
        </h3>
      </Col>

      <Col {...colSpan} style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              onClick={() =>
                navigate(
                  `/institucion-educativa/busqueda?tipo_institucion=${TipoInstitucionIds.COLEGIO}`
                )
              }
              src={colegiosIcon}
              className="categoria-icon"
              alt="Colegios"
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Colegios</label>
          </Col>
        </Row>
      </Col>

      <Col {...colSpan} style={{ textAlign: "center" }}>
        <Row justify="center">
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              onClick={() =>
                navigate(
                  `/institucion-educativa/busqueda?tipo_institucion=${TipoInstitucionIds.JARDIN_INFANTIL}`
                )
              }
              src={JardinInfantilIcon}
              className="categoria-icon"
              alt="Jardin Infantil"
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Jardín Infantil</label>
          </Col>
        </Row>
      </Col>

      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              onClick={() =>
                navigate(
                  `/institucion-educativa/busqueda?tipo_institucion=${TipoInstitucionIds.ESCUELA_LENGUAJE}`
                )
              }
              src={EscuelaLenguajeIcon}
              className="categoria-icon"
              alt="Escuelas de Lenguaje"
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Escuela de Lenguaje</label>
          </Col>
        </Row>
      </Col>

      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              onClick={() =>
                navigate(
                  `/institucion-educativa/busqueda?tipo_institucion=${TipoInstitucionIds.ESCUELA_LIBRE}`
                )
              }
              src={EscuelasLibresIcon}
              className="categoria-icon"
              alt="Escuelas Libres"
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Escuelas Libres</label>
          </Col>
        </Row>
      </Col>

      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              src={colegioOnlineIcon}
              onClick={() =>
                navigate(
                  `/institucion-educativa/busqueda?tipo_institucion=${TipoInstitucionIds.COLEGIO_ONLINE}`
                )
              }
              className="categoria-icon"
              alt="Colegios online"
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Colegios Online</label>
          </Col>
        </Row>
      </Col>

      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              style={{ marginTop: "35px" }}
              onClick={() =>
                navigate(
                  `/academia-deportiva/busqueda?tipo_institucion=${TipoInstitucionIds.ACADEMIA_DEPORTIVA}`
                )
              }
              src={academiaDeportivaIcon}
              className="categoria-icon"
              alt="Academias Deportivas"
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Academias Deportivas</label>
          </Col>
        </Row>
      </Col>

      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              src={escuelaIdiomasIcon}
              className="categoria-icon"
              alt="Escuela de idiomas"
              onClick={() =>
                navigate(
                  `/academia-idiomas/busqueda?tipo_institucion=${TipoInstitucionIds.ACADEMIA_IDIOMAS}`
                )
              }
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Instituto de idiomas</label>
          </Col>
        </Row>
      </Col>

      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              src={preuniversitarioIcon}
              className="categoria-icon"
              alt="Preuniversitario"
              onClick={() =>
                navigate(
                  `/preuniversitario/busqueda?tipo_institucion=${TipoInstitucionIds.PREUNIVERSITARIO}`
                )
              }
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Preuniversitario</label>
          </Col>
        </Row>
      </Col>
      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              src={escuelaVeranoIcon}
              className="categoria-icon"
              alt="Escuela de Verano"
              onClick={() => navigate(`/escuela-verano/busqueda`)}
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Escuela de Verano</label>
          </Col>
        </Row>
      </Col>

      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              src={guarderiaInfantilIcon}
              className="categoria-icon"
              alt="Escuela de Verano"
              onClick={() => navigate("/guarderia/busqueda")}
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Guardería Infantil</label>
          </Col>
        </Row>
      </Col>
      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              onClick={() =>
                navigate(`/academia-artistica/busqueda`)
              }
              src={academiaArtisticaIcon}
              className="categoria-icon"
              alt="Academia Artistica"
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Academias Artísticas</label>
          </Col>
        </Row>
      </Col>
      <Col {...colSpan} className="contIconos" style={{ textAlign: "center" }}>
        <Row>
          <Col {...AntdConsts.fullSizeColLayout}>
            <img
              style={{ marginTop: "30px" }}
              onClick={() => navigate(`/reforzamiento/busqueda`)}
              src={libroIcon}
              className="categoria-icon"
              alt="Reforzamiento"
            />
          </Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <label className="txtCategoria">Reforzamiento</label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CategoriasPagPrincipal;
