import { useRequest } from "ahooks";
import { Col, Row, Space, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../designConstants/antd-constants";
import InsEduContactInfo from "./components/InsEduContactInfo";
import reqSingleInsEdu from "./InsEduDetailViewRequests";
import InsEduCaracteristicas from "./components/test";
import ProgramaEducativo from "./components/ProgramaEducativo";
import NivelesHorararios from "./components/NivelesHoriarios";
import SingleInstitucionEducativaEasyVisualization from "./types/SingleInstitucionEducativaEasyVisualization.type";
import DatosPagoTable from "./components/DatosPagoTable";
import CaracteristicasYExtracurriculares from "./components/CaracteristicasYExtracurriculares";
import IdiomasEducativosTable from "./components/IdiomasEducativosTable";
import SimpleInfoTables from "./components/SimpleInfoTables";
import TablaSimce from "./components/TablaSimce";
import EmpresaUniformes from "./components/EmpresaUniformes";
import useIsMobile from "../../hooks/useMobile";
import HeaderModern from "../header/HeaderModern";
import InsEduDocumentos from "./components/InsEduDocumentos";
import CardContainer from "../../components/CardContainer";
import AnchorMenuInsEdu from "./components/AnchorMenuInsEdu";
import { CommonInstitucion } from "../../types/instituciones.types";
import "./InsEduDetailView.css";
import TablaVacantes from "./components/TablaVacantes";
import SingleInstitucionEducativa from "./types/SingleInsEdu.type";

import InstitucionDetailViewFirstRow from "../../components/InstitucionDetailViewFirstRow";
import AdminBannerOverDetailView from "../../components/AdminBannerOverDetailView";
import YoutubeEmbed from "../../components/YoutubeEmbed";

const InsEduDetailView = () => {
  const { id } = useParams();
  const isMobile = useIsMobile();

  const { data: singleInsEdu, loading } = useRequest(async () => {
    const singleInsEdu = await reqSingleInsEdu(+(id as string));
    if (!singleInsEdu) {
      cogoToast.error("Error al cargar datos de la intitución educativa");
    }
    document.title = `BUSCAESTUDIO - ${singleInsEdu?.nombre}`;
    return singleInsEdu;
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!singleInsEdu) {
    return <p>404</p>;
  }

  const nivelesInstitucionIds = singleInsEdu?.niveles.map((n) => n.id);

  const colSpan = (normalSpan: number) => ({
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: normalSpan,
    xxl: normalSpan,
  });

  return (
    <>
      <div>
        <HeaderModern affixTop />

        <AnchorMenuInsEdu />
        <div
          style={
            isMobile
              ? { paddingLeft: "0.5em", paddingRight: "0.5em" }
              : { paddingLeft: "16%", paddingRight: "1%" }
          }
        >
          <div
            style={{
              paddingBottom: "1em",
              // borderBottom: "1px solid grey",
            }}
          ></div>

          <Row gutter={AntdConsts.rowGutter}>
            <Col {...AntdConsts.fullSizeColLayout} id="mapa-ubicacion">
              <AdminBannerOverDetailView
                institucion={singleInsEdu as unknown as CommonInstitucion}
              />
            </Col>
            <Col
              {...AntdConsts.fullSizeColLayout}
              style={{ display: "flex" }}
              id="descripcion-institucion"
            >
              <InstitucionDetailViewFirstRow
                institucion={singleInsEdu as unknown as CommonInstitucion}
              />
            </Col>

            <Col
              {...colSpan(8)}
              style={{ display: "flex" }}
              id="contacto-institucion"
            >
              <CardContainer smallPadding>
                <InsEduContactInfo
                  insEdu={singleInsEdu}
                  className="insEduContactInfo"
                />
              </CardContainer>
            </Col>

            <Col
              {...colSpan(16)}
              style={{ display: "flex" }}
              id="caracteristicas-institucion"
            >
              <CardContainer smallPadding>
                <InsEduCaracteristicas insEdu={singleInsEdu} />
                {singleInsEdu?.empresaUniforme && (
                  <EmpresaUniformes insEdu={singleInsEdu} />
                )}
              </CardContainer>
            </Col>

            {singleInsEdu?.videoInstitucion && (
              <Col
                {...AntdConsts.fullSizeColLayout}
                style={{ display: "inline-block" }}
                id="video-institucional"
              >
                <CardContainer smallPadding>
                  {singleInsEdu?.videoInstitucion && (
                    <div>
                      <Typography.Title level={3}>
                        Vídeo Institucional
                      </Typography.Title>
                      <YoutubeEmbed url={singleInsEdu.videoInstitucion} />
                    </div>
                  )}
                </CardContainer>
              </Col>
            )}

            <Col
              {...AntdConsts.fullSizeColLayout}
              style={{ display: "flex" }}
              id="programa-educativo"
            >
              <ProgramaEducativo
                insEdu={singleInsEdu as SingleInstitucionEducativa}
              />
            </Col>

            <Col {...AntdConsts.fullSizeColLayout} id="caracteristicas-extra">
              <CaracteristicasYExtracurriculares
                insEdu={singleInsEdu as SingleInstitucionEducativa}
              />
            </Col>

            <Col
              {...AntdConsts.fullSizeColLayout}
              style={{ display: "flex" }}
              id="niveles-horarios"
            >
              <CardContainer smallPadding>
                <NivelesHorararios
                  insEdu={
                    singleInsEdu as SingleInstitucionEducativaEasyVisualization
                  }
                />
              </CardContainer>
            </Col>

            <Col
              {...AntdConsts.twoColLayout}
              style={{ display: "flex" }}
              id="datos-pago"
            >
              <CardContainer smallPadding>
                <DatosPagoTable
                  insEdu={
                    singleInsEdu as SingleInstitucionEducativaEasyVisualization
                  }
                />
              </CardContainer>
            </Col>

            <Col
              {...AntdConsts.twoColLayout}
              style={{ display: "flex" }}
              id="idiomas-educativos"
            >
              <CardContainer smallPadding>
                <IdiomasEducativosTable
                  insEdu={
                    singleInsEdu as SingleInstitucionEducativaEasyVisualization
                  }
                />
              </CardContainer>
            </Col>

            <Col {...AntdConsts.fullSizeColLayout} style={{ display: "flex" }}>
              <CardContainer smallPadding>
                <TablaSimce
                  insEdu={
                    singleInsEdu as SingleInstitucionEducativaEasyVisualization
                  }
                />
              </CardContainer>
            </Col>

            <Col {...AntdConsts.twoColLayout} style={{ display: "flex" }}>
              <CardContainer smallPadding>
                <Space
                  direction="vertical"
                  style={{ display: "flex" }}
                  size="large"
                >
                  <InsEduDocumentos
                    insEdu={
                      singleInsEdu as SingleInstitucionEducativaEasyVisualization
                    }
                  />
                </Space>
              </CardContainer>
            </Col>

            {(singleInsEdu.alianzas?.length !== 0 ||
              singleInsEdu.becas?.length !== 0 ||
              singleInsEdu.planesYProgramas?.length !== 0) && (
              <Col
                {...AntdConsts.twoColLayout}
                style={{ display: "flex" }}
                id="vinculacion-medio"
              >
                <CardContainer smallPadding>
                  <SimpleInfoTables insEdu={singleInsEdu} />
                </CardContainer>
              </Col>
            )}
            <Col {...AntdConsts.fullSizeColLayout}>
              <TablaVacantes
                insEdu={
                  singleInsEdu as SingleInstitucionEducativaEasyVisualization
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default InsEduDetailView;
