import { UploadFile } from "antd/lib/upload/interface";
import _ from "lodash";
import {
  CommonFilters,
  commonFiltersComparator,
  CommonInstitucion,
  compareComplexFilter,
} from "./instituciones.types";
import {
  Comuna,
  GeneroAlumnos,
  JornadaEscolar,
  ModalidadEstudio,
  Pais,
  Region,
} from "./params.type";

export interface DeportesAcademiaDeportiva {
  id: number;
  nombre: string;
  default: boolean;
}

export interface AcademiaDeportiva extends CommonInstitucion {
  id?: number;
  nombre: string;
  pais: Pais;
  region: Region;
  comuna: Comuna;
  direccion: string;
  telefono: string;
  paginaWeb: string;
  correo: string;
  facebook: string;
  whatsapp: string;
  twitter: string;
  instagram: string;
  descripcion: string;
  generoAlumnos: GeneroAlumnos;
  edadMinma: number;
  edadMaxima: number;
  precioMin: number;
  precioMax: number;
  jornada: JornadaEscolar;
  deportes: DeportesAcademiaDeportiva[];
  latitud: number;
  longitud: number;
  fotos: { id: number }[];
  videoInstitucional: string;
  modalidad: ModalidadEstudio;
}
export interface AcademiaDeportivaFilters extends CommonFilters {
  nombre?: string;
  deportes?: number[] | number;
}

export interface AcademiaDeportivaFiltersSelector {
  categoria: string;
  count: number;
}

export const academiaDeportivaFiltersComparator = (
  current: AcademiaDeportivaFilters | undefined,
  old: AcademiaDeportivaFilters | undefined
) => {
  if (!current) {
    return !old ? true : false;
  }

  old = old as AcademiaDeportivaFilters;

  const isEqual =
    current.nombre === old.nombre &&
    compareComplexFilter(current.deportes, old.deportes) &&
    commonFiltersComparator(current, old);

  return isEqual;
};

export interface AcademiaDeportivaCreateObject {
  nombre: string;
  paisId: number;
  regionId: number;
  correo: string;
  comunaId: number;
  direccion: string;
  descripcion: string;
  generoAlumnosId: number;
  jornadaId: number;
  telefono: string;
  paginaWeb?: string;
  instagram?: string;
  facebook?: string;
  whatsapp?: string;
  twitter?: string;
  videoInstitucional?: string;
  modalidadEstudioId: number;
  precioMin: number;
  precioMax: number;
  edadMinma: number;
  edadMaxima: number;
  fotos: { id: number }[];
  deportes: { id: number }[];
  deportesCustom: string[]
}

export interface AcademiaDeportivaUpdateObject
  extends AcademiaDeportivaCreateObject {
  id: number;
  descripcionEdicion?: string
}

export interface AcademiaDeportivaFormData {
  nombre: string;
  paisId: number;
  regionId: number;
  comunaId: number;
  direccion: string;
  descripcion: string;
  correo: string;
  generoAlumnosId: number;
  jornadaId: number;
  telefono: string;
  paginaWeb?: string;
  instagram?: string;
  facebook?: string;
  whatsapp?: string;
  twitter?: string;
  videoInstitucional?: string;
  precioMin: number;
  precioMax: number;
  edadMinma: number;
  edadMaxima: number;
  modalidadEstudioId: number;
  defaultImage: UploadFile<{ id: number }>[];
  restOfImages: UploadFile<{ id: number }>[];
  deportes: number[];
  deportesCustom: string[]
}
