import { Space, Input, Button } from "antd";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { CommonFilters } from "../../types/instituciones.types";

export interface NombreFilterSelectorProps {
  currentFilters: CommonFilters;
  setFilters?: React.Dispatch<React.SetStateAction<any>>;
}

const NombreFilterSelector = ({
  currentFilters,
  setFilters,
}: NombreFilterSelectorProps) => {
  const [searchString, setSearchString] = useState<string>();

  return (
    <Space direction="vertical">
      <label htmlFor="buscar_por_nombre" style={{ fontSize: "1em" }}>
        Nombre
      </label>
      <Space direction="horizontal">
        <Input
          placeholder="Buscar por nombre"
          id="buscar_por_nombre"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <Button
          onClick={() => {
            setFilters?.({ ...currentFilters, nombre: searchString });
          }}
          type="primary"
          icon={<FaSearch />}
        />
      </Space>
    </Space>
  );
};

export default NombreFilterSelector;
