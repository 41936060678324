import { useNavigate } from "react-router-dom"

const useNavigateScrollTop = () => {
  const navigate = useNavigate();

  return (url: string) => {
    window.scrollTo(0, 0);
    navigate(url)
    window.scrollTo(0, 0);
  }
}

export default useNavigateScrollTop;