import { useDeepCompareEffect, useRequest } from "ahooks";
import { Button, Col, Form, Input, Modal, Row, Space, Table } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import VendedorRequest from "../../../commonRequests/vendedores.req";
import SelectComuna from "../../../components/Input/SelectComuna/SelectComuna";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import SelectRegion from "../../../components/SelectRequest/SelectRegion";
import AntdConsts from "../../../designConstants/antd-constants";
import { Vendedor, VendedorFilters } from "../../../types/vendedores.type";
import VendedorForm from "./VendedorForm";
import VendedoresTableActions from "./VendedoresTableActions";

const VendedoresTable = () => {
  const [filters, setFilters] = useState<VendedorFilters>({});
  const [regionId, setRegionId] = useState<number>();

  const [vendorFormModal, setVendorFormModal] = useState<boolean>(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const vendedoresReq = useRequest(async () => {
    const response = await VendedorRequest.getMany(filters);
    if (!response) {
      cogoToast.error("No fue posible obtener la lista de vendedores");
      return [] as Vendedor[];
    }
    return response;
  });

  useDeepCompareEffect(() => {
    vendedoresReq.refreshAsync();
  }, [filters]);

  if (vendedoresReq.loading) {
    return <LoadingSpinner />;
  }

  const dataSource = vendedoresReq.data?.map((v) => ({
    key: v.id,
    ...v,
    comuna: v.comuna.nombre,
    region: v.region.nombre,
  }));

  const onSearch = (newFilters: VendedorFilters) => {
    setFilters(newFilters);
  };

  const onResetFields = () => {
    form.resetFields();
    setFilters({});
  };

  const vendedorTableColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}`),
      }),
    },
    {
      title: "Rut",
      dataIndex: "rut",
      key: "rut",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}`),
      }),
    },
    {
      title: "Telefono",
      dataIndex: "telefono",
      key: "telefono",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}`),
      }),
    },
    {
      title: "Correo",
      dataIndex: "correo",
      key: "correo",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}`),
      }),
    },
    {
      title: "Región",
      dataIndex: "region",
      key: "region",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}`),
      }),
    },
    {
      title: "Comuna",
      dataIndex: "comuna",
      key: "comuna",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}`),
      }),
    },
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}`),
      }),
    },
    {
      title: "Acciones",
      render: (_: any, record: Vendedor) => {
        return (
          <VendedoresTableActions key={record.id} vendedorId={record.id} />
        );
      },
    },
  ];

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onSearch}>
        <Row gutter={AntdConsts.rowGutter}>
          <Col {...AntdConsts.fourColLayout}>
            <Form.Item label="Nombre" name="nombre">
              <Input />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.fourColLayout}>
            <Form.Item name="regionId" label="Región">
              <SelectRegion onChange={setRegionId} />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.fourColLayout}>
            <Form.Item name="comunaId" label="Comuna">
              <SelectComuna regionId={regionId} />
            </Form.Item>
          </Col>
          <Col {...AntdConsts.fourColLayout}>
            <Form.Item label={" "}>
              <Button type="link" onClick={onResetFields}>
                Limpiar filtros
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={" "}>
              <Space direction="horizontal">
                <Button block type="primary" htmlType="submit">
                  Buscar
                </Button>
                <Button
                  block
                  htmlType="submit"
                  onClick={() => setVendorFormModal(true)}
                >
                  Crear nuevo vendedor
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        style={{ cursor: "pointer" }}
        size="small"
        bordered
        columns={vendedorTableColumns}
        dataSource={dataSource as any}
      />
      <Modal
        title="Crear nuevo vendedor"
        visible={vendorFormModal}
        onCancel={() => setVendorFormModal(false)}
        onOk={() => setVendorFormModal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ block: true, style: { display: "none" } }}
        cancelText="Cancelar"
      >
        <VendedorForm />
      </Modal>
    </>
  );
};

export default VendedoresTable;
