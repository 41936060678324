import { Typography } from "antd";
import GoogleMapReact from "google-map-react";
import { MdLocationOn } from "react-icons/md";

interface MapInsEduProps {
  longitude: number;
  latitude: number;
  zoomLevel?: number;
  width?: number | string;
  height?: number | string;
}

const LocationPin = ({
  text,
}: {
  text?: string;
  lat?: number;
  lng?: number;
}) => (
  <div className="pin">
    <MdLocationOn color="red" size={"5em"} /> <p>{text}</p>
  </div>
);

const MapInsEdu = ({ longitude, latitude, zoomLevel }: MapInsEduProps) => {
  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY as string }}
        defaultCenter={{ lat: latitude, lng: longitude }}
        defaultZoom={zoomLevel || 14}
        style={{ height: "500px" }}
      >
        <LocationPin lat={latitude} lng={longitude} />
      </GoogleMapReact>
    </>
  );
};

export default MapInsEdu;
