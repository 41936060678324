import { useRequest } from "ahooks";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import { CSSProperties } from "react";
import { requestExtraprogramaticas } from "../../../../commonRequests/params";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";
import useIsMobile from "../../../../hooks/useMobile";
import { CategoriaExtraprogramaticas } from "../../../../types/insEdu.types";
import AddCustomExtracurricular from "./AddCustomExtracurricular";

const ExtracurricularesInput = () => {
  const isMobile = useIsMobile();
  const extraprogramaticasReq = useRequest(requestExtraprogramaticas);

  if (extraprogramaticasReq.loading) {
    return <LoadingSpinner />;
  }

  const extraprogramaticas = extraprogramaticasReq.data;

  if (!extraprogramaticas) {
    return <></>;
  }

  const rowGutter: [number, number] = [7, 7];

  const rowStyle: CSSProperties = {
    borderStyle: "ridge",
    borderWidth: "0.1",
    borderColor: "var(--pencilBK)",
    padding: "1em",
  };

  const titleStyle: CSSProperties = { marginTop: "1em" };

  const extraprogramaticasJsx = (
    <>
      <Form.Item name={"extraprogramaticas"}>
        <Checkbox.Group>
          <Typography.Title level={4} style={titleStyle}>
            ARTE
          </Typography.Title>
          <Row gutter={rowGutter} style={rowStyle}>
            {extraprogramaticas
              .filter((c) => c.categoriaId === CategoriaExtraprogramaticas.ARTE)
              .map((c) => (
                <Col {...AntdConsts.fourColLayout}>
                  <Checkbox value={c.id}>{c.nombre}</Checkbox>
                </Col>
              ))}
          </Row>

          <Typography.Title level={4} style={titleStyle}>
            DEPORTES
          </Typography.Title>
          <Row gutter={rowGutter} style={rowStyle}>
            {extraprogramaticas
              .filter(
                (c) => c.categoriaId === CategoriaExtraprogramaticas.DEPORTE
              )
              .map((c) => (
                <Col {...AntdConsts.fourColLayout}>
                  <Checkbox value={c.id}>{c.nombre}</Checkbox>
                </Col>
              ))}
          </Row>

          <Typography.Title level={4} style={titleStyle}>
            CULTURAL
          </Typography.Title>
          <Row gutter={rowGutter} style={rowStyle}>
            {extraprogramaticas
              .filter(
                (c) => c.categoriaId === CategoriaExtraprogramaticas.CULTURAL
              )
              .map((c) => (
                <Col {...AntdConsts.fourColLayout}>
                  <Checkbox value={c.id}>{c.nombre}</Checkbox>
                </Col>
              ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <AddCustomExtracurricular />
    </>
  );

  return (
    <Row style={isMobile ? { marginLeft: "1em" } : undefined}>
      <Col>
        <Typography.Title level={3}>
          Actividades extraprogramáticas
        </Typography.Title>
        <Typography.Paragraph>
          Seleccione aquellas actividades extraprogramáticas con las que cuente
          su establecimiento
        </Typography.Paragraph>
        <Row>{extraprogramaticasJsx}</Row>
      </Col>
    </Row>
  );
};

export default ExtracurricularesInput;
