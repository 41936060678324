import { Space, Button, Popconfirm, Row, Col } from "antd";
import {
  CheckSquareOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import {
  changeUserVerificationStatus,
  reqDeleteUser,
} from "../../../commonRequests/authRequests";
import cogoToast from "cogo-toast";
import AntdConsts from "../../../designConstants/antd-constants";

interface UsuariosTableActionsProps {
  userId: number;
  initialVerificationStatus: boolean;
}

const UsuariosTableActions = ({
  userId,
  initialVerificationStatus,
}: UsuariosTableActionsProps) => {
  const [isVerified, setIsVerified] = useState(initialVerificationStatus);
  const [isVerifiedLoading, setIsVerifiedLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const onChangeVerificationStatus = async () => {
    const response = await changeUserVerificationStatus(userId, !isVerified);
    setIsVerifiedLoading(true);
    if (!response) {
      cogoToast.error("No fue posible modificar el usuario");
      return;
    }
    cogoToast.success("Estado de verificación modificado correctamente");
    setIsVerified(response.verificado);
    setIsVerifiedLoading(false);
  };

  const onDelete = async () => {
    const response = await reqDeleteUser(userId);
    setIsDeletingLoading(true);
    if (!response) {
      cogoToast.error("No fue posible eliminar el usuario");
      return;
    }
    cogoToast.success("Usuario eliminado correctamente");
    setIsDeletingLoading(false);
    setIsDeleted(true);
  };

  if (isDeleted) {
    return (
      <Row justify="center">
        <b>USUARIO ELIMINADO</b>
      </Row>
    );
  }

  return (
    <Space style={{ width: "100%" }}>
      <Popconfirm
        title="Cambiar verificación del usuario?"
        okButtonProps={{ block: true, style: { marginTop: "0.5em" } }}
        cancelButtonProps={{ block: true }}
        okText="Confirmar"
        cancelText="Cancelar"
        onConfirm={onChangeVerificationStatus}
      >
        <Button
          block
          type="ghost"
          icon={
            isVerifiedLoading ? <LoadingOutlined /> : <CheckSquareOutlined />
          }
        >
          {isVerified ? "Revocar Verificación" : "Verificar"}
        </Button>
      </Popconfirm>

      <Popconfirm
        title="Eliminar usuario? Las instituciones creadas por este usuario dejaran de ser visibles en el sistema"
        onConfirm={onDelete}
        okButtonProps={{ block: true, style: { marginTop: "0.5em" } }}
        cancelButtonProps={{ block: true }}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <Button
          block
          type="primary"
          icon={isDeletingLoading ? <LoadingOutlined /> : <DeleteOutlined />}
        >
          Eliminar
        </Button>
      </Popconfirm>
    </Space>
  );
};

export default UsuariosTableActions;
