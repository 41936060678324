import queryString from "query-string";
import { InstitucionEducativaFilters } from "../types/filtros.type";

export const FilterObjectToUrlParams = (filters: any) => {
  let params: string[] = [];
  const filterKeys = Object.keys(filters);

  for (const key of filterKeys) {
    const filter = filters[key as keyof any];
    if (!filter) {
      continue;
    }
    if (Array.isArray(filter)) {
      filter.forEach((f) => {
        params.push(`${key}=${f}`);
      });
    }
    else if (!Number.isNaN(+filter)) {
      params.push(`&${key}=${filter}`);
    } else {
      params.push(`&${key}=${filter}`);
    }
  }

  if (params.length === 0) {
    return "";
  }
  const result = "&" + params.join("&");
  return result;
};

export const getFilterObjectFromUrlParams = <T>(
  searchParams: URLSearchParams
) => {
  const paramsKeys = Array.from(searchParams.keys());
  // Sin repetidos
  const keys = paramsKeys.filter((c, index) => {
    return paramsKeys.indexOf(c) === index;
  });

  let filterObject: { [key: string]: any } = {};
  for (const key of keys) {
    filterObject[key] = searchParams
      .getAll(key)
      .map((v) => (Number.isNaN(+v) ? v : +v));
  }

  return filterObject as T;
};

export const parseUrlQueryInsEdu = () => {
  const query: any = queryString.parse(window.location.search);
  const parseArrayFilter = (filter?: string[]) => {
    if (!filter) {
      return undefined;
    }
    if (!Array.isArray(filter)) {
      return [+filter]
    }
    return filter?.map((id) => +id)
  };
  const parseNumberFilter = (filter?: number) => (filter ? +filter : undefined);

  return {
    nivel_escolar: parseArrayFilter(query.nivel_escolar),
    tipo_dependencia: parseArrayFilter(query.tipo_dependencia),
    tipo_educacion: parseArrayFilter(query.tipo_educacion),
    orientacion_religiosa: parseArrayFilter(query.orientacion_religiosa),
    idiomas: parseArrayFilter(query.idiomas),
    region_id: parseNumberFilter(query.region_id),
    comuna_id: parseNumberFilter(query.comuna_id),
    geo_direccion: query.geo_direccion,
    genero_alumnos: parseArrayFilter(),
    modalidad_estudios: parseArrayFilter(),
    tipo_institucion: parseArrayFilter(query.tipo_institucion),
    nombre: query.nombre,
    mensualidad: (query.mensualidad_min !== undefined || query.mensualidad_max !== undefined) ? {
      min: parseNumberFilter(query.mensualidad_min),
      max: parseNumberFilter(query.mensualidad_max),
    }: undefined,
  };
};

export const formatDistance = (distanceInMeters: number): string => {
  if (!distanceInMeters) {
    return "";
  }

  if (distanceInMeters < 500) {
    return `~${500} Mts`;
  }

  if (distanceInMeters > 1000) {
    return `${(distanceInMeters / 1000).toFixed(1)} KM`;
  }

  return `${distanceInMeters.toFixed(0)} Mts`;
};
