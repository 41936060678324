import { Affix, Anchor, Typography } from "antd";
import AntdConsts from "../../../../designConstants/antd-constants";
import useIsMobile from "../../../../hooks/useMobile";

//Tightly coupled with VistaDetalleAcademiaIdiomasPage
const AnchorMenuAcademiaDeportes = () => {
  const isMobile = useIsMobile();
  return (
    <div style={{ float: "left", display: isMobile ? "none" : undefined }}>
      {/* <Affix offsetTop={AntdConsts.headerHeight + 20}> */}
        <Typography.Title level={4} style={{ marginLeft: "1em" }}>
          Secciones
        </Typography.Title>
        <Anchor
          style={{ marginLeft: "3em" }}
          offsetTop={AntdConsts.headerHeight + 20}
          targetOffset={AntdConsts.headerHeight + 30}
        >
          <Anchor.Link
            href="#descripcion-institucion"
            title="Descripción de la institución"
          />
          <Anchor.Link href="#contacto-institucion" title="Contacto" />
          <Anchor.Link
            href="#caracteristicas-institucion"
            title="Caracteristicas"
          />
          <Anchor.Link href="#caracteristicas-institucion" title="Idiomas" />
          <Anchor.Link href="#video-institucion" title="Video institucional" />
          <Anchor.Link href="#mapa-ubicacion" title="Mapa ubicación" />
        </Anchor>
      {/* </Affix> */}
    </div>
  );
};

export default AnchorMenuAcademiaDeportes;
