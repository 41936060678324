import { useRequest } from "ahooks";
import { BackTop, Col, PageHeader, Row, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useNavigate, useParams } from "react-router-dom";

import { requestSingleAcademiaDeportiva } from "../../../commonRequests/academiaDeportesRequest";
import AdminBannerOverDetailView from "../../../components/AdminBannerOverDetailView";
import CardContainer from "../../../components/CardContainer";
import ContactInfo from "../../../components/ContactInfo";
import InstitucionDetailViewFirstRow from "../../../components/InstitucionDetailViewFirstRow";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import AntdConsts from "../../../designConstants/antd-constants";
import { youTubeIdFromLink } from "../../../designConstants/antd-utils";
import useIsMobile from "../../../hooks/useMobile";
import { AcademiaDeportiva } from "../../../types/academiaDeportiva.type";
import HeaderModern from "../../header/HeaderModern";
import AcademiaDeportivaCaracteristicas from "./components/AcademiaDeportivaCaracteristicas";
import AnchorMenuAcademiaDeportes from "./components/AnchorMenuAcademiaDeportes";

const VistaDetalleAcademiaDeportesPage = () => {
  const { id } = useParams();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { data: academiaDeportiva, loading } = useRequest(async () => {
    const response = await requestSingleAcademiaDeportiva(+(id as string));
    if (!response) {
      cogoToast.error("Error al cargar datos de la intitución educativa");
    }
    document.title = `BUSCAESTUDIO - ${response?.nombre}`;
    return response;
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!academiaDeportiva) {
    return <p>404</p>;
  }

  const colSpan = (normalSpan: number) => ({
    xs: 24,
    sm: 24,
    md: 24,
    lg: normalSpan,
    xl: normalSpan,
    xxl: normalSpan,
  });

  return (
    <>
      <div className="detalleCol">
        <HeaderModern affixTop />
        <AnchorMenuAcademiaDeportes />
        <PageHeader
          className="site-page-header"
          onBack={() => navigate("/academia-deportiva/busqueda")}
          title="Volver a busqueda de Academias deportivas"
        />
        <div
          style={
            isMobile
              ? { padding: "0.5em" }
              : { paddingLeft: "16%", paddingRight: "3%" }
          }
        >
          
          <Row gutter={AntdConsts.rowGutter}>
            <Col {...AntdConsts.fullSizeColLayout}>
              <AdminBannerOverDetailView institucion={academiaDeportiva} />
            </Col>
            <Col {...AntdConsts.fullSizeColLayout}>
              <InstitucionDetailViewFirstRow institucion={academiaDeportiva} />
            </Col>

            <Col
              {...colSpan(12)}
              style={{ display: "flex" }}
              id="contacto-institucion"
            >
              <CardContainer smallPadding>
                <ContactInfo institucion={academiaDeportiva} />
              </CardContainer>
            </Col>
            <Col
              {...colSpan(12)}
              style={{ display: "flex" }}
              id="caracteristicas-institucion"
            >
              <CardContainer smallPadding>
                <AcademiaDeportivaCaracteristicas
                  academiaDeportiva={academiaDeportiva as AcademiaDeportiva}
                />
              </CardContainer>
            </Col>

            <Col
              {...colSpan(24)}
              style={{ display: "flex" }}
              id="video-institucion"
            >
              {academiaDeportiva?.videoInstitucional && (
                <CardContainer smallPadding>
                  <Typography.Title level={3}>
                    Video institucional
                  </Typography.Title>
                  <YoutubeEmbed url={academiaDeportiva.videoInstitucional} />
                </CardContainer>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <BackTop />
    </>
  );
};

export default VistaDetalleAcademiaDeportesPage;
