import { Table, Typography } from "antd";
import SingleInstitucionEducativaEasyVisualization from "../types/SingleInstitucionEducativaEasyVisualization.type";

interface TablaVacanteseProps {
  insEdu: SingleInstitucionEducativaEasyVisualization;
}

const TablaVacantes = ({ insEdu }: TablaVacanteseProps) => {
  const renderedDataSource = insEdu.tablaVacantes.dataSource.map((v) => {
    return {
      ...v,
      nivelEducativo: v.anioEscolar,
      vacantes: `${v.vacantes.min} - ${v.vacantes.max}`,
    };
  });

  return (
    <>
      <Typography.Title level={3}>VACANTES</Typography.Title>
      <Table
        bordered
        pagination={false}
        columns={insEdu.tablaVacantes.headers}
        dataSource={renderedDataSource}
      />
    </>
  );
};

export default TablaVacantes;
