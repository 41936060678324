import { useRequest } from "ahooks";
import { Col, Modal, Pagination, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useCustomCompareEffect } from "use-custom-compare";
import cogoToast from "cogo-toast";
import AntdConsts from "../../../designConstants/antd-constants";
import getManyInsEdu from "../buscadorInsEduRequests";
import InsEduSimpleView from "./InsEduSimpleView";
import CardLoading from "../../../components/CardLoading";
import AdsBanner from "../../../components/AdsBanner";
import useGeo from "../../../hooks/useGeo";
import {
  insEduFilterComparator,
  InstitucionEducativaFilters,
} from "../../../types/filtros.type";
import { useParams } from "react-router-dom";
import { parseUrlQueryInsEdu } from "../../../utils/filterUtils";
import QuitarUbicacionButton from "../../../components/QuitarUbicacionButton";
import Swal from "sweetalert2";
import PopUpBusquedas from "../../../components/PopUpBusquedas";

interface BuscadorInsEduProps {
  filters?: InstitucionEducativaFilters;
  setFilters?: (filter: InstitucionEducativaFilters) => any;
}

const ListaInsEduPage = ({ filters, setFilters }: BuscadorInsEduProps) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(filters?.take || 50);

  const geolocation = useGeo();

  const params = useParams();

  //El objetivo de este effect es recargar la pagina cuando la query de la url cambie
  useEffect(() => {
    setFilters?.({
      ...filters,
      tipo_institucion: parseUrlQueryInsEdu()?.tipo_institucion,
    });
  }, [params, setFilters]);

  const {
    data: manyInsEdu,
    loading,
    runAsync: loadMoreInsEdu,
  } = useRequest(
    async () => {
      const insEduList = await getManyInsEdu({
        ...filters,
        take: pageSize,
        skip: (page - 1) * pageSize,
        latitud: geolocation?.latitude,
        longitud: geolocation?.longitude,
      });
      if (insEduList === null) {
        cogoToast.error("No se pudo cargar la información solicitada");
      }

      return insEduList;
    },
    { manual: true }
  );

  useCustomCompareEffect(
    () => {
      loadMoreInsEdu();
    },
    [filters],
    (prevDeps, nextDeps) => insEduFilterComparator(nextDeps[0], prevDeps[0])
  );

  const onPageChange = async (newPage: number, newPageSize: number) => {
    window.scrollTo(0, 0);
    setFilters?.({
      ...filters,
      take: pageSize,
      skip: (newPage - 1) * pageSize,
    });
    setPage(newPage);
    setPageSize(pageSize);
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <>
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
      </>
    );
  }

  if (!manyInsEdu) {
    return (
      <div>
        No se encontraron instituciones educativas. <QuitarUbicacionButton />
      </div>
    );
  }

  const cardSpans = { xs: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

  if (manyInsEdu.items.length === 0) {
    return (
      <>
        <Row gutter={AntdConsts.rowGutter} justify="center">
          <Typography.Text>
            {PopUpBusquedas()}
            {window.scrollTo(0, 0)}
            No se encontraron instituciones educativas.
          </Typography.Text>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row gutter={AntdConsts.rowGutter} justify="center">
        {manyInsEdu.items.map((insEdu, index) => {
          const showAd = index % 4 === 0 && index !== 0;
          return (
            <>
              {showAd && (
                <Col {...AntdConsts.fullSizeColLayout}>
                  <AdsBanner />
                </Col>
              )}
              <Col {...cardSpans} style={{ display: "flex" }}>
                <InsEduSimpleView
                  key={insEdu.id}
                  insEdu={insEdu}
                  geoLocation={geolocation}
                />
              </Col>
            </>
          );
        })}
      </Row>
      <Pagination
        style={{ backgroundColor: "whitesmoke", padding: "1em", zIndex: 1000 }}
        onChange={onPageChange}
        total={manyInsEdu.total}
        current={page}
        pageSize={pageSize}
        responsive
      />
      {/* <Affix offsetBottom={10}>
        
      </Affix> */}
    </>
  );
};

export default ListaInsEduPage;
