import {
  Card,
  Col,
  Descriptions,
  List,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { EnrichedGeolocationCoordinates } from "react-hook-geolocation";
import { MdLocationPin } from "react-icons/md";
import {
  FaChalkboardTeacher,
  FaDirections,
  FaMapSigns,
  FaMoneyBillAlt,
  FaSchool,
} from "react-icons/fa";
import cogoToast from "cogo-toast";
import { HomeOutlined } from "@ant-design/icons";
import currencyFormatter from "../../../../utils";
import CardContainer from "../../../../components/CardContainer";
import AntdConsts from "../../../../designConstants/antd-constants";
import { Preuniversitario } from "../../../../types/preuniversitario.type";
import IconoTablas from "../../../../components/iconosTablas/IconoTablas";
import { formatDistance } from "../../../../utils/filterUtils";

interface PreuniversitarioSimpleViewProps {
  preuniversitario: Preuniversitario;
  geoLocation?: EnrichedGeolocationCoordinates;
  className?: string;
}

const PreuniversitarioSimpleView = ({
  preuniversitario,
  geoLocation,
}: PreuniversitarioSimpleViewProps) => {
  //

  const geoDistance = preuniversitario?.distance;

  const info = (
    <>
      {geoDistance && (
        <Space direction="horizontal">
          <FaDirections color="blue" fontSize={"1.5em"} />
          <Typography.Text>
            {`A ${formatDistance(geoDistance)} de su ubicación`}
          </Typography.Text>
        </Space>
      )}
      <Descriptions
        size="small"
        title="INFORMACIÓN"
        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        bordered
        layout="horizontal"
      >
        <Descriptions.Item
          label={
            <Space size={4}>
              <MdLocationPin style={{ color: "blue", fontSize: "1.5em" }} />
              Dirección
            </Space>
          }
        >
          {preuniversitario.direccion}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size={4}>
              <FaMapSigns style={{ color: "blue", fontSize: "1.5em" }} />
              Dirección
            </Space>
          }
        >
          {preuniversitario.comuna.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size={4}>
              <HomeOutlined style={{ color: "blue", fontSize: "1.5em" }} />
              Modalidad
            </Space>
          }
        >
          {preuniversitario.modalidad.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size={4}>
              <FaMoneyBillAlt style={{ color: "blue", fontSize: "1.5em" }} />
              Precio
            </Space>
          }
        >{`${currencyFormatter.format(
          preuniversitario.precioMin
        )} - ${currencyFormatter.format(
          preuniversitario.precioMax
        )}`}</Descriptions.Item>

        <Descriptions.Item
          span={2}
          label={
            <Space size={4}>
              <FaSchool style={{ color: "blue", fontSize: "1.5em" }} />
              Niveles
            </Space>
          }
        >
          <Space style={{ marginTop: "1em" }} wrap>
            {preuniversitario.niveles.map((a) => (
              <Tag style={{ marginBottom: "5px" }} color="cyan">
                <Typography.Text>{a.nombre}</Typography.Text>
              </Tag>
            ))}
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  return (
    <CardContainer noPadding>
      <Card
        className="small-card-body-padding"
        title={preuniversitario.nombre.toUpperCase()}
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open(`/preuniversitario/${preuniversitario.id}`, "_blank")
        }
        cover={
          <img
          style={{ aspectRatio: "1 / 1", overflow: "hidden",position:"relative" , minHeight:"100%", maxHeight: "100%", minWidth: "100%", maxWidth: "100%" }}
            alt="example"
            src={`${process.env.REACT_APP_API_URL}instituciones/images/${preuniversitario.fotos?.[0].id}`}
          />
        }
      >
        <Row style={{ marginBottom: 0 }}>
          <Col {...AntdConsts.fullSizeColLayout}>{info}</Col>
          <Col
            {...AntdConsts.fullSizeColLayout}
            style={{ marginBottom: 0, paddingBottom: 0 }}
          >
            <List
              style={{
                width: "100%",
                marginTop: 2,
                height: "100%",
                minHeight: "150px",
              }}
              size="small"
              header={<b>{"ASIGNATURAS"}</b>}
              grid={{
                xs: 2,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 3,
              }}
              dataSource={preuniversitario.asignaturas}
              bordered
              renderItem={(c) => (
                <List.Item style={{ margin: 0 }} key={c.id}>
                  <Space align="center">
                    <IconoTablas nombreIcono={c.nombre} />
                    <Typography.Text>{c.nombre.toUpperCase()}</Typography.Text>
                  </Space>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
    </CardContainer>
  );
};

export default PreuniversitarioSimpleView;
