import { useRequest } from "ahooks";
import { BackTop, Col, Row, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useParams } from "react-router-dom";

import { requestSingleAcademiaIdiomas } from "../../../commonRequests/academiaIdiomasRequest";
import AdminBannerOverDetailView from "../../../components/AdminBannerOverDetailView";
import CardContainer from "../../../components/CardContainer";
import ContactInfo from "../../../components/ContactInfo";
import InstitucionDetailViewFirstRow from "../../../components/InstitucionDetailViewFirstRow";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import AntdConsts from "../../../designConstants/antd-constants";
import { youTubeIdFromLink } from "../../../designConstants/antd-utils";
import useIsMobile from "../../../hooks/useMobile";
import { AcademiaIdiomas } from "../../../types/academiaIdiomas.type";
import HeaderModern from "../../header/HeaderModern";
import AcademiaIdiomasCaracteristicas from "./components/AcademiaIdiomasCaracteristicas";
import AnchorMenuAcademiaDeportes from "./components/AnchorMenuAcademiaDeportes";

const VistaDetalleAcademiaIdiomasPage = () => {
  const { id } = useParams();
  const isMobile = useIsMobile();

  const { data: academiaIdiomas, loading } = useRequest(async () => {
    const response = await requestSingleAcademiaIdiomas(+(id as string));
    if (!response) {
      cogoToast.error("Error al cargar datos de la intitución educativa");
    }
    document.title = `BUSCAESTUDIO - ${response?.nombre}`;
    return response;
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!academiaIdiomas) {
    return <p>404</p>;
  }

  return (
    <div className="detalleCol">
      <HeaderModern affixTop />
      <AnchorMenuAcademiaDeportes />

      <div
        style={
          isMobile
            ? { padding: "0.5em" }
            : { paddingLeft: "16%", paddingRight: "3%" }
        }
      >
        <BackTop />

        <Row gutter={AntdConsts.rowGutter}>
          <Col {...AntdConsts.fullSizeColLayout}>
            <br />
            <AdminBannerOverDetailView institucion={academiaIdiomas} />
          </Col>

          <Col {...AntdConsts.fullSizeColLayout}>
            <InstitucionDetailViewFirstRow institucion={academiaIdiomas} />
          </Col>

          <Col {...AntdConsts.twoColLayout} style={{ display: "flex" }}>
            <CardContainer>
              <ContactInfo institucion={academiaIdiomas} />
            </CardContainer>
          </Col>

          <Col {...AntdConsts.twoColLayout} style={{ display: "flex" }}>
            <CardContainer>
              <AcademiaIdiomasCaracteristicas
                academiaIdiomas={academiaIdiomas as AcademiaIdiomas}
              />
            </CardContainer>
          </Col>



          {/* <Col {...AntdConsts.fullSizeColLayout} style={{ display: "flex" }}>
            <CardContainer>
              <Typography.Title level={3}>Video institucional</Typography.Title>
              <YoutubeEmbed url={academiaIdiomas.videoInstitucional} />
            </CardContainer>
          </Col> */}

            {academiaIdiomas?.videoInstitucional && (
              <Col
                {...AntdConsts.fullSizeColLayout}
                style={{ display: "inline-block" }}
                id="video-institucional"
              >
                <CardContainer smallPadding>
                  {academiaIdiomas?.videoInstitucional && (
                    <div>
                      <Typography.Title level={3}>
                        Vídeo Institucional
                      </Typography.Title>
                      <YoutubeEmbed url={academiaIdiomas.videoInstitucional} />
                    </div>
                  )}
                </CardContainer>
              </Col>
            )}

        </Row>
      </div>
    </div>
  );
};

export default VistaDetalleAcademiaIdiomasPage;
