import { FormInstance } from "antd";
import { baseUrl } from "../../../commonRequests/axiosInstance";
import { transformUploadFilesToFormData } from "../../../designConstants/antd-utils";
import {
  AcademiaIdiomas,
  AcademiaIdiomasCreateObject,
  AcademiaIdiomasFormData,
} from "../../../types/academiaIdiomas.type";

export const transformPayloadAcademiaIdiomas = (
  form: FormInstance<AcademiaIdiomasFormData>
): AcademiaIdiomasCreateObject => {
  const data: AcademiaIdiomasFormData = form.getFieldsValue(true);

  const fotos = transformUploadFilesToFormData(form);

  return {
    nombre: data.nombre,
    paisId: data.paisId,
    regionId: data.regionId,
    correo: data.correo,
    comunaId: data.comunaId,
    direccion: data.direccion,
    descripcion: data.descripcion,
    generoAlumnosId: data.generoAlumnosId,
    jornadaId: data.jornadaId,
    telefono: data.telefono,
    paginaWeb: data.paginaWeb,
    instagram: data.instagram,
    facebook: data.facebook,
    whatsapp: data.whatsapp,
    twitter: data.twitter,
    videoInstitucional: data.videoInstitucional,
    modalidadEstudioId: data.modalidadEstudioId,
    precioMin: data.precioMin,
    precioMax: data.precioMax,
    edadMinma: data.edadMinma,
    edadMaxima: data.edadMaxima,
    idiomasExtra: data?.idiomasExtra,
    fotos,
    idiomas: data.idiomas.map((id) => ({ id })),
  };
};

export const academiaIdiomasToFormData = (
  academia: AcademiaIdiomas
): AcademiaIdiomasFormData => {
  return {
    nombre: academia.nombre,
    paisId: academia.pais.id,
    regionId: academia.region.id,
    correo: academia.correo,
    comunaId: academia.comuna.id,
    direccion: academia.direccion,
    descripcion: academia.descripcion,
    generoAlumnosId: academia.generoAlumnos.id,
    jornadaId: academia.jornada.id,
    telefono: academia.telefono,
    paginaWeb: academia.paginaWeb,
    instagram: academia.instagram,
    facebook: academia.facebook,
    whatsapp: academia.whatsapp,
    twitter: academia.twitter,
    videoInstitucional: academia.videoInstitucional,
    modalidadEstudioId: academia.modalidad.id,
    precioMin: academia.precioMin,
    precioMax: academia.precioMax,
    edadMinma: academia.edadMinma,
    edadMaxima: academia.edadMaxima,
    idiomas: academia.idiomas?.filter(i=> i.esComun === true).map((i) => i.id),
    idiomasExtra: academia.idiomas?.filter(i=> i.esComun === false).map((i) => i.nombre).join("\n"),
    defaultImage: [
      {
        uid: "0",
        name: `default.png`,
        status: "done",
        response: academia?.fotos?.[0],
        url: `${baseUrl}instituciones/images/${academia?.fotos?.[0]?.id}`,
      },
    ],
    restOfImages: academia.fotos.slice(1).map((f, index) => ({
      uid: "rest" + index.toString(),
      name: `${index}.png`,
      status: "done",
      response: f,
      url: `${baseUrl}instituciones/images/${f?.id}`,
    })),
  };
};
