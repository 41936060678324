import { Col, Form, FormInstance, Input, Row, Select, Typography } from "antd";
import { useState } from "react";
import SelectComuna from "../../../../components/Input/SelectComuna/SelectComuna";
import SelectTipoInsEdu from "../../../../components/Input/SelectTipoInstitucion/SelectTipoInstitucion";
import SelectRegion from "../../../../components/SelectRequest/SelectRegion";
import AntdConsts from "../../../../designConstants/antd-constants";

const DatosBasicosForm = ({ form }: { form: FormInstance }) => {
  const [regionId, setRegionId] = useState<number>(
    form.getFieldValue("regionId")
  );
  const [comunaId, _] = useState<number>(form.getFieldValue("comunaId"));

  return (
    <>
      <Typography.Title level={2}>Datos Básicos</Typography.Title>
      <Form.Item
        label="Nombre Institución"
        name="nombre"
        rules={[{ required: true }]}
      >
        <Input placeholder="Nombre" />
      </Form.Item>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="RBD"
            name="rbd"
            // {...AntdConsts.twoColsFormRow}
          >
            <Input placeholder="RBD" />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="País"
            name="paisId"
            rules={[{ required: true }]}
            initialValue={1}
          >
            <Select placeholder="País" disabled>
              <Select.Option value={1}>Chile</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Región"
            name="regionId"
            rules={[{ required: true }]}
          >
            <SelectRegion placeholder="Región" onChange={setRegionId} />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Comuna"
            name="comunaId"
            rules={[{ required: true }]}
            initialValue={comunaId}
          >
            <SelectComuna
              value={comunaId}
              placeholder="Comuna"
              regionId={regionId}
            />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.fullSizeColLayout}>
          <Form.Item
            label="Dirección"
            name="direccion"
            rules={[{ required: true }]}
          >
            <Input placeholder="Dirección" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Tipo de institución"
        name="tipoInstitucionId"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <SelectTipoInsEdu disabled placeholder="Tipo institución" />
      </Form.Item>
      <Form.Item
        label="Nombre del director(a)"
        name="director"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Input placeholder="Nombre director" />
      </Form.Item>
      <Form.Item
        label="Descripción"
        name="descripcion"
        rules={[{ required: true }]}
      >
        <Input.TextArea
          placeholder="Descripción breve del establecimiento"
          rows={5}
        />
      </Form.Item>
    </>
  );
};

export default DatosBasicosForm;
