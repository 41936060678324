import { Col, Descriptions, List, Row, Space, Typography } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChildren,
  faClock,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { AcademiaDeportiva } from "../../../../types/academiaDeportiva.type";
import AntdConsts from "../../../../designConstants/antd-constants";
import currencyFormatter from "../../../../utils";
import { BsCheckLg } from "react-icons/bs";
import IconoTablas from "../../../../components/iconosTablas/IconoTablas";

interface TestProps {
  academiaDeportiva: AcademiaDeportiva;
  className?: string;
}

const academiaDeportivaCaracteristicas = ({ academiaDeportiva }: TestProps) => {
  return (
    <>
      <Descriptions
        labelStyle={{ backgroundColor: "var(--labelsDescription)" }}
        contentStyle={{ backgroundColor: "white" }}
        style={{ color: "black" }}
        title="CARACTERÍSTICAS"
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
        size="default"
      >
        <Descriptions.Item
          label={
            <Space>
              <FontAwesomeIcon
                style={{ color: "#1f3de7", fontSize: "25px" }}
                icon={faChildren}
              />
              {"  "}
              Género
            </Space>
          }
        >
          {academiaDeportiva?.generoAlumnos?.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FontAwesomeIcon
                style={{ color: "#1f3de7", fontSize: "25px" }}
                icon={faChildren}
              />
              {"  "}
              Edades
            </Space>
          }
        >
          {`${academiaDeportiva.edadMinma} - ${academiaDeportiva.edadMaxima} Años`}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FontAwesomeIcon
                style={{ color: "#1f3de7", fontSize: "25px" }}
                icon={faDollarSign}
              />
              {"     "}
              Precio
            </Space>
          }
        >{`${currencyFormatter.format(
          academiaDeportiva?.precioMin as number
        )} - ${currencyFormatter.format(
          academiaDeportiva?.precioMax as number
        )}`}</Descriptions.Item>
        <Descriptions.Item
          label={
            <Space>
              <FontAwesomeIcon
                style={{ color: "#1f3de7", fontSize: "25px" }}
                icon={faClock}
              />
              {"  "}
              Jornada
            </Space>
          }
        >
          {"Mañana o tarde"}
        </Descriptions.Item>
      </Descriptions>

      <List
        size="small"
        header={<b>{"DEPORTES"}</b>}
        grid={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={academiaDeportiva.deportes}
        bordered
        renderItem={(c) => (
          <List.Item style={{ margin: 0 }} key={c.id}>
            <div>
              <Space>
                <IconoTablas nombreIcono={c.nombre} />
                <Typography.Text>{c.nombre.toUpperCase()}</Typography.Text>
              </Space>
            </div>
          </List.Item>
        )}
        style={{ marginTop: "2em", width: "100%" }}
      />
    </>
  );
};

export default academiaDeportivaCaracteristicas;
