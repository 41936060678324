import { Button, Descriptions, Divider, Modal, Typography } from "antd";
import { useState } from "react";
import SingleInstitucionEducativa from "../types/SingleInsEdu.type";

interface EmpresaUniformesProps {
  insEdu: SingleInstitucionEducativa;
  className?: string;
}

const EmpresaUniformes = ({ insEdu }: EmpresaUniformesProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        style={{
          borderRadius: "5px",
          backgroundColor: "#BBFF99",
          fontWeight: "bold",
          marginTop: "2em",
        }}
        block
        onClick={() => setIsOpen(true)}
      >
        Contactar empresa de uniformes
      </Button>
      <Modal
        title="Empresas de uniformes"
        visible={isOpen}
        onOk={() => setIsOpen(false)}
        okText="Cerrar"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {insEdu.empresaUniforme.map((e, index) => (
          <>
            <Typography.Title level={4}>{`Empresa uniformes ${
              index + 1
            }`}</Typography.Title>

            <Descriptions
              // title="Responsive Descriptions"
              column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Nombre">{e.nombre}</Descriptions.Item>
              <Descriptions.Item label="Dirección">
                {e.direccion}
              </Descriptions.Item>
              <Descriptions.Item label="Teléfono">
                {e.telefono}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                <a href={`mailto:${e.email}`}>{e.email}</a>
              </Descriptions.Item>
              <Descriptions.Item label="Presencia Online">
                <a href={e.linkContacto} target="_blank" rel="noreferrer">
                  {e.linkContacto}
                </a>
              </Descriptions.Item>
            </Descriptions>
            <Divider />
          </>
        ))}
      </Modal>
    </>
  );
};

export default EmpresaUniformes;
