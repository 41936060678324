import { Button, Result, Row, Space, Typography } from "antd";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import HeaderModern from "../views/header/HeaderModern";
import ResendEmailVerificationButton from "./ResendEmailVerificationButton";

interface UserNotReadyToPostPageProps {
  userVerified?: boolean;
  emailVerified?: boolean;
  couldNotVerifyUser?: boolean;
}

const UserNotReadyToPostPage = ({
  userVerified,
  emailVerified,
  couldNotVerifyUser,
}: UserNotReadyToPostPageProps) => {
  const navigate = useNavigate();

  let message: ReactElement = <></>;

  if (!emailVerified) {
    message = (
      <Space direction="vertical">
        <div>
          <Typography.Text style={{textAlign: "justify" }}>
            Su E-mail aún no ha sido verficado, por favor revisé su bandeja de
            correos electrónicos. (Recuerde revisar en la sección de spam)
          </Typography.Text>
        </div>
        <ResendEmailVerificationButton block size="large"/>
      </Space>
    );
  } else if (!userVerified) {
    message = (
      <Space direction="vertical">
        <div>
          <Typography.Text>
            Su cuenta está siendo revisada por nuestros administradores. Te
            avisaremos por correo electrónico cuando este proceso haya
            terminado.
          </Typography.Text>
        </div>
      </Space>
    );
  }

  return (
    <>
      <HeaderModern affixTop />
      <Row justify="center" style={{ width: "100%" }}>
        <Result
          status="403"
          title="Sin autorización"
          subTitle={message}
          extra={
            <Button size="large" type="primary" onClick={() => navigate("/")}>
              Volver al inicio
            </Button>
          }
        />
      </Row>
    </>
  );
};

export default UserNotReadyToPostPage;
