import { useRequest } from "ahooks";
import { Col, Row, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useParams } from "react-router-dom";
import { reqSingleAcademiaArtistica } from "../../../commonRequests/academiaArtisticasRequests";
import AdminBannerOverDetailView from "../../../components/AdminBannerOverDetailView";
import CardContainer from "../../../components/CardContainer";
import ContactInfo from "../../../components/ContactInfo";
import InstitucionDetailViewFirstRow from "../../../components/InstitucionDetailViewFirstRow";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import AntdConsts from "../../../designConstants/antd-constants";
import useIsMobile from "../../../hooks/useMobile";
import { AcademiaArtistica } from "../../../types/academiaArtistica.type";
import HeaderModern from "../../header/HeaderModern";
import AcademiaArtisticaCaracteristicas from "./components/AcademiaArtisticaCaracteristicas";
import AnchorMenuAcademiaArtistica from "./components/AnchorMenuAcademiaArtistica";

const VistaDetalleAcademiaArtisticaPage = () => {
  const { id } = useParams();
  const isMobile = useIsMobile();

  const { data: academiaArtistica, loading } = useRequest(async () => {
    const response = await reqSingleAcademiaArtistica(+(id as string));
    if (!response) {
      cogoToast.error("Error al cargar datos de la intitución educativa");
    }
    document.title = `BUSCAESTUDIO - ${response?.nombre}`;
    return response;
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!academiaArtistica) {
    return <p>404</p>;
  }

  return (
    <div className="detalleCol">
      <HeaderModern affixTop />
      <AnchorMenuAcademiaArtistica />

      <div
        style={
          isMobile
            ? { padding: "0.5em" }
            : { paddingLeft: "20%", paddingRight: "3%" }
        }
      >            

        <Row gutter={AntdConsts.rowGutter}>
          <Col {...AntdConsts.fullSizeColLayout}>
            <AdminBannerOverDetailView
              institucion={academiaArtistica}
            />
          </Col>
          <Col
            {...AntdConsts.fullSizeColLayout}
            style={{ display: "flex" }}
            id="descripcion-institucion"
          >
            <InstitucionDetailViewFirstRow
              institucion={academiaArtistica}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={AntdConsts.rowGutter}>
          <CardContainer>
            <Row gutter={AntdConsts.rowGutter}>
              <Col {...AntdConsts.twoColLayout} id="contacto-institucion">
                <ContactInfo
                  institucion={academiaArtistica}
                />
              </Col>
              <Col
                {...AntdConsts.twoColLayout}
                id="caracteristicas-institucion"
              >
                <AcademiaArtisticaCaracteristicas
                  academiaArtistica={academiaArtistica as AcademiaArtistica}
                />
              </Col>
            </Row>
          </CardContainer>
          <CardContainer>
            <Typography.Title id="video-institucion" level={3}>Video institucional</Typography.Title>
            <YoutubeEmbed url={academiaArtistica.videoCorporativo} />
          </CardContainer>
        </Row>
      </div>
    </div>
  );
};

export default VistaDetalleAcademiaArtisticaPage;
