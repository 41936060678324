import { Button, Col, Input, Row, Tooltip } from "antd";
import cogoToast from "cogo-toast";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SelectTipoInsEdu from "../../../components/Input/SelectTipoInstitucion/SelectTipoInstitucion";
import AntdConsts from "../../../designConstants/antd-constants";
import useNavigateScrollTop from "../../../hooks/useNavigateScrollTop";
import {
  CommonFilters,
  InsEduTiposIds,
  TipoInstitucionIds,
} from "../../../types/instituciones.types";
import { FilterObjectToUrlParams } from "../../../utils/filterUtils";
import HeaderModern from "../../header/HeaderModern";
import "../css/BusquedaNombreEstablecimientos.css";

function BusquedaNombreEstablecimiento() {
  const [tipoInsEduId, setTipoInsEdu] = useState<number>();

  const [nombreEstablecimiento, setNombreEstablecimiento] = useState<string>();

  const navigate = useNavigateScrollTop();

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  const onSearch = () => {

    if (!tipoInsEduId) {
      cogoToast.info("Debe seleccionar un tipo de establecimiento");
      return;
    }

    const filterObj: CommonFilters = {
      tipo_institucion: tipoInsEduId,
      nombre: nombreEstablecimiento,
    };

    try {
      if (InsEduTiposIds.includes(tipoInsEduId as number)) {
        navigate(
          `/institucion-educativa/busqueda?${FilterObjectToUrlParams({
            ...filterObj,
          })}`
        );
      } else if (tipoInsEduId === TipoInstitucionIds.ACADEMIA_DEPORTIVA) {
        navigate(
          `/academia-deportiva/busqueda?${FilterObjectToUrlParams({
            ...filterObj,
          })}`
        );
      } else if (tipoInsEduId === TipoInstitucionIds.ACADEMIA_IDIOMAS) {
        navigate(
          `/academia-idiomas/busqueda?${FilterObjectToUrlParams({
            ...filterObj,
          })}`
        );
      } else if (tipoInsEduId === TipoInstitucionIds.ACADEMIA_ARTISTICA) {
        navigate(
          `/academia-artistica/busqueda?${FilterObjectToUrlParams({
            ...filterObj,
          })}`
        );
      } else if (tipoInsEduId === TipoInstitucionIds.PREUNIVERSITARIO) {
        navigate(
          `/preuniversitario/busqueda?${FilterObjectToUrlParams({
            ...filterObj,
          })}`
        );
      } else if (tipoInsEduId === TipoInstitucionIds.REFORZAMIENTO) {
        navigate(
          `/reforzamiento/busqueda?${FilterObjectToUrlParams({
            ...filterObj,
          })}`
        );
      } else if (tipoInsEduId === TipoInstitucionIds.ESCUELA_VERANO) {
        navigate(
          `/escuela-verano/busqueda?${FilterObjectToUrlParams({
            ...filterObj,
          })}`
        );
      } else if (tipoInsEduId === TipoInstitucionIds.GUARDERIA_INFANTIL) {
        navigate(
          `/guarderia/busqueda?${FilterObjectToUrlParams({
            ...filterObj,
          })}`
        );
      }
    } catch (error) {
      navigate(
        `/institucion-educativa/busqueda?${FilterObjectToUrlParams(filterObj)}`
      );
    }
  };

  return (
    <>
      <HeaderModern hideSearchbar />
      <div id="buscadores">
        <div className="contbusquedas">
          <br />
          <br />
          <br />
          <br />
          <br />
          <Row justify="center" align="bottom">
            <Col {...AntdConsts.fullSizeColLayout}>
              <h2 className="titleBusNom">
                Ingrese el <strong>Nombre</strong> del establecimiento para
                comenzar a <strong>buscar:</strong>
              </h2>
            </Col>
          </Row>
          <div className="contenedorBarrasDebusqueda">
            <Row gutter={AntdConsts.rowGutter} justify={"space-between"}>
              <Col {...AntdConsts.threeColLayout}>
                <label className="labels">Tipo establecimiento:</label>
                <SelectTipoInsEdu
                  size="large"
                  onChange={setTipoInsEdu}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col {...AntdConsts.threeColLayout}>
                <label className="labels">Nombre:</label>
                <Input
                  onChange={(e) => setNombreEstablecimiento(e.target.value)}
                  disabled={!tipoInsEduId}
                  size="large"
                  placeholder="Nombre del Establecimiento"
                />
              </Col>
              <Col {...AntdConsts.threeColLayout}>
                <Tooltip title="Buscar" className="botonBuscar">
                  <br />
                  <br />
                  <label className="labels"> </label>
                  <Button
                    style={{ height: "2em" }}
                    type="primary"
                    shape="circle"
                    icon={<FaSearch style={{ fontSize: "25px" }} />}
                    block
                    onClick={onSearch}
                  />
                </Tooltip>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusquedaNombreEstablecimiento;
