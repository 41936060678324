import { Col, ConfigProvider, Divider, Row } from "antd";
import SponsoredCarousel from "../../components/SponsoredCarousel";
import HeaderModern from "../header/HeaderModern";
import InfoImages from "./components/InfoImages";
import MainBannerHome from "./components/MainBannerHome";
import PresentationCards from "./components/PresentationCards";
import "./css/HomeScreen.css";

function HomeScreen() {
  return (
    <>
      <HeaderModern hideSearchbar />

      <Row gutter={[0, 30]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <MainBannerHome />
        </Col>
      </Row>

      <Row align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <ConfigProvider componentSize="large">
            <PresentationCards />
          </ConfigProvider>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[0, 30]} justify="space-around">
        <Col xs={24} sm={24} md={24} lg={24} xl={21}>
          <SponsoredCarousel />
        </Col>
      </Row>

      <br />
      <Divider />

      <Row gutter={[0, 30]} justify="space-around">
        <Col xs={24} sm={24} md={24} lg={24} xl={21}>
          <InfoImages />
        </Col>
      </Row>
    </>
  );
}

export default HomeScreen;
