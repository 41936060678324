import { useRequest } from "ahooks";
import { Checkbox, Col, Divider, Form, Row, Typography } from "antd";
import { CSSProperties } from "react";
import { requestCaracteristicasExtra } from "../../../../commonRequests/params";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";
import useIsMobile from "../../../../hooks/useMobile";
import { CategoriaCaractersiticasExtra } from "../../../../types/insEdu.types";
import AddCustomCaracteristicasExtra from "./AddCustomCaracteristicasExtra";

const CaracteristicasExtraInput = () => {
  const isMobile = useIsMobile();
  const caracteristicasExtraReq = useRequest(requestCaracteristicasExtra);

  if (caracteristicasExtraReq.loading) {
    return <LoadingSpinner />;
  }

  const caracteristicas = caracteristicasExtraReq.data;

  if (!caracteristicas) {
    return <></>;
  }

  const rowGutter: [number, number] = [7, 7];

  const rowStyle: CSSProperties = {
    borderStyle: "ridge",
    borderWidth: "0.1",
    borderColor: "var(--pencilBK)",
    padding: "1em",
  };

  const titleStyle: CSSProperties = { marginTop: "1em" };

  const caracteristicasJsx = (
    <>
      <Form.Item name={"caracteristicasExtra"}>
        <Checkbox.Group>
          <Typography.Title level={4} style={titleStyle}>
            Servicios
          </Typography.Title>
          <Row gutter={rowGutter} style={rowStyle}>
            {caracteristicas
              .filter(
                (c) => c.categoriaId === CategoriaCaractersiticasExtra.SERVICIOS
              )
              .map((c) => (
                <Col {...AntdConsts.fourColLayout}>
                  <Checkbox value={c.id}>{c.nombre}</Checkbox>
                </Col>
              ))}
          </Row>

          <Typography.Title level={4} style={titleStyle}>
            Apoyo
          </Typography.Title>
          <Row gutter={rowGutter} style={rowStyle}>
            {caracteristicas
              .filter(
                (c) => c.categoriaId === CategoriaCaractersiticasExtra.APOYO
              )
              .map((c) => (
                <Col {...AntdConsts.fourColLayout}>
                  <Checkbox value={c.id}>{c.nombre}</Checkbox>
                </Col>
              ))}
          </Row>

          <Typography.Title level={4} style={titleStyle}>
            Infraestructura
          </Typography.Title>
          <Row gutter={rowGutter} style={rowStyle}>
            {caracteristicas
              .filter(
                (c) =>
                  c.categoriaId ===
                  CategoriaCaractersiticasExtra.INFRAESTRUCTURA
              )
              .map((c) => (
                <Col {...AntdConsts.fourColLayout}>
                  <Checkbox value={c.id}>{c.nombre}</Checkbox>
                </Col>
              ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <AddCustomCaracteristicasExtra />
    </>
  );

  return (
    <Row style={isMobile ? { marginLeft: "1em" } : undefined}>
      <Col>
        <Typography.Title level={3}>Características extras</Typography.Title>
        <Typography.Paragraph>
          Seleccione aquellas características con las que cuente su
          establecimiento
        </Typography.Paragraph>
        <Row>{caracteristicasJsx}</Row>
      </Col>
    </Row>
  );
};

export default CaracteristicasExtraInput;
