import { useRequest } from "ahooks";
import { List } from "antd";
import FiltersReq from "../../../../../commonRequests/filters.req";
import CardContainer from "../../../../../components/CardContainer";
import MobileFiltersWrapper from "../../../../../components/FilterSelector/MobileFilterWrapper";
import NombreFilterSelector from "../../../../../components/FilterSelector/NombreFilterSelector";
import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import useIsMobile from "../../../../../hooks/useMobile";
import { AcademiaArtisticaFilters } from "../../../../../types/filtros.type";
import currencyFormatter from "../../../../../utils";

interface FilterSelectorProps {
  currentFilters: AcademiaArtisticaFilters;
  setFilters?: (filters: AcademiaArtisticaFilters) => any;
}

const AcademiaArtisticaFilterSelector = ({
  currentFilters,
  setFilters,
}: FilterSelectorProps) => {
  const filterSelectorReq = useRequest(() =>
    FiltersReq.getFiltersAcademiaArtistica()
  );

  const isMobile = useIsMobile();

  if (filterSelectorReq.loading) {
    return <LoadingSpinner />;
  }

  if (!filterSelectorReq.data) {
    return <p>Error, no fue posible obtener los filtros</p>;
  }

  const filterSelectors = filterSelectorReq.data;

  const onFilterClick = (
    filterId: number,
    category: keyof AcademiaArtisticaFilters
  ) => {
    const filter = currentFilters[category] as number[] | undefined;

    if (!filter) {
      setFilters?.({ ...currentFilters, [category]: [filterId] });
      return;
    }
    if (!filter?.includes(filterId)) {
      setFilters?.({ ...currentFilters, [category]: [...filter, filterId] });
      return;
    }

    const newFilter = filter.filter((id) => id !== filterId);
    setFilters?.({
      ...currentFilters,
      [category]: newFilter.length === 0 ? undefined : newFilter,
    });
    return;
  };

  const onQuantityFilterClick = (
    min: number,
    max: number,
    key: keyof AcademiaArtisticaFilters
  ) => {
    if (checkNumberFilterRange(min, max)) {
      setFilters?.({
        ...currentFilters,
        [key]: undefined,
      });

      return;
    }

    setFilters?.({
      ...currentFilters,
      [key]: { min, max },
    });
  };

  const checkNumberFilterRange = (min: number, max: number) => {
    if (!currentFilters.mensualidad) {
      return;
    }

    if (
      (!currentFilters.mensualidad?.min &&
        currentFilters.mensualidad.min !== 0) ||
      !currentFilters.mensualidad.max
    ) {
      return false;
    }

    return (
      min <= currentFilters.mensualidad.min &&
      max >= currentFilters.mensualidad.max
    );
  };

  const filters = (
    <>
      <NombreFilterSelector
        setFilters={setFilters}
        currentFilters={currentFilters}
      />

      <List
        header={<b>{filterSelectors.artes.title}</b>}
        dataSource={filterSelectors.artes.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.artes?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "artes")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.instrumentos.title}</b>}
        dataSource={filterSelectors.instrumentos.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.instrumentos?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "instrumentos")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.modalidadesEstudio.title}</b>}
        dataSource={filterSelectors.modalidadesEstudio.filters}
        renderItem={(filter) => (
          <List.Item
            className={
              currentFilters?.modalidad_estudios?.includes(filter.id)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() => onFilterClick(filter.id, "modalidad_estudios")}
          >
            {`${filter.name}`}
          </List.Item>
        )}
      />

      <List
        header={<b>{filterSelectors.mensualidad.title}</b>}
        dataSource={filterSelectors.mensualidad.filters.slice(0, -1)}
        renderItem={(filter) => (
          <List.Item
            className={
              checkNumberFilterRange(filter.min, filter.max)
                ? "selected-filter"
                : "filters-row"
            }
            style={{ marginLeft: "1em", cursor: "pointer" }}
            onClick={() =>
              onQuantityFilterClick(filter.min, filter.max, "mensualidad")
            }
          >
            {`${currencyFormatter.format(
              filter.min
            )} - ${currencyFormatter.format(filter.max)}`}
          </List.Item>
        )}
      >
        <List.Item
          className={
            currentFilters.mensualidad?.min &&
            currentFilters.mensualidad?.min > 600000
              ? "selected-filter"
              : "filters-row"
          }
          style={{ marginLeft: "1em", cursor: "pointer" }}
          onClick={() => {
            if (
              !currentFilters.mensualidad ||
              !currentFilters.mensualidad.min
            ) {
              setFilters?.({
                ...currentFilters,
                mensualidad: { min: 600001, max: undefined },
              });
              return;
            }

            if (currentFilters.mensualidad.min > 600000) {
              setFilters?.({
                ...currentFilters,
                mensualidad: undefined,
              });
            } else {
              setFilters?.({
                ...currentFilters,
                mensualidad: { min: 600001, max: undefined },
              });
            }
          }}
        >
          600.000 +
        </List.Item>
      </List>
    </>
  );

  if (isMobile) {
    return <MobileFiltersWrapper>{filters}</MobileFiltersWrapper>;
  }

  return <CardContainer smallPadding>{filters}</CardContainer>;
};

export default AcademiaArtisticaFilterSelector;
