import { UploadFile } from "antd/lib/upload/interface";
import {
  TipoDependencia,
  OrientacionReligiosa,
  IdiomaEducativo,
} from "../views/InsEduDetailView/types/SingleInsEdu.type";
import { Image } from "./Images.type";
import { CommonFilters, CommonInstitucion } from "./instituciones.types";
import {
  TipoInstitucion,
  GeneroAlumnos,
  Comuna,
  Region,
  Pais,
} from "./params.type";

export interface InstitucionEducativaMany extends CommonInstitucion {
  id: number;
  nombre: string;
  rbd: string;
  tipoEducacion: { id: number; nombre: string };
  descripcion: string;
  tipoDependencia: TipoDependencia;
  tipoInstitucion: TipoInstitucion;
  generoAlumnos: GeneroAlumnos;
  nivelesEducativos: { id: number; nombre: string }[];
  orientacionReligiosa: OrientacionReligiosa;
  idiomasEducativos: IdiomaEducativo[];
  rangoPrecios: { min: number; max: number };
  direccion: string;
  comuna: Comuna;
  region: Region;
  pais: Pais;
  alumnosPorCurso: number;
  mision: string;
  latitud: number;
  longitud: number;
  hidden: boolean;
  needsReview: boolean;
  createdAt: string; //ISO TIME
  updatedAt: string; //ISO TIME
  descripcionEdicion?: string;
  fotos: Image[];
}

export default InstitucionEducativaMany;

export interface GetManyInsEduResponse {
  total: number;
  items: InstitucionEducativaMany[];
}

export interface GetManyInsEduResponseWithFilters {
  total: number;
  items: InstitucionEducativaMany[];
  Filters: any;
}

export interface InsEduFilters extends CommonFilters {
  nombre?: string;
  tipo_dependencia?: number[] | number;
  genero_alumnos?: number[] | number;
  niveles_educativos?: number[] | number;
  tipo_educacion?: number[] | number;
  idioma_educativo?: number[] | number;
  orientacion_religiosa?: number[] | number;
  alumnos_curso_min?: number;
  alumnos_curso_max?: number;
  mensualidad_min?: number;
  mensualidad_max?: number;
  comuna_id?: number;
  region_id?: number;
  pais_id?: number;
  tipo_institucion?: number[] | number;
}

export interface InsEduCreateObject {
  paisId: number;
  comunaId: number;
  nombre: string;
  rbd: string;
  regionId: number;
  direccion: string;
  director: string;
  descripcion: string;
  telefono: string;
  email: string;
  paginaWeb: string;
  modalidadEstudioId: number;
  instagram: string;
  facebook: string;
  whatsapp: string;
  twitter: string;
  videoInstitucion: string;
  descripcionEdicion?: string;
  mision: string;
  vision: string;
  tipoDependenciaId: number;
  orientacionReligiosaId: number;
  cantAlumnosPorCurso: number;
  alumnosMatriculados: number;
  generoAlumnosId: number;
  tipoEducacionId: number;
  nivelesEducativosIds: number[];
  nivelesEducativos: NivelesEducativoCreateObj[];
  caracteristicasExtra: { id: number }[];
  caracteristicasCustom: string[];
  extraprogramaticas: { id: number }[];
  extracurricularCustom: string[];
  alianzas: string[];
  becas: string[];
  tipoInstitucionId: number;
  planesYProgramas: string[];
  resultadoSimceBasicaMatematica?: number;
  resultadoSimceBasicaLenguaje?: number;
  resultadoSimceMediaMatematica?: number;
  resultadoSimceMediaLenguaje?: number;
  fotos: { id: number }[];
  empresaUniformes?: EmpresaUniforme[];
  documents: {
    reglamentoInternoFile?: { id: number };
    reglamentoEvaluacionFile?: { id: number };
    protocolaConvivenciaFile?: { id: number };
    listadoUtilesFile?: { id: number };
    protocoloCovidFile?: { id: number };
  };
}

export interface CreateInsEduFormData
  extends Omit<
    InsEduCreateObject,
    | "nivelesEducativos"
    | "fotos"
    | "documents"
    | "caracteristicasExtra"
    | "extraprogramaticas"
  > {
  nivelesEducativosIds: number[];
  defaultImage: UploadFile<{ id: number }>[];
  restOfImages: UploadFile<{ id: number }>[];
  extraprogramaticas: number[];
  caracteristicasExtra: number[];
  nivelesEducativos: (NivelesEducativoFormData | null)[];
  documents: {
    reglamentoInternoFile?: UploadFile;
    reglamentoEvaluacionFile?: UploadFile;
    protocolaConvivenciaFile?: UploadFile;
    listadoUtilesFile?: UploadFile;
    protocoloCovidFile?: UploadFile;
  };
  descripcionEdicion?: string;
  resultadoSimceBasicaMatematica?: number,
  resultadoSimceBasicaLenguaje?: number,
  resultadoSimceMediaMatematica?: number,
  resultadoSimceMediaLenguaje?: number,
}

export interface InsEduUpdateObject extends InsEduCreateObject {
  id: number;
}

export interface NivelesEducativoCreateObj {
  id: number;
  opcionHorarioExtendido: boolean;
  jornadaEscolarId: number;
  idiomasEducativos: number[];
  aniosEscolares?: AniosEscolaresCreateObj[];
  numeroCuotas: number;
  costoMensualidad: number;
  costoMatricula: number;
}

export interface NivelesEducativoFormData {
  id: number;
  opcionHorarioExtendido: boolean;
  jornadaEscolarId: number;
  idiomasEducativos: number[];
  aniosEscolares: (AniosEscolaresCreateObj | null)[];
  numeroCuotas: number;
  costoMensualidad: number;
  costoMatricula: number;
}

interface EmpresaUniforme {
  nombre?: string;
  direccion?: string;
  telefono?: string;
  email?: string;
  linkContacto?: string;
}

export interface AniosEscolaresCreateObj {
  id: number;
  vacantesMinimas: number;
  vacantesMaximas: number;
}

export enum CategoriaCaractersiticasExtra {
  INFRAESTRUCTURA = 1,
  SERVICIOS = 2,
  APOYO = 3,
  ARTE = 4,
  OTRO = 100000,
}

export enum CategoriaExtraprogramaticas {
  ARTE = 1,
  DEPORTE = 2,
  CULTURAL = 3,
  OTRO = 100000,
}

export interface NivelEducativo {
  id: number;
  nombre: string;
}
