import request from "../../commonRequests/axiosInstance";

interface Region {
  id: number;
  nombre: string;
  ordinal: number;
  paisId: number;
}

interface Comuna {
  id: number;
  nombre: string;
  ordinal: number;
  paisId: number;
}

export const getRegiones = async (regionId?: number) => {
  let url: string;
  if (!regionId) {
    url = "/params/regions";
  } else {
    url = `/params/regions?paisId=${regionId}`;
  }
  const regionsResponse = await request.get<Region[]>(url);
  return regionsResponse.data;
};
