import { NavigateFunction } from "react-router-dom";
import { tipoInstitucionType } from "../types/instituciones.types";

export const NavigateToInstitucion = (
  tipoInstitucion: tipoInstitucionType,
  id: number,
  newTab?: boolean,
  navigate?: NavigateFunction,
) => {
  if (
    [
      "Colegio",
      "Jardín Infantil",
      "Escuela de lenguaje",
      "Escuelas Libres",
      "Colegios Online",
    ].includes(tipoInstitucion)
  ) {
    const url = `/categoria/colegios/${id}`;
    if (newTab) {
      window.open(url, "_blank");
    }
    navigate?.(`/categoria/colegios/${id}`);
  } else if (tipoInstitucion === "Academias Deportivas") {
    const url = `/academia-deportiva/${id}`;
    if (newTab) {
      window.open(url, "_blank");
    }
    navigate?.(url);
  } else if (tipoInstitucion === "Idiomas") {
    const url = `/academia-idiomas/${id}`;
    if (newTab) {
      window.open(url, "_blank");
    }
    navigate?.(url);
  } else if (tipoInstitucion === "Preuniversitario") {
    const url = `/preuniversitario/${id}`;
    if (newTab) {
      window.open(url, "_blank");
    }
    navigate?.(url);
  } else if (tipoInstitucion === "Academias Artísticas") {
    const url = `/academia-artistica/${id}`;
    if (newTab) {
      window.open(url, "_blank");
    }
    navigate?.(url);
  }
};
