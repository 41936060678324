import { Divider, Typography } from "antd";
import HeaderModern from "../../views/header/HeaderModern";
import MejoresVendedoresTable from "./components/MejoresVendedoresTable";
import VendedoresTable from "./components/VendedoresTable";

const ListaVendedoresPage = () => {

  return (
    <>
      <HeaderModern />
      <div style={{padding: "2em", overflow: "auto"}}>
        <Typography.Title level={2}>
          Administración de vendedores
        </Typography.Title>

        <Typography.Title level={3}>Lista de vendedores</Typography.Title>
        <VendedoresTable />
        <Divider />
        <Typography.Title level={3}>Lista mejores vendedores</Typography.Title>
        <MejoresVendedoresTable />
      </div>
    </>
  );
};

export interface VendedorTableData {}

export default ListaVendedoresPage;
