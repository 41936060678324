import { Space, Button, Popconfirm, Row, Col, Modal } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import cogoToast from "cogo-toast";
import AntdConsts from "../../../designConstants/antd-constants";
import VendedorRequest from "../../../commonRequests/vendedores.req";
import { FaEdit } from "react-icons/fa";
import VendedorForm from "./VendedorForm";

interface UsuariosTableActionsProps {
  vendedorId: number;
}

const VendedoresTableActions = ({ vendedorId }: UsuariosTableActionsProps) => {
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const [vendorFormModal, setVendorFormModal] = useState<boolean>(false);

  const onDelete = async () => {
    const response = await VendedorRequest.deleteVendedor(vendedorId);
    setIsDeletingLoading(true);
    if (!response) {
      cogoToast.error("No fue posible eliminar el usuario");
      return;
    }
    cogoToast.success("Usuario eliminado correctamente");
    setIsDeletingLoading(false);
    setIsDeleted(true);
  };

  if (isDeleted) {
    return (
      <Row justify="center">
        <b>VENDEDOR ELIMINADO</b>
      </Row>
    );
  }

  return (
    <>
      <Space style={{ width: "100%" }}>
        <Col {...AntdConsts.fullSizeColLayout}>
          <Popconfirm
            title="Eliminar vendedor?"
            okText="Confirmar"
            onConfirm={onDelete}
          >
            <Button
              type="primary"
              icon={
                isDeletingLoading ? <LoadingOutlined /> : <DeleteOutlined />
              }
            >
              Eliminar
            </Button>
          </Popconfirm>
        </Col>
        <Button icon={<FaEdit />} onClick={() => setVendorFormModal(true)}>Editar</Button>
      </Space>
      <Modal
        visible={vendorFormModal}
        onCancel={() => setVendorFormModal(false)}
        onOk={() => setVendorFormModal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{block: true, style: { display: "none" }}}
        cancelText="Cancelar"
        title="Editar vendedor"
      >
        <VendedorForm isUpdate id={vendedorId}/>
      </Modal>
    </>
  );
};

export default VendedoresTableActions;
