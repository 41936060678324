import { Button, Empty, Typography } from "antd";
import SingleInstitucionEducativaEasyVisualization from "../types/SingleInstitucionEducativaEasyVisualization.type";

interface InsEduDocumentosProps {
  insEdu: SingleInstitucionEducativaEasyVisualization;
}

const InsEduDocumentos = ({ insEdu }: InsEduDocumentosProps) => {
  if (insEdu.insEduDocumentos?.length === 0 && insEdu.insEduDocumentos) {
    return (
      <>
        <b style={{ fontSize: "20px" }}>Documentos</b>
        <Empty
          // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={
            <span>
              Sin documentos que mostrar
            </span>
          }
        >
        </Empty>
      </>
    );
  }

  const reglamentoInterno = insEdu.insEduDocumentos?.find(
    (d) => d.nombre === "REGLAMENTO_INTERNO"
  );

  const reglamentoEvaluacion = insEdu.insEduDocumentos?.find(
    (d) => d.nombre === "REGLAMENTO_EVALUACION"
  );

  const protocolaConvivencia = insEdu.insEduDocumentos?.find(
    (d) => d.nombre === "PROTOCOLO_CONVIVENCIA"
  );

  const listadoUtiles = insEdu.insEduDocumentos?.find(
    (d) => d.nombre === "LISTADO_UTILES"
  );

  const protocoloCovid = insEdu.insEduDocumentos?.find(
    (d) => d.nombre === "PROTOCOLO_COVID"
  );

  return (
    <>
      <b style={{ fontSize: "20px" }}>Documentos</b>
      {reglamentoInterno && (
        <Button
          style={{
            paddingLeft: "1em",
            borderRadius: "5px",
            backgroundColor: "#CCF7FF",
            fontWeight: "bold",
            fontSize: "1.2em",
            marginBottom: "1em",
            marginTop: "2em",
          }}
          block
          href={`${process.env.REACT_APP_API_URL}instituciones/documentos/${reglamentoInterno.documentoId}`}
          target="_blank"
        >
          Reglamento interno
        </Button>
      )}
      {reglamentoEvaluacion && (
        <Button
          style={{
            paddingLeft: "1em",
            borderRadius: "5px",
            backgroundColor: "#ffccf4",
            fontWeight: "bold",
            fontSize: "1.2em",
            marginBottom: "1em",
          }}
          block
          href={`${process.env.REACT_APP_API_URL}instituciones/documentos/${reglamentoEvaluacion.documentoId}`}
          target="_blank"
        >
          Reglamento Evaluación
        </Button>
      )}
      {protocolaConvivencia && (
        <Button
          style={{
            paddingLeft: "1em",
            borderRadius: "5px",
            backgroundColor: "#FFFF99",
            fontWeight: "bold",
            fontSize: "1.2em",
            marginBottom: "1em",
          }}
          block
          href={`${process.env.REACT_APP_API_URL}instituciones/documentos/${protocolaConvivencia.documentoId}`}
          target="_blank"
        >
          Protocolo de Convivencia
        </Button>
      )}
      {listadoUtiles && (
        <Button
          style={{
            paddingLeft: "1em",
            borderRadius: "5px",
            backgroundColor: "#BBFF99",
            fontWeight: "bold",
            fontSize: "1.2em",
            marginBottom: "1em",
          }}
          block
          href={`${process.env.REACT_APP_API_URL}instituciones/documentos/${listadoUtiles.documentoId}`}
          target="_blank"
        >
          Listado de Útiles
        </Button>
      )}
      {protocoloCovid && (
        <Button
          style={{
            paddingLeft: "1em",
            borderRadius: "5px",
            backgroundColor: "#ffccf4",
            fontWeight: "bold",
            fontSize: "1.2em",
            marginBottom: "1em",
          }}
          block
          href={`${process.env.REACT_APP_API_URL}instituciones/documentos/${protocoloCovid.documentoId}`}
          target="_blank"
        >
          Protocolo COVID-2019
        </Button>
      )}
    </>
  );
};

export default InsEduDocumentos;
