import { Col, Image, Layout, Row, Space } from "antd";
import { Header } from "antd/lib/layout/layout";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import AntdConsts from "../../designConstants/antd-constants";
import useIsMobile from "../../hooks/useMobile";
import HeaderButtons from "./components/HeaderButtons";
import SearchBarHeader from "./components/SearchBarHeader";
import UbicacionButton from "./components/UbicacionButton";
import "./css/HeaderModern.css";
import HeaderEmailNotVerifiedWarning from "./HeaderEmailNotVerifiedWarning";
import logo from "./img/logoGeneric.png";

interface HeaderModernProps {
  affixTop?: boolean;
  whiteColor?: boolean;
  transparent?: boolean;
  hideButtons?: boolean;
  hideSearchbar?: boolean;
  askLocation?: boolean;
  onChangeLocation?: Function;
  hideEmailNotVerifiedWarning?: boolean;
}

function HeaderModern({
  affixTop = true,
  hideButtons,
  hideSearchbar,
  askLocation,
  onChangeLocation,
  hideEmailNotVerifiedWarning,
}: HeaderModernProps) {
  const isMobile = useIsMobile();

  const headerJSX = (
    <Layout className="header-sticky" style={{position: "sticky", top: "0px", zIndex: 50, width: "100vw"}}>
        <Header
          style={{
            borderBottom: "20 solid blue",
          }}
        >
          <Row
            gutter={[10, 0]}
            justify="space-between"
            style={{
              height: "80px",
              borderBottom: "20 solid blue",
            }}
          >
            <Col xs={6} sm={6} md={6} lg={4} xl={4}>
              <Link to="/">
                <Animated
                  animationIn="bounceInDown"
                  animationOut="zoomOutDown"
                  animationInDuration={1800}
                  animationOutDuration={1800}
                  isVisible={true}
                >
                  <Image
                    style={{ marginBottom: "1em", marginTop: "0.5em" }}
                    src={logo}
                    alt="Logo"
                    height={"3em"}
                    width={"auto"}
                    preview={false}
                    className="logotipo-pagina"
                  />
                </Animated>
              </Link>
            </Col>
            <Col
              xs={0}
              sm={0}
              md={0}
              lg={14}
              xl={14}
              className="searchbarItems"
            >
              {(!hideSearchbar || !isMobile) && <SearchBarHeader />}
            </Col>

            <Col
              xs={18}
              sm={18}
              md={18}
              lg={6}
              xl={6}
              className="searchbarItems"
            >
              <div style={isMobile ? { float: "right" } : undefined}>
                <Space direction="horizontal">
                  {isMobile && (
                    <div style={{ marginBottom: "1em" }}>
                      <UbicacionButton
                        askLocation={askLocation}
                        onChangeLocation={onChangeLocation}
                      />
                    </div>
                  )}
                  <HeaderButtons
                    hideButtons={hideButtons}
                    askLocation={askLocation}
                    onChangeLocation={onChangeLocation}
                  />
                </Space>
              </div>
            </Col>
          </Row>
        </Header>
        {!hideEmailNotVerifiedWarning && (
          <Col {...AntdConsts.fullSizeColLayout}>
            <HeaderEmailNotVerifiedWarning />
          </Col>
        )}
    </Layout>
  );

  // if (affixTop) {
  //   return (
  //     <Affix offsetTop={0}>
  //       {headerJSX}
  //     </Affix>
  //   );
  // }

  return headerJSX;
}

export default HeaderModern;
