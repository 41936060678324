import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { youTubeIdFromLink } from "../designConstants/antd-utils";

interface YoutubeEmbedProps {
  url?: string;
}

const YoutubeEmbed = ({ url }: YoutubeEmbedProps) => {
  return (
    <div>
      <LiteYouTubeEmbed
        id={youTubeIdFromLink(url) as string}
        title="url"
      />
    </div>
  );
};

export default YoutubeEmbed;
