import { useRequest } from "ahooks";
import { Button, Form, Input, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useCustomCompareEffect } from "use-custom-compare";
import { reqGetSponsoredList } from "../../commonRequests/adminRequests";
import SelectComuna from "../../components/Input/SelectComuna/SelectComuna";
import SelectTipoInsEdu from "../../components/Input/SelectTipoInstitucion/SelectTipoInstitucion";
import SelectRegion from "../../components/SelectRequest/SelectRegion";
import {
  CommonFilters,
  commonFiltersComparator,
} from "../../types/filtros.type";
import {
  TipoInstitucionEducativaIds,
  TipoInstitucionIds,
  tipoInstitucionType,
} from "../../types/instituciones.types";
import DestacarInstitucionButton from "./DestacarInstitucionButton";

interface TableDataSource {
  key: string | undefined;
  id: number;
  name: string;
  region: string;
  comuna: string;
  tipo: tipoInstitucionType;
  tipoInstitucionId: number;
  isSponsored: boolean;
}
const SponsoredAdminTable = () => {
  const [filters, setFilters] = useState<CommonFilters>({
    is_sponsored: true,
    take: 10,
    skip: 0,
  });

  const [form] = Form.useForm();

  const SponsoredReq = useRequest(() => reqGetSponsoredList(filters), {
    manual: true,
  });

  useCustomCompareEffect(
    () => {
      SponsoredReq.runAsync();
    },
    [filters],
    (prevDeps, nextDeps) => commonFiltersComparator(nextDeps[0], prevDeps[0])
  );

  const onCellClick = (record: TableDataSource) => {
    if (TipoInstitucionEducativaIds.includes(record.tipoInstitucionId)) {
      window.open(`/categoria/colegios/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.ACADEMIA_DEPORTIVA
    ) {
      window.open(`/academia-deportiva/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.ACADEMIA_IDIOMAS
    ) {
      window.open(`/academia-idiomas/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.PREUNIVERSITARIO
    ) {
      window.open(`/preuniversitario/${record.id}`, "_blank");
    } else if (record.tipoInstitucionId === TipoInstitucionIds.REFORZAMIENTO) {
      window.open(`/reforzamiento/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.ACADEMIA_ARTISTICA
    ) {
      window.open(`/academia-artistica/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.ESCUELA_VERANO ||
      record.tipoInstitucionId === TipoInstitucionIds.GUARDERIA_INFANTIL
    ) {
      window.open(`/escuela-verano/${record.id}`, "_blank");
    }
  };

  const dataSource: TableDataSource[] | undefined =
    SponsoredReq.data?.items?.map((i) => ({
      key: `${i.tipoInstitucion.nombre} ${i.id}`,
      id: i.id as number,
      name: i.nombre.toUpperCase(),
      region: i.region.nombre,
      comuna: i.comuna.nombre,
      tipo: i.tipoInstitucion.nombre as tipoInstitucionType,
      tipoInstitucionId: i.tipoInstitucion.id,
      isSponsored: i.isSponsored,
    }));

  const columns: ColumnsType<TableDataSource> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Región",
      dataIndex: "region",
      key: "region",
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Comuna",
      dataIndex: "comuna",
      key: "comuna",
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Acción",
      key: "Destacar",
      render: (_: any, record: TableDataSource) => (
        <>
          <DestacarInstitucionButton
            tipoInstitucionId={record.tipoInstitucionId}
            id={record.id}
            startsDestacado={record.isSponsored}
          />
        </>
      ),
    },
  ];

  const regionId = Form.useWatch("region_id", form);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          if (values.tipo_institucion) {
            values.tipo_institucion = [values.tipo_institucion];
          }
          setFilters({ ...filters, ...values });
        }}
      >
        <Space direction="horizontal" wrap>
          <Form.Item name="is_sponsored" noStyle />
          <Form.Item name="nombre" label="Nombre">
            <Input />
          </Form.Item>
          <Form.Item name="tipo_institucion" label="Tipo institución">
            <SelectTipoInsEdu />
          </Form.Item>
          <Form.Item name="region_id" label="Región">
            <SelectRegion style={{ width: "15em" }} />
          </Form.Item>
          <Form.Item name="comuna_id" label="Comuna">
            <SelectComuna
              style={{ width: "15em" }}
              regionId={regionId}
              disabled={!regionId}
            />
          </Form.Item>
          <Form.Item label=" ">
            <Button htmlType="submit">Buscar</Button>
          </Form.Item>
          <Button />
        </Space>
      </Form>
      <Button
        type="link"
        onClick={() => {
          form.resetFields();
          setFilters({});
        }}
      >
        Limpiar filtros
      </Button>
      <Typography.Title level={3}>Instituciones destacadas</Typography.Title>
      <Table
        scroll={{ x: "max-content" }}
        style={{ cursor: "pointer" }}
        dataSource={dataSource}
        columns={columns}
        loading={SponsoredReq.loading || !SponsoredReq.data}
        pagination={{
          total: SponsoredReq.data?.total,
          onChange: (page, pageSize) => {
            setFilters({
              ...filters,
              take: pageSize,
              skip: pageSize * (page - 1),
            });
          },
        }}
        bordered
        size="middle"
      />
    </div>
  );
};

export default SponsoredAdminTable;
