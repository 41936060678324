import { useRequest } from "ahooks";
import { Affix, Button, Col, Pagination, Row, Typography } from "antd";
import { useState } from "react";
import cogoToast from "cogo-toast";
import AntdConsts from "../../../../designConstants/antd-constants";
import { academiaDeportivaFiltersComparator } from "../../../../types/academiaDeportiva.type";
import { requestManyAcademiaDeportiva } from "../../../../commonRequests/academiaDeportesRequest";
import AcademiaDeportivaSimpleView from "./AcademiaDeportivaSimpleView";
import AdsBanner from "../../../../components/AdsBanner";
import CardLoading from "../../../../components/CardLoading";
import { useCustomCompareEffect } from "use-custom-compare";
import useGeo from "../../../../hooks/useGeo";
import { FaArrowCircleLeft } from "react-icons/fa";
import { AcademiaDeportivaFilters } from "../../../../types/filtros.type";
import QuitarUbicacionButton from "../../../../components/QuitarUbicacionButton";
import Swal from "sweetalert2";
import PopUpBusquedas from "../../../../components/PopUpBusquedas";

interface ListaAcademiaDeportesPageProps {
  filters?: AcademiaDeportivaFilters;
  setFilters?: (filters: AcademiaDeportivaFilters) => any;
}

const ListaAcademiaDeportesPage = ({
  filters,
  setFilters,
}: ListaAcademiaDeportesPageProps) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(filters?.take || 18);

  const geolocation = useGeo();

  const {
    data: manyAcademiaDeportes,
    loading,
    runAsync: loadMoreAcademiaDeporte,
  } = useRequest(
    async () => {
      const academiaDeportesList = await requestManyAcademiaDeportiva({
        ...filters,
        latitud: geolocation?.latitude,
        longitud: geolocation?.longitude,
      });
      if (academiaDeportesList === null) {
        cogoToast.error("No se pudo cargar la información solicitada");
      }

      return academiaDeportesList;
    },
    { manual: true }
  );

  useCustomCompareEffect(
    () => {
      loadMoreAcademiaDeporte();
    },
    [filters],
    (prevDeps, nextDeps) =>
      academiaDeportivaFiltersComparator(nextDeps[0], prevDeps[0])
  );

  const onPageChange = async (newPage: number, newPageSize: number) => {
    window.scrollTo(0, 0);
    setPage(newPage);
    setPageSize(pageSize);
    setFilters?.({
      ...filters,
      take: newPageSize,
      skip: (page - 1) * pageSize,
    });
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <>
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
      </>
    );
  }

  if (!manyAcademiaDeportes) {
    return (
      <div>
        No se encontraron instituciones educativas. <QuitarUbicacionButton />
      </div>
    );
  }

  const spans = { xs: 24, md: 24, lg: 24, xl: 12, xxl: 12 };

  if (manyAcademiaDeportes.items.length === 0) {
    {PopUpBusquedas()}
    return (
      <>
        <Row gutter={AntdConsts.rowGutter} justify="center">
          <Typography.Text>
            {window.scrollTo(0, 0)}
            No se encontraron Academias Deportivas.
          </Typography.Text>
        </Row>
      </>
    );
  }

  const cardSpans = { xs: 24, md: 24, lg: 12, xl: 12, xxl: 12 };

  return (
    <>
      <Row>
        <Col {...AntdConsts.fullSizeColLayout}>
          <Button type="text" href="/" style={{ fontWeight: "bold" }}>
            <FaArrowCircleLeft />
            &nbsp;&nbsp;Volver a la Página Principal
          </Button>
        </Col>
      </Row>
      <Row gutter={AntdConsts.rowGutter} justify="center">
        {manyAcademiaDeportes.items.map((ad, index) => {
          const showAd = index % 4 === 0 && index !== 0;
          return (
            <>
              {showAd && (
                <Col {...AntdConsts.fullSizeColLayout}>
                  <AdsBanner />
                </Col>
              )}
              <Col {...cardSpans} style={{ display: "flex" }}>
                <AcademiaDeportivaSimpleView
                  key={ad.id}
                  academiaDeportiva={ad}
                  geoLocation={geolocation}
                />
              </Col>
            </>
          );
        })}
      </Row>
      <Pagination
        style={{ backgroundColor: "whitesmoke", padding: "1em", zIndex: 1000 }}
        onChange={onPageChange}
        total={manyAcademiaDeportes.total}
        responsive
        current={page}
      />
    </>
  );
};

export default ListaAcademiaDeportesPage;
