import { Row, Col, Affix, Divider, Space, Button, Form } from "antd";
import AntdConsts from "../../../../designConstants/antd-constants";
import AcademiaDeportiveFormStepCounter from "../../submitacademiasDeportivasPage/components/AcademiaDeportiveFormStepCounter";
import PreuniversitarioDatosInstitucionalesForm from "../components/PreuniversitarioDatosInstitucionalesForm";
import AsignaturasSelectorInput from "../components/AsignaturasSelectorInput";
import NivelesSelectorInput from "../components/NivelesSelectorInput";
import DescripcionEdicionInput from "../../commonComponents/DescripcionEdicionInput";
import TextArea from "antd/lib/input/TextArea";

interface PreuniversitarioFormStepTwoProps {
  onNext: () => void;
  onBack: () => void;
}

const PreuniversitarioFormStepTwo = ({
  onBack,
}: PreuniversitarioFormStepTwoProps) => {
  return (
    <>
      <Row>
        <Col
          lg={8}
          xl={8}
          xxl={8}
          {...AntdConsts.removeColOnMobileLayout}
          style={{ padding: "2em", paddingTop: "20px" }}
        >
          <Affix offsetTop={AntdConsts.headerHeight + 20}>
            <AcademiaDeportiveFormStepCounter
              currentStep={1}
              direction="vertical"
            />
          </Affix>
        </Col>
        <Col
          lg={16}
          xl={16}
          xxl={16}
          {...AntdConsts.fullSizeColOnMobileLayout}
          style={{ padding: "2em", backgroundColor: "white" }}
        >
          <Divider />
          <PreuniversitarioDatosInstitucionalesForm />
          <NivelesSelectorInput />
          <Divider />
          <AsignaturasSelectorInput />
          <Form.Item
            name="asignaturasExtra"
            label="Ingrese las asignaturas faltantes (una por línea)"
            rules={[{ required: false }]}
          >

            <TextArea rows={4}>
            </TextArea>
          </Form.Item>
          <Divider />
          <DescripcionEdicionInput />
          <Space
            direction="horizontal"
            style={{ float: "right", marginTop: "2em" }}
          >
            <Button type="default" onClick={onBack}>
              Anterior
            </Button>
            <Button type="primary" htmlType="submit">
              Enviar formulario
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default PreuniversitarioFormStepTwo;
