import { useRequest } from "ahooks";
import { Col, Form, Row, Typography, Checkbox } from "antd";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";
import { requestIdiomas } from "../../../../commonRequests/academiaIdiomasRequest";

const IdiomasSelectorInput = () => {
  const { data: idiomas, loading } = useRequest(requestIdiomas);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Typography.Title level={3}>Idiomas</Typography.Title>
      <Form.Item
        name="idiomas"
        label="Seleccione los idiomas instruidos por su institución"
        rules={[{ required: true }]}
      >
        <Checkbox.Group>
          <Row gutter={AntdConsts.rowGutter}>
            {idiomas?.map((d) => (
              <Col {...AntdConsts.fourColLayout}>
                <Checkbox value={d.id}>{d.nombre}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default IdiomasSelectorInput;
