import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Typography,
} from "antd";
import SelectGeneroAlumnos from "../../../../components/Input/SelectGeneroAlumnos/SelectGeneroAlumnos";
import SelectOrientacionReligiosa from "../../../../components/InsEduSelectInputs/SelectOrientacionReligiosa";
import SelectTipoDependencia from "../../../../components/InsEduSelectInputs/SelectTipoDependencia";
import SelectModalidadEstudio from "../../../../components/SelectModalidadEstudio";
import SelectTipoEducacion from "../../../../components/SelectTipoEducacion";
import AntdConsts from "../../../../designConstants/antd-constants";
import NivelEducativoDetailsInput from "./NivelesEducativosInput/NivelEducativoDetailsInput";

interface InfoInstitucionalFormProps {
  form: FormInstance;
}

const InfoInstitucionalForm = ({ form }: InfoInstitucionalFormProps) => {
  return (
    <>
      <Typography.Title level={2}>Información Institucional</Typography.Title>
      <Form.Item label="Misión" name="mision" rules={[{ required: true }]}>
        <Input.TextArea placeholder="Misión del establecimiento" rows={4} />
      </Form.Item>
      <Form.Item label="Visión" name="vision" rules={[{ required: true }]}>
        <Input.TextArea placeholder="Visión del establecimiento" rows={4} />
      </Form.Item>

      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Tipo dependencia"
            name="tipoDependenciaId"
            rules={[{ required: true }]}
          >
            <SelectTipoDependencia />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Orientación religiosa"
            name="orientacionReligiosaId"
            rules={[{ required: true }]}
          >
            <SelectOrientacionReligiosa />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Genero alumnos"
            name="generoAlumnosId"
            rules={[{ required: true }]}
          >
            <SelectGeneroAlumnos />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Modelo educativo"
            name="tipoEducacionId"
            rules={[{ required: true }]}
          >
            <SelectTipoEducacion />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Modalidad de estudio"
            name="modalidadEstudioId"
            rules={[{ required: true }]}
          >
            <SelectModalidadEstudio />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Alumnos por curso"
            name="cantAlumnosPorCurso"
            rules={[{ required: true }]}
          >
            <InputNumber
              min={1}
              placeholder="Alumnos por curso"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Total alumnos matriculados"
            name="alumnosMatriculados"
            rules={[{ required: true }]}
          >
            <InputNumber
              min={1}
              placeholder="Alumnos matriculados"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default InfoInstitucionalForm;
