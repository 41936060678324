import { Typography, Anchor, Affix } from "antd";
import AntdConsts from "../../../designConstants/antd-constants";
import useIsMobile from "../../../hooks/useMobile";

//Tightly coupled with VistaDetalleAcademiaIdiomasPage
const AnchorMenuInsEdu = () => {
  const isMobile = useIsMobile();
  return (
    <div style={{ float: "left", display: isMobile ? "none" : undefined }}>
      {/* <Affix offsetTop={AntdConsts.headerHeight + 50}> */}
        <Typography.Title level={4} style={{ marginLeft: "1em" }}>
          Secciones
        </Typography.Title>
        <Anchor
          style={{ marginLeft: "3em" }}
          offsetTop={AntdConsts.headerHeight + 50}
          targetOffset={AntdConsts.headerHeight + 80}
        >
          <Anchor.Link
            href="#descripcion-institucion"
            title="Descripción de la institución"
          />
          <Anchor.Link href="#contacto-institucion" title="Contacto" />
          <Anchor.Link
            href="#caracteristicas-institucion"
            title="Información"
          />
          <Anchor.Link href="#programa-educativo" title="Programa educativo" />
          <Anchor.Link href="#caracteristicas-extra" title="Características" />
          <Anchor.Link href="#niveles-horarios" title="Niveles - Horarios" />
          <Anchor.Link href="#datos-pago" title="Datos de pago" />
          <Anchor.Link href="#idiomas-educativos" title="Idiomas en aula" />
          <Anchor.Link href="#resultados-simce" title="Resultados SIMCE" />
          <Anchor.Link
            href="#vinculacion-medio"
            title="Documentos relevantes"
          />
          <Anchor.Link
            href="#vinculacion-medio"
            title="Vinculación con el medio"
          />
          <Anchor.Link
            href="#video-institucional"
            title="Vídeo Institucional"
          />
          <Anchor.Link href="#mapa-ubicacion" title="Mapa ubicación" />
        </Anchor>
      {/* </Affix> */}
    </div>
  );
};

export default AnchorMenuInsEdu;
