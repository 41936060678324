import { Table, Typography } from "antd";
import FlagsViews from "../../../components/FlagsViews/FlagsViews";
import SingleInstitucionEducativaEasyVisualization from "../types/SingleInstitucionEducativaEasyVisualization.type";

interface IdiomasEducativosProps {
  insEdu: SingleInstitucionEducativaEasyVisualization;
}

const IdiomasEducativosTable = ({ insEdu }: IdiomasEducativosProps) => {
  const renderedDataSource = insEdu.tablaidiomasEducativos.dataSource.map(
    (d) => {
      return {
        ...d,
        idioma: (
          <FlagsViews flagsIso639Codes={d.idioma.map((i) => i.iso639_2_code)} />
        ),
      };
    }
  );

  return (
    <>
      <Typography.Title level={3}>IDIOMAS EDUCATIVOS</Typography.Title>
      <Table
        bordered
        pagination={false}
        columns={insEdu.tablaidiomasEducativos.headers}
        dataSource={renderedDataSource}
      />
    </>
  );
};

export default IdiomasEducativosTable;
