import { useRequest } from "ahooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthReqs from "../commonRequests/authRequests";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import UserNotReadyToPostPage from "../components/UserNotReadyToPostPage";
import { useAuthStorage } from "../hooks/useBrowserStorage";
import { AuthTokenPayload } from "../types/auth.types";

interface ProtectedRouteProps {
  children: React.ReactElement;
  shouldBeAdmin?: boolean;
}

const ProtectedRoute = ({ children, shouldBeAdmin }: ProtectedRouteProps) => {
  const auth = useAuthStorage(true) as AuthTokenPayload;
  const navigate = useNavigate();

  const [emailVerified, setEmailVerified] = useState<boolean>();
  const [userVerified, setUserVerified] = useState<boolean>();
  const [couldNotVerifyUser, setCouldNotVerifyUser] = useState<boolean>();

  const emailVerifiedReq = useRequest(async () => {
    if (!auth) {
      return;
    }
    const emailVerified = await AuthReqs.reqCheckEmailVerified(auth.userId);

    if (emailVerified === null) {
      setCouldNotVerifyUser(true);
      return;
    }

    setEmailVerified(emailVerified);
  });

  const userVerifiedReq = useRequest(async () => {
    if (!auth) {
      return;
    }
    const userVerified = await AuthReqs.checkUserVerified(auth.userId);
    if (userVerified === null) {
      setCouldNotVerifyUser(true);
      return;
    }
    setUserVerified(userVerified);
  });

  if (emailVerifiedReq.loading || userVerifiedReq.loading) {
    return <LoadingSpinner />;
  }

  if (!auth) {
    window.location.href = "/inicio-de-sesion";
    return <></>;
  }

  if (!userVerified || !emailVerified || couldNotVerifyUser) {
    return (
      <UserNotReadyToPostPage
        userVerified={userVerified}
        emailVerified={emailVerified}
        couldNotVerifyUser
      />
    );
  }

  if (shouldBeAdmin && auth.profileType.type !== "ADMIN") {
    navigate("/");
  }

  return children;
};

export default ProtectedRoute;
