import { Col, Form, Input, Row, Select, Typography } from "antd";
import { useState } from "react";
import SelectComuna from "../../../../components/Input/SelectComuna/SelectComuna";
import SelectRegion from "../../../../components/SelectRequest/SelectRegion";
import AntdConsts from "../../../../designConstants/antd-constants";

const DatosBasicosAcademiaArtisticaForm = () => {
  const [regionId, setRegionId] = useState();

  return (
    <>
      <Typography.Title level={2}>Datos Básicos</Typography.Title>
      <Form.Item
        label="Nombre academia artística"
        name="nombre"
        rules={[{ required: true }]}
      >
        <Input placeholder="Nombre academia artística" />
      </Form.Item>
      <Row gutter={AntdConsts.rowGutter}>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="País"
            name="paisId"
            rules={[{ required: true }]}
            initialValue={1}
          >
            <Select placeholder="Pais" disabled >
              <Select.Option value={1}>Chile</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item label="Región" name="regionId" rules={[{ required: true }]}>
            <SelectRegion placeholder="Región" onChange={setRegionId} />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item label="Comuna" name="comunaId" rules={[{ required: true }]}>
            <SelectComuna placeholder="Comuna" regionId={regionId} />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            label="Dirección"
            name="direccion"
            rules={[{ required: true }]}
          >
            <Input placeholder="Dirección" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Descripción"
        name="descripcion"
        rules={[{ required: true }]}
      >
        <Input.TextArea
          placeholder="Descripción breve del establecimiento"
          rows={5}
        />
      </Form.Item>
    </>
  );
};

export default DatosBasicosAcademiaArtisticaForm;
