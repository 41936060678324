import { useDeepCompareEffect, useRequest } from "ahooks";
import { Button, Form, Input, Space, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { getInstitucionesComunes } from "../../../commonRequests/adminRequests";
import SelectComuna from "../../../components/Input/SelectComuna/SelectComuna";
import SelectTipoInsEdu from "../../../components/Input/SelectTipoInstitucion/SelectTipoInstitucion";
import SelectRegion from "../../../components/SelectRequest/SelectRegion";
import { CommonFilters } from "../../../types/filtros.type";
import {
  TipoInstitucionEducativaIds,
  TipoInstitucionIds,
  tipoInstitucionType,
} from "../../../types/instituciones.types";
import CambiarVisibilidadInstitucionButton from "./CambiarVisibilidadInstitucionButton";

interface TableDataSource {
  key: string | undefined;
  id: number;
  name: string;
  region: string;
  comuna: string;
  tipo: tipoInstitucionType;
  motivo: "CREACIÓN" | "EDICIÓN";
  estado: "NO VISIBLE" | "VISIBLE";
  description: string | undefined;
  tipoInstitucionId: number;
}

const InstitucionesInNeedOfReviewTable = () => {
  const [filters, setFilters] = useState<CommonFilters>({
    take: 10,
    skip: 0,
  });

  const institucionesReq = useRequest(() => getInstitucionesComunes({...filters, needsReview: true}));

  useDeepCompareEffect(() => {
    institucionesReq.runAsync();
  }, [filters]);

  const dataSource: TableDataSource[] | undefined =
    institucionesReq.data?.items
      ?.sort((a, b) => a.updatedAt.localeCompare(b.updatedAt))
      ?.map((i) => ({
      key: `${i.tipoInstitucion.nombre} ${i.id}`,
      id: i.id as number,
      name: i.nombre.toUpperCase(),
      region: i.region.nombre,
      comuna: i.comuna.nombre,
      tipo: i.tipoInstitucion.nombre as tipoInstitucionType,
      motivo: !i.updatedAt ? "CREACIÓN" : "EDICIÓN",
      estado: i.hidden ? "NO VISIBLE" : "VISIBLE",
      description: !i.updatedAt ? undefined : i.descripcionEdicion,
      tipoInstitucionId: i.tipoInstitucion.id,
      updatedAt: i.updatedAt,
    }));

  const onCellClick = (record: TableDataSource) => {
    if (TipoInstitucionEducativaIds.includes(record.tipoInstitucionId)) {
      window.open(`/categoria/colegios/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.ACADEMIA_DEPORTIVA
    ) {
      window.open(`/academia-deportiva/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.ACADEMIA_IDIOMAS
    ) {
      window.open(`/academia-idiomas/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.PREUNIVERSITARIO
    ) {
      window.open(`/preuniversitario/${record.id}`, "_blank");
    } else if (record.tipoInstitucionId === TipoInstitucionIds.REFORZAMIENTO) {
      window.open(`/reforzamiento/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.ACADEMIA_ARTISTICA
    ) {
      window.open(`/academia-artistica/${record.id}`, "_blank");
    } else if (
      record.tipoInstitucionId === TipoInstitucionIds.ESCUELA_VERANO ||
      record.tipoInstitucionId === TipoInstitucionIds.GUARDERIA_INFANTIL
    ) {
      window.open(`/escuela-verano/${record.id}`, "_blank");
    }
  };

  const columns: ColumnsType<TableDataSource> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Región",
      dataIndex: "region",
      key: "region",
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Comuna",
      dataIndex: "comuna",
      key: "comuna",
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Fecha edición",
      dataIndex: "updatedAt",
      key: "updatedAt",
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
      render: (updatedAt: string) => new Date(updatedAt).toLocaleDateString(),
    },
    {
      title: "Motivo",
      dataIndex: "motivo",
      key: "motivo",
      render: (motivo: string) => (
        <>
          <Tag color={motivo === "CREACIÓN" ? "blue" : "magenta"}>{motivo}</Tag>
        </>
      ),
      onCell: (record) => ({ onClick: () => onCellClick(record) }),
    },
    {
      title: "Acción",
      key: "Volver visible",
      render: (_: any, record: TableDataSource) => (
        <>
          <CambiarVisibilidadInstitucionButton
            tipoInstitucionId={record.tipoInstitucionId}
            id={record.id}
            startsHidden={record.estado === "NO VISIBLE"}
          />
        </>
      ),
    },
  ];

  const [form] = Form.useForm();
  const regionId = Form.useWatch("region_id", form);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          if (values.tipo_institucion) {
            values.tipo_institucion = [values.tipo_institucion];
          }
          setFilters({ ...filters, ...values });
        }}
      >
        <Space direction="horizontal" wrap>
          <Form.Item name="nombre" label="Nombre">
            <Input />
          </Form.Item>
          <Form.Item name="tipo_institucion" label="Tipo institución">
            <SelectTipoInsEdu />
          </Form.Item>
          <Form.Item name="region_id" label="Región">
            <SelectRegion style={{ width: "15em" }} />
          </Form.Item>
          <Form.Item name="comuna_id" label="Comuna">
            <SelectComuna
              style={{ width: "15em" }}
              regionId={regionId}
              disabled={!regionId}
            />
          </Form.Item>
          <Form.Item label=" ">
            <Button htmlType="submit">Buscar</Button>
          </Form.Item>
          <Button />
        </Space>
      </Form>
      <Button
        type="link"
        onClick={() => {
          form.resetFields();
          setFilters({});
          institucionesReq.runAsync();
        }}
      >
        Limpiar filtros
      </Button>
      <Typography.Title level={3}>Instituciones por revisar</Typography.Title>
      <Table
        scroll={{x: "max-content"}}
        style={{ cursor: "pointer" }}
        dataSource={dataSource}
        columns={columns}
        loading={institucionesReq.loading || !institucionesReq.data}
        pagination={{
          total: institucionesReq.data?.total,
          onChange: (page, pageSize) => {
            setFilters({
              ...filters,
              take: pageSize,
              skip: pageSize * (page - 1),
            });
          },
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Typography.Paragraph
              strong
              style={{ marginRight: "1em", marginLeft: "3em" }}
            >
              {`Comentario ultima edición: ${record.description}`}
            </Typography.Paragraph>
          ),
          rowExpandable: (record) =>
            record.motivo === "EDICIÓN" && !!record.description,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                {`${institucionesReq.data?.total} instituciones requieren verificación`}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        bordered
        size="middle"
      />
    </div>
  );
};

export default InstitucionesInNeedOfReviewTable;
