// import { StickyContainer, Sticky } from "react-sticky";
import { useRequest } from "ahooks";
import { Image, Row, Space } from "antd";
import AdsRequests from "../../commonRequests/adsRequests";
import { baseUrl } from "../../commonRequests/axiosInstance";
import "./AdsBanner.css";

interface AdsBannerProps {
  vertical?: boolean;
}

const AdsBanner = ({ vertical }: AdsBannerProps) => {
  if (vertical) {
    return <AddBannerVertical />;
  }

  return (
    <Row justify="center">
      <AddBannerHorizontal />;
    </Row>
  );
};

const AddBannerHorizontal = () => {
  const adsReq = useRequest(() => AdsRequests.get(1, "HORIZONTAL"));

  if (adsReq.loading) {
    return <></>;
  }

  if (!adsReq.data) {
    return <></>;
  }

  if (adsReq.data.length === 0) {
    return <></>;
  }

  const onAnuncioClick = async (idanuncio: number, anuncioLink: string) => {
    await AdsRequests.countClick(idanuncio);
    window.open(`//${anuncioLink.replace("https://","")}`);
  };

  const anunciosJsx = adsReq.data.map((anuncio) => {
    if (!anuncio) {
      return <></>;
    }
    return (
      <Image
        style={{ cursor: "pointer" }}
        onClick={() => onAnuncioClick(anuncio.id, anuncio.link)}
        preview={false}
        src={`${baseUrl}instituciones/images/${anuncio.image.id}`}
      />
    );
  });

  return (
    <Space direction="vertical" size={70}>
      {anunciosJsx}
    </Space>
  );
};

const AddBannerVertical = () => {
  const adsReq = useRequest(() => AdsRequests.get(16, "VERTICAL"));

  if (adsReq.loading) {
    return <></>;
  }

  if (!adsReq.data) {
    return <></>;
  }

  if (adsReq.data.length === 0) {
    return <></>;
  }

  const onAnuncioClick = async (idanuncio: number, anuncioLink: string) => {
    await AdsRequests.countClick(idanuncio);
    window.open(`//${anuncioLink.replace("https://","")}`);
  };

  const anunciosJsx = adsReq.data.map((anuncio) => {
    if (!anuncio) {
      return <></>;
    }
    return (
      <Image
        style={{ cursor: "pointer" }}
        onClick={() => onAnuncioClick(anuncio.id, anuncio.link)}
        preview={false}
        src={`${baseUrl}instituciones/images/${anuncio.image.id}`}
      />
    );
  });

  return (
    <Space direction="vertical" size={70}>
      {anunciosJsx}
    </Space>
  );
};

export default AdsBanner;
