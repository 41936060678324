import { useRequest } from "ahooks";
import {
  Row,
  Typography,
  ConfigProvider,
  Space,
  Statistic,
  Collapse,
  Divider,
  Col,
} from "antd";
import AdsRequests from "../../commonRequests/adsRequests";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useIsMobile from "../../hooks/useMobile";
import HeaderModern from "../../views/header/HeaderModern";
import AdsList from "./components/AdsList";
import SubmitAdsForm from "./components/SubmitAdsForm";

const AdsAdminPage = () => {
  const isMobile = useIsMobile();

  const AdReq = useRequest(AdsRequests.getAll);

  if (AdReq.loading) {
    return <LoadingSpinner />;
  }

  if (!AdReq.data) {
    return <>404</>;
  }

  const adsVerticales = AdReq.data.filter(
    (ad) => ad.orientacion === "VERTICAL"
  );

  const adsHorizontales = AdReq.data.filter(
    (ad) => ad.orientacion === "HORIZONTAL"
  );

  let porcentajeUtilizadoAnuncioVertical = 0;
  for (const ad of adsVerticales) {
    porcentajeUtilizadoAnuncioVertical += ad.porcentajeAparicion;
  }

  let porcentajeUtilizadoAnuncioHorizontal = 0;
  for (const ad of adsHorizontales) {
    porcentajeUtilizadoAnuncioHorizontal += ad.porcentajeAparicion;
  }

  return (
    <>
      <HeaderModern />
      <br />
      <Row
        justify="center"
        // style={
        //   isMobile
        //     ? { padding: "1em", marginLeft: "40em" }
        //     : { paddingLeft: "10%", paddingRight: "10%" }
        // }
      >
        <Col xs={20} sm={20} md={20} lg={22} xl={22}>
          <div>
            <ConfigProvider
              form={{ validateMessages: { required: "Campo obligatorio" } }}
            >
              <Typography.Title level={3}>Anuncios verticales</Typography.Title>
              {/* <Descriptions bordered>
              <Descriptions.Item label="Porcentaje utilizado">{`${porcentajeUtilizadoAnuncioVertical}%`}</Descriptions.Item>
              <Descriptions.Item label="Porcentaje disponible">{`${100 - porcentajeUtilizadoAnuncioVertical}%`}</Descriptions.Item>
            </Descriptions> */}
              <Space
                direction="horizontal"
                wrap
                style={{ marginBottom: "2em" }}
              >
                <Statistic
                  title="Porcentaje utilizado"
                  value={`${porcentajeUtilizadoAnuncioVertical}%`}
                />
                <Statistic
                  title="Porcentaje disponible"
                  value={`${100 - porcentajeUtilizadoAnuncioVertical}%`}
                />
              </Space>

              <AdsList anuncios={adsVerticales} onDelete={AdReq.refreshAsync} />

              <Collapse>
                <Collapse.Panel header="Crear nuevo anuncio vertical" key="1">
                  <SubmitAdsForm
                    orientacion="VERTICAL"
                    usedAdPercemtage={porcentajeUtilizadoAnuncioVertical}
                    onCreate={AdReq.refreshAsync}
                  />
                </Collapse.Panel>
              </Collapse>

              <Divider />

              <Typography.Title level={3}>
                Anuncios horizontales
              </Typography.Title>
              <Space
                direction="horizontal"
                wrap
                style={{ marginBottom: "2em" }}
              >
                <Statistic
                  title="Porcentaje utilizado"
                  value={`${porcentajeUtilizadoAnuncioHorizontal}%`}
                />
                <Statistic
                  title="Porcentaje disponible"
                  value={`${100 - porcentajeUtilizadoAnuncioHorizontal}%`}
                />
              </Space>
              <AdsList anuncios={adsHorizontales} />

              <Collapse>
                <Collapse.Panel header="Crear nuevo anuncio horizontal" key="1">
                  <SubmitAdsForm
                    orientacion="HORIZONTAL"
                    usedAdPercemtage={porcentajeUtilizadoAnuncioHorizontal}
                    onCreate={AdReq.refreshAsync}
                  />
                </Collapse.Panel>
              </Collapse>
            </ConfigProvider>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AdsAdminPage;
