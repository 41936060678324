import { Card, Col, Descriptions, List, Row, Space, Typography } from "antd";
import { EnrichedGeolocationCoordinates } from "react-hook-geolocation";
import {
  FaChalkboardTeacher,
  FaDirections,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaMoneyBillAlt,
  FaTransgender,
} from "react-icons/fa";
import { AcademiaDeportiva } from "../../../../types/academiaDeportiva.type";
import currencyFormatter from "../../../../utils";
import AntdConsts from "../../../../designConstants/antd-constants";
import { formatDistance } from "../../../../utils/filterUtils";
import CardContainer from "../../../../components/CardContainer";
import IconoTablas from "../../../../components/iconosTablas/IconoTablas";
import { BsGenderAmbiguous } from "react-icons/bs";

interface AcademiaDeportivaSimpleViewProps {
  academiaDeportiva: AcademiaDeportiva;
  geoLocation?: EnrichedGeolocationCoordinates;
  className?: string;
}

const AcademiaDeportivaSimpleView = ({
  academiaDeportiva,
}: AcademiaDeportivaSimpleViewProps) => {
  const geoDistance = academiaDeportiva.distance;

  const info = (
    <>
      <Descriptions size="small" column={1} bordered title="INFORMACIÓN">
        {geoDistance && (
          <Descriptions.Item>
            <Space size="small" direction="horizontal">
              <FaDirections style={{ color: "blue" }} />
              <Typography.Text
                ellipsis={{
                  tooltip: `${academiaDeportiva.comuna.nombre}, ${academiaDeportiva.region.nombre}`,
                }}
                style={{ width: "15em" }}
              >
                {`A ${formatDistance(geoDistance)} de su ubicación`}
              </Typography.Text>
            </Space>
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={
            <Space direction="horizontal">
              <FaDirections color="blue" size={"1.2em"} />
              Dirección
            </Space>
          }
        >
          {academiaDeportiva.direccion}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space direction="horizontal">
              <FaMapMarkerAlt color="blue" size={"1.2em"} />
              Comuna
            </Space>
          }
        >
          {academiaDeportiva.comuna.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space direction="horizontal">
              <BsGenderAmbiguous color="blue" size={"1.2em"} />
              Genero
            </Space>
          }
        >
          {academiaDeportiva.generoAlumnos.nombre}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space direction="horizontal">
              <FaMoneyBillAlt color="blue" size={"1.2em"} />
              Precio
            </Space>
          }
        >
          {`${currencyFormatter.format(
            academiaDeportiva.precioMin
          )} - ${currencyFormatter.format(academiaDeportiva.precioMax)}`}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space direction="horizontal">
              <FaChalkboardTeacher color="blue" size={"1.2em"} />
              Modalidad
            </Space>
          }
        >
          {academiaDeportiva.modalidad.nombre}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  return (
    <CardContainer noPadding>
      <Card
        hoverable
        className="small-card-body-padding"
        title={<b>{academiaDeportiva.nombre.toUpperCase()}</b>}
        style={{
          backgroundColor: "transparent",
        }}
        onClick={() =>
          window.open(`/academia-deportiva/${academiaDeportiva.id}`, "_blank")
        }
        cover={
          <img
          style={{ aspectRatio: "1 / 1", overflow: "hidden",position:"relative" , minHeight:"100%", maxHeight: "100%", minWidth: "100%", maxWidth: "100%" }}
            alt="example"
            src={`${process.env.REACT_APP_API_URL}instituciones/images/${academiaDeportiva.fotos?.[0].id}`}
          />
        }
      >
        <Row gutter={AntdConsts.rowGutter}>
          <Col {...AntdConsts.fullSizeColLayout}>{info}</Col>
          <Col {...AntdConsts.fullSizeColLayout}>
            <div style={{ marginBottom: "1em", fontSize: "1.2em" }}>
              <b>{"DEPORTES"}</b>
            </div>
            <List
              style={{
                width: "100%",
                marginTop: 2,
                height: "100%",
                minHeight: "150px",
                maxHeight: "200px",
                overflowY: "auto",
                overflowX: "hidden",
                padding: "1em",
              }}
              //header={<b style={{ paddingLeft: "1em" }}>{"DEPORTES"}</b>}
              dataSource={academiaDeportiva.deportes}
              grid={{
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
                xxl: 3,
              }}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Space direction="horizontal" style={{ padding: "0.5" }}>
                    <IconoTablas nombreIcono={item.nombre} />
                    <Typography.Text>
                      {item.nombre.toUpperCase()}
                    </Typography.Text>
                  </Space>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
    </CardContainer>
  );
};

export default AcademiaDeportivaSimpleView;
