import { Typography, Row, Form, Col, Checkbox } from "antd";
import SelectJornada from "../../../../../components/Input/SelectJornadas/SelectJornada";
import AntdConsts from "../../../../../designConstants/antd-constants";
import { NivelEducativo } from "../../../../../types/insEdu.types";
import { IdiomaEducativo } from "../../../../listaInsEscuelasIdiomas/types/SingleInstitucionEducativa.type";

interface NivelEducativoDetalleInputProps {
  nivelEducativoId: number;
  nivelesEducativos: NivelEducativo[];
  idiomasEducativos: IdiomaEducativo[];
}

const NivelEducativoDetalleInput = ({
  nivelEducativoId,
  nivelesEducativos,
  idiomasEducativos,
}: NivelEducativoDetalleInputProps) => {
  const nivelEducativo = nivelesEducativos.find(
    (n) => n.id === nivelEducativoId
  );

  if (!nivelEducativo) {
    return <></>; 
  }

  return (
    <div style={{ padding: "3em" }}>
      <Typography.Title level={4}>{nivelEducativo.nombre}</Typography.Title>
      <Row gutter={AntdConsts.rowGutter}>
        <Form.Item
          name={["nivelesEducativos", nivelEducativoId, "id"]}
          initialValue={nivelEducativoId}
          noStyle
        />
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            name={["nivelesEducativos", nivelEducativoId, "jornadaEscolarId"]}
            label="Tipo de jornada"
            rules={[{ required: true }]}
          >
            <SelectJornada />
          </Form.Item>
        </Col>
        <Col {...AntdConsts.twoColLayout}>
          <Form.Item
            valuePropName="checked"
            label=" "
            name={[
              "nivelesEducativos",
              nivelEducativoId,
              "opcionHorarioExtendido",
            ]}
            initialValue={false}
          >
            <Checkbox>Opción horario extendido</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div
        style={{
          borderStyle: "solid",
          borderColor: "var(--blush)",
          padding: "1em",
        }}
      >
        <Form.Item
          label="Idiomas en los que se imparte"
          name={["nivelesEducativos", nivelEducativoId, "idiomasEducativos"]}
          rules={[{ required: true }]}
        >
          <Checkbox.Group>
            <Row gutter={AntdConsts.rowGutter}>
              {idiomasEducativos?.map((i) => (
                <Col {...AntdConsts.threeColLayout}>
                  <Checkbox value={i.id}>{i.nombre}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </div>
    </div>
  );
};

export default NivelEducativoDetalleInput;
