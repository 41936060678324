import { Col, Row, Typography } from "antd";
import "../css/Banner.css";
const bannerL = require("../img/BannerImageLongLeft.png");
const bannerR = require("../img/BannerImageLongRight.png");

function MainBannerHome() {
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <img src={bannerL} alt="bannerImage" id="bannerImage" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <img src={bannerR} alt="bannerImage" id="bannerImage" />
        </Col>
      </Row>
      <Row>
        <Col xs={0} sm={0} md={0} lg={24} xl={24}>
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </Col>
      </Row>
    </>
  );
}

export default MainBannerHome;
