import { FormInstance } from "antd";
import { baseUrl } from "../../../commonRequests/axiosInstance";
import { transformUploadFilesToFormData } from "../../../designConstants/antd-utils";
import {
  EscuelaVerano,
  EscuelaVeranoCreateObject,
  EscuelaVeranoFormData,
} from "../../../types/escuelaVerano.type";

export const transformPayloadEscuelaVerano = (
  form: FormInstance<EscuelaVeranoFormData>
): EscuelaVeranoCreateObject => {
  const data: EscuelaVeranoFormData = form.getFieldsValue(true);

  const fotos = transformUploadFilesToFormData(form);

  return {
    nombre: data.nombre,
    paisId: data.paisId,
    regionId: data.regionId,
    correo: data.correo,
    comunaId: data.comunaId,
    direccion: data.direccion,
    descripcion: data.descripcion,
    jornadaEscolarId: data.jornadaEscolarId,
    telefono: data.telefono,
    paginaWeb: data.paginaWeb,
    instagram: data.instagram,
    facebook: data.facebook,
    whatsapp: data.whatsapp,
    twitter: data.twitter,
    videoCorporativo: data.videoCorporativo,
    modalidadEstudioId: data.modalidadEstudioId,
    precioMin: data.precioMin,
    precioMax: data.precioMax,
    edadMinima: data.edadMinima,
    edadMaxima: data.edadMaxima,
    fotos,
    actividades: data.actividades,
    esGuarderia: data.esGuarderia,
  };
};

export const escuelaVeranoToFormData = (
  academia: EscuelaVerano
): EscuelaVeranoFormData => {
  return {
    nombre: academia.nombre,
    paisId: academia.pais.id,
    regionId: academia.region.id,
    correo: academia.correo,
    comunaId: academia.comuna.id,
    direccion: academia.direccion,
    descripcion: academia.descripcion,
    jornadaEscolarId: academia.jornada.id,
    telefono: academia.telefono,
    paginaWeb: academia.paginaWeb,
    instagram: academia.instagram,
    facebook: academia.facebook,
    whatsapp: academia.whatsapp,
    twitter: academia.twitter,
    videoCorporativo: academia.videoCorporativo,
    modalidadEstudioId: academia.modalidad.id,
    precioMin: academia.precioMin,
    precioMax: academia.precioMax,
    edadMinima: academia.edadMinima,
    edadMaxima: academia.edadMaxima,
    actividades: academia.actividades,
    defaultImage: [
      {
        uid: "0",
        name: `default.png`,
        status: "done",
        response: academia?.fotos?.[0],
        url: `${baseUrl}instituciones/images/${academia?.fotos?.[0]?.id}`,
      },
    ],
    restOfImages: academia.fotos.slice(1).map((f, index) => ({
      uid: "rest" + index.toString(),
      name: `${index}.png`,
      status: "done",
      response: f,
      url: `${baseUrl}instituciones/images/${f?.id}`,
    })),
  };
};
