import { useRequest } from "ahooks";
import { Button, Col, Form, Input, Radio, Row } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import AdsRequests from "../../../commonRequests/adsRequests";
import { baseUrl } from "../../../commonRequests/axiosInstance";
import FileUpload from "../../../components/FileUpload";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../designConstants/antd-constants";
import { useAuthStorage } from "../../../hooks/useBrowserStorage";
import { AuthToken } from "../../../types/auth.types";
import AdsOcurrenceSelector from "./AdsOcurrenceSelector";

interface SubmitAdsFormProps {
  usedAdPercemtage: number;
  orientacion: "HORIZONTAL" | "VERTICAL";
  onCreate: Function;
  id?: number;
}

const SubmitAdsForm = ({
  usedAdPercemtage,
  orientacion,
  onCreate,
  id,
}: SubmitAdsFormProps) => {
  const authToken = useAuthStorage(true);

  const [usedPercentageState, setUsedPercentageState] =
    useState(usedAdPercemtage);

  const [form] = Form.useForm();

  const AdReq = useRequest(async () => {
    if (!id) {
      return;
    }
    const response = await AdsRequests.getSingle(id);
    if (!response) {
      return cogoToast.error("No fue posible obtener el anuncio");
    }

    setUsedPercentageState(usedPercentageState - response.porcentajeAparicion);

    form.setFieldsValue({
      ...response,
      image: [
        {
          uid: "1",
          name: `${response.id}.png`,
          status: "done",
          response: [{ id: response.image.id }],
          url: `${baseUrl}instituciones/images/${response.image.id}`,
          id: response.image.id,
        },
      ],
    });
  });

  if (AdReq.loading) {
    return <LoadingSpinner />;
  }

  const onSubmit = async () => {
    const data: any = form.getFieldsValue();
    let image = { id: data?.image?.file?.response?.id };
    if (!image) {
      image = { id: data.image[0].response[0].id };
    }

    data.image = image;

    if (id) {
      const response = await AdsRequests.update({ id, ...data });
      if (!response) {
        cogoToast.error("No fue posible editar el anuncio");
        return;
      }
      cogoToast.success("Anuncio editado correctamente");
      onCreate();
      window.location.reload();
      return;
    }

    const response = await AdsRequests.create(data);

    if (!response) {
      cogoToast.error("No fue posible crear el anuncio");
      return;
    }
    cogoToast.success("Anuncio creado correctamente");
    onCreate();
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onFinishFailed={() => {
          cogoToast.error("Existen errores en el formulario");
        }}
      >
        <Row gutter={AntdConsts.rowGutter}>
          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="nombreCliente"
              label="Nombre cliente"
              rules={[{ required: true }]}
            >
              <Input placeholder="Nombre cliente" />
            </Form.Item>
          </Col>

          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="telefonoCliente"
              label="Teléfono cliente"
              rules={[{ required: true }]}
            >
              <Input placeholder="Teléfono cliente" />
            </Form.Item>
          </Col>

          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="correoCliente"
              label="E-mail cliente"
              rules={[
                { required: true },
                { type: "email", message: "Email invalido" },
              ]}
            >
              <Input type="email" placeholder="E-mail cliente" />
            </Form.Item>
          </Col>

          <Col {...AntdConsts.twoColLayout}>
            <Form.Item
              name="rutCliente"
              label="Rut Cliente"
              rules={[{ required: true }]}
            >
              <Input placeholder="Rut Cliente" />
            </Form.Item>
          </Col>

          <Col {...AntdConsts.fullSizeColLayout}>
            <Form.Item
              name="orientacion"
              label="¿La imagen del anuncio es vertical u horizontal?"
              rules={[{ required: true }]}
              initialValue={orientacion}
            >
              <Radio.Group disabled>
                <Radio value={"HORIZONTAL"}>Horizontal</Radio>
                <Radio value={"VERTICAL"}>Vertical</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col {...AntdConsts.twoColLayout}></Col>
        </Row>

        <Form.Item
          name="link"
          label="Link al que se redirecionará al hacer click en la publicidad"
          rules={[{ required: true }]}
        >
          <Input placeholder="Link" />
        </Form.Item>

        <Form.Item
          name="image"
          label="Imagen del anuncio. Solo PNG, GIF o JPG. Al menos 300 x 600"
          rules={[{ required: true }]}
        >
          <FileUpload
            action={`${process.env.REACT_APP_API_URL}instituciones/images`}
            listType="picture-card"
            headers={{ Authorization: authToken as AuthToken }}
            maxCount={1}
            accept="image/png, image/gif, image/jpeg"
            defaultFileList={form.getFieldValue("image")}
          >
            <span>+ Agregar imagen</span>
          </FileUpload>
        </Form.Item>

        <Form.Item
          name="porcentajeAparicion"
          label="Porcentaje de aparición"
          rules={[{ required: true }]}
        >
          <AdsOcurrenceSelector usedPercentage={usedPercentageState} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {id ? "Editar anuncio" : "Crear anuncio"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SubmitAdsForm;
