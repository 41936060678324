import { useRequest } from "ahooks";
import { Col, Descriptions, PageHeader, Row, Table, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useNavigate, useParams } from "react-router-dom";
import VendedorRequest from "../../commonRequests/vendedores.req";
import CardContainer from "../../components/CardContainer";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../designConstants/antd-constants";
import useIsMobile from "../../hooks/useMobile";
import HeaderModern from "../../views/header/HeaderModern";

const DetallerVendedorPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const vendedoresReq = useRequest(async () => {
    const response = await VendedorRequest.getSingle(+(id as string));
    if (!response) {
      cogoToast.error("No fue posible obtener la lista de vendedores");
    }
    return response;
  });

  if (vendedoresReq.loading) {
    return <LoadingSpinner />;
  }

  if (!vendedoresReq.data) {
    return <>404</>;
  }

  const vendedor = vendedoresReq.data;

  const users = vendedor.usuariosAtraidos.sort(function (a, b) {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Nombre institución",
      dataIndex: "nombreInstitucion",
      key: "nombreInstitucion",
    },
    {
      title: "Rut Institución",
      dataIndex: "rutInstitucion",
      key: "rutInstitucion",
    },
    {
      title: "Fecha creación",
      dataIndex: "createdAt",
      key: "createdAt",
    },
  ];

  const dataSource = users.map((u) => {
    const createdAt = new Date(u.createdAt);
    return {
      ...u,
      createdAt: `${createdAt.getDate()}/${
        createdAt.getMonth() + 1
      }/${createdAt.getFullYear()}`,
    };
  });

  const description = (
    <Descriptions title="Información vendedor" column={1} bordered>
      <Descriptions.Item label="Nombre">
        {vendedor.nombre.toUpperCase()}
      </Descriptions.Item>
      <Descriptions.Item label="Rut">{vendedor.rut}</Descriptions.Item>
      <Descriptions.Item label="E-mail">{vendedor.correo}</Descriptions.Item>
      <Descriptions.Item label="Teléfono">
        {vendedor.telefono}
      </Descriptions.Item>
      <Descriptions.Item label="Región">
        {vendedor.region.nombre}
      </Descriptions.Item>
      <Descriptions.Item label="Comuna">
        {vendedor.comuna.nombre}
      </Descriptions.Item>
      <Descriptions.Item label="Dirección">
        {vendedor.direccion ? vendedor.direccion.toUpperCase() : "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Código de ventas">
        {vendedor.codigo}
      </Descriptions.Item>
    </Descriptions>
  );

  return (
    <>
      <HeaderModern />
      <div
        style={
          isMobile
            ? { padding: "1em" }
            : { paddingLeft: "10%", paddingRight: "10%" }
        }
      >
        <PageHeader
          className="site-page-header"
          onBack={() => navigate("/admin/vendedores")}
          title="Volver a administración de vendedores"
          subTitle={`Vendedor: ${vendedor.nombre.toUpperCase()}`}
        />
        <Row gutter={AntdConsts.rowGutter}>
          <Col {...AntdConsts.twoColLayout} style={{ display: "flex" }}>
            <CardContainer smallPadding>{description}</CardContainer>
          </Col>
          <Col {...AntdConsts.twoColLayout}>
            <CardContainer smallPadding>
              <Typography.Title level={3}>
                Usuarios atraidos al sistema
              </Typography.Title>
              <Table
                size="small"
                columns={columns}
                dataSource={dataSource}
                style={{ display: "flex" }}
              />
            </CardContainer>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DetallerVendedorPage;
