import { Divider, Dropdown, Menu, Row, Space } from "antd";
import { useAuthStorage } from "../../../hooks/useBrowserStorage";
import useIsMobile from "../../../hooks/useMobile";
import { AuthTokenPayload } from "../../../types/auth.types";
import UserAvatar from "./UserAvatar";
import { MenuOutlined } from "@ant-design/icons";
import NotificationsIcon from "./NotificationsIcon";
import AntdConsts from "../../../designConstants/antd-constants";
import UbicacionButton from "./UbicacionButton";
import useNavigateScrollTop from "../../../hooks/useNavigateScrollTop";

interface HeaderButtonsProps {
  hideButtons?: boolean;
  askLocation?: boolean;
  onChangeLocation?: Function;
}

const HeaderButtons = ({
  hideButtons,
  askLocation,
  onChangeLocation,
}: HeaderButtonsProps) => {
  const authToken = useAuthStorage(true) as AuthTokenPayload | null;

  const isMobile = useIsMobile();

  const isLoggedIn = !!authToken;

  const navigate = useNavigateScrollTop();

  if (isLoggedIn) {
    return (
      <div style={{ float: isMobile ? "right" : undefined }}>
        <Space style={{ marginRight: "2em" }}>
          <UserAvatar style={isMobile ? { marginBottom: "1em" } : undefined} />
          <Divider type="vertical" />
          {!isMobile && (
            <UbicacionButton
              askLocation={askLocation}
              onChangeLocation={onChangeLocation}
            />
          )}
          {!isMobile && (
            <>
              <Divider type="vertical" />
              <NotificationsIcon />
            </>
          )}
        </Space>
      </div>
    );
  }

  if (hideButtons) {
    return <></>;
  }

  if (isMobile) {
    const mobileOptionsMenu = (
      <Menu>
        <Menu.Item onClick={() => navigate("/inicio-de-sesion")}>
          Iniciar sesión
        </Menu.Item>
        <Menu.Item onClick={() => navigate("/registro")}>Registrarse</Menu.Item>
        <Menu.Item onClick={() => navigate("/busqueda-por-nombre")}>
          Búsqueda por nombre
        </Menu.Item>
        <Menu.Item onClick={() => navigate("/busqueda-por-ubicacion")}>
          Búsqueda por ubicación
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        {/* //@ts-ignore */}
        <Dropdown overlay={mobileOptionsMenu} trigger={["click"]}>
          <div>
            <MenuOutlined
              style={{
                // translate: "(0,10)",
                fontSize: "2.2em",
                cursor: "pointer",
              }}
            />
          </div>
        </Dropdown>
      </>
    );
  }

  return (
    <>
      <Space size="middle">
        <UbicacionButton
          askLocation={askLocation}
          onChangeLocation={onChangeLocation}
        />
        <span>
          <a href="/inicio-de-sesion">Iniciar sesión</a>
          <span>&nbsp;/&nbsp;</span>
          <a href="/registro">Registrarse</a>
        </span>
      </Space>
    </>
  );
};

export default HeaderButtons;
