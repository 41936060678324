import { Tabs } from "antd";
import useIsMobile from "../../hooks/useMobile";
import { useUserPrivileges } from "../../hooks/useUserPrivileges";
import HeaderModern from "../../views/header/HeaderModern";
import SponsoredAdminTable from "../SponsoredAdminPage/SponsoredAdminPage";
import InstitucionesInNeedOfReviewTable from "./components/InstitucionesInNeedOfReviewTable";
import InstitucionesOcultasTable from "./components/InstitucionesOcultasTable";
import InstitucionesUsuarioTable from "./components/InstitucionesUsuarioTable";
import UsuariosTable from "./components/UsuariosTable";

const RegisteredUserAdminPage = () => {
  const userType = useUserPrivileges();
  const isMobile = useIsMobile();

  return (
    <>
      <HeaderModern affixTop />

      <div
        style={
          !isMobile
            ? { paddingLeft: "10%", paddingRight: "7%", marginTop: "1em" }
            : { paddingLeft: "1em", paddingRight: "1em", marginTop: "1em" }
        }
      >
        <Tabs defaultActiveKey="1">
          {userType === "ADMIN" && (
            <>
              <Tabs.TabPane tab="Instituciones por revisar" key="1">
                <InstitucionesInNeedOfReviewTable />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Instituciones ocultas" key="2">
                <InstitucionesOcultasTable />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Usuarios" key="3">
                <UsuariosTable />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Destacados" key="4">
                <SponsoredAdminTable />
              </Tabs.TabPane>
            </>
          )}
          <Tabs.TabPane tab="Instituciones propias" key="5">
            <InstitucionesUsuarioTable />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default RegisteredUserAdminPage;
