import { Button } from "antd"

const QuitarUbicacionButton = () => {
  const removeUbicacion = () => {
    localStorage.removeItem("REGION")
    localStorage.removeItem("COMUNA")
    localStorage.removeItem("DIRECCION")
    window.location.reload()
  }

  return (
    <Button type="link" onClick={removeUbicacion}>Buscar en todo el pais?</Button>
  )
}

export default QuitarUbicacionButton;