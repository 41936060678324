import { Grid } from "antd";

const useIsMobile = () => {
  const breakPoints = Grid.useBreakpoint();
  if (!breakPoints["xl"]) {
    return true;
  }
  return false;
};

export default useIsMobile;
