import axios, { HeadersDefaults } from "axios";
import cogoToast from "cogo-toast";
import { useAuthStorage } from "../hooks/useBrowserStorage";
import { AuthToken } from "../types/auth.types";


// eslint-disable-next-line react-hooks/rules-of-hooks
const getAuthToken = useAuthStorage;

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

interface CommonHeaderProperties extends HeadersDefaults {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

request.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
} as CommonHeaderProperties

request.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (!config.headers) {
    config.headers = {};
  }
  if (token) {
    config.headers.Authorization = token as AuthToken;
  }

  return config;
});

request.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 403) {
      cogoToast.error("Permisos insuficientes", { hideAfter: 5 });
    }
    return Promise.reject(error);
  }
);

export default request;
export const baseUrl = process.env.REACT_APP_API_URL;
