import { useRequest } from "ahooks";
import { Affix, Button, Col, Pagination, Row, Typography } from "antd";
import { useState } from "react";
import cogoToast from "cogo-toast";
import AntdConsts from "../../../../designConstants/antd-constants";
import AcademiaArtisticaSimpleView from "./AcademiaArtisticaSimpleView";
import CardLoading from "../../../../components/CardLoading";
import { requestManyAcademiaArtistica } from "../../../../commonRequests/academiaArtisticasRequests";
import { useCustomCompareEffect } from "use-custom-compare";
import useGeo from "../../../../hooks/useGeo";
import { FaArrowCircleLeft } from "react-icons/fa";
import {
  AcademiaArtisticaFilters,
  academiaArtisticaFiltersComparator,
} from "../../../../types/filtros.type";
import QuitarUbicacionButton from "../../../../components/QuitarUbicacionButton";
import Swal from "sweetalert2";
import PopUpBusquedas from "../../../../components/PopUpBusquedas";
import AdsBanner from "../../../../components/AdsBanner";

interface ListaAcademiaIdiomasPageProps {
  filters?: AcademiaArtisticaFilters;
  setFilters: (filters: AcademiaArtisticaFilters) => any;
}

const ListaAcademiaArtisticaPage = ({
  filters,
  setFilters,
}: ListaAcademiaIdiomasPageProps) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(filters?.take || 18);

  const geolocation = useGeo();

  const {
    data: manyAcademiaArtistica,
    loading,
    runAsync: loadMoreAcademiaArtistica,
  } = useRequest(
    async () => {
      const academiaArtisticaList = await requestManyAcademiaArtistica({
        ...filters,
        latitud: geolocation?.latitude,
        longitud: geolocation?.longitude,
      });
      if (academiaArtisticaList === null) {
        cogoToast.error("No se pudo cargar la información solicitada");
      }

      return academiaArtisticaList;
    },
    { manual: true }
  );

  useCustomCompareEffect(
    () => {
      loadMoreAcademiaArtistica();
    },
    [filters],
    (prevDeps, nextDeps) =>
      academiaArtisticaFiltersComparator(nextDeps[0], prevDeps[0])
  );

  const onPageChange = async (newPage: number, newPageSize: number) => {
    window.scrollTo(0, 0);
    setPage(newPage);
    setPageSize(pageSize);
    setFilters?.({
      ...filters,
      take: pageSize,
      skip: (page - 1) * pageSize,
    });
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <>
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
        <CardLoading />,
      </>
    );
  }

  if (!manyAcademiaArtistica) {
    return <div></div>;
  }

  if (manyAcademiaArtistica.items.length === 0) {
    { PopUpBusquedas() }
    return (
      <>
        <Row gutter={AntdConsts.rowGutter} justify="center">
          <Typography.Text>
            {window.scrollTo(0, 0)}
            No se encontraron Academias Artísticas.
          </Typography.Text>
        </Row>
      </>
    );
  }

  const cardSpans = { xs: 24, md: 24, lg: 12, xl: 12, xxl: 12 };

  return (
    <>
      <Row>
        <Col>
          <Button type="text" href="/" style={{ fontWeight: "bold" }}>
            <FaArrowCircleLeft />
            &nbsp;&nbsp;Volver a la Página Principal
          </Button>
        </Col>
      </Row>
      <Row gutter={AntdConsts.rowGutter} justify="center">
        {manyAcademiaArtistica.items.map((ad, index) => {
          const showAd = index % 4 === 0 && index !== 0;
          return (
            <>
              {showAd && (
                <Col {...AntdConsts.fullSizeColLayout}>
                  <AdsBanner />
                </Col>
              )}
              <Col {...cardSpans} style={{ display: "flex" }}>
                <AcademiaArtisticaSimpleView
                  key={ad.id}
                  academiaArtistica={ad}
                  geoLocation={geolocation}
                />
              </Col>
            </>
          )
        })}
      </Row>
      <Pagination
        style={{
          backgroundColor: "whitesmoke",
          padding: "1em",
          zIndex: 1000,
        }}
        onChange={onPageChange}
        total={manyAcademiaArtistica.total}
        responsive
        current={page}
      />
    </>
  );
};

export default ListaAcademiaArtisticaPage;
