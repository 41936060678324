import { useRequest } from "ahooks";
import { Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import { getTipoInsEdu } from "./selectTipoInsEduRequests";

interface SelectTipoInsEduProps extends  SelectProps {
  value?: number;
  onChange?: (newValue: any) => any;
  placeholder?: string;
  props?: SelectProps;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const SelectTipoInsEdu = ({
  value,
  onChange,
  placeholder,
  className,
  style,
  disabled,
  ...props
}: SelectTipoInsEduProps) => {
  const [internalValue, setInternalValue] = useState<number>();

  const { data: comunas, loading } = useRequest(async () => {
    return await getTipoInsEdu();
  });

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <Select
      style={style}
      className={className}
      disabled={loading || disabled}
      value={internalValue}
      onChange={onChange}
      placeholder={placeholder ? placeholder : "Tipo Establecimiento"}
      {...props}
    >
      {comunas?.map((c) => {
        return <Select.Option value={c.id}>{c.nombre}</Select.Option>;
      })}
    </Select>
  );
};

export default SelectTipoInsEdu;
