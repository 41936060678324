import { Collapse, Typography } from "antd";
import { CSSProperties } from "react";
import useIsMobile from "../hooks/useMobile";
import SearchBarHeader from "../views/header/components/SearchBarHeader";

interface BuscadorPorUbicacionMobileProps {
  style?: CSSProperties;
}

const BuscadorPorUbicacionMobile = ({
  style,
}: BuscadorPorUbicacionMobileProps) => {
  const isMobile = useIsMobile()

  if (!isMobile) {
    return <></>;
  }

  return (
    <div>
      <Collapse style={style}>
        <Collapse.Panel key="1" header={"Buscador"} >
          <SearchBarHeader/>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default BuscadorPorUbicacionMobile;
