import { Col, Row } from "antd";
import Text from "antd/lib/typography/Text";
import {
  TiSocialFacebookCircular,
  TiSocialLinkedinCircular,
  TiSocialTwitterCircular,
} from "react-icons/ti";
import "../css/Footer.css";

function Footer() {
  return (
    <>
      <footer className="site-footer" style={{ marginTop: 40 }}>
        <div className="container">
          <Row align="stretch">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <h6 className="AcercaNosotros">Acerca de Nosotros</h6>
              <p className="text-justify">
                Sabemos lo importante que es la adquisición de habilidades para
                el sano y completo desarrollo mental, intelectual y físico en
                los seres humanos. Para facilitar la búsqueda de
                establecimientos de educación que entregan estas habilidades,
                tanto en el ámbito de educación formal, informal, idiomas,
                deportivo y artístico, nace Buscaestudio.cl, una plataforma de
                fácil acceso en donde podrá realizar búsquedas para encontrar el
                mejor establecimiento o academia que necesite.
              </p>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingLeft: "20px" }}
            >
              <h6>Categorías:</h6>
              <ul className="footer-links">
                <li>
                  <a href="/institucion-educativa/busqueda?tipo_institucion=1">
                    Colegios
                  </a>
                </li>
                <li>
                  <a href="/institucion-educativa/busqueda?tipo_institucion=2">
                    Jardín Infantil
                  </a>
                </li>
                <li>
                  <a href="/institucion-educativa/busqueda?tipo_institucion=3">
                    Escuela de lenguaje
                  </a>
                </li>
                <li>
                  <a href="/institucion-educativa/busqueda?tipo_institucion=4">
                    Escuelas Libres
                  </a>
                </li>
                <li>
                  <a href="/institucion-educativa/busqueda?tipo_institucion=5">
                    Colegios Online
                  </a>
                </li>
                <li>
                  <a href="/academia-deportiva/busqueda?tipo_institucion=6">
                    Academias Deportivas
                  </a>
                </li>
              </ul>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingLeft: "20px" }}
            >
              <h6>&nbsp;</h6>
              <ul className="footer-links">
                <li>
                  <a href="/academia-idiomas/busqueda?tipo_institucion=7">
                    Instituto de Idiomas
                  </a>
                </li>
                <li>
                  <a href="/preuniversitario/busqueda?tipo_institucion=8">
                    Preuniversitario
                  </a>
                </li>
                <li>
                  <a href="/escuela-verano/busqueda">Escuela de Verano</a>
                </li>
                <li>
                  <a href="#">Guardería Infantil</a>
                </li>
                <li>
                  <a href="/academia-artistica/busqueda">
                    Academias Artísticas
                  </a>
                </li>
                <li>
                  <a href="/reforzamiento/busqueda">Reforzamiento</a>
                </li>
              </ul>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingLeft: "20px" }}
            >
              <h6>Links</h6>
              <ul className="footer-links">
                {/* 
                <li>
                  <a href="#">Quienes Somos</a>
                </li> 
                */}
                <li>
                  <a href="mailto:contacto@buscaestudio.cl">Contacto</a>
                </li>
                <li>
                  <span>contacto@buscaestudio.cl</span>
                </li>
                <li>
                  <a href="/terminos-y-condiciones">Términos y Condiciones</a>
                </li>
              </ul>
            </Col>
          </Row>
          <hr />
        </div>
        <div className="container">
          <Row  align="stretch">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <p className="copyright-text" style={{ paddingLeft: "20px" }}>
                Copyright &copy; 2022 Todos los derechos reservados a &nbsp;
                <a href="#">Buscaestudio.cl</a>&nbsp; un sitio web de Byweb Chile SpA.
              </p>
            </Col>

            {/* <Col xs={6} sm={8} md={8} lg={8} xl={8}>
              <ul className="social-icons">
                <li>
                  <a className="facebook" href="#">
                    <i>
                      <TiSocialFacebookCircular fontSize={"2.5em"} />
                    </i>
                  </a>
                </li>
                <li>
                  <a className="twitter" href="#">
                    <i>
                      <TiSocialTwitterCircular fontSize={"2.5em"} />
                    </i>
                  </a>
                </li>
                <li>
                  <a className="linkedin" href="#">
                    <i>
                      <TiSocialLinkedinCircular fontSize={"2.5em"} />
                    </i>
                  </a>
                </li>
              </ul>
            </Col> */}
          </Row>
        </div>
      </footer>
    </>
  );
}

export default Footer;
