import { AxiosError } from "axios";
import {
  AuthToken,
  CreateUserPayload,
  LoginRequestResponse,
} from "../types/auth.types";
import request from "./axiosInstance";

export class WrongCredentialError extends Error {
  constructor() {
    super();
    this.message = `Wrong credentials`;
    this.name = "WrongCredentialError";
  }
}

export const loginRequest = async (username: string, password: string) => {
  try {
    const response = await request.post<LoginRequestResponse>("/auth/login", {
      username,
      password,
    });

    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      if ((err.response.status = 403)) {
        throw new WrongCredentialError();
      }
      return null;
    }
  }
};

export const registerUserRequest = async (payload: CreateUserPayload) => {
  try {
    const response = await request.post<AuthToken>("/auth/register", payload);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqVerifyEmail = async (code?: string) => {
  try {
    if (!code) {
      return false;
    }
    await request.patch<AuthToken>(`/auth/verify-email/${code}`);
    return true;
  } catch (error) {
    return false;
  }
};

export const reqCheckEmailUnique = async (email: string) => {
  try {
    const response = await request.post<AuthToken>(`/auth/check-email-unique`, {
      email,
    });
    return response.data;
  } catch (error) {
    return false;
  }
};

export const changeUserVerificationStatus = async (
  userId: number,
  verificado: boolean
) => {
  try {
    const response = await request.post<{ verificado: boolean }>(
      "/auth/change-user-status",
      {
        userId,
        verificado,
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqDeleteUser = async (userId: number) => {
  try {
    const response = await request.delete<{ verificado: boolean }>(
      `/auth/users/${userId}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqResendVerificationMail = async () => {
  try {
    const response = await request.get("/auth/resend-verification-email");
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 403) {
      return null;
    }
    return null;
  }
};

export const reqCheckEmailVerified = async (userId: number) => {
  try {
    const response = await request.get<boolean>(
      `/auth/check-mail-verified/${userId}`
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

const checkUserVerified = async (userId: number) => {
  try {
    const response = await request.get<boolean>(
      `/auth/check-user-verified/${userId}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const changePasswordPetition = async (email: string) => {
  try {
    const response = await request.post<boolean>(
      `/auth/request-password-reset`,
      { email }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const checkChangePasswordToken = async (token: string) => {
  try {
    const response = await request.post<boolean>(
      `/auth/check-change-password-token`,
      { token }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const resetPassword = async (token: string, newPassword: string) => {
  try {
    const response = await request.post(
      `/auth/reset-password`,
      { token, newPassword }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const AuthReqs = {
  reqResendVerificationMail,
  reqDeleteUser,
  changeUserVerificationStatus,
  reqCheckEmailUnique,
  reqVerifyEmail,
  registerUserRequest,
  loginRequest,
  reqCheckEmailVerified,
  checkUserVerified,
  changePasswordPetition,
  checkChangePasswordToken,
  resetPassword
};

export default AuthReqs;
