import { useDeepCompareEffect, useRequest } from "ahooks";
import { Form, DatePicker, Table, Button, Row, Col } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import VendedorRequest from "../../../commonRequests/vendedores.req";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../designConstants/antd-constants";
import { Vendedor, VendedorFilters } from "../../../types/vendedores.type";

const MejoresVendedoresTable = () => {
  const [filters, setFilters] = useState<VendedorFilters>({
    order_by: "cantidad_usuarios",
  });

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const vendedoresReq = useRequest(async () => {
    const response = await VendedorRequest.getMany(filters);
    if (!response) {
      cogoToast.error("No fue posible obtener la lista de vendedores");
      return [] as Vendedor[];
    }
    return response;
  });

  useDeepCompareEffect(() => {
    vendedoresReq.refreshAsync();
  }, [filters]);

  if (vendedoresReq.loading) {
    return <LoadingSpinner />;
  }

  const vendedorTableColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}}`),
      }),
    },
    {
      title: "Usuarios registrados",
      dataIndex: "usuariosCount",
      key: "usuariosCount",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}}`),
      }),
    },
    {
      title: "Rut",
      dataIndex: "rut",
      key: "rut",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}}`),
      }),
    },
    {
      title: "Telefono",
      dataIndex: "telefono",
      key: "telefono",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}}`),
      }),
    },
    {
      title: "Correo",
      dataIndex: "correo",
      key: "correo",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}}`),
      }),
    },
    {
      title: "Región",
      dataIndex: "region",
      key: "region",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}}`),
      }),
    },
    {
      title: "Comuna",
      dataIndex: "comuna",
      key: "comuna",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}}`),
      }),
    },
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
      onCell: (record: Vendedor) => ({
        onClick: () => navigate(`/admin/vendedores/${record.id}}`),
      }),
    },
  ];

  const dataSource = vendedoresReq.data?.map((v) => ({
    key: v.id,
    ...v,
    comuna: v.comuna.nombre,
    region: v.region.nombre,
    usuariosCount: v.usuariosAtraidos.length,
  }));

  const onSearch = (values: { dateRange: [Date, Date] }) => {
    const min_date = values.dateRange?.[0]?.toISOString();
    const max_date = values.dateRange?.[1]?.toISOString();
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onSearch}>
        <Row gutter={AntdConsts.rowGutter}>
          <Col>
            <Form.Item name="dateRange" label="Rango de fechas">
              <DatePicker.RangePicker />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={" "}>
              <Button type="primary" htmlType="submit">
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        style={{ cursor: "pointer" }}
        bordered
        size="small"
        columns={vendedorTableColumns}
        dataSource={dataSource as any}
      />
    </>
  );
};

export default MejoresVendedoresTable;
