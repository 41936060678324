import { AuthToken, AuthTokenPayload } from "../types/auth.types";
import jwt_decode from "jwt-decode";
import { useState } from "react";

/**
 * Gets data from local/session storage based on a key
 *
 * @param key - browser storage key
 * @param storage - storage to look up first
 */
const useBrowserStorage = (key: string, storage?: "local" | "session") => {
  const storageToUse = storage === "session" ? sessionStorage : localStorage;
  let value = storageToUse.getItem(key);
  if (!value) {
    if (storage === "session") {
      value = localStorage.getItem(key);
    } else {
      value = sessionStorage.getItem(key);
    }
  }

  return value;
};

/**
 * Like useStorage but default value is read from localStorage
 * setState also syncs localStorage value
 * Only accepts calues of type string
 *
 * @param storageKey
 * @returns
 */
export const useStateLocalStorage = (storageKey: string) => {
  const [state, setState] = useState<string | null>(
    localStorage.getItem(storageKey)
  );

  const setStateSyncLocalStorage = (newState: string | null) => {
    setState(newState);
    if (!newState) {
      localStorage.removeItem(storageKey);
    } else {
      localStorage.setItem(storageKey, newState);
    }
  };

  return [state, setStateSyncLocalStorage] as [
    string | null,
    (newState: string | null) => void
  ];
};

/**
 * returna auth token as string or json
 *
 * @param json - if true returns json instead of jwt
 * @returns
 */

export const useAuthStorage = (json?: boolean) => {
  const token = useBrowserStorage(
    process.env.REACT_APP_AUTH_TOKEN_NAME as string
  ) as AuthToken;
  if (!token) {
    return null;
  }
  if (json) {
    try {
      return jwt_decode(token) as AuthTokenPayload;
    } catch (error) {
      localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
      sessionStorage.removeItem(
        process.env.REACT_APP_AUTH_TOKEN_NAME as string
      );
      return null;
    }
  }
  return token;
};

export default useBrowserStorage;
