import { User, UserFilters } from "../types/auth.types";
import { CommonFilters } from "../types/filtros.type";
import {
  CommonInstitucion,
  ContactoInstitucion,
  ContactoInstitucionCreateObject,
  CountResponse,
  SponsoredInstitucion,
  TipoInstitucionIds,
} from "../types/instituciones.types";
import request from "./axiosInstance";

interface GetInstitucionesUsuarioResponseBody {
  activas: CommonInstitucion[];
  enRevision: CommonInstitucion[];
  ocultas: CommonInstitucion[];
}
export const getInstitucionesUsuario = async (userId: number) => {
  try {
    const response = await request.get<GetInstitucionesUsuarioResponseBody>(
      `/admin/instituciones/${userId}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

/**
 * Obtiene una lista con todas las instituciones sin importar su tipo, no usa geocoding
 * Solo stan disponibles los filtros de CommonFilters
 * @param filters
 * @returns
 */
export const getInstitucionesComunes = async (filters: CommonFilters) => {
  try {
    const response = await request.post<{
      items: CommonInstitucion[];
      total: number;
    }>(`/admin/instituciones/review`, {
      ...filters,
      disable_pagination: true,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

interface CountInstitucionesARevisarResponseBody {
  institucionEducativa: number;
  academiaIdiomas: number;
  academiaDeportiva: number;
}
export const countInstitucionesARevisar = async () => {
  try {
    const response = await request.get<CountInstitucionesARevisarResponseBody>(
      "/admin/instituciones/review/count"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getUsuarios = async (filters?: UserFilters) => {
  try {
    const response = await request.get<{ total: number; items: User[] }>(
      "/admin/usuarios",
      {
        params: filters,
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqCountUsuariosPorRevisar = async () => {
  try {
    const response = await request.get<CountResponse>(
      "/admin/usuarios/review/count"
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqGetSingleUser = async (id: number) => {
  try {
    const response = await request.get<User>(`/admin/usuarios/${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqCreateSolicitudContacto = async (
  data: ContactoInstitucionCreateObject,
  tipoInstitucionId: TipoInstitucionIds,
  idInstitucion: number
) => {
  try {
    const response = await request.post<ContactoInstitucion>(
      "/admin/contacto-institucion",
      {
        data,
        tipoInstitucionId,
        idInstitucion,
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqGetponsored = async (
  tipoInstitucionId?: TipoInstitucionIds,
  comunaId?: number,
  regionId?: number,
  take?: number
) => {
  try {
    const response = await request.post<SponsoredInstitucion[]>(
      `/admin/sponsored`,
      { tipoInstitucionId, comunaId, regionId, take }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const reqGetSponsoredList = async (data: CommonFilters) => {
  try {
    const response = await request.post<{
      items: SponsoredInstitucion[];
      total: number;
    }>(`/admin/get-instituciones`, data);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const UpdateSponsoredStatus = async (
  tipoInstitucionId: TipoInstitucionIds,
  id: number,
  isSponsored: boolean
) => {
  try {
    const response = await request.post<boolean>(
      `/admin/change-sponsor-state`,
      { tipoInstitucionId, id, isSponsored }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};
