import { Button, Col, Form, Input, Row, Typography } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import AuthReqs from "../../commonRequests/authRequests";
import CardContainer from "../../components/CardContainer";
import useNavigateScrollTop from "../../hooks/useNavigateScrollTop";
import HeaderModern from "../header/HeaderModern";

const ForgotPasswordPage = () => {
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);

  const navigate = useNavigateScrollTop();

  const onSubmit = async (values: { email: string }) => {
    try {
      await AuthReqs.changePasswordPetition(values.email);
    } catch {}
    setDisableSubmitButton(true)
    cogoToast.info(
      "Si su correo aparece en nuestros registros recibirá un correo para reestablecer su contraseña"
    );
  };

  return (
    <>
      <HeaderModern hideSearchbar />
      <Row justify="center" style={{ marginTop: "2em" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <CardContainer>
            <Typography.Title level={2}>
              Reestablecimiento de contraseña
            </Typography.Title>
            <Form layout="vertical" onFinish={onSubmit}>
              <Form.Item
                name="email"
                label="Por favor ingrese su correo electrónico, si este se encuentra en nuestros registros recibirá un correo de restablecimiento de contraseña"
                rules={[
                  { required: true, message: "Campo obligatorio" },
                  {
                    type: "email",
                    message: "Correo invalido",
                  },
                ]}
              >
                <Input type="email" placeholder="Correo electrónico" />
              </Form.Item>
              <Form.Item>
                {!disableSubmitButton && (
                  <Button block type="primary" htmlType="submit">
                    Enviar
                  </Button>
                )}
                {disableSubmitButton && (
                  <Button block onClick={() => navigate("/")}>
                    Volver al inicio
                  </Button>
                )}
              </Form.Item>
            </Form>
          </CardContainer>
        </Col>
      </Row>
    </>
  );
};

export default ForgotPasswordPage;
