import { Col, Row } from "antd";
import HeaderModern from "../header/HeaderModern";
import "./css/registro.css";
import FormularioInicio from "./FormularioInicio";
// const logo = require("../header/img/logoGeneric.svg");
import logo from "../header/img/logoGeneric.png";

import images from "./img/loginPic.png";
import useIsMobile from "../../hooks/useMobile";
import AntdConsts from "../../designConstants/antd-constants";

function InicioDeSesion() {
  const isMobile = useIsMobile();

  return (
    <div className="background-login-page">
      <HeaderModern transparent hideButtons hideSearchbar />
      <Row align="top" gutter={AntdConsts.rowGutter} style={{ padding: "2em" }}>
        <Col {...AntdConsts.RegLogImg} xs={0} md={0}>
          <img
            height={"100%"}
            width={"auto"}
            src={images}
            alt="estudiantes en diversos contextos"
            className="imgLogin"
          />
        </Col>
        <Col
          {...AntdConsts.RegLogCol}
          {...AntdConsts.fullSizeColOnMobileLayout}
          className="form-login"
          style={isMobile ? { padding: "1em"} : undefined}
        >
          <img
            src={logo}
            className="App-logo2"
            alt="logo"
            style={{ transform: "scale(0.7)" }}
          />
          <div>
            <FormularioInicio />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default InicioDeSesion;
