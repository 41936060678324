import { useRequest } from "ahooks";
import { ConfigProvider, Form } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  reqCreatePreuniversitrario,
  requestSinglePreuniversitrario,
  reqUpdatePreuniversitario,
} from "../../../commonRequests/preuniversitarioRequests";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import HeaderModern from "../../header/HeaderModern";
import PreuniversitarioFormStepTwo from "./steps/PreuniversitarioFormStepTwo";
import PreuniversitarioStepOne from "./steps/PreuniversitarioStepOne";
import AcademiaArtisticaFinalStep from "./steps/FinalStep";
import {
  preiuniversitarioToFormData,
  formDataToPreuniversitarioCreateObject,
} from "./transformers";
import useIsMobile from "../../../hooks/useMobile";

const SubmitPreuniversitario = () => {
  // Solo habrá id si es una edición
  const { id } = useParams();

  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [createdId, setCreatedId] = useState<number>();

  const isMobile = useIsMobile()

  const preuniverisitarioReq = useRequest(async () => {
    if (!id) {
      return;
    }
    const response = await requestSinglePreuniversitrario(+id);

    if (!response) {
      return;
    }
    const formData = preiuniversitarioToFormData(response);
    form.setFieldsValue(formData);
  });

  const onNextStep = () => {window.scrollTo(0, 0); setCurrentStep(currentStep + 1)};
  const onBackStep = () => setCurrentStep(currentStep - 1);

  const onEdit = async () => {
    const data = formDataToPreuniversitarioCreateObject(form);
    const response = await reqUpdatePreuniversitario({
      ...data,
      id: +(id as string),
    });
    if (!response) {
      cogoToast.error("No fue posible editar la institución");
    } else {
      setCreatedId(response.id);
      setCurrentStep(2);
      cogoToast.success("Institución editada correctamente");
    }
  };

  const onSubmit = async () => {
    if (id) {
      return onEdit();
    }

    const data = formDataToPreuniversitarioCreateObject(form);
    const response = await reqCreatePreuniversitrario(data);
    if (!response) {
      cogoToast.error("No fue posible crear la Institución");
    } else {
      setCreatedId(response.id);
      setCurrentStep(2);
      cogoToast.success("Institución creada correctamente");
    }
  };

  if (preuniverisitarioReq.loading) {
    return <LoadingSpinner />;
  }

  const onFormError = (values: any) => {
    cogoToast.error("Existen errores en el formulario");
  };

  const steps = [
    {
      step: 0,
      component: <PreuniversitarioStepOne form={form} onNext={onNextStep} />,
    },
    {
      step: 1,
      component: (
        <PreuniversitarioFormStepTwo onNext={onNextStep} onBack={onBackStep} />
      ),
    },
    {
      step: 2,
      component: <AcademiaArtisticaFinalStep id={createdId as number} />,
    },
  ];

  return (
    <>
      <HeaderModern affixTop />

      <div style={isMobile ?  { paddingLeft: "1em", paddingRight: "1em" } : { paddingLeft: "15%", paddingRight: "15%" }}>
        <ConfigProvider
          componentSize="large"
          form={{ validateMessages: { required: "Campo obligatorio" } }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={onFormError}
          >
            <Form.Item name="isReforzamiento" noStyle initialValue={window.location.href.includes("reforzamiento")}/>
            {steps.find((step) => step.step === currentStep)?.component}
          </Form>
        </ConfigProvider>
      </div>
    </>
  );
};

export default SubmitPreuniversitario;
