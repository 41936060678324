import { Button, Modal, Popconfirm, Space } from "antd";
import cogoToast from "cogo-toast";
import { useState } from "react";
import AdsRequests from "../../../commonRequests/adsRequests";
import { Anuncio } from "../../../types/ads.types";
import SubmitAdsForm from "./SubmitAdsForm";

const ActionAdButton = ({
  anuncio,
  onDelete,
}: {
  anuncio: Anuncio;
  onDelete?: Function;
}) => {
  const [eliminado, setEliminado] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [porcentajeUtilizado, setPorcentajeUtilizado] = useState<number>();

  const onClickDelete = async () => {
    const response = await AdsRequests.deleteAnuncio(anuncio.id);
    if (!response) {
      cogoToast.error("No fue posible eliminar el anuncio");
    }
    cogoToast.success("Anuncio eliminado correctamente");
    setEliminado(true);
    onDelete?.();
  };

  const onClickUpdate = async () => {
    const allAnuncios = await AdsRequests.getAll();

    if (!allAnuncios) {
      cogoToast.error("No fue posible editar el anuncio");
      return;
    }

    const adsVerticales = allAnuncios.filter(
      (ad) => ad.orientacion === "VERTICAL"
    );

    const adsHorizontales = allAnuncios.filter(
      (ad) => ad.orientacion === "HORIZONTAL"
    );

    let porcentajeUtilizadoAnuncioVertical = 0;
    for (const ad of adsVerticales) {
      porcentajeUtilizadoAnuncioVertical += ad.porcentajeAparicion;
    }

    let porcentajeUtilizadoAnuncioHorizontal = 0;
    for (const ad of adsHorizontales) {
      porcentajeUtilizadoAnuncioHorizontal += ad.porcentajeAparicion;
    }

    setPorcentajeUtilizado(
      anuncio.orientacion === "HORIZONTAL"
        ? porcentajeUtilizadoAnuncioHorizontal
        : porcentajeUtilizadoAnuncioVertical
    );

    setEditModalOpen(true);
  };

  if (eliminado) {
    return <b>ANUNCIO ELIMINADO</b>;
  }

  return (
    <>
      <Space>
        <Popconfirm
          title="Eliminar anuncio?"
          okText="Confirmar"
          cancelText="Cancelar"
          onConfirm={onClickDelete}
        >
          <Button type="primary">Eliminar</Button>
        </Popconfirm>
        <Button type="default" onClick={onClickUpdate}>
          Editar
        </Button>
      </Space>
      <Modal
        visible={editModalOpen}
        footer={null}
        onCancel={() => setEditModalOpen(false)}
        width={"90vw"}
        title="Edición de anuncio"
      >
        <SubmitAdsForm
          id={anuncio.id}
          onCreate={onClickUpdate}
          usedAdPercemtage={porcentajeUtilizado as number}
          orientacion={anuncio.orientacion}
        />
        <Button type="default" block onClick={() => setEditModalOpen(false)}>
          Cancelar
        </Button>
      </Modal>
    </>
  );
};

export default ActionAdButton;
