import { Col, Descriptions, Row, Space } from "antd";
import FlagsViews from "../../../components/FlagsViews/FlagsViews";
import currencyFormatter from "../../../utils";
import SingleInstitucionEducativa from "../types/SingleInsEdu.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBowlFood,
  faBus,
  faChalkboardTeacher,
  faChildren,
  faClock,
  faComments,
  faDollarSign,
  faDrum,
  faPeopleGroup,
  faPrayingHands,
  faSchool,
  faTshirt,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import AntdConsts from "../../../designConstants/antd-constants";

interface TestProps {
  insEdu: SingleInstitucionEducativa;
  className?: string;
}

const InsEduCaracteristicas = ({ insEdu, className }: TestProps) => {
  const tieneTransporteEscolar = !!insEdu.caracteristicasExtra.find(
    (c) => c.nombre === "Transporte escolar"
  );

  const tieneUniformePropio = !!insEdu.caracteristicasExtra.find(
    (c) => c.nombre === "Uniforme Propio"
  );

  const tieneComedor = !!insEdu.caracteristicasExtra.find(
    (c) => c.nombre === "Comedor"
  );

  const joranadasEscolares = [
    ...new Set(insEdu.niveles?.map((n) => n.jornada?.nombre)),
  ];

  return (
    <>
      <Row className={className} justify="space-around">
        <Col {...AntdConsts.twoColLayout}>
          <Descriptions
            labelStyle={{ backgroundColor: "var(--labelsDescription)" }}
            contentStyle={{ backgroundColor: "white" }}
            style={{ color: "black" }}
            title="CARACTERÍSTICAS"
            bordered
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            size="default"
          >
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faSchool}
                  />
                  {"  "}
                  Dependencia
                </b>
              }
            >
              {insEdu?.dependencia.nombre}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faDrum}
                  />
                  {"  "}
                  Extraprogramáticas
                </b>
              }
            >
              {insEdu?.tieneActividadesExtraprogramaticas ? "Si" : "No"}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faChildren}
                  />
                  {"  "}
                  Género
                </b>
              }
            >
              {insEdu?.generoAlumnos.nombre}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faComments}
                  />
                  {"  "}
                  Idiomas
                </b>
              }
            >
              <>
                {
                  insEdu.idiomasEducativos.map((i) => (
                    <Space key={i.id} direction="horizontal" style={{marginTop: "0.3em"}}>
                      <FlagsViews
                        flagsIso639Codes={[i.iso639_2_code]}
                      />
                      <span>{i.nombre}</span>
                    </Space>
                  )
                  )
                }
              </>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faChalkboardTeacher}
                  />
                  {"  "}
                  Niveles
                </b>
              }
            >
              {`${insEdu?.nivelMenorNombre} - ${insEdu?.nivelMayorNombre}`}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faBowlFood}
                  />
                  {"  "}
                  Comedor
                </b>
              }
            >
              {tieneComedor ? "Sí" : "No"}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faUserGroup}
                  />
                  {"  "}
                  Alumnos por curso
                </b>
              }
            >
              {insEdu.cantAlumnosPorCurso}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col {...AntdConsts.twoColLayout}>
          <Descriptions
            labelStyle={{ backgroundColor: "var(--labelsDescription)" }}
            contentStyle={{ backgroundColor: "white" }}
            style={{ color: "black" }}
            title={"‎"}
            bordered
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            size="default"
          >
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faBus}
                  />
                  {"  "}
                  Transporte Escolar
                </b>
              }
            >
              {tieneTransporteEscolar ? "Sí" : "No"}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faPeopleGroup}
                  />
                  {"  "}
                  Matriculados
                </b>
              }
            >
              {insEdu.totalMatriculados}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faTshirt}
                  />
                  {"  "}
                  Uniforme propio
                </b>
              }
            >
              {tieneUniformePropio ? "Sí" : "No"}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faPrayingHands}
                  />
                  {"  "}
                  Orientación Religiosa
                </b>
              }
            >
              {insEdu.orientacionReligiosa.nombre}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faDollarSign}
                  />
                  {"     "}
                  Precio
                </b>
              }
            >{`${currencyFormatter.format(
              insEdu?.rangoCostosMensuales[0] as number
            )} - ${currencyFormatter.format(
              insEdu?.rangoCostosMensuales[1] as number
            )}`}</Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faClock}
                  />
                  {"  "}
                  Jornada
                </b>
              }
            >
              {joranadasEscolares.join(" - ")}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <b>
                  <FontAwesomeIcon
                    style={{ color: "#1f3de7", fontSize: "25px" }}
                    icon={faChalkboardTeacher}
                  />
                  {"  "}
                  Modalidad
                </b>
              }
            >
              {insEdu.modalidad.nombre}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};

export default InsEduCaracteristicas;
