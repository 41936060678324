import { useRequest } from "ahooks";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import AntdConsts from "../../../../designConstants/antd-constants";
import { reqInstrumentos } from "../../../../commonRequests/academiaArtisticasRequests";
import TextArea from "antd/lib/input/TextArea";

const InstrumentosSelectorInput = () => {
  const { data: artes, loading } = useRequest(reqInstrumentos);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Typography.Title level={3}>Instrumentos (Opcional)</Typography.Title>
      <Form.Item
        name="instrumentos"
        label="Seleccione los instrumentos instruidos por su institución"
      >
        <Checkbox.Group>
          <Row gutter={AntdConsts.rowGutter}>
            {artes?.map((d) => (
              <Col {...AntdConsts.fourColLayout}>
                <Checkbox value={d.id}>{d.nombre}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        name={"instrumentosCustom"}
        label={"Instrumentos faltantes (uno por linea)"}
      >
        <TextArea rows={4} />
      </Form.Item>
    </>
  );
};

export default InstrumentosSelectorInput;
