import AliceCarousel from "react-alice-carousel";
import "./SponsoredCarousel.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { Card, Col, Row, Space, Typography } from "antd";
import { useRequest } from "ahooks";
import { reqGetponsored } from "../../commonRequests/adminRequests";
import { useStateLocalStorage } from "../../hooks/useBrowserStorage";
import {
  TipoInstitucionIds,
  SponsoredInstitucion,
  goToInstitucion,
} from "../../types/instituciones.types";
import { FaDirections, FaFire, FaMapMarkedAlt } from "react-icons/fa";

interface SponsoredCarouselProps {
  tipoInstitucionId?: TipoInstitucionIds;
}

const SponsoredCarousel = ({ tipoInstitucionId }: SponsoredCarouselProps) => {
  const [comunaId] = useStateLocalStorage("COMUNA");
  const [regionId] = useStateLocalStorage("REGION");

  const sponsoredReq = useRequest(() =>
    reqGetponsored(
      tipoInstitucionId,
      comunaId ? +comunaId : undefined,
      regionId ? +regionId : undefined,
      10
    )
  );

  if (sponsoredReq.loading) {
    return <></>;
  }

  if (!sponsoredReq.data) {
    return <></>;
  }

  let sponsored: SponsoredInstitucion[] | undefined;

  sponsored = sponsoredReq.data;

  if (!sponsored || sponsored.length === 0) {
    return <></>;
  }

  const items = sponsored.map((s) => (
    // <CardContainer noPadding style={{ width: undefined }}>
    <Card
      className="small-card-body-padding"
      hoverable
      onClick={() => goToInstitucion(s)}
      style={{ maxWidth: 240, marginLeft: "1em", minHeight: "270px" }}
      cover={
        <img
          alt="example"
          style={{ minHeight: "190px", maxHeight: "190px" }}
          src={`${process.env.REACT_APP_API_URL}instituciones/images/${s.fotos?.[0].id}`}
        />
      }
    >
      <Space direction="vertical">
        <Typography.Text style={{ color: "GrayText" }} strong>
          {s.nombre}
        </Typography.Text>
        <Space style={{ marginLeft: "1em" }}>
          <FaDirections />
          <Typography.Text style={{ color: "GrayText" }}>
            {s.direccion}
          </Typography.Text>
        </Space>
      </Space>
      <Space style={{ marginLeft: "1em" }}>
        <FaMapMarkedAlt />
        <Typography.Text style={{ color: "GrayText" }}>
          {s.comuna.nombre}
        </Typography.Text>
      </Space>
    </Card>
    // </CardContainer>
  ));

  return (
    <>
      <div
        // className="alice-carousel"
        style={{
          width: "100%",
          marginTop: "2em",
          paddingLeft: "2em",
          paddingRight: "2em",
          marginBottom: "1em",
        }}
      >
        <Space direction="horizontal">
          <FaFire color="blue" size="1.7em" />
          <Typography.Title level={5}>Lo más destacado</Typography.Title>
        </Space>
        {/* <Divider /> */}
      </div>
      <AliceCarousel
        disableButtonsControls
        autoWidth
        mouseTracking
        paddingRight={400}
        items={items}
      />
    </>
  );
};

export default SponsoredCarousel;
